import React, { useState, useEffect } from "react";
import { Card, Col, Modal, Typography } from "antd";
import ReactApexChart from "react-apexcharts";
import { Button } from "react-bootstrap";
import Theme from "../../constants/Theme";
import { useSelector } from "react-redux";
const { Text } = Typography;

const ChartComponent = ({ chartData, section, showY }) => {
  const { Theme } = useSelector((state) => state.auth);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [color, setColor] = useState(Theme.PRIMARY);
  const [chartDimensions, setChartDimensions] = useState({
    width: "100%",
    height: 300,
  });

  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const tooltipFormatter = (value) => {
    return `${Math.round(value)}°`;
  };

  useEffect(() => {
    if (section.title == "Shoulder Movement") {
      setColor(Theme.SECONDARY);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth > 768 ? "75%" : "90%";
      const newHeight = "50%";

      setChartDimensions({ width: newWidth, height: newHeight });
    };

    // Initial dimensions
    handleResize();

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const options = {
    chart: {
      id: "basic-line",
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false, // Disable zooming
      },
      selection: {
        enabled: false, // Disable selection
      },
      events: {
        dataPointSelection: false, // Disable data point selection
      },
    },
    xaxis: {
      categories: chartData.map((dataPoint, index) => index),
      labels: {
        show: false,
      },
    },
    yaxis: [
      {
        labels: {
          show: showY,
          formatter: (value) => `${value}°`,
          style: {
            fontSize: ".5vw", // Initial font size for Y-axis labels
          },
        },
      },
    ],
    tooltip: {
      enabled: false, // Disable tooltip
    },
    legend: {
      show: true,
      position: "top",
    },
    stroke: {
      width: 2, // Adjust this value to change the line width
      colors: [color],
    },
  };

  const options2 = {
    chart: {
      id: "basic-line",
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: chartData.map((dataPoint, index) => index),
      labels: {
        show: false,
      },
    },
    yaxis: [
      {
        labels: {
          formatter: (value) => `${value}°`,
          style: {
            fontSize: ".5vw", // Initial font size for Y-axis labels
          },
        },
      },
    ],
    tooltip: {
      y: {
        formatter: tooltipFormatter,
      },
    },
    legend: {
      show: true,
      position: "top",
    },
    stroke: {
      width: 2, // Adjust this value to change the line width
      colors: [color],
    },
  };

  const series = [
    {
      name: "Movement",
      data: chartData,
    },
  ];

  const chartContainerStyle = {
    position: "relative",
    alignSelf: "center",
    right: ".35vw",
    width: chartDimensions.width,
    height: chartDimensions.height,
  };

  const tooltipStyle = {
    fontSize: "10px", // Adjust the font size as needed
    padding: "5px", // Adjust the padding as needed
  };

  return (
    <Col align="middle" justify={"center"}>
      <div style={chartContainerStyle}>
        <ReactApexChart options={options} series={series} type="line" />
      </div>

      <Button
        style={{
          fontSize: ".55vw",
          fontWeight: "bold",
          position: "relative",
          bottom: 5,
          height: "1vw",
          width: "5vw",
          backgroundColor: "white",
          borderRadius: 10,
          borderWidth: 0,
          cursor: "pointer",
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.175)",
        }}
        onClick={handleModalOpen}
      >
        Expand
      </Button>

      <Modal
        // title={`${section.title}`}
        open={isModalVisible}
        width={"25vw"}
        bodyStyle={{ height: "25vw", padding: "1vw" }}
        onCancel={handleModalClose}
        footer={null}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <h1 style={{ color: Theme.TEXT }}>{section.title}</h1>
        <Text
          style={{ fontSize: ".75vw", marginBottom: 15, color: Theme.TEXT2 }}
        >
          {section.text}
        </Text>
        <div
          style={{
            width: "100%",
            position: "relative",
            right: ".9vw",
            top: "1vw",
          }}
        >
          <ReactApexChart
            options={options2}
            series={series}
            type="line"
            style={{ position: "relative" }}
          />
          <div className="apexcharts-tooltip" style={tooltipStyle}></div>
        </div>
      </Modal>
    </Col>
  );
};

export default ChartComponent;
