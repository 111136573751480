
export const FREE_CLIENTS = 5;
export const FREE_ASSESSMENTS = 10;



export const checkAssLimit = (
  subscriptionStatus,
  compedMembership,
  setReachedLimit,
  user
) => {
  if (
    (subscriptionStatus === false &&
      compedMembership === false &&
      user?.stats?.assessments_current_month < user?.profile?.assessment_cap) ||
    subscriptionStatus === true ||
    compedMembership === true
  ) {
    setReachedLimit(false);
  } else if (
    subscriptionStatus === false &&
    compedMembership === false &&
    user?.stats?.assessments_current_month >= user?.profile?.assessment_cap
  ) {
    setReachedLimit(true);
  }
};

export const checkClientLimit = (
  compedMembership,
  setReachedLimit,
  user
) => {
  if (
    user?.stats?.client_count < user?.stats?.client_limit + 1 || compedMembership
  ) {
    setReachedLimit(false);
  } else {
    setReachedLimit(true);
  }
};


export const checkTrainerLimit = (
  setReachedLimit,
  user
) => {
  if (
    user?.stats?.trainer_count < user?.stats?.trainer_limit
  ) {
    setReachedLimit(false);
  } else {
    setReachedLimit(true);
  }
};

export const checkTeamLimit = (setReachedLimit, user) => {

  if (user?.stats?.team_count < user?.stats?.team_limit) {
    setReachedLimit(false);
  } else {
    setReachedLimit(true);
  }
};


export const refreshVideo = (video, fetchAssessment, setPlayVideo) => {
  if (video === null) {
    fetchAssessment();
  } else if (video !== null) {
    setPlayVideo(true);
  }
};

export const openVideo = (
  setModalVisible,
  subscriptionStatus,
  compedMembership,
  video,
  isLoaded,
  vidsUnlocked,
  show,
  fetchAssessment,
  setPlayVideo
) => {
  setModalVisible(false);
  if (
    subscriptionStatus === false &&
    compedMembership === false &&
    video !== null &&
    isLoaded &&
    !vidsUnlocked
  ) {
    show();
  } else {
    setModalVisible(true);
    refreshVideo(video, fetchAssessment, setPlayVideo);
  }
};
