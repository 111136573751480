import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSingleAssessment,
  getAssessments,
} from "../redux/actions/assessments";
import {
  getJointComparisons
} from "../redux/actions/joint_comparisons"
import { Card, Col, Modal, Row, Typography } from "antd";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import AssignAssessments from "./AssignAssesments";
import { Button } from "react-bootstrap";
import { getWorkouts } from "../redux/actions/workouts";

const { Text } = Typography;

const AssessmentList = ({ clientID, header, goToResults, editAssessments }) => {
  const { assessments } = useSelector((state) => state.assessments);
  const { jointComparisons } = useSelector((state) => state.joint_comparisons);
  const dispatch = useDispatch();
  const { user, Theme } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.clients);
  const { id } = useParams();
  const [showAll, setShowAll] = useState(false);
  const [chooseModalVisible, setChooseModalVisible] = useState(false);
  const [assigned, setAssigned] = useState(false);
  const deleteAlert = (assessID) => {
    Modal.confirm({
      title: "Are You Sure You Want to Delete this Assessment?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => dispatch(deleteSingleAssessment(assessID, id)),
    });
  };

  useEffect(() => {
    dispatch(getAssessments(id));
    dispatch(getJointComparisons(id))
    dispatch(getWorkouts(client.id))
  }, []);

  const showModal = () => {
    setChooseModalVisible(true);
  };

  const handleOk = () => {
    setChooseModalVisible(false);
  };

  const handleCancel = () => {
    setChooseModalVisible(false);
  };
  const listOfMovements = (item) => {
    var array = [];
  if (item.comparison_names) {
    const processedNames = item.comparison_names.map((name) => {
      // Get the first word from the name
     
      if (name.includes('Thoracic')) {
        return "Trunk";
      }
      let firstWord = name.split("_")[0];
      return firstWord;
    });

    // Return unique values
    return [...new Set(processedNames)];
  }
    for (var [key, value] of Object.entries(item)) {
      if (
        key !== "created_on" &&
        key !== "id" &&
        key !== "overallScore" &&
        value !== null
      ) {
        if (key === "squat" && value !== null) {
          array.push("Squat");
        } else if (key === "standard_squat" && value !== null) {
          array.push("Squat");
        } else if (key === "posture" && value !== null) {
          array.push("Posture");
        } else if (key === "push" && value !== null) {
          array.push("Push");
        } else if (key === "r_balance" && value !== null) {
          array.push("Balance");
        } else if (key === "r_lunge" && value !== null) {
          array.push("Lunge");
        } else if (key === "r_lat_lunge" && value !== null) {
          array.push("Lunge");
        } else if (key === "r_rdl" && value !== null) {
          array.push("Hinge");
        } else if (key === "hinge" && value !== null) {
          array.push("Hinge");
        } else if (key === "free_record" && value !== null) {
          array.push("Free Record");
        } else if (key === "cmj" && value !== null) {
          array.push("Jump");
        }
      }
    }
    return array;
  };

  
  /*
  const sortedAssessments = assessments
    .filter((item) => item.overallScore !== null)
    .sort((a, b) => {
      const aDate = new Date(a.created_on);
      const bDate = new Date(b.created_on);
      return bDate - aDate;
    });
    */
  const filteredAssessments = assessments.filter(
    (item) => item.overallScore !== undefined && item.overallScore !== null
  );

  const sortedAssessments = [
    ...filteredAssessments,
    ...jointComparisons.map((item) => ({ ...item, isJointComparison: true }))
  ].sort((a, b) => {
    const aDate = new Date(a.created_on || a.date);
    const bDate = new Date(b.created_on || b.date);
    return bDate - aDate;
  });
  
  const displayedAssessments = showAll
    ? sortedAssessments
    : sortedAssessments.slice(0, 6);
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const flexDirection = isRowLayout ? "row" : "column";

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };
  const history = useHistory();
  const handleClick = (id, item) => {
    if(item.isJointComparison){
      return history.push(`/jcresults/${clientID}/${id}`);
    }
    return history.push(`/results/${clientID}/${id}`);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <Card
      style={{
        zIndex: 100,
        flex: 1,
        position: "relative",
        width: isRowLayout ? "38vw" : "55vw",
        minHeight: "15vw",
        right: isRowLayout ? ".75vw" : null,
        marginBottom: isRowLayout || assessments.length > 0 ? 10 : 50,
        background: Theme.CARD,
        borderWidth: 0,
      }}
      className="criclebox h-full"
    >
      <Modal
        open={chooseModalVisible}
        onCancel={handleCancel}
        width={"32vw"}
        bodyStyle={{ height: "34vw" }}
        footer={[null]}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <AssignAssessments
          setChooseModalVisible={setChooseModalVisible}
          clientID={clientID}
        />
      </Modal>
      <Button
        color={"PRIMARY"}
        style={{
          width: "5vw",
          position: "absolute",
          right: "1vw",
          height: "1.5vw",
          cursor: "pointer",
          zIndex: 999,
          top: "1.85vw",
          backgroundImage: "linear-gradient(60deg, #C04848 25%, #480048 120%)",
          borderRadius: 10,
          borderWidth: 0,
          // boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.3)", // Applying a box shadow all around
          opacity: 0.9,
          // Add the following properties to create a box shadow around all sides
          // boxShadow:
          //   "2px 2px 2px rgba(0, 0, 0, 0.2), -2px -2px 2px rgba(0, 0, 0, 0.2)",
        }}
        onClick={() => setChooseModalVisible(true)}
      >
        {assigned > 0 ? (
          <div style={{ flexDirection: "column" }}>
            <div style={{ flexDirection: "row" }}>
              <Text
                style={{
                  color: "white",
                  fontSize: ".75vw",
                  textAlign: "center",
                  opacity: 1,
                  fontWeight: "bold",
                }}
              >
                Assigned
              </Text>
            </div>
          </div>
        ) : (
          <div style={{ flexDirection: "column" }}>
            <div>
              <Text
                style={{
                  color: "white",
                  fontSize: ".75vw",
                  textAlign: "center",
                  opacity: 1,
                  fontWeight: "bold",
                }}
              >
                <div>
                  <span style={{ position: "relative" }}>Assign</span>
                </div>
              </Text>
            </div>
          </div>
        )}
      </Button>
      <Col
        justify={"center"}
        align={"center"}
        style={{
          zIndex: 100,
          flex: 1,
          position: "relative",
          width: "40vw",
          right: ".5vw",
          // marginLeft: 25,
          // left: "1vw",
          marginBottom: isRowLayout || assessments.length > 0 ? 10 : 100,
        }}
      >
        <Row justify={"center"} align={"center"} style={{ marginBottom: "2%" }}>
          <h3
            style={{
              // right: isRowLayout ? ".0vw" : "5vw",
              color: Theme.TEXT,
              fontSize: "1.1vw",
              position: "relative",
            }}
          >
            Assessments
          </h3>
        </Row>
        <Row style={{ position: "relative" }}>
          {sortedAssessments.length > 6 && (
            // <div style={{ position: "absolute", bottom: 10, left: 0 }}>
            <Text
              style={{
                fontSize: ".7vw",
                cursor: "pointer",
                color: Theme.PRIMARY,
                width: "4vw",
                bottom: ".75vw",
                right: isRowLayout ? "-3.5vw" : "6vw",
                position: "relative",
                // top: isRowLayout ? null : '.75vw',
              }}
              onClick={() => setShowAll((prevState) => !prevState)}
            >
              {showAll ? "Collapse" : "Show All"}
            </Text>
            // </div>
          )}
        </Row>

        <Row
          justify={"center"}
          // gutter={[18, 0]}
          style={{
            flexWrap: "wrap",
            position: "relative",
            bottom: "1vw",
            right: ".25vw",
            // right: "vw",
          }}
        >
          {sortedAssessments.length > 0 ? (
            displayedAssessments.map((item) => {
              const score = item.overallScore !== undefined ? item.overallScore : item.overall_score;
              const movements = listOfMovements(item);
              return (
                <Card
                  key={item.id}
                  onClick={() => handleClick(item.id, item)}
                  style={{
                    flex: "0 0 100%",
                    maxWidth: "8vw",
                    margin: "2% 0% auto 5%",
                    position: "relative",
                    right: "2%",
                    backgroundColor: "#FAFAFA",
                    borderRadius: 10,
                    cursor: "pointer",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)", // Adding a shadow effect
                    borderWidth: 0,
                    background: Theme.BACKGROUND
                    // maxHeight: "9vw",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",

                      position: "relative"
                    }}
                  >
                    <Col>
                      {user?.profile?.account_type === 1 && editAssessments && (
                        <Button
                          type="danger"
                          icon={<DeleteOutlined />}
                          style={{
                            position: "absolute",
                            top: "40%",
                            right: "-2%"
                          }}
                          onClick={() => deleteAlert(item.id)}
                        />
                      )}

                      <CircularProgressbar
                        styles={{
                          // Customize the root svg element

                          root: { height: "3.5vw", marginBottom: ".5vw" },
                          // Customize the path, i.e. the "completed progress"
                          path: {
                            // Path color
                            stroke:
                              score >= 80
                                ? Theme.GRAD6
                                : score >= 65
                                ? Theme.YELLOW
                                : Theme.RED,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",
                            // Customize transition animation
                            transition: "stroke-dashoffset 0.5s ease 0s",
                            // Rotate the path
                            transformOrigin: "center center"
                          },
                          // Customize the circle behind the path, i.e. the "total progress"
                          trail: {
                            // Trail color
                            stroke: Theme.WHITE,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",
                            // Rotate the trail
                            transformOrigin: "center center"
                          },
                          // Customize the text
                          text: {
                            // Text color
                            fill: Theme.TEXT,
                            // Text size
                            fontSize: "1.5vw"
                          },
                          // Customize background - only used when the `background` prop is true
                          background: {
                            fill: "#3e98c7"
                          }
                        }}
                        value={score?.toFixed(0)}
                        text={score?.toFixed(0)}
                      />
                      <div
                        onClick={() => goToResults(item)}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          alignItems: "center"
                        }}
                      >
                        <div style={{ flex: 1 }}>
                          <Text style={{ fontSize: "1vw" }}>{header}</Text>
                          <Text
                            style={{
                              fontSize: "0.75vw",
                              color: Theme.PRIMARY,
                              fontWeight: "500"
                            }}
                          >
                            {new Date(
                              item.created_on || item.date
                            ).toLocaleDateString()}
                          </Text>
                        </div>
                      </div>
                    </Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",

                        marginTop: "2%",
                        alignSelf: "center",
                        flex: 1
                      }}
                    >
                      {item.isJointComparison ? (
                        <Text
                          style={{
                            // fontWeight: "bold",
                            fontSize: ".75vw",
                            alignSelf: "center",
                            color: Theme.TEXT2
                          }}
                        >
                          Comparisons
                        </Text>
                      ) : (
                        <Text
                          style={{
                            // fontWeight: "bold",
                            fontSize: ".75vw",
                            alignSelf: "center",
                            color: Theme.TEXT2
                          }}
                        >
                          Movements
                        </Text>
                      )}
                      <Text
                        style={{
                          fontSize: "0.65vw",
                          fontWeight: "600",
                          color: Theme.PRIMARY,
                          width: "7vw",
                          alignSelf: "center",
                          position: "relative",
                          textAlign: "center"
                        }}
                      >
                        {movements.join(", ")}
                      </Text>
                      {movements.length < 4 && (
                        <div
                          style={{
                            fontSize: "0.6vw",
                            color: Theme.PRIMARY
                          }}
                        >
                          &nbsp;
                        </div>
                      )}
                    </div>
                  </div>
                </Card>
              );
            })
          ) : (
            <div
              style={{
                maxWidth: "90%",
                margin: "auto", // Use auto for horizontal centering

                borderRadius: 10,
                cursor: "pointer",
                display: "flex",
                position: "relative",
                flexDirection: "column",
                alignItems: "center",
                // left: isRowLayout ? "-1vw" : '1vw',
                // right: 0, // Set left and right to 0 for horizontal centering
                bottom: "1vw",
                transform: "translate(0, 50%)",
              }}
            >
              <SlidersOutlined
                style={{ color: Theme.PRIMARY, fontSize: "1.5vw" }}
              />
              <Text
                style={{ fontSize: "1.25vw", marginTop: 5, color: Theme.TEXT }}
              >
                No Data
              </Text>
              <Text
                style={{ fontSize: ".75vw", marginTop: "5", color: Theme.TEXT }}
              >
                Complete This {user?.profile?.client_label}'s First Assessment
              </Text>
            </div>
          )}
        </Row>
      </Col>
    </Card>
  );
};

export default AssessmentList;
