import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  Input,
  Button,
  Checkbox,
  Card,
  Row,
  Spin,
  Typography,
  Col,
} from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { IoPeopleOutline } from "react-icons/io5";
import BootstrapInput from "../common/Input";
import CustomSelect from "../common/DropDown";
import { load_user } from "../../redux/actions/auth";
import { createProfile, getProfiles, resetProfileStatus } from "../../redux/actions/clients";

const { Text } = Typography;

const CreateRangeProfileTemplate = ({ closeModal }) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const { user, Theme } = useSelector((state) => state.auth);

  const { profiles, profileStatus } = useSelector((state) => state.clients);

  const profileOptions = profiles.map(profile => ({
    value: profile.id, 
    label: profile.name 
  }));

  const [isLoading, setLoading] = useState(false);
  const history = useHistory();
  const styles = createStyles(Theme);

  const handleSubmit = async (values, { resetForm }) => {
    
    const selectedProfile = profiles.find(profile => profile.id === values.profile_id);
    const data = {
      name: values.name,
      range_profile: selectedProfile ? selectedProfile.range_profile : null,
    };

    try {
      const newProfile = await dispatch(createProfile(data));
      if (newProfile && newProfile.id) {
        history.push(`/templates/ranges/${newProfile.id}`);
      } else {
        console.error("Failed to create profile");
      }
  
      // Optionally reset the form after submission
      resetForm();
    } catch (error) {
      console.error("Error creating profile:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (profileStatus === 200) {
      setLoading(true);
      dispatch(getProfiles());
      
      dispatch(load_user());
      setTimeout(() => {
        history.push("/templates");
        setLoading(false);
        dispatch(resetProfileStatus());
        closeModal(false);
        formikRef.current?.resetForm();
      }, 250);
    }
  }, [profileStatus]);

  const getValidationSchema = () => {
    let schema = {
      name: yup.string().required("Required"),
      profile_id: yup.number().required("Required"),
    };


    return yup.object().shape(schema);
  };

  return (
    <Col
      bordered={false}
      style={{
        zIndex: 100,
        // flex: 1,
        position: "relative",
        background: 'transparent'
      }}
      className="criclebox h-full"
    >
     
        <div>
          <Formik
            initialValues={{
              name: "",
              profile_id: null,

            }}
            innerRef={formikRef}
            onSubmit={handleSubmit}
            context={{ accountType: user?.profile?.account_type }}
            validationSchema={getValidationSchema()}
          >
            {({ handleChange, handleSubmit, setFieldValue, values, errors, touched }) => (
              <Col align="center" style={styles.registerContainer}>
                <div style={styles.block}>
                  <div style={styles.title}>
                    <p style={styles.titleText}>Create Range Profile</p>
                  </div>
                  <Row justify={"center"} align={"middle"}>
                  <BootstrapInput
                    styles={styles}
                    value={values.name}
                    placeholder="Name"
                    onChange={handleChange("name")}
                    error={touched.name && errors.name}
                    Theme={Theme}
                    width={"15vw"}
                  />
                  </Row>
                  <p style={styles.smallText}>Select Existing Profile to Start From</p>

                  <Row justify={"center"} align={"middle"}>
                  <CustomSelect
                   style={{ width: "9vw"}}
                   showSearch
                   suffixIcon={null}
                   options={profileOptions}
                   placeholder={
                     <span
                       style={{
                         color:
                           Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                         fontSize: ".7vw",
                         top: ".05vw",
                         position: "relative",
                         fontWeight: "normal"
                       }}
                     >
                       Choose Profile
                     </span>
                   }
                  size={"large"}
                  lightModeBackground="#f1f1fb"
                  darkModeBackground="#000"
                  onChange={(value) => setFieldValue("profile_id", value)
                  }>                 
                  </CustomSelect>
                  </Row>
            

                  <Button
                    small
                    style={{
                      backgroundColor: Theme.BACKGROUND,
                      borderRadius: 5,
                      borderWidth: 0,
                      cursor: "pointer",
                      width: "10vw",
                      //  padding: "1.5%",
                      backgroundImage:
                        "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                      marginTop: "2vw",
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)",
                    }}
                    type="submit"
                    onClick={handleSubmit}

                  >
                    {!isLoading ?

                      <Text style={styles.buttontext}>Submit</Text>
                      : (
                        <Spin size="small" />
                      )}
                  </Button>

                  {profileStatus > 399 && (
                    <div
                      style={styles.row}

                    >
                      <p style={styles.errorText}>
                        Error creating range profile
                      </p>
                    </div>
                  )}
                </div>
              </Col>
            )}
          </Formik>
        </div>
    </Col>
  );
};

export default CreateRangeProfileTemplate;

const createStyles = (theme) => ({
  container: {
    backgroundColor: "#FFF",
    alignContent: "center",
  },
  title: {
    color: "#232b2b",
    fontSize: 18,

    bottom: 25,
    fontWeight: "600",
    alignSelf: "center",
  },
  input: {
    backgroundColor: theme.BLACK,
    borderBottomColor: "gray",
    borderColor: "white",
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
    margin: 10,
    width: "15vw",
    height: "2vw",
    fontSize: "1vw",
  },
  smallInput: {
    backgroundColor: theme.BACKGROUND,
    placeholder: {
      color: theme.TEXT,
    },
    // borderBottomColor: "black",
    // borderColor: "white",
    // borderWidth: 5,
    color: theme.TEXT,
    borderRadius: 10,
    padding: 10,
    margin: 10,
    width: "15vw",
    height: "2vw",
    fontSize: "1vw",
    ":focus": {
      borderColor: theme.WHITE, // Change the border color as needed
    },
  },
  checkboxText: {
    fontSize: ".75vw",
    width: "80%",
  },
  registerContainer: {
    backgroundColor: "transparent",
    borderRadius: 10,
    shadowColor: theme.BLACK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    justifyContent: "center",
    shadowRadius: 8,
    shadowOpacity: 0.3,
    elevation: 1,
    // height: "15vw",
    // width: "15vw",
    // marginTop: 10,
  },
  inputIcons: {
    marginRight: 12,
  },
  titleText: { fontSize: "1vw", color: theme.TEXT },
  smallText: { fontSize: "0.8vw", color: theme.TEXT },

  createButton: {
    width: "5vw",
    marginTop: "5%",
    backgroundImage: "linear-gradient(45deg, #C04848 0%, #480048 80%)",
    borderWidth: 0,
    borderRadius: 10,
    cursor: "pointer"
  },
  buttontext: {
    color: theme.WHITE,
    fontWeight: "bold",

    fontSize: ".75vw",

    // paddingHorizontal: 5,
  },
  errors: {
    position: "relative",
    // top: 55,
    color: "red",
    fontSize: ".75vw",
    // right: '25%'
    // alignSf: 'flex-start'
    // marginLeft: 12,
  },
  checkbox: {
    // top: 9,
    color: theme.PRIMARY,
    borderRadius: 5,
    width: 15,
    height: 15,
    position: "relative",
    right: "5%",
    top: "10%",
  },
  avatar: {
    height: 100,
    width: 100,
    borderRadius: 62,
    borderWidth: 0,
    alignSelf: "center",
    top: 20,
  },
  movementTitle: {
    alignSelf: "center",
    fontSize: "1.3vw",
    fontWeight: "bold",
    color: theme.TEXT,
    textAlign: "center",
  },
  clientDescr: {
    alignSelf: "center",
    fontSize: ".75vw",
    // fontWeight: "bold",
    color: theme.TEXT2,
    textAlign: "center",
  },
});
