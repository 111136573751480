import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { REACT_APP_API_URL } from "../env";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine
} from "recharts";
import { Card, Select, Spin, Col, Modal, Row, Typography } from "antd";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  LineChartOutlined
} from "@ant-design/icons";
import { getGroups } from "../redux/actions/groups";
import { mockComponent } from "react-dom/test-utils";
import CustomSelect from "./common/DropDown";

const { Text } = Typography;
const TrendReportJC = ({
  trend_reports,
  movements,
  title,
  chartColor,
  period,
  timeLoad
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [state, setState] = React.useState({});
  const [variation, setVariation] = useState(movements[0]);
  const [movement_prefix, setPrefix] = useState(null);
  const [loading, setLoading] = useState(false);
  const { Theme } = useSelector((state) => state.auth);
  const [dataKeys, setDataKeys] = useState({
    leftKey: "Left",
    rightKey: "Right"
  });

  const formatDates = (unformattedDates) => {
    var dates = [];
    if (period === "monthly") {
      dates = unformattedDates.map((item) => {
        const dateObject = new Date(item);
        const year = dateObject.getFullYear();
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ];
        const month = dateObject.getMonth() + 1;
        if (month === 12) {
          return months[0];
        } else {
          return months[dateObject.getMonth() + 1];
        }
      });
    } else if (period === "quarterly") {
      dates = unformattedDates.map((item) => item);
    } else if (period === "sixweeks") {
      dates = unformattedDates.map((item) => {
        const dateObject = new Date(item);
        const dateString = dateObject.toLocaleDateString();
        if (dateString[3] === "/") {
          return dateString.slice(0, 3);
        } else if (dateString[4] === "/") {
          return dateString.slice(0, 4);
        } else {
          return dateString.slice(0, 5);
        }
      });
    }
    return dates;
  };

  const mergeData = (leftData, rightData) => {
    const mergedData = [];

    const leftKey =
      variation === "cervical_flexion_extension" ? "Flexion" : "Left";
    const rightKey =
      variation === "cervical_flexion_extension" ? "Extension" : "Right";
    setDataKeys({ leftKey, rightKey });

    const leftDataMap = leftData
      ? new Map(leftData.map((item) => [item.date, item.Score]))
      : new Map();
    const rightDataMap = rightData
      ? new Map(rightData.map((item) => [item.date, item.Score]))
      : new Map();

    const allDates = Array.from(
      new Set([
        ...(leftData ? leftData.map((item) => item.date) : []),
        ...(rightData ? rightData.map((item) => item.date) : [])
      ])
    );

    allDates.forEach((date) => {
      mergedData.push({
        date,
        [leftKey]: leftDataMap.get(date) || null,
        [rightKey]: rightDataMap.get(date) || null
      });
    });

    return mergedData.length > 0 ? mergedData : null;
  };

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 500);

    // Determine which fields to merge based on availability
    const leftScore = trend_reports?.[variation]?.Left?.overall_score;
    const rightScore = trend_reports?.[variation]?.Right?.overall_score;
    const flexionScore = trend_reports?.[variation]?.Flexion?.overall_score;
    const extensionScore = trend_reports?.[variation]?.Extension?.overall_score;

    // Merge data based on availability of flexion and extension fields
    const mergedScore =
      flexionScore !== undefined && extensionScore !== undefined
        ? mergeData(flexionScore, extensionScore)
        : mergeData(leftScore, rightScore);

    setState((prevState) => ({
      ...prevState,
      movementSelected: mergedScore,
      unit: "Score",
      unit_symbol: "%"
    }));

    setPrefix("overall_score"); // Assuming this is set elsewhere in your component
  }, [variation, trend_reports]);

  function getUnits(value) {
    var unit = "";
    var unit_symbol = "";
    if (
      value == "raw_angle" ||
      value == "start_angle" ||
      value == "end_angle"
    ) {
      unit = "Score";
      unit_symbol = "°";
    } else {
      unit = "Score";
      unit_symbol = "%";
    }
    return [unit, unit_symbol];
  }

  function formatVariations(inputString) {
    const words = inputString.split("_");
    words.shift();
    return words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  function handleChangeMovement(value) {
    let [unit, unit_symbol] = getUnits(value);
    setState((prevState) => ({
      ...prevState,
      movementSelected: mergeData(
        trend_reports?.[variation]?.Left?.[value],
        trend_reports?.[variation]?.Right?.[value]
      ),
      //movementSelected: trend_reports[variation][value],
      unit: unit,
      unit_symbol: unit_symbol
    }));
  }

  function handleChangeVariation(value) {
    setLoading(true);
    setVariation(value);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };
  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Card
      style={{
        flex: 1,
        background: Theme.CARD,
        position: "relative",
        width: isRowLayout ? "25vw" : "55vw",
        minHeight: "20vw",
        maxHeight: "45vw",
        borderWidth: 0
      }}
      className="criclebox h-full"
    >
      <Row style={{ position: "relative", bottom: "1vw" }}>
        <h3
          style={{
            marginLeft: 0,
            fontSize: "1vw",
            marginBottom: "1vw",
            position: "relative",
            color: Theme.TEXT
          }}
        >
          {title}
        </h3>
        {trend_reports !== null && (
          <Col
            style={{
              width: "7vw",

              bottom: ".75vw",
              position: "relative",
              left: "45%",
              alignSelf: "flex-end"
            }}
          >
            <CustomSelect
              defaultValue={movements[0]}
              options={Object.keys(trend_reports).map((key) => ({
                value: key,
                label: formatVariations(key)
              }))}
              onChange={handleChangeVariation}
              width={"9vw"}
            />
          </Col>
        )}
    
      </Row>
      {trend_reports?.[variation] === undefined || (trend_reports?.[variation]?.Left !== undefined &&
        trend_reports?.[variation]?.Right !== undefined &&
        trend_reports?.[variation]?.Left === null &&
        trend_reports?.[variation]?.Right === null) ||
      (trend_reports?.[variation]?.Flexion !== undefined &&
        trend_reports?.[variation]?.Extension !== undefined &&
        trend_reports?.[variation]?.Flexion === null &&
        trend_reports?.[variation]?.Extension === null) ? (
        <div
          style={{
            maxWidth: "90%",
            margin: "auto", 
            borderRadius: 10,
            cursor: "pointer",
            display: "flex",
            position: "relative",
            flexDirection: "column",
            alignItems: "center",
            left: isRowLayout ? "0vw" : "1vw",
            right: 0, // Set left and right to 0 for horizontal centering
            bottom: "1vw",
            transform: isRowLayout
              ? "translate(0%, 50%)"
              : "translate(-2.5%, 50%)"
          }}
        >
          {loading || timeLoad ? (
            <Spin
              size="large"
              color={Theme.TEXT}
              style={{ top: "5vw", position: "relative" }}
            />
          ) : (
            <Col align="middle" style={{ marginTop: "2vw" }}>
       
              <LineChartOutlined
                style={{ color: Theme.BLUE, fontSize: "1.5vw" }}
              />
              <div>
                <Text style={{ fontSize: "1vw", color: Theme.TEXT }}>
                  No Data
                </Text>
              </div>
            </Col>
          )}
        </div>
      ) : (
        <Col align="middle">
          {loading && (
            <Spin
              size="large"
              color={Theme.TEXT}
              style={{ top: "5vw", position: "relative" }}
            />
          )}

          {trend_reports &&
            state?.movementSelected?.length > 0 &&
            //trend_reports?.[variation][`${movement_prefix}`]?.length > 0 &&
            loading !== true &&
            timeLoad !== true && (
              <div>
                <ResponsiveContainer width="100%" aspect={2}>
                  <LineChart
                    width={500}
                    height={500}
                    data={state.movementSelected}
                    margin={{
                      top: 5,
                      right: 30
                    }}
                  >
                    <CartesianGrid
                      vertical={false}
                      horizontal={false}
                      yAxisTicks={[0, 25, 50, 75]}
                    />
                    <XAxis
                      dataKey="date"
                      axisLine={{ stroke: Theme.TEXT }}
                      tickFormatter={(value) => formatDates([value])[0]}
                    />
                    <YAxis
                      tickFormatter={(value) => `${value}${state.unit_symbol}`}
                      axisLine={{ stroke: Theme.TEXT }}
                    />
                    <Tooltip
                      formatter={(value) =>
                        `${Math.round(value)}${state.unit_symbol}`
                      }
                      labelStyle={{ color: Theme.BLACK }}
                      itemStyle={{ color: Theme.BLACK }}
                    />
                    <Line
                      dataKey={dataKeys.leftKey}
                      stroke={Theme.BLUE}
                      fill={Theme.BLUE}
                      dot={{
                        fill: Theme.BLUE,
                        stroke: Theme.BLUE,
                        strokeWidth: 0,
                        r: 4,
                        className: "boxShadow"
                      }}
                    />
                    <Line
                      dataKey={dataKeys.rightKey}
                      stroke={chartColor}
                      fill={chartColor}
                      dot={{
                        fill: chartColor,
                        stroke: Theme.TEXT,
                        strokeWidth: 0,
                        r: 4,
                        className: "boxShadow"
                      }}
                    />
                    {state.movementSelected &&
                      state.movementSelected.map((entry, index) => (
                        <ReferenceLine
                          key={index}
                          x={entry.date}
                          stroke={Theme.TEXT}
                          strokeWidth={0.25}
                        />
                      ))}
                  </LineChart>
                </ResponsiveContainer>
                <Col
                  align={"center"}
                  justify={"center"}
                  style={{ marginTop: ".5vw" }}
                >
                  <CustomSelect
                    defaultValue={"overall_score"}
                    options={[
                      { value: "overall_score", label: "Overall Score" },
                      { value: "raw_angle", label: "Raw Angle" },
                      { value: "end_angle", label: "End Angle" }
                    ]}
                    width={"9vw"}
                    onChange={handleChangeMovement}
                  />
                </Col>
              </div>
            )}
        </Col>
      )}
    </Card>
  );
};
export default TrendReportJC;
