import {
  CREATE_ASSESSMENT_SUCCESS,
  CREATE_ASSESSMENT_FAIL,
  GET_ASSESSMENTS,
  GET_SINGLE_ASSESSMENT,
  RESET_ASSESSMENT,
  RESET_ASSESSMENT_STATUS,
  CREATE_SQUAT_RESULT,
  RESET_MOVEMENTS,
  CREATE_POSTURE_RESULT,
  CREATE_PUSH_RESULT,
  CREATE_RIGHT_BALANCE_RESULT,
  CREATE_LEFT_BALANCE_RESULT,
  CREATE_RIGHT_LUNGE_RESULT,
  CREATE_LEFT_LUNGE_RESULT,
  CREATE_RIGHT_RDL_RESULT,
  CREATE_LEFT_RDL_RESULT,
  CREATE_FREE_RESULT,
  RESET_FREE_UPDATED,
  FAIL_SQUAT_RESULT,
  FAIL_POSTURE_RESULT,
  FAIL_PUSH_RESULT,
  FAIL_RIGHT_BALANCE_RESULT,
  FAIL_LEFT_BALANCE_RESULT,
  FAIL_RIGHT_LUNGE_RESULT,
  FAIL_LEFT_LUNGE_RESULT,
  FAIL_RIGHT_RDL_RESULT,
  FAIL_LEFT_RDL_RESULT,
  RESET_SQUAT_STATUS,
  RESET_POSTURE_STATUS,
  RESET_PUSH_STATUS,
  RESET_BALANCE_STATUS,
  RESET_LUNGE_STATUS,
  RESET_RDL_STATUS,
  RESET_FREE_STATUS,
  FAIL_FREE_RESULT,
  RESET_COUNTER,
  GET_FREE_RECORDS,
  PATCH_ASSESSMENT_SUCCESS,
} from "../actions/types";

const initialState = {
  assessmentStatus: null,
  assessments: [],
  freeRecords: [],
  assessment: {
    overallScore: 0,
    mobilityScore: 0,
    compensationScore: 0,
    stabilityScore: 0,
    areasOfConcern: [],
    squat: null,
    standard_squat: null,
    push: null,
    posture: null,
    r_rdl: null,
    l_rdl: null,
    hinge: null,
    r_balance: null,
    l_balance: null,
    r_lunge: null,
    l_lunge: null,
    r_lat_lunge: null,
    l_lat_lunge: null,
    free_record: {
      id: 0,
      video: null
    },
    notes:""
  },
  squatResult: null,
  postureResult: null,
  pushResult: null,
  rightBalanceResult: null,
  leftBalanceResult: null,
  rightLungeResult: null,
  leftLungeResult: null,
  rightRDLResult: null,
  leftRDLResult: null,
  freeRecord: null,
  freeResult: null,
  resultCounter: 0,
  freeRecordUpdated: false,
  squatStatus: null,
  postureStatus: null,
  pushStatus: null,
  rightBalanceStatus: null,
  leftBalanceStatus: null,
  rightLungeStatus: null,
  leftLungeStatus: null,
  rightRDLStatus: null,
  leftRDLStatus: null,
  freeStatus: null,
};

function assessmentsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ASSESSMENTS:
      return {
        ...state,
        assessments: action.payload.data,
      };
    case GET_FREE_RECORDS:
      return {
        ...state,
        freeRecords: action.payload.data,
      };
    case CREATE_ASSESSMENT_SUCCESS:
      return {
        ...state,
        assessments: [...state.assessments, action.payload.data],
        assessment: action.payload.data,
        assessmentStatus: "success",
      };
      case PATCH_ASSESSMENT_SUCCESS:
        return {
          ...state,
          assessment: {
            ...state.assessment,
            notes: action.payload.notes,
          },
        };
    case CREATE_ASSESSMENT_FAIL:
      return {
        ...state,
        assessmentStatus: action.payload,
      };
    case GET_SINGLE_ASSESSMENT:
      return {
        ...state,
        assessment: action.payload.data[0],
      };
    case RESET_ASSESSMENT_STATUS:
      return {
        ...state,
        assessmentStatus: "reset",
      };
    case CREATE_SQUAT_RESULT:
      return {
        ...state,
        squatResult: action.payload,
        resultCounter: state.resultCounter + 1,
        squatStatus: action.status,
      };
    case CREATE_POSTURE_RESULT:
      return {
        ...state,
        postureResult: action.payload,
        resultCounter: state.resultCounter + 1,
        postureStatus: action.status,
      };
    case CREATE_PUSH_RESULT:
      return {
        ...state,
        pushResult: action.payload,
        resultCounter: state.resultCounter + 1,
        pushStatus: action.status,
      };
    case CREATE_RIGHT_BALANCE_RESULT:
      return {
        ...state,
        rightBalanceResult: action.payload,
        rightBalanceStatus: action.status,
      };
    case CREATE_LEFT_BALANCE_RESULT:
      return {
        ...state,
        leftBalanceResult: action.payload,
        resultCounter: state.resultCounter + 1,
        leftBalanceStatus: action.status,
      };
    case CREATE_RIGHT_LUNGE_RESULT:
      return {
        ...state,
        rightLungeResult: action.payload,
        rightLungeStatus: action.status,
      };
    case CREATE_LEFT_LUNGE_RESULT:
      return {
        ...state,
        leftLungeResult: action.payload,
        resultCounter: state.resultCounter + 1,
        leftLungeStatus: action.status,
      };
    case CREATE_RIGHT_RDL_RESULT:
      return {
        ...state,
        rightRDLResult: action.payload,
        rightRDLStatus: action.status,
      };
    case CREATE_LEFT_RDL_RESULT:
      return {
        ...state,
        leftRDLResult: action.payload,
        resultCounter: state.resultCounter + 1,
        leftRDLStatus: action.status,
      };
    case CREATE_FREE_RESULT:
      return {
        ...state,
        freeResult: action.payload,
        resultCounter: state.resultCounter + 1,
      };
    case FAIL_SQUAT_RESULT:
      return {
        ...state,
        squatStatus: action.payload,
      };
    case FAIL_POSTURE_RESULT:
      return {
        ...state,
        postureStatus: action.payload,
      };
    case FAIL_PUSH_RESULT:
      return {
        ...state,
        pushStatus: action.payload,
      };
    case FAIL_RIGHT_BALANCE_RESULT:
      return {
        ...state,
        rightBalanceStatus: action.payload,
      };
    case FAIL_LEFT_BALANCE_RESULT:
      return {
        ...state,
        leftBalanceStatus: action.payload,
      };
    case FAIL_RIGHT_LUNGE_RESULT:
      return {
        ...state,
        rightLungeStatus: action.payload,
      };
    case FAIL_LEFT_LUNGE_RESULT:
      return {
        ...state,
        leftLungeStatus: action.payload,
      };
    case FAIL_RIGHT_RDL_RESULT:
      return {
        ...state,
        rightRDLStatus: action.payload,
      };
    case FAIL_LEFT_RDL_RESULT:
      return {
        ...state,
        leftRDLStatus: action.payload,
      };
    case FAIL_FREE_RESULT:
      return {
        ...state,
        freeStatus: action.payload,
      };
    case RESET_COUNTER:
      return{
        ...state,
        resultCounter: 0
      }
    case RESET_MOVEMENTS:
      return {
        ...state,
        squatResult: null,
        postureResult: null,
        pushResult: null,
        rightBalanceResult: null,
        leftBalanceResult: null,
        rightLungeResult: null,
        leftLungResult: null,
        rightRDLResult: null,
        leftRDLResult: null,
        squatStatus: null,
        postureStatus: null,
        pushStatus: null,
        rightBalanceStatus: null,
        leftBalanceStatus: null,
        rightLungeStatus: null,
        leftLungeStatus: null,
        rightRDLStatus: null,
        leftRDLStatus: null,
        freeStatus: null,
        resultCounter: 0,
      };

    case RESET_SQUAT_STATUS:
      return {
        ...state,
        squatStatus: null,
      };
    case RESET_POSTURE_STATUS:
      return {
        ...state,
        postureStatus: null,
      };
    case RESET_PUSH_STATUS:
      return {
        ...state,
        pushStatus: null,
      };
    case RESET_BALANCE_STATUS:
      return {
        ...state,
        rightBalanceStatus: null,
        leftBalanceStatus: null,
      };
    case RESET_LUNGE_STATUS:
      return {
        ...state,
        rightLungeStatus: null,
        leftLungeStatus: null,
      };
    case RESET_RDL_STATUS:
      return {
        ...state,
        rightRDLStatus: null,
        leftRDLStatus: null,
      };
    case RESET_FREE_STATUS:
      return {
        ...state,
        freeStatus: null,
      };
    case RESET_FREE_UPDATED:
      return {
        ...state,
        freeRecordUpdated: false,
      };
    case RESET_ASSESSMENT:
      return {
        ...state,
        freeRecordUpdated: false,
        assessment: {
          overallScore: 0,
          mobilityScore: 0,
          compensationScore: 0,
          stabilityScore: 0,
          areasOfConcern: [],
          squat: null,
          push: null,
          posture: null,
          r_rdl: null,
          l_rdl: null,
          r_balance: null,
          l_balance: null,
          r_lunge: null,
          l_lunge: null,
          free_record: null,
        },
      };
    default:
      return state;
  }
}

export default assessmentsReducer;
