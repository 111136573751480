import { useState, useEffect } from "react";

import {
  Row,
  Col,
  Modal,
  Avatar,
  Typography,
} from "antd";


import { Link } from "react-router-dom";
import avtar from "../../assets/images/team-2.jpg";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/actions/auth";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";





function Header({
  placement,
  name,
  subName,
  onPress,
  handleSidenavColor,
  handleSidenavType,
  handleFixedNavbar,
}) {
  const { Title, Text } = Typography;
  const { isAuthenticated, Theme } = useSelector((state) => state.auth);
  const [visible, setVisible] = useState(false);
  const [sidenavType, setSidenavType] = useState("transparent");
  const [showAccountsModal, setShowAccountsModal] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => window.scrollTo(0, 0));
const [isMobile, setIsMobile] = useState(window.innerWidth < 1100);

const handleWindowResize = () => {
  setIsMobile(window.innerWidth < 1100);
};

useEffect(() => {
  // Add event listener for window resize
  window.addEventListener("resize", handleWindowResize);
  // Clean up the event listener on component unmount
  return () => {
    window.removeEventListener("resize", handleWindowResize);
  };
}, []);
  const history = useHistory();
  const handleLogout = () => {
    dispatch(logout());
    history.push("/sign-in");
  };
  return (
    <div>
      <Row
        align={"middle"}
        justify={"end"}
        style={{ marginLeft: "5vw" }}
        gutter={[24, 0]}
      >

        <Col > 
          <Row
            align={"middle"}
            justify={"center"}
            style={{ right: "5vw", position: "relative" }}
          >
            {isAuthenticated ? (
              <span
                style={{
                  cursor: "pointer",
                  fontSize: isMobile ? "3vw" : ".7vw",
                  position: "relative",
                  right: isMobile ? "10vw": "2.5vw",
                  top: isMobile ? "1vw" : "1vw",
                  color: Theme.TEXT,
                  zIndex: 999
                }}
                onClick={handleLogout}
              >
                Log Out
              </span>
            ) : (
              <Link
                style={{ position: "relative" }}
                to="/sign-in"
                className="btn-sign-in"
              >
                Sign in
              </Link>
            )}
          </Row>
          <Modal
            open={showAccountsModal}
            onCancel={() => setShowAccountsModal(false)}
            width={"32vw"}
            bodyStyle={{ height: "34vw" }}
            footer={[null]}
          ></Modal>
          {/* <Input
          style={{right: 75}}
            className="header-search"
            placeholder="Search Clients"
            prefix={<SearchOutlined />}
          /> */}
        </Col>
      </Row>
    </div>
  );
}

export default Header;
