import axios from "axios";

import {
  GET_GROUPS,
  CREATE_GROUP_FAIL,
  CREATE_GROUP_SUCCESS,
  GET_SINGLE_GROUP,
  UPDATE_GROUP,
  DELETE_GROUP,
  SEARCH_GROUPS,
  RESET_GROUP_STATUS,
  RESET_GROUP
} from "./types";

 
import { REACT_APP_API_URL } from "../../env";
import { checkAuthenticated } from "./auth";


// Create group
export const createGroup = (data) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };
 
  try {
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/organization/groups/`,
      data,
      config
    );
    if (res.data) {
      dispatch({
        type: CREATE_GROUP_SUCCESS,
        payload: res,
      });
    }
    
  } catch (err) {
    dispatch({
      type: CREATE_GROUP_FAIL,
      payload: err.response.status,
    });
   
  }
};

// Get Groups
export const getGroups = () => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: "JWT " + token,
        },
      };
      const res = await axios.get(`${REACT_APP_API_URL}/api/organization/groups`, config);
      if (res.data) {
        dispatch({
          type: GET_GROUPS,
          payload: res.data,
        });
      } else {

      }
    };
  } catch (err) {
  
  }
};

// Get single group
export const getSingleGroup = (id) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + token,
        },
      };

      try {
        const res = await axios.get(
          `${REACT_APP_API_URL}/api/organization/groups/${id}/`,
          config
        );
        if (res.data) {
          dispatch({
            type: GET_SINGLE_GROUP,
            payload: res.data,
          });
        }
      } catch (err) {
    
      }
    };
  } catch (err) {

  }
};


// Search Groups
export const searchGroups = (searchTerm) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: "JWT " + token,
        },
      };
      const res = await axios.get(
        `${REACT_APP_API_URL}/api/organization/groups?search=${searchTerm}`,
        config
      );
      if (res.data) {
        dispatch({
          type: SEARCH_GROUPS,
          payload: res.data,
        });
      } else {
      }
    };
  } catch (err) {

  }
};

// TODO: Update Group
export const updateGroups = (id, data) =>
  async (dispatch) => {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
  
    try {
      const res = await axios.patch(
        `${REACT_APP_API_URL}/api/organization/groups/${id}/`,
        data,
        config
      );
      if (res.data) {
        dispatch({
          type: UPDATE_GROUP,
          payload: res,
        });
      }
    } catch (err) {
  
    }
  };

// Delete trainer
export const deleteGroup = (id) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };


  try {
    const res = await axios.delete(
      `${REACT_APP_API_URL}/api/organization/groups/${id}/`,
      config
    );
    dispatch({
      type: DELETE_GROUP,
      payload: {res, id},
    });
  } catch (err) {

  }
};

export const resetGroupStatus = () => ({
  type: "RESET_GROUP_STATUS",
});

export const resetGroup = () => ({
  type: RESET_GROUP,
});


