import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Input, Card, Row, Typography, Select, Col, Spin } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import {
  createTrainerGroup,
  resetTrainerGroupStatus,
  getSingleTrainerGroup,
  getTrainerGroups,
} from "../../redux/actions/trainer_groups";
import { useHistory } from "react-router-dom";
import { css, StyleSheet } from "aphrodite";
import Theme from "../../constants/Theme";
import { getTrainers } from "../../redux/actions/trainers";
import BootstrapInput from "../common/Input";
const CreateTrainerGroupForm = ({ closeModal }) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const { trainerGroupStatus } = useSelector((state) => state.trainer_groups);
  const { user, Theme } = useSelector((state) => state.auth);
  const { trainers } = useSelector((state) => state.trainers);

  const ST_FRANCIS = 5;
  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    const data = new FormData();
    data.append("name", values.group_name);
    data.append("trainer_id", values.trainer_id);
    dispatch(createTrainerGroup(data));
    setLoading(false);
  };

  useEffect(() => {
    if (trainerGroupStatus === 200) {
      formikRef.current?.resetForm();
      dispatch(resetTrainerGroupStatus());
      closeModal();
  
    }
  }, [trainerGroupStatus]);

  useEffect(() => {
    if (
      user?.profile?.organization === ST_FRANCIS &&
      user?.profile?.account_type === 2
    ) {
      dispatch(getTrainers());
    }
  }, []);

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  return (
    <Col
      style={{
        zIndex: 100,
        paddingBottom: "10%",
        position: "relative",
      }}
      className="criclebox h-full"
    >
      <div>
        <Formik
          initialValues={{
            group_name: "",
            trainer_id: null,
          }}
          onSubmit={handleSubmit}
          validationSchema={yup.object().shape({
            group_name: yup.string().required("Required"),
            trainer_id: yup
              .number()
              .nullable(true)
              .when([], (value, schema) =>
                user?.profile?.organization === ST_FRANCIS
                  ? schema.required("Required")
                  : schema
              ),
          })}
        >
          {({
            handleChange,
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
          }) => (
            <Col align="center" className={css(styles.registerContainer)}>
              <div className={css(styles.block, styles.center)}>
                <div className={css(styles.title)}>
                  <p
                    className={css(styles.titleText)}
                    style={{ color: Theme.TEXT }}
                  >
                    Create New Group
                  </p>
                </div>

                <Row
                  justify={"center"}
                  className={css(styles.row)}
                  style={{ bottom: 25 }}
                >
                  <div className={css(styles.column, styles.margin5)}>
                    <BootstrapInput
                      value={values.group_name}
                      placeholder="Group Name"
                      onChange={handleChange("group_name")}
                      width={"17vw"}
                      error={touched.height_inches && errors.height_inches}
                    />
                    {touched.group_name && errors.group_name && (
                      <p className={css(styles.errors)}>{errors.group_name}</p>
                    )}
                  </div>

                  {user?.profile?.account_type === 2 &&
                    user?.profile?.organization === ST_FRANCIS && (
                      <div className={css(styles.column, styles.margin5)}>
                        <Select
                          style={{ width: "15vw" }}
                          showSearch
                          placeholder="Trainer Name"
                          optionFilterProp="children"
                          filterOption={filterOption}
                          value={values.trainer_id}
                          onChange={(selectedValues) => {
                            setFieldValue("trainer_id", selectedValues);
                          }}
                          options={trainers.map((trainer) => ({
                            value: trainer.user_account.id,
                            label:
                              trainer.user_account.first_name +
                              " " +
                              trainer.user_account.last_name,
                          }))}
                        />
                        {touched.trainer_id && errors.trainer_id && (
                          <p className={css(styles.errors)}>
                            {errors.trainer_id}
                          </p>
                        )}
                      </div>
                    )}
                </Row>
                {!isLoading ? (
                  <button
                    className={css(styles.createButton)}
                    type="submit"
                    onClick={handleSubmit}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <div className={css(styles.buttonGradient)}>
                      <p className={css(styles.buttontext)}>Submit</p>
                    </div>
                  </button>
                ) : (
                  <Spin size="medium" style={{ marginTop: "1vw" }} />
                )}
                {trainerGroupStatus > 399 && (
                  <div
                    className={css(styles.row, styles.center)}
                    style={{ top: 25 }}
                  >
                    <p className={css(styles.errorText)}>
                      Group with this name already exists
                    </p>
                  </div>
                )}
              </div>
            </Col>
          )}
        </Formik>
      </div>
    </Col>
  );
};

export default CreateTrainerGroupForm;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FFF",
    alignContent: "center",
  },
  title: {
    color: "#232b2b",
    fontSize: 18,

    bottom: 25,
    fontWeight: "600",
    alignSelf: "center",
  },
  input: {
    backgroundColor: Theme.COLORS.WHITE,
    borderBottomColor: "gray",
    borderColor: "white",
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
    margin: 10,
    width: "15vw",
    height: "2vw",
    fontSize: "1vw",
  },
  smallInput: {
    backgroundColor: Theme.COLORS.WHITE,

    // borderBottomColor: "black",
    // borderColor: "white",
    // borderWidth: 5,
    borderRadius: 10,
    padding: 10,
    margin: 10,
    width: "15vw",
    height: "2vw",
    fontSize: "1vw",
    ":focus": {
      borderColor: Theme.COLORS.WHITE, // Change the border color as needed
    },
  },
  checkboxText: {
    fontSize: ".75vw",
    width: "80%",
  },
  registerContainer: {
    borderRadius: 10,
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    justifyContent: "center",
    shadowRadius: 8,
    shadowOpacity: 0.3,
    elevation: 1,
    // height: "15vw",
    // width: "15vw",
    // marginTop: 10,
  },
  inputIcons: {
    marginRight: 12,
  },
  titleText: { fontSize: "1vw" },
  createButton: {
    width: "6vw",
    marginTop: "5%",
    backgroundImage: "linear-gradient(45deg, #C04848 0%, #480048 80%)",
    borderWidth: 0,
    borderRadius: 10,
  },
  buttontext: {
    color: Theme.COLORS.WHITE,
    fontWeight: "bold",
    marginHorizontal: 5,
    fontSize: ".75vw",
    // paddingHorizontal: 5,
  },
  errors: {
    position: "relative",
    // top: 55,
    color: "red",
    fontSize: ".75vw",
    // right: '25%'
    // alignSf: 'flex-start'
    // marginLeft: 12,
  },
  checkbox: {
    // top: 9,
    color: Theme.COLORS.PRIMARY,
    borderRadius: 5,
    width: 15,
    height: 15,
    position: "relative",
    right: "5%",
    top: "10%",
  },
  avatar: {
    height: 100,
    width: 100,
    borderRadius: 62,
    borderWidth: 0,
    alignSelf: "center",
    top: 20,
  },
  movementTitle: {
    alignSelf: "center",
    fontSize: "1.3vw",
    fontWeight: "bold",
    color: Theme.COLORS.BLACK,
    textAlign: "center",
  },
  clientDescr: {
    alignSelf: "center",
    fontSize: ".75vw",
    // fontWeight: "bold",
    color: Theme.COLORS.BLACK,
    textAlign: "center",
  },
});
