// Import necessary components and libraries
import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Typography,
  Col,
  Modal,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createProgram,
  deleteProgram,
  resetProgramStatus,
  updateProgram,
} from "../../redux/actions/workouts";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import StyledDatePickerComponent from "../common/DatePickerWorkouts";

const { Text } = Typography;

const CreateProgramForm = (programData, modalOpen) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Theme } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.clients);
  const { programStatus, program } = useSelector((state) => state.workouts);
  const [form] = Form.useForm();
  const [error, setError] = useState(false);
  const [selectedStartDate, setSelectedStartDate] = useState(
    programData.time_length_start ? programData.time_length_start : null
  );
  const [selectedEndDate, setSelectedEndDate] = useState(
    programData.time_length_end ? program.time_length_end : null
  );
  const onFinish = async (values) => {
    try {
      setError(false);
      const { start_date, end_date, name, description } = values;
      const newDate = new Date(selectedStartDate);
      const formattedDate = newDate.toISOString();
      const indexOfT = formattedDate.indexOf("T");
      const dateBeforeTStart = formattedDate.substring(0, indexOfT);

      const newDateEnd = new Date(selectedEndDate);
      const formattedDateEnd = newDateEnd.toISOString();
      const indexOfTEnd = formattedDateEnd.indexOf("T");
      const dateBeforeTEnd = formattedDateEnd.substring(0, indexOfTEnd);

      const progData = {
        time_length_start: dateBeforeTStart,
        time_length_end: dateBeforeTEnd,
        name,
        description,
        client: client.id,
      };
      if (programData.programData) {
        dispatch(updateProgram(programData.programData[0].id, progData));
      } else {
        dispatch(createProgram(progData));
      }
    } catch (error) {
      console.error("Error creating program:", error);
      setError(true);
    }
  };

  const handleDeleteProgram = () => {
    Modal.confirm({
      title: "Delete Program",
      content: "Are you sure you want to delete this program?",
      onOk: () => {
        dispatch(deleteProgram(programData.programData[0].id));
      },
      onCancel: () => {},
      okButtonProps: {
        type: "primary",
      },
    });
  };

  useEffect(() => {
    if (programData.programData) {
      setSelectedStartDate(programData.programData[0].time_length_start);
      setSelectedEndDate(programData.programData[0].time_length_end);
      form.setFieldsValue({
        name: programData.programData[0].name,
        description: programData.programData[0].description,
        start_date: programData.programData[0].time_length_start,
        end_date: programData.programData[0].time_length_end,
      });
    }
  }, [modalOpen]);

  useEffect(() => {
    if (
      (programStatus === "success" || programStatus === "updated") &&
      !programData.programData
    ) {
      history.push(`/program/${client.id}/${program.id}`);
      dispatch(resetProgramStatus());
    } else if (programStatus === "deleted") {
      history.push(`/client/${client.id}`, { fromWorkout: true });
      dispatch(resetProgramStatus());
    } else if (
      (programStatus === "success" || programStatus === "updated") &&
      programData.programData
    ) {
      programData.closeModal();
    }
  }, [programStatus]);

  return (
    <Form
      name="createProgram"
      onFinish={onFinish}
      initialValues={{ remember: true }}
      layout="vertical"
      align="middle"
      justify="center"
      form={form}
      style={{ background: Theme.CARD }}
    >
      <Row align="middle" justify="center">
        <Form.Item
          className="custom-form-item"
          name="start_date"
          style={{ fontSize: "0.75vw", marginRight: "1vw", zIndex: 100 }}
          rules={[{ required: true, message: "Please select the start date" }]}
        >
          <StyledDatePickerComponent
            selected={selectedStartDate}
            onChange={(date) => setSelectedStartDate(date)}
            placeholder={"Start Date"}
          />
        </Form.Item>
        <Form.Item
          className="custom-form-item"
          name="end_date"
          style={{ fontSize: "0.75vw", zIndex: 100 }}
          rules={[{ required: true, message: "Please select the start date" }]}
        >
          <StyledDatePickerComponent
            selected={selectedEndDate}
            onChange={(date) => setSelectedEndDate(date)}
            placeholder={"End Date"}
          />
        </Form.Item>
      </Row>
      <Col align="middle" justify="center">
        <Form.Item
          className="custom-form-item"
          //   label="Name"
          name="name"
          style={{ fontSize: "0.75vw", marginTop: ".5vw" }}
          rules={[{ required: true, message: "Please enter the program name" }]}
        >
          <Input
            placeholder="Name"
            className={
              Theme.BACKGROUND === "#000"
                ? "custom-placeholder-color-dark"
                : "custom-placeholder-color-light"
            }
            style={{
              width: "15vw",
              color: Theme.TEXT,
              background: Theme.BACKGROUND,
              borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1,
              fontSize: ".75vw",
            }}
          />
        </Form.Item>
      </Col>

      <Col align="middle" justify="center">
        <Form.Item
          className="custom-form-item"
          name="description"
          style={{ marginTop: ".5vw" }}
        >
          <Input.TextArea
            className={
              Theme.BACKGROUND === "#000"
                ? "custom-placeholder-color-dark"
                : "custom-placeholder-color-light"
            }
            placeholder="Description"
            autosize={{ minRows: 1, maxRows: 6 }}
            style={{
              width: "19vw",
              resize: "none",
              minHeight: "5vw",
              color: Theme.TEXT,
              fontSize: ".75vw",
              background: Theme.BACKGROUND,
              borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1,
            }}
          />
        </Form.Item>
      </Col>
      <Row style={{ marginTop: "1.5vw" }}>
        {programData.programData && (
          <Button
            type="primary"
            color={"PRIMARY"}
            style={{
              zIndex: 1,
              // backgroundImage:
              //   "linear-gradient(60deg, #C04848 25%, #480048 120%)",
              borderRadius: 10,
              borderWidth: 0,
              marginRight: "1vw",
              opacity: 0.9,
            }}
            onClick={() => handleDeleteProgram()}
          >
            <Text style={{ color: "white" }}>Delete Program</Text>
          </Button>
        )}
        <div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              color={"PRIMARY"}
              style={{
                zIndex: 1,
                backgroundImage:
                  "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                borderRadius: 10,
                borderWidth: 0,
                opacity: 0.9,
              }}
            >
              {programData.programData ? (
                <Text style={{ color: "white" }}>Update Program</Text>
              ) : (
                <Text style={{ color: "white" }}>Create Program</Text>
              )}{" "}
            </Button>
          </Form.Item>{" "}
        </div>
      </Row>

      {error === true && (
        <Text style={{ fontsize: ".65vw", color: "red" }}>
          Error - Please Check the Fields and Try Again
        </Text>
      )}
    </Form>
  );
};

export default CreateProgramForm;
