import {
  SET_QUERY_FILTER,
  SET_GROUP_FILTER,
  SET_TRAINER_GROUP_FILTER,
  SET_TRAINER_FILTER,
  SET_PAGE_NUMBER,
  RESET_FILTERS
} from "../actions/types";

export const setQuery = (filter) => ({
  type: SET_QUERY_FILTER,
  payload: filter
});

export const setGroupFilter = (filter) => ({
  type: SET_GROUP_FILTER,
  payload: filter
});

export const setTrainerGroupFilter = (filter) => ({
  type: SET_TRAINER_GROUP_FILTER,
  payload: filter
});

export const setTrainerFilter = (filter) => ({
  type: SET_TRAINER_FILTER,
  payload: filter
});

export const setPageNumber = (numero) => ({
  type: SET_PAGE_NUMBER,
  payload: numero
});

export const resetFilters = () => ({
  type: RESET_FILTERS
});
