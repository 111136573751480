import React, { useState, useEffect } from "react";
import { Col, Row, Typography } from "antd";
import Theme from "../../constants/Theme";
import { CircularProgressbar } from "react-circular-progressbar";
import ProgressProvider from "../chart/ProgressProvider";
import { useSelector } from "react-redux";
import { debounce } from "lodash";

const { Text } = Typography;

const ResultsScores = ({
  isRowLayout,
  scoreArray,
  headerClass,
  headerTitleClass,
  createdOn
}) => {
  const [chartWidth, setChartWidth] = useState("8vw");
  const { Theme } = useSelector((state) => state.auth);

  const handleResize = debounce(() => {
    const containerWidth = Math.min(Math.round(window.innerWidth * 0.05));
    setChartWidth(containerWidth);
  }, 200);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Adjust the number of columns based on the number of score items
  const getColumnWidth = () => {
    if (scoreArray.length === 1) return "100%"; // Single item, take full width
    return "35%"; // Two items per row
  };

  return (
    <Col align="middle" justify="center">
      <Col
        align="middle"
        justify="center"
        style={{
          width: 370,
          justifyContent: "center",
          borderRadius: 10,
          shadowColor: Theme.BLACK,
          shadowOffset: { width: 0, height: 1.5 },
          shadowRadius: 1,
          shadowOpacity: 0.8,
          borderWidth: 0,
          backgroundColor: Theme.CARD,
          height: 300,
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Col
          align="middle"
          justify="center"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            width: "100%"
          }}
        >
          <Row
            align="middle"
            justify="center"
            style={{
              width: "100%",
              margin: 0,
              flexWrap: "wrap",
              marginTop: 45,
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            {scoreArray.map((score, index) => (
              <Col
                key={index}
                align="middle"
                justify="center"
                style={{
                  width: getColumnWidth(),
                  marginBottom: 50,
                  display: "flex",
                  justifyContent: "center" // Ensures the item is centered
                }}
              >
                {!isNaN(score.score) && (
                  <Col align="middle" justify="center">
                    <ProgressProvider valueStart={0} valueEnd={score.score}>
                      {(value) => (
                        <CircularProgressbar
                          styles={{
                            root: { width: "100%", height: 60 },
                            path: {
                              stroke:
                                score.score >= 80
                                  ? Theme.GRAD6
                                  : score.score >= 65
                                  ? Theme.YELLOW
                                  : Theme.RED,
                              strokeLinecap: "butt",
                              transition: "stroke-dashoffset 0.5s ease 0s"
                            },
                            trail: {
                              stroke: Theme.INPUT,
                              strokeLinecap: "butt"
                            },
                            text: {
                              fill: Theme.TEXT,
                              fontSize: 25
                            }
                          }}
                          value={value}
                          text={isNaN(score.score) ? "N/A" : `${score.score}`}
                        />
                      )}
                    </ProgressProvider>
                    <Text
                      style={{
                        color: Theme.TEXT,
                        fontSize: 13,
                        marginTop: "1vw",
                        position: "relative",
                        top: ".25vw",
                        fontWeight: "bold"
                      }}
                    >
                      {score.title}
                    </Text>
                  </Col>
                )}
              </Col>
            ))}
          </Row>
        </Col>
      </Col>
    </Col>
  );
};

export default ResultsScores;
