/*!
  =========================================================
  * Muse Ant Design Dashboard - v1.0.0
  =========================================================
  * Product Page: https://www.creative-tim.com/product/muse-ant-design-dashboard
  * Copyright 2021 Creative Tim (https://www.creative-tim.com)
  * Licensed under MIT (https://github.com/creativetimofficial/muse-ant-design-dashboard/blob/main/LICENSE.md)
  * Coded by Creative Tim
  =========================================================
  * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { useState } from "react";
import { Menu, Button, Col } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import emblem from "../../assets/images/logowhite.png";
import { matchPath } from "react-router-dom/cjs/react-router-dom.min";
import Text from "antd/lib/typography/Text";
import { LuLayoutDashboard, LuUsers, LuTrendingUp, LuLayoutTemplate} from "react-icons/lu";

import { LuSettings2 } from "react-icons/lu";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";


function Sidenav({ color, user, groups }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");
  const onAccount = () => {
    return page === "account"
  };

  const onAdmin = () => {
    return page === "admin" || page.startsWith("group")
  };

  const onTrends = () => {
    return page =="trends";
  }

  const onTemplates = () => {
    return page == "templates" || page.startsWith("templates/ranges");
  }

  const accountPage = onAccount();
  const adminPage= onAdmin();
  const trendsPage = onTrends();
  const templatesPage = onTemplates();
  const onDash = () => {

    if (
      page !== "account" &&
      page !== "admin" &&
      page !== "trends" &&
      page != "templates" &&
      !page.startsWith("group") &&
      !page.startsWith("templates/ranges")
    ) {
      return true;
    } else {
      return false;
    }
  };
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const flexDirection = isRowLayout ? "row" : "column";

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 1000);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  
  const dashpage = onDash();

  const dashboard = [
    <svg
      width="30"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M3 4C3 3.44772 3.44772 3 4 3H16C16.5523 3 17 3.44772 17 4V6C17 6.55228 16.5523 7 16 7H4C3.44772 7 3 6.55228 3 6V4Z"
        fill={"linear-gradient(45deg, #1d34c2 0%, #d12675 100%)"}
      ></path>
      <path
        d="M3 10C3 9.44771 3.44772 9 4 9H10C10.5523 9 11 9.44771 11 10V16C11 16.5523 10.5523 17 10 17H4C3.44772 17 3 16.5523 3 16V10Z"
        fill={"linear-gradient(45deg, #1d34c2 0%, #d12675 100%)"}
      ></path>
      <path
        d="M14 9C13.4477 9 13 9.44771 13 10V16C13 16.5523 13.4477 17 14 17H16C16.5523 17 17 16.5523 17 16V10C17 9.44771 16.5523 9 16 9H14Z"
        fill={"linear-gradient(45deg, #1d34c2 0%, #d12675 100%)"}
      ></path>
    </svg>,
  ];

  const tables = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M9 2C8.44772 2 8 2.44772 8 3C8 3.55228 8.44772 4 9 4H11C11.5523 4 12 3.55228 12 3C12 2.44772 11.5523 2 11 2H9Z"
        fill={"linear-gradient(45deg, #1d34c2 0%, #d12675 100%)"}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 3.89543 4.89543 3 6 3C6 4.65685 7.34315 6 9 6H11C12.6569 6 14 4.65685 14 3C15.1046 3 16 3.89543 16 5V16C16 17.1046 15.1046 18 14 18H6C4.89543 18 4 17.1046 4 16V5ZM7 9C6.44772 9 6 9.44772 6 10C6 10.5523 6.44772 11 7 11H7.01C7.56228 11 8.01 10.5523 8.01 10C8.01 9.44772 7.56228 9 7.01 9H7ZM10 9C9.44772 9 9 9.44772 9 10C9 10.5523 9.44772 11 10 11H13C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9H10ZM7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15H7.01C7.56228 15 8.01 14.5523 8.01 14C8.01 13.4477 7.56228 13 7.01 13H7ZM10 13C9.44772 13 9 13.4477 9 14C9 14.5523 9.44772 15 10 15H13C13.5523 15 14 14.5523 14 14C14 13.4477 13.5523 13 13 13H10Z"
        fill={"linear-gradient(45deg, #1d34c2 0%, #d12675 100%)"}
      ></path>
    </svg>,
  ];

  const account = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        d="M4 4C2.89543 4 2 4.89543 2 6V7H18V6C18 4.89543 17.1046 4 16 4H4Z"
        fill={color}
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 9H2V14C2 15.1046 2.89543 16 4 16H16C17.1046 16 18 15.1046 18 14V9ZM4 13C4 12.4477 4.44772 12 5 12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H5C4.44772 14 4 13.5523 4 13ZM9 12C8.44772 12 8 12.4477 8 13C8 13.5523 8.44772 14 9 14H10C10.5523 14 11 13.5523 11 13C11 12.4477 10.5523 12 10 12H9Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const rtl = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6C3 4.34315 4.34315 3 6 3H16C16.3788 3 16.725 3.214 16.8944 3.55279C17.0638 3.89157 17.0273 4.29698 16.8 4.6L14.25 8L16.8 11.4C17.0273 11.703 17.0638 12.1084 16.8944 12.4472C16.725 12.786 16.3788 13 16 13H6C5.44772 13 5 13.4477 5 14V17C5 17.5523 4.55228 18 4 18C3.44772 18 3 17.5523 3 17V6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const profile = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const signin = [
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 2C5.44772 2 5 2.44772 5 3V4H4C2.89543 4 2 4.89543 2 6V16C2 17.1046 2.89543 18 4 18H16C17.1046 18 18 17.1046 18 16V6C18 4.89543 17.1046 4 16 4H15V3C15 2.44772 14.5523 2 14 2C13.4477 2 13 2.44772 13 3V4H7V3C7 2.44772 6.55228 2 6 2ZM6 7C5.44772 7 5 7.44772 5 8C5 8.55228 5.44772 9 6 9H14C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7H6Z"
        fill={color}
      ></path>
    </svg>,
  ];

  const iconStyle = {
    color: "white", // Set the color of the icon
    fontSize: "1vw", // Adjust the icon size
    position: 'relative',
 
  };

  const gradientBackgroundStyle = {
    background: "linear-gradient(45deg, #C04848 0%, #480048 80%)", // Define your gradient colors
    width: "2vw", // Set the background width as a percentage of the viewport width
    height: "2vw", // Set the background height as a percentage of the viewport width
    borderRadius: "15%", // To create a circular background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // right: '7%',
    left: "5%",
    position: "relative",
  };

  return (
    <Col align={"middle"} justify={"center"}>
      <div className="brand" align="center">
        <img
          src={emblem}
          alt=""
          style={{
            left: 0,
            position: "relative",
            height: "2vw",
            width: "3vw",
            objectFit: "contain"
          }}
        />
      </div>
      <hr />
      <Menu
        align="middle"
        justify="center"
        theme="light"
        mode="vertical"
        style={{ position: "relative" }}
      >
        <Menu.Item
          key="1"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <NavLink
            align={"middle"}
            justify={"center"}
            to="/"
            isActive={onDash}
            activeClassName="activeNavLink"
            style={{
              width: "100%",
              // height: "100%",
              // alignitems: "center",
              // display: "flex",
              // left: '1%',
              // paddingLeft: "22%",
              position: "relative",
              background: dashpage ? "white" : null
            }}
          >
            <Col
              align={"middle"}
              justify={"center"}
              style={{ position: "relative" }}
            >
              <div
                style={{
                  background: "linear-gradient(45deg, #C04848 0%, #480048 80%)", // Define your gradient colors
                  width: "2vw", // Set the background width as a percentage of the viewport width
                  height: "2vw", // Set the background height as a percentage of the viewport width
                  borderRadius: "15%", // To create a circular background
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // right: '7%',
                  // left: "3%",
                  position: "relative"
                }}
              >
                <LuLayoutDashboard style={iconStyle} />
              </div>
              <div>
                <Text
                  style={{
                    color: dashpage ? "black" : "white",
                    fontWeight: 600,
                    fontSize: ".65vw",
                    position: "relative",
                    // right:8,
                    // left: "1%",
                    top: ".25vw"
                  }}
                  className="label"
                >
                  Home
                </Text>
              </div>
            </Col>
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="2"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <NavLink
            to="/trends"
            isActive={onTrends}
            activeClassName="activeNavLink"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: trendsPage ? "white" : null
            }}
          >
            <Col
              align={"middle"}
              justify={"center"}
              style={{ position: "relative" }}
            >
              <div
                style={{
                  background: "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                  width: "2vw",
                  height: "2vw",
                  borderRadius: "15%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                <LuTrendingUp style={iconStyle} />
              </div>
              <div>
                <Text
                  style={{
                    color: trendsPage ? "black" : "white",
                    fontWeight: 600,
                    fontSize: ".65vw",
                    position: "relative",
                    // right: "7.5%",
                    top: ".25vw"
                  }}
                  className="label"
                >
                  Trends
                </Text>
              </div>
            </Col>
          </NavLink>
        </Menu.Item>
        <Menu.Item
          key="3"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <NavLink
            to="/account"
            isActive={onAccount}
            activeClassName="activeNavLink"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: accountPage ? "white" : null
            }}
          >
            <Col
              align={"middle"}
              justify={"center"}
              style={{ position: "relative" }}
            >
              <div
                style={{
                  background: "linear-gradient(45deg, #C04848 0%, #480048 80%)", // Define your gradient colors
                  width: "2vw", // Set the background width as a percentage of the viewport width
                  height: "2vw", // Set the background height as a percentage of the viewport width
                  borderRadius: "15%", // To create a circular background
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // right: '7%',
                  position: "relative"
                }}
              >
                <LuSettings2 style={iconStyle} />
              </div>
              <div>
                <Text
                  style={{
                    color: accountPage ? "black" : "white",
                    fontWeight: 600,
                    fontSize: ".65vw",
                    position: "relative",
                    // right: "17.5%",
                    top: ".25vw"
                  }}
                  className="label"
                >
                  Account
                </Text>
              </div>
            </Col>
          </NavLink>
        </Menu.Item>
       {user?.profile?.allow_templates && <Menu.Item
          key="4"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <NavLink
            to="/templates"
            isActive={onTemplates}
            activeClassName="activeNavLink"
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              background: templatesPage ? "white" : null
            }}
          >
            <Col
              align={"middle"}
              justify={"center"}
              style={{ position: "relative" }}
            >
              <div
                style={{
                  background: "linear-gradient(45deg, #C04848 0%, #480048 80%)", // Define your gradient colors
                  width: "2vw",
                  height: "2vw",
                  borderRadius: "15%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // right: '7%',

                  position: "relative"
                }}
              >
                <LuLayoutTemplate style={iconStyle} />
              </div>
              <div>
                <Text
                  style={{
                    color: templatesPage ? "black" : "white",
                    fontWeight: 600,
                    fontSize: ".65vw",
                    position: "relative",
                    // right: '15.5%',
                    top: ".25vw"
                  }}
                  className="label"
                >
                  Templates
                </Text>
              </div>
            </Col>
          </NavLink>
        </Menu.Item>}
        {(user?.profile?.account_type === 2 || groups.length > 0) && (
          <Menu.Item
            key="5"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <NavLink
              to="/admin"
              isActive={onAdmin}
              activeClassName="activeNavLink"
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                background: adminPage ? "white" : null
              }}
            >
              <Col style={{ position: "relative",  }}>
                <div
                  style={{
                    background:
                      "linear-gradient(45deg, #C04848 0%, #480048 80%)", // Define your gradient colors
                    width: "2vw", // Set the background width as a percentage of the viewport width
                    height: "2vw", // Set the background height as a percentage of the viewport width
                    borderRadius: "15%", // To create a circular background
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    // right: '7%',
                 
                    position: "relative"
                  }}
                >
                  <LuUsers style={iconStyle} />
                </div>
                <div style={{    background: adminPage ? "white" : null}}>
                  <Text
                    style={{
                      color: adminPage ? "black" : "white",
                      fontWeight: 600,
                      fontSize: ".65vw",
                      position: "relative",
                      
                      top: ".25vw"
                    }}
                    className="label"
                  >
                    Admin
                  </Text>
                </div>
              </Col>
            </NavLink>
          </Menu.Item>
        )}
      </Menu>
    </Col>
  );
}

export default Sidenav;
