export default [
  {
    title: "Range of Motion",
    text: "Calculated by comparing measured angle difference against standard ranges of motion for what each joint is capabable of during a given movement"
  },
  {
    title: "Compensations",
    text: "Compensations are triggered by incorrect movement patterns in a certain direction. Each compensation will list the overactive and underactive muscles that contribute to the failed movement."
  },
  {
    title: "Stability",
    text: "Joints that must remain stable during movements are measured by angle differences throughout the assessment. A high score indicates little to no movement of the joint."
  },
  {
    title: "RANGE OF MOTION",
    text: "For hinge, range of motion is calculated by the angle difference reaching an optimal angle. If movement records too high or too low of an angle difference, the score will reflect this. For example, a client who squats instead of hinges will receive a similar score to one who does not hinge far enough."
  },
  {
    title: "Front",
    text: "Forward posture compares the difference between left and right angles. Ideal posture has angle differences of 0° across all three joint pairs."
  },
  {
    title: "Side",
    text: "Compared in relation to the ankle, side posture looks at the distance in centimeters that each center joint veers forward or backwards."
  },
  {
    title: "Joint Displacement",
    text: "Average level of joint pairings throughout the movement. A negative number indicates left side was higher, while a positive number indicates right side was higher."
  },
  {
    title: "Hip Movement",
    text: "Level of hips in relation to each other throughout the movement. A negative number indicates left side was higher, while a positive number indicates right side was higher."
  },
  {
    title: "Shoulder Movement",
    text: "Level of shoulders in relation to each other throughout the movement. A negative number indicates left side was higher, while a positive number indicates right side was higher."
  },
  {
    title: "Knee Level",
    text: "Knee position from starting. A higher number means the knee raised up by the given degree, while a lower number means it fell during the assessment."
  },
  {
    title: "Replay",
    text: "Video replay of the movement with raw angle calculations available for review"
  },
  {
    title: "Exercise Recs",
    text: "Video replay of the movement with raw angle calculations available for review"
  },
  {
    title: "Landing Angles",
    text: "Raw angles at initial contact and when knee reaches max flexion"
  },
  {
    title: "Landing Mechanics",
    text: "The landing mechanics score evaluates various aspects of your client's landing technique. Each criterion is assessed as optimal or suboptimal, with a pass (+1) for each optimal criterion. The score is calculated by dividing the total number of optimal criteria by 11."
  }
];

