import { combineReducers } from "redux";
import assessmentsReducer from "./assessments";
import auth from "./auth";
import assetReducer from "./assets";
import clientsReducer from "./clients";
import paymentReducer from "./payment";
import trainersReducer from "./trainers";
import groupsReducer from "./groups";
import trainerGroupsReducer from './trainer_groups';

import organizationReducer from "./organization";
import workoutExerciseReducer from "./workkouts";
import sessionReducer from "./session"
import jointComparisonsReducer from "./joint_comparisons";

const rootReducer = combineReducers({
  auth: auth,
  clients: clientsReducer,
  assessments: assessmentsReducer,
  assets: assetReducer,
  payment: paymentReducer,
  trainers: trainersReducer,
  groups: groupsReducer,
  trainer_groups: trainerGroupsReducer,
  organization: organizationReducer,
  workouts: workoutExerciseReducer,
  session: sessionReducer,
  joint_comparisons: jointComparisonsReducer,
});

export default rootReducer;
