export default [
  {
    title: "Posture",
    image: require("../assets/images/posture.png"),
    video: "https://demotu-web.s3.us-west-2.amazonaws.com/posture.mp4"
  },
  {
    title: "Squat",
    image: require("../assets/images/squatresults.png"),
    video: "https://demotu-web.s3.us-west-2.amazonaws.com/squat.mp4"
  },
  {
    title: "Hinge",
    image: require("../assets/images/rdlresults.png"),
    video: "https://demotu-web.s3.us-west-2.amazonaws.com/rdl.mp4"
  },
  {
    title: "Push",
    image: require("../assets/images/pushresults.png"),
    video: "https://demotu-web.s3.us-west-2.amazonaws.com/push.mp4"
  },
  {
    title: "Lunge",
    image: require("../assets/images/lunge.png"),
    video: "https://demotu-web.s3.us-west-2.amazonaws.com/lunge.mp4"
  },
  {
    title: "Balance",
    image: require("../assets/images/balancewhite.png"),
    video: "https://demotu-web.s3.us-west-2.amazonaws.com/balance.mp4"
  },
  {
    title: "Free Record",
    image: require("../assets/images/freeRecord.png"),
    video: "https://demotu-web.s3.us-west-2.amazonaws.com/freerecord.mp4"
  },
  {
    title: "Jump",
    image: require("../assets/images/cmj.png"),
    video: "https://demotu-web.s3.us-west-2.amazonaws.com/cmj.mp4"
  }
];