import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBUzLKvcMDYxKNCdy82REe7O5X8hAsYvIo",
  authDomain: "demotu-app-web.firebaseapp.com",
  projectId: "demotu-app-web",
  storageBucket: "demotu-app-web.appspot.com",
  messagingSenderId: "306248545711",
  appId: "1:306248545711:web:6f5e42c20f9d60b5dd6b5a",
  measurementId: "G-KHE9MJ49J3"
};


const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
