import React from "react";
import { Card, Col, Row, Typography, Spin } from "antd";
import { useSelector } from "react-redux";
import ResultsSectionTitle from "./ResultsSectionTitle";
import sections from "../../constants/sections";
import ScoreCircle from "../chart/ScoreCircle";
const { Text } = Typography;

const ComparisonsResult = ({ leftComparison, rightComparison }) => {
  const { Theme } = useSelector((state) => state.auth);
  const styles = createStyles(Theme);

  function cleanName(input) {
    if (!input) return "";

    if (
      input?.content_object_type === "cervical_movement" &&
      input.side === "Flexion"
    ) {
      return "CERVICAL FLEXION";
    } else if (
      input.content_object_type === "cervical_movement" &&
      input.side === "Extension"
    ) {
      return "CERVICAL EXTENSION";
    } else if (
      input.content_object_type === "thoracic_rotation" 
    ) {
      return "TRUNK ROTATION";
    }

    return input.content_object_type
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ")
      .toUpperCase();
  }
  return (
    <Row
      align="center"
      justify="center"
      style={{
        alignItems: "center",
        display: "flex",
        top: "5%",
        position: "relative",
        marginBottom: "1.5%"
      }}
    >
      <Row
        align="center"
        justify="center"
        style={{
          alignSelf: "center",
          position: "relative"
        }}
        gutter={[16, 16]}
      >
        {rightComparison && (
          <Col align="middle" justify={"center"}>
            <Col
              align="middle"
              justify={"center"}
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: "18vw",

                borderRadius: 10,
                backgroundColor: Theme.CARD,
                boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.025)"
                // marginBottom: "10%",
              }}
              className="criclebox h-full"
            >
              <Col
                align="middle"
                justify={"center"}
                style={{
                  flexDirection: "column",
                  alignItems: "center",
                  top: "3vw",
                  position: "relative",
                  width: "20vw"

                  // minHeight: "13vw",
                  // height: 'auto'
                }}
              >
                <Col
                  align="middle"
                  justify={"center"}
                  style={{ position: "relative" }}
                >
                  {rightComparison.content_object_type !==
                  "cervical_movement" ? (
                    <Col>
                      <Col>
                        <Text style={styles.movementTitle}>
                          {cleanName(rightComparison)}
                        </Text>
                      </Col>
                      <Text style={styles.movementTitle}>
                        ({rightComparison.side})
                      </Text>
                    </Col>
                  ) : (
                    <Text style={styles.movementTitle}>
                      {cleanName(rightComparison)}
                    </Text>
                  )}
                </Col>

                <Col
                  name="scores"
                  align="middle"
                  justify={"center"}
                  style={{
                    marginBottom: "7vw",
                    position: "relative",
                    bottom: ".25vw"
                    // width: "20vw",
                  }}
                >
                  <Row
                    align="center"
                    style={{ top: "2vw", position: "relative" }}
                  >
                    <Col align="center" justify="center">
                      <Col align="center" justify="center">
                        <ScoreCircle
                          score={Math.round(rightComparison.overall_score)}
                          height="4vw"
                        />
                      </Col>
                      <Col
                        align="center"
                        justify="center"
                        style={{ marginTop: "15%", marginBottom: "15%" }}
                      >
                        <Col style={{ top: ".5vw", position: "relative" }}>
                          <Text style={styles.messageText}>End Angle</Text>
                          <Col>
                            <Text style={styles.messageTextVal}>
                              {Math.round(rightComparison.end_angle)}°
                            </Text>
                          </Col>
                        </Col>
                      </Col>
                      <Col align="center" justify="center">
                        <Col style={{ top: ".5vw", position: "relative" }}>
                          <Text style={styles.messageText}>
                            Range of Motion
                          </Text>
                          <Col>
                            <Text style={styles.messageTextVal}>
                              {Math.round(rightComparison.raw_angle)}°
                            </Text>
                          </Col>
                        </Col>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Col>
          </Col>
        )}
        {rightComparison && (
          <Col>
            <Card
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: "16.3vw",
                borderRadius: 10,
                background: Theme.CARD,
                borderWidth: 0
              }}
              className="criclebox h-full"
            >
              <div
                className="aoc"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"

                  // minHeight: "13vw",
                  // height: 'auto'
                }}
              >
                <Row
                  type="flex"
                  style={{ alignItems: "center" }}
                  justify="center"
                  gutter={10}
                >
                  <Col
                    align="center"
                    span={24}
                    style={{
                      fontWeight: "bold",
                      // marginTop: "5%",
                      // left: "7.5%",
                      width: "100%",
                      position: "relative"
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        marginTop:
                          rightComparison.content_object.video === null
                            ? "40%"
                            : "19%", // left: "21%",
                        // left: "21%",

                        position: "relative",
                        alignItems: "center"
                      }}
                    >
                      <ResultsSectionTitle section={sections[10]} />
                      {rightComparison.content_object.video &&
                        rightComparison.content_object.video !== null && (
                          <video
                            controls
                            src={rightComparison.content_object.video}
                            style={{
                              width: "10vw",
                              height: "19vw",
                              objectFit: "contain",
                              top: "1.5vw",
                              position: "relative"
                            }}
                          />
                        )}
                      {!rightComparison.content_object.video && (
                        <div
                          center
                          style={{ top: "5vw", position: "relative" }}
                        >
                          <div>
                            <Spin
                              size="large"
                              style={{ marginBottom: "1vw" }}
                            />
                          </div>
                          <Text style={styles.messageTextVideo}>
                            Video Processing
                          </Text>
                          <div>
                            <Text style={styles.messageTextVideo}>
                              Check Back Soon
                            </Text>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        )}
        {leftComparison && (
          <Col align="center">
            <Col
              align="center"
              justify="center"
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: "18vw",

                borderRadius: 10,
                backgroundColor: Theme.CARD,
                boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.025)"
                // marginBottom: "10%",
              }}
              className="criclebox h-full"
            >
              <Col
                align="middle"
                justify="center"
                style={{
                  alignItems: "center",
                  top: "3vw",
                  position: "relative",
                  // width: "20vw",

                  minHeight: "30vw"
                  // height: 'auto'
                }}
              >
                <Col
                  align="center"
                  justify="center"
                  style={{ position: "relative" }}
                >
                  {leftComparison &&
                  leftComparison.content_object_type !== "cervical_movement" ? (
                    <Col>
                      <Col>
                        <Text style={styles.movementTitle}>
                          {cleanName(leftComparison)}
                        </Text>
                      </Col>
                      <Text style={styles.movementTitle}>
                        ({leftComparison.side})
                      </Text>
                    </Col>
                  ) : (
                    <Text style={styles.movementTitle}>
                      {cleanName(leftComparison)}
                    </Text>
                  )}
                </Col>

                <Col
                  name="scores"
                  align="middle"
                  justify={"center"}
                  style={{
                    marginBottom: "7vw",
                    position: "relative",
                    bottom: ".25vw"
                    // width: "20vw",
                  }}
                >
                  <Row
                    align="center"
                    style={{ top: "2vw", position: "relative" }}
                  >
                    <Col align="center" justify="center">
                      <Col align="center" justify="center">
                        <ScoreCircle
                          score={Math.round(leftComparison.overall_score)}
                          height="4vw"
                        />
                      </Col>
                      <Col
                        align="center"
                        justify="center"
                        style={{ marginTop: "15%", marginBottom: "15%" }}
                      >
                        <Col style={{ top: ".5vw", position: "relative" }}>
                          <Text style={styles.messageText}>End Angle</Text>
                          <Col>
                            <Text style={styles.messageTextVal}>
                              {Math.round(leftComparison.end_angle)}°
                            </Text>
                          </Col>
                        </Col>
                      </Col>
                      <Col align="center" justify="center">
                        <Col style={{ top: ".5vw", position: "relative" }}>
                          <Text style={styles.messageText}>
                            Range of Motion
                          </Text>
                          <Col>
                            <Text style={styles.messageTextVal}>
                              {Math.round(leftComparison.raw_angle)}°
                            </Text>
                          </Col>
                        </Col>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Col>
          </Col>
        )}

        {leftComparison && (
          <Col>
            <Card
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: "16.3vw",
                borderRadius: 10,
                background: Theme.CARD,
                borderWidth: 0
              }}
              className="criclebox h-full"
            >
              <div
                className="aoc"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"

                  // minHeight: "13vw",
                  // height: 'auto'
                }}
              >
                <Row
                  type="flex"
                  style={{ alignItems: "center" }}
                  justify="center"
                  gutter={10}
                >
                  <Col
                    align="center"
                    span={24}
                    style={{
                      fontWeight: "bold",
                      // marginTop: "5%",
                      // left: "7.5%",
                      width: "100%",
                      position: "relative"
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        marginTop:
                          leftComparison.video === null ? "40%" : "19%", // left: "21%",
                        // left: "21%",

                        position: "relative",
                        alignItems: "center"
                      }}
                    >
                      <ResultsSectionTitle section={sections[10]} />
                      {leftComparison.content_object.video !== null && (
                        <video
                          controls
                          src={leftComparison.content_object.video}
                          style={{
                            width: "10vw",
                            height: "19vw",
                            objectFit: "contain",
                            top: "1.5vw",
                            position: "relative"
                          }}
                        />
                      )}
                      {leftComparison.content_object.video === null && (
                        <div
                          center
                          style={{ top: "5vw", position: "relative" }}
                        >
                          <div>
                            <Spin
                              size="large"
                              style={{ marginBottom: "1vw" }}
                            />
                          </div>
                          <Text style={styles.messageTextVideo}>
                            Video Processing
                          </Text>
                          <div>
                            <Text style={styles.messageTextVideo}>
                              Check Back Soon
                            </Text>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        )}
      </Row>
    </Row>
  );
};

export default ComparisonsResult;

let textSize = 14;
let modalText = 16;
let compTitle = 20;
let modalMargin = 0;
let minHeight = 160;

const createStyles = (theme) => ({
  container: {
    alignSelf: "center"
  },
  card: {
    backgroundColor: theme.WHITE,
    //   marginVertical: Theme.SIZES.BASE,
    borderWidth: 0,
    minHeight: minHeight,
    marginBottom: 1,
    marginHorizontal: 1
  },

  cardTitle: {
    flex: 1,
    alignSelf: "center",
    fontWeight: "500"
    // paddingTop: 5,
  },
  cardDescription: {
    // paddingTop: 5,
  },
  imageContainer: {
    borderRadius: 3,
    elevation: 1,
    overflow: "hidden",
    minHeight: 80
  },

  horizontalImage: {
    width: "40%",
    height: "40%"
    // position: "absolute",
    // left: "5%",
    // bottom: "1vw",
  },
  videoText: {
    color: theme.BLACK,
    fontSize: 12,
    left: "5.5%",
    marginTop: "1%",
    maxWidth: "60%",
    maxHeight: "50%",
    fontWeight: "bold"
  },
  horizontalStyles: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  verticalStyles: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
  },
  fullImage: {
    height: 215
  },

  messageText: {
    color: theme.TEXT,
    fontSize: ".85vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  messageTextVal: {
    color: theme.BLUE,
    fontSize: ".95vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  typeText: {
    color: theme.TEXT,
    fontSize: ".55vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".75vw"
  },
  messageTextVideo: {
    color: theme.WHITE,
    fontSize: ".75vw",
    fontWeight: "bold",
    paddingVertical: 8,
    paddingRight: 4,
    fontFamily: "Arial"
  },
  compScoreText: {
    color: theme.BLACK,
    fontSize: textSize,
    fontWeight: "bold",
    // paddingVertical: 8,
    // paddingRight: 4,
    fontFamily: "Arial",
    // alignSelf: 'flex-end',

    top: 275
  },
  resultText: {
    color: theme.PRIMARY,
    fontSize: ".75vw",
    position: "relative",

    fontWeight: "bold"
    // marginRight: "5%",
  },
  sectionText: {
    fontWeight: "500",
    fontSize: 19,
    color: theme.BLACK
  },
  compensationTitle: {
    fontWeight: "500",
    fontSize: compTitle,
    marginTop: 30,
    marginBottom: 5,
    color: theme.POP
  },
  scoreText: {
    fontWeight: "500",
    fontSize: ".75vw",
    color: theme.PRIMARY
  },
  movementTitle: {
    textAlign: "left",
    fontSize: "1vw",
    fontWeight: "bold",
    // fontFamily: "ArialBold",
    color: theme.TEXT,
    position: "relative",
    // alignSelf: "center",
    left: ".5%"
  },
  movementScore: {
    fontWeight: "bold",
    fontSize: ".75vw",
    // alignSelf: "center",
    color: theme.PRIMARY,
    left: ".45vw",
    position: "relative"
    // marginTop: 5,
  },
  list: {
    position: "absolute",
    top: 20,
    zIndex: 20,
    flex: 1
  },

  modalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    top: modalMargin
    // elevation: 5,
  },
  bottomView: {
    flex: 1,
    justifyContent: "flex-end",
    top: 20,
    alignSelf: "center"
  },
  infoModalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4
    // elevation: 5,
  },
  button: {
    marginVertical: 15,
    width: 250,
    borderRadius: 15,
    // position: "relative",
    backgroundColor: "white"
  },
  buttontext: {
    color: theme.PRIMARY,
    fontWeight: "bold",
    paddingHorizontal: 5
  },
  modalText: {
    color: theme.WHITE,
    maxWidth: "85%",
    marginBottom: 20,
    textAlign: "center",
    fontSize: modalText,
    marginTop: 5
  },
  video: {
    alignSelf: "center",
    width: "100%",
    height: "95%",
    marginVertical: "10%",
    marginHorizontal: "2%",
    bottom: "8%",

    backgroundColor: "black"
  }
});
