import { useState, useEffect } from "react";
import { Col, Grid, Modal, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSingleClient } from "../redux/actions/clients";
import CreateClientForm from "../components/results/CreateClientForm";

const { Text } = Typography;
const { confirm } = Modal;
const { Title } = Typography;

const { useBreakpoint } = Grid;

const CreateClient = () => {
  const [chooseModalVisible, setChooseModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { assessmentStatus } = useSelector(
    (state) => state.assessments
  );
  const { client } = useSelector((state) => state.clients);
  const dispatch = useDispatch();
  const fetchClient = () => dispatch(getSingleClient(client.id));
  const [editAssessments, setEditAssessments] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useSelector((state) => state.auth);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1500);
  }, []);

  useEffect(() => {
    if (assessmentStatus === "success") {
      fetchClient(client.id);
    }
  }, [assessmentStatus]);



  const [isFadedIn, setIsFadedIn] = useState(false);

  useEffect(() => {
    // Trigger the fade-in effect once the component is mounted
    setIsFadedIn(true);
  }, []);
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
  
    <Col
        justify="center" // Horizontally center content
      align="center"
      style={{
        // marginBottom: "5%",
        position: "absolute",
        left: '17.5%',
        top: '5vw'
      }}
      className={`fade-in-slide-up-element ${
        isFadedIn ? "fade-in-slide-up-animation" : ""
      }`}
    >
      <CreateClientForm />
    </Col>

  );
};

export default CreateClient;
