import { Row } from "antd";

import { useLocation } from "react-router-dom";
import PdfSections from "../components/pdf/PdfSections";
import { assessment2 } from "../components/pdf/Ass";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { setTheme } from "../redux/actions/auth";

const PDFResults = ({ route, navigation }) => {
  const [pdfTheme, setPdfTheme] = useState("light");
  const [assessmentData, setAssessmentData] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);

    // Get light_pdf from query params
    const lightPdfParam = queryParams.get("light_pdf");
    document.body.style.backgroundColor =
      lightPdfParam === "light" ? "white" : "black";
    document.documentElement.style.backgroundColor =
      lightPdfParam === "light" ? "white" : "black";
    setPdfTheme(lightPdfParam);
    dispatch(setTheme(lightPdfParam));
  }, []);

 useEffect(() => {
   const interval = setInterval(() => {
     if (window.assessmentData) {
       setAssessmentData(window.assessmentData);
       clearInterval(interval); // Stop polling once data is found
     }
   }, 100); // Check every 100ms (you can adjust this interval)

   // Cleanup the interval when the component unmounts
   return () => clearInterval(interval);
 }, []);

  const clientName = assessmentData?.client?.first_name == "Guest" ? "" : `${assessmentData?.client?.first_name} ${assessmentData?.client?.last_name}`;
  const { Theme } = useSelector((state) => state.auth);

  return (
    <Row
      align={"middle"}
      justify={"center"}
      style={{
        // justifyContent: "center", // Center the content vertically
        marginBottom: "2rem",
        backgroundColor: "white",
        position: "relative",
        backgroundColor: pdfTheme === "light" ? "white" : Theme.BACKGROUND
        // top: "2vw"
      }}
    >
      {assessmentData && (
        <PdfSections assessment={assessmentData} clientName={clientName} />
      )}{" "}
    </Row>
  );
};

export default PDFResults;
