import React, { useState } from 'react';
import { Checkbox, Button, Row, Col } from 'antd';
import { exerciseFilters } from './exerciseFilters';
import { useDispatch, useSelector } from "react-redux";

const ExerciseFilterModal = ({ onApply, closeModal }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const { Theme } = useSelector((state) => state.auth);

  const toggleCheckbox = (option) => {
    setSelectedOptions(prev => {
      if (prev.includes(option)) {
        return prev.filter(item => item !== option);
      } else {
        return [...prev, option];
      }
    });
  };

  const handleApply = () => {
    onApply(selectedOptions);
    closeModal();
  };

  return (
    <>
      <div style={{ maxHeight: '70vh', overflowY: 'auto', overflowX: 'hidden', marginBottom: '20px' }}>
        {exerciseFilters.map((section, index) => (
          <div key={index} style={{ marginBottom: 20 }}>
            <div style={{ color: Theme.PRIMARY, fontWeight: 'bold', marginBottom: 8, fontSize: ".85vw" }}>{section.name}</div>
            <Row gutter={[16, 24]}>
              <Col span={12}>
                {section.options.slice(0, Math.ceil(section.options.length / 2)).map((option, optionIdx) => (
                  <div key={optionIdx} style={{ marginBottom: 8, }}>
                    <Checkbox
                      checked={selectedOptions.includes(option)}
                      style={{color: Theme.TEXT, fontSize: ".75vw"}}
                      onChange={() => toggleCheckbox(option)}
                    >
                      {option}
                    </Checkbox>
                  </div>
                ))}
              </Col>
              <Col span={12}>
                {section.options.slice(Math.ceil(section.options.length / 2)).map((option, optionIdx) => (
                  <div key={optionIdx} style={{ marginBottom: 8 }}>
                    <Checkbox
                      checked={selectedOptions.includes(option)}
                      style={{color: Theme.TEXT, fontSize: ".75vw"}}
                      onChange={() => toggleCheckbox(option)}
                    >
                      {option}
                    </Checkbox>
                  </div>
                ))}
              </Col>
            </Row>
          </div>
        ))}
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', gap: '10px' }}>
        <Button onClick={() => setSelectedOptions([])}>Reset</Button>
        <Button type="primary" onClick={handleApply}>Apply</Button>
      </div>
    </>
  );
};

export default ExerciseFilterModal;
