import {
  SET_QUERY_FILTER,
  SET_GROUP_FILTER,
  SET_TRAINER_GROUP_FILTER,
  SET_TRAINER_FILTER,
  SET_PAGE_NUMBER,
  RESET_FILTERS
} from "../actions/types";

const initialState = {
  query: "",
  groupFilter: [],
  trainerGroupFilter: [],
  trainerFilter: [],
  pageNo: 1
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_QUERY_FILTER:
      return { ...state, query: action.payload };
    case SET_GROUP_FILTER:
      return { ...state, groupFilter: action.payload };
    case SET_TRAINER_GROUP_FILTER:
      return { ...state, trainerGroupFilter: action.payload };
    case SET_TRAINER_FILTER:
      return { ...state, trainerFilter: action.payload };
    case SET_PAGE_NUMBER:
      return { ...state, pageNo: action.payload };
    case RESET_FILTERS:
      return {
        ...state,
        query: "",
        groupFilter: [],
        trainerGroupFilter: [],
        trainerFilter: [],
        pageNo: 1
      };
    default:
      return state;
  }
};

export default sessionReducer;
