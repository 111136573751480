import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSingleAssessment,
  getFreeRecords,
} from "../redux/actions/assessments";
import { Button, Card, Col, Modal, Row, Typography } from "antd";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";

import Title from "antd/lib/skeleton/Title";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const { Text } = Typography;

const FreeRecordList = ({ clientID, header, goToResults, editAssessments }) => {
  const { freeRecords } = useSelector((state) => state.assessments);
  const dispatch = useDispatch();
  const { user, Theme } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.clients);
  const { id } = useParams();
  const [showAll, setShowAll] = useState(false);

  const deleteAlert = (recordID) => {
    Modal.confirm({
      title: "Are You Sure You Want to Delete this Free Record Video?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "Cancel",
      onOk: () => dispatch(deleteSingleAssessment(recordID, id)),
    });
  };

  useEffect(() => {
    // dispatch(getFreeRecords(id));
  }, []);
  const sortedFreeRecords = freeRecords
    .filter((item) => item.video !== null)
    .sort((a, b) => {
      const aDate = new Date(a.created_on);
      const bDate = new Date(b.created_on);
      return bDate - aDate;
    });

  const displayedFreeRecords = showAll
    ? sortedFreeRecords
    : sortedFreeRecords.slice(0, 4);
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const flexDirection = isRowLayout ? "row" : "column";

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 1000);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Card
      // data-aos="fade-up"
      // data-aos-anchor-placement="bottom-bottom"
      // data-aos-duration="1000"
      // bordered={true}
      justify={"center"}
      align={"center"}
      style={{
        zIndex: 100,
        flex: 1,
        position: "relative",
        width: isRowLayout ? "40vw" : "55vw",
        marginLeft: isRowLayout ? ".75vw" : null,
        minHeight: "25vw",
        marginBottom: isRowLayout || freeRecords.length > 0 ? "10vh" : "6vw",
        background: Theme.CARD,
        borderWidth: 0
      }}
      className="criclebox h-full"
    >
      <Col
        justify={"center"}
        align={"center"}
        style={{
          zIndex: 100,
          flex: 1,
          position: "relative",
          width: isRowLayout ? "40vw" : "60vw",

          left: freeRecords.length !== 0 && isRowLayout ? "1vw" : null,
          marginBottom: isRowLayout || freeRecords.length > 0 ? 10 : 100
        }}
      >
        <Row
          justify={"center"}
          align={"center"}
          style={{
            marginBottom: "2%",
            left: isRowLayout ? null : null,
            position: "relative"
          }}
        >
          <h3
            style={{
              color: Theme.TEXT,
              fontSize: "1.1vw",
              position: "relative"
            }}
          >
           Progress
          </h3>
        </Row>
        <div style={{ position: "relative" }}>
          {freeRecords.length > 4 && (
            <div style={{ position: "absolute", bottom: 10, left: 0 }}>
              <Text
                style={{
                  fontSize: ".7vw",
                  cursor: "pointer",
                  color: Theme.PRIMARY,
                  width: "4vw",
                  bottom: 0,
                  position: "relative",
                  top: ".5vw"
                }}
                onClick={() => setShowAll((prevState) => !prevState)}
              >
                {showAll ? "Collapse" : "Show All"}
              </Text>
            </div>
          )}
        </div>
        <Row
          justify={"center"}
          align={"center"}
          style={{
            flexWrap: "wrap",
            position: "relative",
            right: isRowLayout ? "4vw" : "-1vw",
            width: isRowLayout ? "50vw" : "50vw",
            marginBottom: isRowLayout ? "5vw" : "5vw"
          }}
        >
          {displayedFreeRecords.length > 0 ? (
            displayedFreeRecords.map((record, index) => {
              return (
                <div
                  key={record.id}
                  style={{
                    height: "7.5vw",
                    width: "10vw",
                    marginRight: "1vw",
                    backgroundColor: Theme.CARD,
                    marginBottom: "5%",
                    borderWidth: 0,
                    borderRadius: 10,
                    // marginTop: 15,
                    top: "1vw",
                    marginTop: index > 3 ? "5vw" : null,
                    marginBottom: "10%",
                    position: "relative"
                    // boxShadow: "0 2px 4px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  <Row justify={"center"} align={"center"}>
                    <Col justify={"center"} align={"center"}>
                      {user?.profile?.account_type === 1 && editAssessments && (
                        <Button
                          type="danger"
                          icon={<DeleteOutlined />}
                          style={{
                            // position: "absolute",
                            top: "40%",
                            right: "-2%"
                          }}
                          onClick={() => deleteAlert(record.id)}
                        />
                      )}

                      <div
                        id={record.id}
                        onClick={() => goToResults(record)}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "1000%",
                          alignItems: "center"
                        }}
                      ></div>
                    </Col>
                  </Row>
                  {record !== null && (
                    <Col>
                      <video
                        controls
                        src={record.free_record.video}
                        style={{
                          width: "7vw",
                          height: "12.5vw",
                          objectFit: "contain"
                        }}
                      />
                    </Col>
                  )}
                  <Text
                    style={{
                      fontSize: "0.75vw",
                      color: Theme.PRIMARY,
                      fontWeight: "bold",
                      position: "relative",
                      top: "175%",
                      right: "15%"
                    }}
                  >
                    {new Date(record.created_on).toLocaleDateString()}
                  </Text>
                  <div
                    style={{
                      position: "relative",
                      width: "100%",
                      top: "175%",
                      right: "15%"
                    }}
                  >
                    <Text style={{ fontSize: 20 }}>{record.title}</Text>
                  </div>
                </div>
              );
            })
          ) : (
            <Col
              justify={"center"}
              align={"center"}
              style={{
                // maxWidth: "90%",
                margin: "auto", // Use auto for horizontal centering

                borderRadius: 10,
                cursor: "pointer",
                display: "flex",
                position: "relative",
                flexDirection: "column",
                alignItems: "center",
                // left: "-5vw",
                bottom: "1vw",
                transform: isRowLayout
                  ? "translate(-10%, 50%)"
                  : "translate(0%, 50%)"
              }}
            >
              <VideoCameraOutlined
                style={{ color: Theme.PRIMARY, fontSize: "1.5vw" }}
              />
              <Text
                style={{ fontSize: "1.25vw", marginTop: 5, color: Theme.TEXT }}
              >
              Guest Clients
              </Text>
              <Text
                style={{ fontSize: ".75vw", marginTop: "5", color: Theme.TEXT }}
              >
                Do Not Track Progress - Create a Client for Progress Reports
              </Text>
            </Col>
          )}
        </Row>
      </Col>
    </Card>
  );
};

export default FreeRecordList;
