import React from "react";
import styled from "styled-components";
import { TreeSelect, Cascader } from "antd";
import { useSelector } from "react-redux";

const { TreeNode } = TreeSelect;

const NestedCustomSelect = ({
  defaultValue,
  value,
  treeData,
  options,
  onChange,
  width,
  mode,
  placeholder,
  lightModeBackground,
  darkModeBackground,
}) => {
  // Determine the theme background color
  const { Theme } = useSelector((state) => state.auth);

  // Define the conditional class name based on the theme background
  const conditionalClassName =
    Theme.BACKGROUND === "#000" ? "customDarkMode" : "customLightMode";

  return (
    <StyledTreeSelect
      className={conditionalClassName} 
      style={{ width: width, textAlign: "center" }}
      defaultValue={defaultValue}
      value={value}
      onChange={onChange}
      treeDefaultExpandAll
      placeholder={placeholder}
      lightModeBackground={lightModeBackground}
      darkModeBackground={darkModeBackground}
      allowClear={false}
      //treeData={treeData}
      options={options}
    >
    </StyledTreeSelect>
  );
};

const StyledTreeSelect = styled(Cascader)`
  // Common styles for both light and dark modes
  .ant-select-selector {
    font-weight: bold;
    border: none;
    font-size: .65vw;
  }

  .ant-select-selection-item,
  .ant-select-item-option-content,
  .ant-select-dropdown {
    align-self: center !important;
    text-align: center !important;
  }

  .ant-select-selector .ant-select-selection-placeholder,
  .ant-select-selector .ant-select-selection-item {
    text-align: left !important;
    font-weight: normal;
  }

  .ant-picker-input > input::placeholder,
  ::placeholder {
    align-self: center !important;
    text-align: center !important;
    color: ${({ darkModeBackground }) =>
      darkModeBackground ? "#fff" : "#000"};
  }

  .ant-select-multiple .ant-select-selection-item-remove svg {
    color: white;
  }

  // Styles specific to dark mode
  &.customDarkMode {
    .ant-select-selector {
      background-color: ${({ darkModeBackground }) =>
        darkModeBackground || "#000"};
      color: #f1f1fb;
      border: none
    }
    :where(.css-dev-only-do-not-override-byeoj0).ant-select:not(
        .ant-select-customize-input
      )
      .ant-select-selector {
      border: none;
    }
    .ant-select-arrow 
    :where(.css-dev-only-do-not-override-byeoj0).ant-select .ant-select-arrow {
      color: white; 
    }
    .ant-select-selection-item,
    .ant-select-item-option-content,
    .ant-select-dropdown,
    .ant-select-item-option {
      color: #f1f1fb;
    }

    .ant-select-item-option:hover,
    .ant-select-dropdown
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${({ darkModeBackground }) =>
        darkModeBackground || "#000"};
    }

    .ant-select-selection-item-remove {
      color: white; // Set the color of the X button to white in dark mode
    }
  }

  // Styles specific to light mode
  &.customLightMode {
    .ant-select-selector {
      background-color: ${({ lightModeBackground }) =>
        lightModeBackground || "#f1f1fb"};
      color: #000;
    }

    .ant-select-selection-item,
    .ant-select-item-option-content,
    .ant-select-dropdown,
    .ant-select-item-option {
      color: #000;
    }

    .ant-select-item-option:hover,
    .ant-select-dropdown
      .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${({ lightModeBackground }) =>
        lightModeBackground || "#f1f1fb"};
    }
    :where(.css-dev-only-do-not-override-byeoj0).ant-select
      .ant-select-selection-placeholder {
      color: #000;
    }
    .ant-select-selection-placeholder {
      color: black;
    }
  }
`;

export default NestedCustomSelect;
