import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { StyleSheet, css } from "aphrodite";
import { Button, Card, Col, Input, Modal, Row, Typography, Spin } from "antd";
import Body from "./Body";
import ResultsScores from "./ResultsScores";
import SquatResults from "./SquatResults";
import PushResults from "./PushResults";
import RdlResults from "./RdlResults";
import LungeResults from "./LungeResults";
import BalanceResults from "./BalanceResults";
import Posture from "./Posture";
import StandardSquatResults from "./StandardSquatResults";
import HingeResults from "./HingeResults";
import LatLungeResults from "./LatLungeResults";
import { IoPencilSharp } from "react-icons/io5";
import { patchAssessment } from "../../redux/actions/assessments";
import { saveAs } from "file-saver";
import axios from "axios";
import { REACT_APP_API_URL } from "../../env";
import Recommendations from "./Recommendations";
import { IoChevronBackOutline } from "react-icons/io5";
import {
  useHistory,
  useLocation
} from "react-router-dom/cjs/react-router-dom.min";
import CMJResults from "./CmjResults";

const { Text } = Typography;
let bodyHeight = 400;
let modalMargin = 0;

const ResultsSections = ({ navigateOption, clientName, loading }) => {
  const video = useRef(null);
  const dispatch = useDispatch();
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const [isEditing, setIsEditing] = useState(false);
  const [pdfModalVisible, setPdfModalVisible] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [browserPdfLoading, setBrowserPdfLoading] = useState(false);

  const [pdfMessage, setPdfMessage] = useState("");
  const { assessment, assessmentStatus } = useSelector(
    (state) => state.assessments
  );
  const { client } = useSelector((state) => state.clients);

  const { user, Theme } = useSelector((state) => state.auth);
  const [notes, setNotes] = useState(assessment.notes);
  const overallScore = parseFloat(assessment.overallScore);
  const overall = [Math.round(overallScore)];
  const createdOn = new Date(assessment.created_on);
  const flex = [Math.round(parseInt(assessment.mobilityScore))];
  const stability = [Math.round(parseInt(assessment.stabilityScore))];
  const compensation = [Math.round(parseInt(assessment.compensationScore))];
  const history = useHistory();
  const location = useLocation();
  const scoreArray = [
    { title: "Overall", score: overall },
    { title: "Compensation", score: compensation },
    { title: "Mobility", score: flex },
    { title: "Stability", score: stability }
  ];
  const styles = createStyles(Theme);

  if (assessment.posture) {
    var posture = [
      Math.round(parseInt(assessment.posture.posture_overall_score))
    ];
  }
  const headerClass = styles.header;
  const headerTitleClass = styles.headerTitle;

  const checkFields = (assessment) => {
    const fieldsToCheck = [
      "hinge",
      "free_record",
      "r_lunge",
      "l_lunge",
      "r_lat_lunge",
      "l_lat_lunge",
      "r_balance",
      "l_balance",
      "r_rdl",
      "l_rdl",
      "posture",
      "push",
      "squat",
      "standard_squat"
    ];

    const allFieldsNull = fieldsToCheck.every(
      (field) => assessment[field] === null
    );
    const cmjHasValue = assessment.cmj !== null;

    return allFieldsNull && cmjHasValue;
  };

  const onlyCmj = checkFields(assessment);

  const handleSaveClick = () => {
    const formData = new FormData();
    formData.append("notes", notes);
    dispatch(patchAssessment(formData, assessment.id));
    setIsEditing(false);
  };

  const cancelEdit = () => {
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const onNotesChange = (e) => {
    // Update the first_name state variable here
    setNotes(e.target.value);
  };

  const listOfMovements = (item) => {
    var array = [];
    for (var [key, value] of Object.entries(item)) {
      if (
        key !== "created_on" &&
        key !== "id" &&
        key !== "overallScore" &&
        value !== null
      ) {
        if (key === "squat" && value !== null) {
          array.push("Squat");
        } else if (key === "standard_squat" && value !== null) {
          array.push("Squat");
        } else if (key === "posture" && value !== null) {
          array.push("Posture");
        } else if (key === "push" && value !== null) {
          array.push("Push");
        } else if (key === "r_balance" && value !== null) {
          array.push("Balance");
        } else if (key === "r_lunge" && value !== null) {
          array.push("Lunge");
        } else if (key === "r_lat_lunge" && value !== null) {
          array.push("Lunge");
        } else if (key === "r_rdl" && value !== null) {
          array.push("Hinge");
        } else if (key === "hinge" && value !== null) {
          array.push("Hinge");
        } else if (key === "free_record" && value !== null) {
          array.push("Free Record");
        }
      }
    }
    return array;
  };
  const movements = listOfMovements(assessment);

  const handleGoToClients = () => {
    const pathParts = location.pathname.split("/");
    const id = pathParts[2];
    history.push(`/client/${id}`);
  };

  const handleGeneratePDF = async (send) => {
    const token = await localStorage.getItem("access");
    if (send === "email") {
      setPdfLoading(true);
    }

    setPdfMessage("");
    try {
      const response = await axios.post(
        `${REACT_APP_API_URL}/api/reports/`,
        {},
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
            Authorization: "JWT " + token
          },
          params: {
            id: assessment.id,
            send: send
          }
        }
      );

      if (response && send !== "email") {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = URL.createObjectURL(blob);
        window.open(pdfUrl, "_blank");
      } else if (response && send === "email") {
        if (response.status === 200) {
          setPdfMessage("Email sent!");
        } else {
          setPdfMessage(
            "An error occured - Please try again or contact support at info@demotuapp.com"
          );
        }
      }
      setPdfLoading(false);
      setBrowserPdfLoading(false);
    } catch (error) {
      setPdfLoading(false);

      setBrowserPdfLoading(false);
      console.error("Error generating PDF:", error);
    }
  };

  if (loading === true) {
    // return <LoadingScreenAssess />;
  } else if (overall == 0) {
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div style={{ backgroundColor: "white" }}></div>
      </div>
    );
  } else {
    return (
      <>
        <Row
          onClick={handleGoToClients}
          style={{
            right: "6vw",
            bottom: "0vw",
            position: "relative",
            cursor: "pointer"
          }}
        >
          <IoChevronBackOutline
            style={{
              top: ".2vw",
              marginRight: ".5%",
              marginBottom: "2vw",
              position: "relative",
              color: Theme.TEXT
            }}
          />
          <div>
            <Text style={{ color: Theme.TEXT }}>Back</Text>
          </div>
        </Row>
        <Col
          align="center"
          justify="center"
          style={{
            alignItems: "center",
            display: "flex",
            top: "5%",
            position: "relative",
            marginBottom: "30vh",
            right: "7.5%"
          }}
        >
          <Col align="center" justify="center">
            <Row
              align="center"
              justify="center"
              style={{
                alignSelf: "center",
                position: "relative",
                paddingBottom: "0vw"
              }}
              gutter={[10, 6]}
            >
              <Col>
                <Card
                  style={{
                    zIndex: 100,
                    flex: 1,
                    position: "relative",
                    width: isRowLayout ? "30.75vw" : "16vw",
                    background: Theme.CARD,
                    borderWidth: 0,
                    borderRadius: 10,
                    minHeight: onlyCmj ? "19vw" : "14vw"
                  }}
                  // className="criclebox h-full"
                >
                  <Col
                    style={{
                      alignSelf: "center",
                      position: "relative",
                      textAlign: "center",
                      top: onlyCmj ? "2vw" : "1vw"
                    }}
                  >
                    <div>
                      {onlyCmj && (
                        <Col style={{ marginBottom: "1.25vw" }}>
                          <Text
                            style={{
                              color: Theme.TEXT,
                              fontSize: ".85vw",
                              fontWeight: "bold"
                            }}
                          >
                            Date of Assessment
                          </Text>
                          <Col>
                            <Text
                              style={{
                                fontSize: ".80vw",
                                marginTop: "0vw",
                                fontWeight: "600",
                                color: Theme.PRIMARY,
                                fontSize: ".75vw"
                              }}
                            >
                              {createdOn.toLocaleDateString()}
                            </Text>
                          </Col>
                        </Col>
                      )}
                      <div>
                        {/* <p className={headerTitleClass}>Client:</p> */}
                        <Text
                          style={{
                            color: Theme.TEXT,
                            fontWeight: "bold",
                            // paddingLeft: "5%"
                            fontSize: "1.25vw",
                            position: "relative",
                            // bottom: 10,
                            shadowColor: Theme.TEXT,
                            shadowOffset: { width: 0, height: 2 },
                            shadowRadius: 4,
                            shadowOpacity: 0.2
                          }}
                        >
                          {clientName}
                        </Text>

                        <Col
                          align={"middle"}
                          justify={"center"}
                          style={{ marginTop: "1.25vw", position: "relative" }}
                        >
                          
                            <Button
                              onClick={() => {
                                if (client.email) {
                                  setPdfModalVisible(true);
                                } else {
                                  handleGeneratePDF("download");
                                  setPdfLoading(true);
                                }
                              }}
                              disabled={pdfLoading || browserPdfLoading}
                              style={{
                                color: "white",
                                borderWidth: 0,
                                borderRadius: 12,
                                // height: "1.5vw",
                                width: "7vw",
                                fontWeight: "bold",
                                fontSize: ".75vw",
                                cursor: "pointer",
                                backgroundImage:
                                  "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                              }}
                            >
                              {pdfLoading && !client.email ? (
                                <Spin />
                              ) : (
                                <Text
                                  style={{
                                    color: "white",

                                    fontSize: ".75vw"
                                  }}
                                >
                                  Generate PDF
                                </Text>
                              )}
                            </Button>
                          
                          <Modal
                            open={pdfModalVisible}
                            onCancel={() => {
                              setPdfModalVisible(false);
                            }}
                            footer={null}
                            width={"25vw"}
                            bodyStyle={{ height: "12.5vw", padding: ".5vw" }}
                            className={
                              Theme.BACKGROUND === "#000"
                                ? "customAntdModalDark"
                                : "customAntdModalLight"
                            }
                          >
                            <Col
                              align="middle"
                              justify="center"
                              style={{ marginTop: "4vw" }}
                            >
                              <Text
                                style={{
                                  position: "relative",
                                  bottom: "1vw",
                                  color: Theme.TEXT,
                                  textAlign: "center",
                                  fontSize: "1.15vw"
                                }}
                              >
                                How would you like to generate the pdf?
                              </Text>
                              <Row
                                align={"middle"}
                                justify={"center"}
                                style={{ marginTop: ".5vw" }}
                              >
                                <Button
                                  onClick={() => handleGeneratePDF("email")}
                                  style={{
                                    color: "white",
                                    borderWidth: 0,
                                    borderRadius: 12,

                                    width: "7vw",
                                    fontWeight: "bold",
                                    fontSize: ".65vw",
                                    cursor: "pointer",
                                    marginRight: "1vw",
                                    backgroundImage:
                                      "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                                  }}
                                >
                                  {pdfLoading ? (
                                    <Spin
                                      size="small"
                                      color="white"
                                      style={{
                                        marginBottom: "1vw",
                                        color: "white",
                                        marginTop: ".5vw"
                                      }}
                                    />
                                  ) : (
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: ".65vw",
                                        color: "white"
                                      }}
                                    >
                                      Email to {user?.profile.client_label}
                                    </Text>
                                  )}
                                </Button>
                                <Button
                                  onClick={() => {
                                    setBrowserPdfLoading(true);
                                    handleGeneratePDF("download");
                                  }}
                                  style={{
                                    color: "white",
                                    borderWidth: 0,
                                    borderRadius: 12,

                                    width: "7vw",
                                    fontWeight: "bold",
                                    fontSize: ".65vw",
                                    cursor: "pointer",
                                    backgroundImage:
                                      "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                                  }}
                                >
                                  {browserPdfLoading ? (
                                    <Spin
                                      size="small"
                                      color="white"
                                      style={{
                                        marginBottom: "1vw",
                                        color: "white",
                                        marginTop: ".5vw"
                                      }}
                                    />
                                  ) : (
                                    <Text
                                      style={{
                                        fontWeight: "bold",
                                        fontSize: ".65vw",
                                        color: "white"
                                      }}
                                    >
                                      View in Browser
                                    </Text>
                                  )}
                                </Button>
                              </Row>
                              <Col
                                style={{
                                  marginTop:
                                    pdfMessage == "Email sent!"
                                      ? "1.5vw"
                                      : "1vw"
                                }}
                              >
                                <Text
                                  style={{
                                    color:
                                      pdfMessage == "Email sent!"
                                        ? Theme.TEXT
                                        : "red",
                                    fontSize:
                                      pdfMessage == "Email sent!"
                                        ? ".85vw"
                                        : ".65vw"
                                  }}
                                >
                                  {pdfMessage}
                                </Text>
                              </Col>
                            </Col>
                          </Modal>
                        </Col>

                        <Col
                          align="center"
                          style={{ marginTop: "1.25vw", position: "relative" }}
                        >
                          <Text
                            style={{
                              left: ".25vw",
                              position: "relative",
                              color: Theme.TEXT,
                              fontWeight: "bold"
                            }}
                          >
                            Notes{" "}
                            <span
                              style={{
                                top: 0,
                                color: Theme.PRIMARY,
                                cursor: "pointer"
                              }}
                              onClick={() => handleEditClick()}
                            >
                              <IoPencilSharp size={".55vw"} />
                            </span>
                          </Text>
                          {!isEditing && (
                            <Row
                              align={"center"}
                              style={{ width: "80%", marginTop: ".25vw" }}
                            >
                              <Text
                                style={{
                                  fontSize: ".8vw",
                                  // top: ".25vw",
                                  position: "relative",
                                  fontWeight: "600",

                                  color: Theme.PRIMARY
                                }}
                              >
                                {assessment.notes}
                              </Text>
                            </Row>
                          )}
                          {isEditing && (
                            <form
                              onSubmit={(e) => handleSaveClick(e)}
                              style={{
                                background: "transparent",
                                paddingBottom: "10%"
                              }}
                            >
                              <Col
                                align="center"
                                justify="center"
                                style={{ top: "1vw", position: "relative" }}
                              >
                                <Col
                                  align="center"
                                  justify="center"
                                  style={styles.fieldrow}
                                >
                                  <Input
                                    className={
                                      Theme.BACKGROUND === "#000"
                                        ? "custom-placeholder-color-dark"
                                        : "custom-placeholder-color-light"
                                    }
                                    style={{
                                      width: "10vw",
                                      color: Theme.TEXT,
                                      background: Theme.BACKGROUND,
                                      borderWidth: 0
                                    }}
                                    placeholder={notes} // This sets the placeholder, not the default value
                                    name="notes"
                                    secureTextEntry={true}
                                    onChange={(e) => onNotesChange(e)}
                                    defaultValue={assessment.notes}
                                  />
                                </Col>

                                <div
                                  middle
                                  row
                                  space="evenly"
                                  style={{
                                    top: "1vw",
                                    position: "relative",
                                    marginBottom: "4vw"
                                  }}
                                >
                                  <Button
                                    small
                                    style={{
                                      backgroundColor: Theme.BACKGROUND,
                                      borderRadius: 5,
                                      borderWidth: 0,

                                      marginRight: ".5vw",
                                      minWidth: "4vw",
                                      cursor: "pointer",
                                      boxShadow:
                                        "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                                    }}
                                    onClick={(e) => cancelEdit(e)}
                                  >
                                    <Text
                                      style={{
                                        color: Theme.TEXT,
                                        fontWeight: "bold"
                                      }}
                                    >
                                      Cancel
                                    </Text>
                                  </Button>
                                  <Button
                                    small
                                    style={{
                                      backgroundColor: Theme.BACKGROUND,
                                      borderRadius: 5,
                                      borderWidth: 0,

                                      marginRight: ".5vw",
                                      minWidth: "4vw",
                                      cursor: "pointer",
                                      boxShadow:
                                        "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                                    }}
                                    onClick={(e) => handleSaveClick(e)}
                                  >
                                    <Text
                                      style={{
                                        color: Theme.TEXT,
                                        fontWeight: "bold"
                                      }}
                                    >
                                      Save
                                    </Text>
                                  </Button>
                                </div>
                              </Col>
                            </form>
                          )}
                        </Col>
                      </div>
                    </div>
                    <div></div>
                  </Col>
                </Card>
              </Col>
              {onlyCmj ? (
                <Col align="middle" justify="center">
                  <Card
                    style={{
                      zIndex: 100,
                      flex: 1,
                      position: "relative",
                      width: isRowLayout ? "40vw" : "40vw",
                      background: Theme.CARD,
                      borderRadius: 10,
                      paddingBottom: "10%",
                      borderWidth: 0,
                      height: "19vw"
                    }}
                  >
                    <Recommendations
                      exercises={assessment.recommended_exercises}
                    ></Recommendations>
                  </Card>
                </Col>
              ) : (
                <Col align="center" justify="center" style={{ height: "15vw" }}>
                  <ResultsScores
                    isRowLayout={isRowLayout}
                    scoreArray={scoreArray}
                    headerClass={headerClass}
                    headerTitleClass={headerTitleClass}
                    createdOn={createdOn}
                  />
                </Col>
              )}
            </Row>
            {!onlyCmj && (
              <Row
                align="center"
                justify="center"
                style={{ alignSelf: "center", position: "relative" }}
                gutter={[12, 12]}
              >
                <Col align="center" justify="center">
                  <Card
                    style={{
                      zIndex: 100,
                      flex: 1,
                      position: "relative",
                      width: isRowLayout ? "30.75vw" : "25vw",
                      background: Theme.CARD,
                      borderRadius: 10,
                      paddingBottom: "10%",
                      borderWidth: 0,
                      height: "19vw"
                    }}
                    // className="criclebox h-full"
                  >
                    {/* <div style={styles.body}> */}
                    <div>
                      <div>
                        <Body
                          areasOfConcerns={assessment.areasOfConcern}
                          movements={movements}
                        />
                      </div>
                    </div>
                    {/* </div> */}
                  </Card>
                </Col>
                <Col align="middle" justify="center">
                  <Card
                    style={{
                      zIndex: 100,
                      flex: 1,
                      position: "relative",
                      width: isRowLayout ? "40vw" : "40vw",
                      background: Theme.CARD,
                      borderRadius: 10,
                      paddingBottom: "10%",
                      borderWidth: 0,
                      height: "19vw"
                    }}
                  >
                    <Recommendations
                      exercises={assessment.recommended_exercises}
                    ></Recommendations>
                  </Card>
                </Col>
              </Row>
            )}
            {assessment.cmj !== null && (
              <Col
                align="center"
                justify="center"
                style={{ marginTop: "2vw", position: "relative" }}
              >
                <CMJResults item={movements[1]} cmj={assessment.cmj} />
              </Col>
            )}
            {assessment.squat !== null && (
              <Col
                align="center"
                justify="center"
                style={{ marginTop: "2vw", position: "relative" }}
              >
                <SquatResults
                  item={movements[1]}
                  compensations={assessment.squat.squat_compensations}
                  squat={assessment.squat}
                  name="OVERHEAD"
                />
              </Col>
            )}
            {assessment.standard_squat !== null && (
              <div style={{ marginTop: "2vw", position: "relative" }}>
                <SquatResults
                  item={movements[1]}
                  compensations={assessment.standard_squat.squat_compensations}
                  squat={assessment.standard_squat}
                  name="STANDARD"
                />
              </div>
            )}
            {assessment.push !== null && (
              <div style={{ marginTop: "2vw", position: "relative" }}>
                <PushResults
                  item={movements[2]}
                  compensations={assessment.push.push_compensations}
                  push={assessment.push}
                />
              </div>
            )}
            {assessment.r_rdl !== null && (
              <div style={{ marginTop: "2vw", position: "relative" }}>
                <RdlResults
                  item={movements[2]}
                  compensations={assessment.r_rdl.rdl_compensations}
                  rdl={assessment.r_rdl}
                />
              </div>
            )}
            {assessment.l_rdl !== null && (
              <div style={{ marginTop: "2vw", position: "relative" }}>
                <RdlResults
                  item={movements[2]}
                  compensations={assessment.l_rdl.rdl_compensations}
                  rdl={assessment.l_rdl}
                />
              </div>
            )}
            {assessment.hinge !== null && (
              <div style={{ marginTop: "2vw", position: "relative" }}>
                <HingeResults
                  item={movements[1]}
                  compensations={assessment.hinge.hinge_compensations}
                  hinge={assessment.hinge}
                />
              </div>
            )}
            {assessment.r_lunge !== null && (
              <div style={{ marginTop: "2vw", position: "relative" }}>
                <LungeResults
                  item={movements[2]}
                  compensations={assessment.r_lunge.lunge_compensations}
                  lunge={assessment.r_lunge}
                />
              </div>
            )}
            {assessment.l_lunge !== null && (
              <div style={{ marginTop: "2vw", position: "relative" }}>
                <LungeResults
                  item={movements[2]}
                  compensations={assessment.l_lunge.lunge_compensations}
                  lunge={assessment.l_lunge}
                />
              </div>
            )}
            {assessment.r_lat_lunge !== null && (
              <div style={{ marginTop: "2vw", position: "relative" }}>
                <LatLungeResults
                  item={movements[2]}
                  compensations={assessment.r_lat_lunge.lunge_compensations}
                  lunge={assessment.r_lat_lunge}
                />
              </div>
            )}
            {assessment.l_lat_lunge !== null && (
              <div style={{ marginTop: "2vw", position: "relative" }}>
                <LatLungeResults
                  item={movements[2]}
                  compensations={assessment.l_lat_lunge.lunge_compensations}
                  lunge={assessment.l_lat_lunge}
                />
              </div>
            )}
            {assessment.r_balance !== null && (
              <div style={{ marginTop: "2vw", position: "relative" }}>
                <BalanceResults
                  item={movements[2]}
                  compensations={assessment.r_balance.balance_compensations}
                  balance={assessment.r_balance}
                />
              </div>
            )}
            {assessment.l_balance !== null && (
              <div style={{ marginTop: "2vw", position: "relative" }}>
                <BalanceResults
                  item={movements[2]}
                  compensations={assessment.l_balance.balance_compensations}
                  balance={assessment.l_balance}
                />
              </div>
            )}
            {assessment.posture !== null && (
              <div style={{ marginTop: "2vw", position: "relative" }}>
                <Posture item={movements[2]} posture={assessment.posture} />
              </div>
            )}
          </Col>
        </Col>
      </>
    );
  }
};

export default ResultsSections;

const createStyles = (theme) => ({
  container: {
    // flex: 1,

    backgroundColor: "#fff"

    // marginBottom: "10%"
  },
  scoreText: {
    // top: 50,
    paddingLeft: "5%",
    fontSize: 15,
    fontWeight: "bold",
    // paddingBottom: "3%"
    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5
  },
  sectionText: {
    marginVertical: 10,
    top: 15,
    alignSelf: "center",
    fontSize: 25,
    fontWeight: "bold",
    paddingBottom: "8%",
    // paddingLeft: '5%'
    fontFamily: "ArialBold",
    color: theme.BLACK
  },
  headerTitle: {
    // top: 5,

    // alignSelf: "center",
    color: theme.BLACK,
    fontWeight: "600",
    fontSize: ".75vw",
    // paddingLeft: "5%"
    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.3,
    elevation: 2
  },
  headerText: {
    // top: 5,
    // alignSelf: "center",
    color: theme.BLACK,
    fontWeight: "bold",
    // paddingLeft: "5%"
    fontSize: "1.5vw",
    position: "relative",
    // bottom: 10,
    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.2
  },
  messageText: {
    color: "black",
    fontSize: 12,
    // top: 50,
    alignSelf: "center",
    fontSize: 15,
    paddingVertical: "3%",
    paddingHorizontal: "3%"
  },
  body: {
    // alignContent: 'center',
    // top: 50
    // width: "100%",
  },
  closeModal: {
    justifyContent: "flex-start",
    borderRadius: 10
    // zIndex: 20,
  },

  resultText: {
    color: theme.PRIMARY,
    fontSize: 14,
    fontWeight: "bold",
    padding: 8
  },
  header: {
    top: "20%",

    borderWidth: 0,

    flex: 1
  },
  concernSection: {
    // top: -300,
    paddingTop: "5%",
    alignSelf: "center",
    // margin: 6,
    backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    borderWidth: 0,
    maxHeight: "100%",
    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.1,
    elevation: 2
  },
  pages: {
    position: "relative",
    // paddingTop: "5%",
    alignSelf: "center",
    // margin: 6,
    // backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    height: 775,
    top: 50,

    borderWidth: 0

    // elevation: 2,
    // elevation: 2,
  },
  body: {
    position: "relative",
    paddingTop: "5%",
    alignSelf: "center",
    margin: 6,
    // backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    height: bodyHeight,

    borderWidth: 0,

    width: "97.5%"
  },
  scoreSection: {
    // top: -300,
    paddingTop: "10%",
    alignSelf: "center",
    margin: 10,
    backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    borderWidth: 0,
    maxHeight: "85%",

    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.7,
    elevation: 2
  },
  detailSection: {
    // top: -300,
    paddingTop: "10%",
    alignSelf: "center",
    margin: 10,
    backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    borderWidth: 0,
    // maxHeight: '110%',

    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.1,
    elevation: 2
  },
  buttontext: {
    color: theme.WHITE,
    fontWeight: "bold",
    marginHorizontal: 5
    // paddingHorizontal: 5,
  },
  video: {
    alignSelf: "center",

    height: 500,
    marginVertical: "10%",
    marginHorizontal: "2%"
  },
  tourvideo: {
    alignSelf: "center",
    width: 200,
    height: 200,
    bottom: "5%",
    // marginVertical: "10%",
    marginHorizontal: "2%"
  },
  modalView: {
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    top: modalMargin
    // elevation: 5,
  },
  bottomView: {
    flex: 1,
    justifyContent: "flex-end",
    top: 20,
    alignSelf: "center"
  },

  messageText: {
    color: theme.PRIMARY,
    fontWeight: "bold",
    paddingVertical: 8,
    paddingRight: 4,
    top: 50,
    fontFamily: "Arial"
  },
  icon: {
    // alignSelf: 'center',
    position: "absolute",
    opacity: 1,

    bottom: "25%",
    // left: '10%'
    zIndex: 25
  },
  lottieContainer: {
    flex: 1,
    alignSelf: "center",
    justifyContent: "center",

    position: "absolute",

    zIndex: 5
  },
  lottieStyle: {
    flex: 1,
    alignSelf: "center",
    justifyContent: "center",
    // backgroundColor: "#8d93de",
    opacity: 0.25,
    position: "absolute",

    zIndex: 5
  },
  modalText: {
    // color: theme.PRIMARY,
    flexWrap: "wrap",
    marginHorizontal: 5,
    left: 10,

    // textAlign: "center",
    alignSelf: "flex-start",
    paddingHorizontal: 10,
    marginVertical: 5,
    fontFamily: "ArgonExtra"
  },
  tourModal: {
    // backgroundColor: "black",
    backgroundColor: "white",
    borderRadius: 10,
    // padding: 150,
    bottom: 5,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5
  },
  bottomView: {
    flex: 1,
    justifyContent: "center",
    top: 15,
    alignSelf: "center"
  }
});
