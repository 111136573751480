import {
  GET_ORG,
  UPDATE_ORG,
  RESET_ORG_STATUS
} from "../actions/types";

const initialState = {
  organization: null,
  organizations: [],
  orgStatus: null,
};

function organizationReducer(state = initialState, action) {
  switch (action.type) {
    case GET_ORG:
      return {
        ...state,
        organization: action.payload,
      };
    case UPDATE_ORG:
      return {
        ...state,
        organization: action.payload,
      }
    case RESET_ORG_STATUS:
      return {
        ...state,
        orgStatus: null,
      };
    default:
      return state;
  }
}

export default organizationReducer;
