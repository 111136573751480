import React from "react";
import { Modal, Col, Row, Typography } from "antd";
import customblank from "../../assets/images/customblank.jpg"; // Import your custom image here

const ExerciseModal = ({
  infoModalVisible,
  handleInfoModalClose,
  selectedExercise,
  Theme,
}) => {
  const { Text } = Typography;

  return (
    <Modal
      open={infoModalVisible}
      onCancel={handleInfoModalClose}
      width={"65vw"}
      bodyStyle={{
        paddingBottom:
          selectedExercise &&
          selectedExercise.library_exercise.video_file &&
          selectedExercise.exercise_type === "custom"
            ? "20vw"
            : "10vw",
        paddingTop: "1vw",
        paddingLeft: "1vw",
        paddingRight: "1vw",
        minHeight: "25vw",
      }}
      className={
        Theme.BACKGROUND === "#000"
          ? "customAntdModalDark"
          : "customAntdModalLight"
      }
      footer={null}
    >
      <Col align="middle" justify="center">
        {selectedExercise && (
          <Col align="center" justify="center">
            <Text
              style={{ color: Theme.TEXT, fontSize: "1vw", fontWeight: "bold" }}
            >
              {selectedExercise.library_exercise.name}
            </Text>
            <Row
              gutter={16}
              align="center"
              justify="center"
              style={{ position: "relative", marginTop: "4vw", marginLeft:"5%" }}
            >
              <Col span={12} align="center" justify="center">
                {selectedExercise.library_exercise.video_file ? (
                  <video
                    style={{
                      width: "85%",
                      height: "auto",
                      maxHeight: "20vw",
                      objectFit: "contain",
                      borderRadius: 20,
                    }}
                    controls
                    src={selectedExercise.library_exercise.video_file}
                  />
                ) : selectedExercise.library_exercise.image ? (
                  <img
                    style={{
                      width: "85%",
                      height: "auto",
                      maxHeight: "20vw",
                      borderRadius: 20,
                    }}
                    alt={selectedExercise.name}
                    src={selectedExercise.image}
                  />
                ) : (
                  <img
                    style={{
                      width: "85%", // Use 100% width
                      height: "auto", // Maintain aspect ratio
                      objectFit: "contain", // Maintain aspect ratio and fill container
                      borderRadius: 20,
                    }}
                    alt={selectedExercise.library_exercise.name}
                    src={customblank}
                  />
                )}
              </Col>
              <Col span={12} style={{ textAlign: "left" }}>
                <div style={{ marginBottom: "1vw" }}>
                  <Text
                    style={{
                      fontWeight: "bold",
                      fontSize: ".75vw",
                      marginBottom: "1vw",
                      marginTop: ".5vw",
                      color: Theme.TEXT,
                    }}
                  >
                    Instructions
                  </Text>
                </div>
                {selectedExercise.library_exercise.instructions &&
                selectedExercise.library_exercise.instructions !==
                  "undefined" ? (
                  <Text
                    style={{
                      fontSize: "1vw",
                      textAlign: "left",
                      marginTop: "1vw",
                    }}
                  >
                    {selectedExercise.library_exercise.instructions
                      .split("\n")
                      .map((instruction, index) => (
                        <Text
                          style={{
                            textAlign: "left",
                            color: Theme.TEXT2,
                            fontSize: ".8vw",
                          }}
                          key={index}
                        >
                          {instruction}
                          <br />
                        </Text>
                      ))}
                  </Text>
                ) : (
                  <Text style={{ fontSize: ".85vw", color: Theme.TEXT2 }}>
                    No Instructions Provided
                  </Text>
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Col>
    </Modal>
  );
};

export default ExerciseModal;
