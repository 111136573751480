import { GET_BLOGS, SEARCH_BLOGS, GET_SINGLE_BLOG, GET_SUPPORT } from "../actions/types";

const initialState = {
  blogs: [],
  blog: {},
  clientStatus: null,
  supportVids: []
};

function assetReducer(state = initialState, action) {
  switch (action.type) {
    case GET_BLOGS:
    case SEARCH_BLOGS:
      return {
        ...state,
        blogs: action.payload,
      };
    case GET_SINGLE_BLOG:
      return {
        ...state,
        blog: action.payload,
      };
    case GET_SUPPORT:
      return{
        ...state,
        supportVids: action.payload
      }
    default:
      return state;
  }
}

export default assetReducer;
