import { useState, useEffect } from "react";
import { Col, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSingleClient } from "../redux/actions/clients";
import Program from "../components/workouts/Program";



const ProgramDetails = ({ route, navigation }) => {

  const { client } = useSelector((state) => state.clients);
  const dispatch = useDispatch();
  const fetchClient = () => dispatch(getSingleClient(client.id));






  const [isFadedIn, setIsFadedIn] = useState(false);

  useEffect(() => {
    // Trigger the fade-in effect once the component is mounted
    setIsFadedIn(true);
  }, []);
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const flexDirection = isRowLayout ? "row" : "column";

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Row style={{ minHeight: "200vh", marginRight: '15vw' }}>
      <Col >
      <Program/>
      </Col>
    </Row>
  );
};

export default ProgramDetails;
