import React, {  } from "react";
import { Card, Col, Row, Typography, Spin } from "antd";
import Theme from "../../constants/Theme";
import { useSelector } from "react-redux";
import ResultsSectionTitle from "./ResultsSectionTitle";
import sections from "../../constants/sections";
import Compensations from "./Compensations";
import JointDisplacementBalance from "./JointDiscplacementBalance";

import ChartComponent from "./LineChart";
import ScoreCircle from "./ScoreCircle";
import JointDisplacement from "./JointDiscplacement";

const { Text } = Typography;


const BalanceResults = ({ item, compensations, balance }) => {

  const { Theme } = useSelector((state) => state.auth);
  const styles = createStyles(Theme);


  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        top: "5%",
        position: "relative"
      }}
    >
      <Col justify="start">
        <Row
          style={{ alignSelf: "center", position: "relative" }}
          gutter={[16, 16]}
        >
          <Col>
            <Col
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: 300,
                height: 500,
                display: "flex",
                borderRadius: 10,
                backgroundColor: Theme.CARD,
                boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.025)"
              }}
              className="criclebox h-full"
            >
              <div
                className="aoc"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  top: "3vw",
                  position: "relative",
                  width: 325,
                  top: 55
                }}
              >
                <Col align="center" style={{ position: "relative" }}>
                  <Row
                    align="center"
                    style={{
                      position: "relative",
                      width: "19vw",
                      right: "1vw"
                    }}
                  >
                    <Col
                      align={"start"}
                      style={{ position: "relative", right: "17.5%" }}
                    >
                      <Text style={styles.movementTitle}>SINGLE LEG</Text>
                      <div style={{ bottom: ".25vw", position: "relative" }}>
                        <Text style={styles.movementTitle}>
                          BALANCE ({balance.side[0]})
                        </Text>
                      </div>
                    </Col>
                    <Col
                      align="middle"
                      style={{
                        position: "relative",
                        left: "20%",
                        bottom: "1vw"
                      }}
                    >
                      <ScoreCircle
                        score={Math.round(balance.balance_overall_score)}
                        height={55}
                      />
                      <div>
                        <Text style={styles.messageText}>Overall</Text>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col
                  style={{
                    bottom: 70,
                    position: "relative",
                    paddingBottom: "1vw",
                    width: 200
                  }}
                >
                  <JointDisplacement
                    movement={balance}
                    hideTitle={true}
                    smallerBody={true}
                  />
                </Col>
              </div>
            </Col>
          </Col>

          <Col align="middle" justify="center">
            <Col
              align="center"
              justify="center"
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: 225,
                borderRadius: 10,
                background: Theme.CARD,
                borderWidth: 0,
                boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.025)"
              }}
              className="criclebox h-full"
            >
              <Col
                align="middle"
                justify={"center"}
                style={{ top: 40, position: "relative" }}
              >
                <Text
                  style={{
                    fontSize: 15,
                    fontWeight: "bold",
                    color: Theme.TEXT,
                    position: "relative",
                    top: 15
                  }}
                >
                  Hip Movement
                </Text>

                <ChartComponent
                  chartData={balance.hip_displacement_array}
                  section={sections[7]}
                  showY={false}
                />
                <div
                  style={{
                    marginTop: "1.5vw",
                    marginBottom: "1.5vw"
                  }}
                >
                  <Text
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                      color: Theme.TEXT,
                      position: "relative",
                      top: 15
                    }}
                  >
                    Shoulder Movement
                  </Text>

                  <ChartComponent
                    chartData={balance.shoulder_displacement_array}
                    section={sections[8]}
                    showY={false}
                  />
                </div>
                <Text
                  style={{
                    fontSize: 15,
                    fontWeight: "bold",
                    color: Theme.TEXT,
                    position: "relative",
                    top: 15
                  }}
                >
                  Knee Level
                </Text>

                <ChartComponent
                  chartData={balance.knee_displacement_array}
                  section={sections[9]}
                  showY={false}
                />
              </Col>
            </Col>
          </Col>

          <Col>
            <Col
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: 200,
                borderRadius: 10,
                background: Theme.CARD,
                borderWidth: 0,
                boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.025)"
              }}
              className="criclebox h-full"
            >
              <Compensations
                compensations={compensations}
                score={balance.compensation_score}
              />
            </Col>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default BalanceResults;

let textSize = 14;
let modalText = 16;
let compTitle = 20;
let compSectText = 18;
let modalMargin = 0;
let picLeft = "2%";
let avgRight = 160;
let minHeight = 160;

const createStyles = (theme) => ({
  container: {
    alignSelf: "center"
  },
  card: {
    backgroundColor: Theme.COLORS.WHITE,
    //   marginVertical: Theme.SIZES.BASE,
    borderWidth: 0,
    minHeight: minHeight,
    marginBottom: 1,
    marginHorizontal: 1
  },

  cardTitle: {
    flex: 1,
    alignSelf: "center",
    fontWeight: "500"
    // paddingTop: 5,
  },
  cardDescription: {
    // paddingTop: 5,
  },
  imageContainer: {
    borderRadius: 3,
    elevation: 1,
    overflow: "hidden",
    minHeight: 80
  },

  horizontalImage: {
    width: "40%",
    height: "40%"
    // position: "absolute",
    // left: "5%",
    // bottom: "1vw",
  },
  videoText: {
    color: Theme.COLORS.BLACK,
    fontSize: 12,
    left: "5.5%",
    marginTop: "1%",
    maxWidth: "60%",
    maxHeight: "50%",
    fontWeight: "bold"
  },
  horizontalStyles: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  verticalStyles: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
  },
  fullImage: {
    height: 215
  },
  messageText: {
    color: theme.TEXT,
    fontSize: 15,
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  typeText: {
    color: theme.TEXT,
    fontSize: ".65vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".65vw"
  },
  depthText: {
    color: theme.TEXT,
    fontSize: ".65vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  messageTextVideo: {
    color: Theme.COLORS.WHITE,
    fontSize: ".5vw",
    fontWeight: "bold",
    paddingVertical: 8,
    paddingRight: 4,
    fontFamily: "Arial"
  },
  compScoreText: {
    color: Theme.COLORS.BLACK,
    fontSize: textSize,
    fontWeight: "bold",
    // paddingVertical: 8,
    // paddingRight: 4,
    fontFamily: "Arial",
    // alignSelf: 'flex-end',

    top: 275
  },
  resultText: {
    color: Theme.COLORS.PRIMARY,
    fontSize: 15,
    position: "relative",
    right: "35%",
    fontWeight: "bold"
    // marginRight: "5%",
  },
  sectionText: {
    fontWeight: "500",
    fontSize: 19,
    color: Theme.COLORS.BLACK
  },
  compensationTitle: {
    fontWeight: "500",
    fontSize: compTitle,
    marginTop: 30,
    marginBottom: 5,
    color: Theme.COLORS.POP
  },
  scoreText: {
    fontWeight: "500",
    fontSize: 15,
    color: Theme.COLORS.PRIMARY
  },
  movementTitle: {
    textAlign: "left",
    fontSize: 15,
    fontWeight: "bold",
    // fontFamily: "ArialBold",
    color: theme.TEXT,
    position: "relative",
    // alignSelf: "center",
    left: ".5%"
  },
  movementScore: {
    fontWeight: "bold",
    fontSize: 15,
    // alignSelf: "center",
    color: Theme.COLORS.PRIMARY,
    left: ".45vw",
    position: "relative"
    // marginTop: 5,
  },
  list: {
    position: "absolute",
    top: 20,
    zIndex: 20,
    flex: 1
  },

  modalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    top: modalMargin
    // elevation: 5,
  },
  bottomView: {
    flex: 1,
    justifyContent: "flex-end",
    top: 20,
    alignSelf: "center"
  },
  infoModalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4
    // elevation: 5,
  },
  button: {
    marginVertical: 15,
    width: 250,
    borderRadius: 15,
    // position: "relative",
    backgroundColor: "white"
  },
  buttontext: {
    color: Theme.COLORS.PRIMARY,
    fontWeight: "bold",
    paddingHorizontal: 5
  },
  modalText: {
    color: Theme.COLORS.WHITE,
    maxWidth: "85%",
    marginBottom: 20,
    textAlign: "center",
    fontSize: modalText,
    marginTop: 5
  },
  video: {
    alignSelf: "center",
    width: "100%",
    height: "95%",
    marginVertical: "10%",
    marginHorizontal: "2%",
    bottom: "8%",

    backgroundColor: "black"
  }
});
