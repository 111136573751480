import axios from "axios";
import {
  CREATE_JOINT_COMPARISON_FAIL,
  CREATE_JOINT_COMPARISON_SUCCESS,
  GET_SINGLE_JOINT_COMPARISON,
  RESET_JOINT_COUNTER,
  GET_JOINT_COMPARISONS,
  PATCH_JOINT_COMPARISON_SUCCESS
} from "./types";
import { REACT_APP_API_URL } from "../../env";

// Get JCs
export const getJointComparisons = (clientID) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("access");
    var config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      }
    };
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/api/jointass/?client_id=${clientID}`,
        config
      );
      if (res.data) {
        dispatch({
          type: GET_JOINT_COMPARISONS,
          payload: res
        });
      }
    } catch (err) {
    }
  };
};

export const createJointComparison = (data) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token
    }
  };
  try {
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/jointass/`,
      data,
      config
    );
    dispatch({
      type: CREATE_JOINT_COMPARISON_SUCCESS,
      payload: res
    });
  } catch (err) {
    dispatch({
      type: CREATE_JOINT_COMPARISON_FAIL,
      payload: err.response.status
    });
  }
};

export const patchJointComparison = (formData, id) => async (dispatch) => {
  const token = await localStorage.getItem("access");
    const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token
    }
  };

  try {
    const res = await axios.patch(
      `${REACT_APP_API_URL}/api/jointass/${id}/`,
      formData,
      config
    );
    if (res.data) {
      dispatch({
        type: PATCH_JOINT_COMPARISON_SUCCESS,
        payload: res.data
      });
    }
  } catch (err) {

  }
};

// Get single assessment
export const getSingleJointComparison = (assessID) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token
    }
  };

  try {
    const res = await axios.get(
      `${REACT_APP_API_URL}/api/jointass/${assessID}/`,
      config
    );
    if (res.data) {

      dispatch({
        type: GET_SINGLE_JOINT_COMPARISON,
        payload: res.data
      });
    }
  } catch (err) {
  }
};

export const deleteJointComparison = (id, clientID) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token
    }
  };

  try {
    const res = await axios.delete(
      `${REACT_APP_API_URL}/api/jointass/${id}/`,
      config
    );

    if (res.status === 204) {
      dispatch(getJointComparisons(clientID));
    }
  } catch (err) {
  }
};
// Reset joint comparison
export const resetJointComparison = () => ({
  type: "RESET_JOINT_COMPARISON"
});

export const resetJointComparisonStatus = () => ({
  type: "RESET_JOINT_COMPARISON_STATUS"
});

export const resetCounter = () => ({
  type: RESET_JOINT_COUNTER
});
