import React from "react";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";

const NumeroInput = ({ value, placeholder, onChange, error, width, required }) => {
  const { Theme } = useSelector((state) => state.auth);
const handleChange = (e) => {
  const newValue = e.target.value;
  // Allow only numbers, and optionally a decimal point
  if (/^\d*\.?\d*$/.test(newValue)) {
    onChange(e);
  }
};
  return (
    <div>
      <Form.Control
        type="numeric"
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        required={required ? true : false}
        style={{
          width: width || "100%",
          height: "auto",
          borderRadius: "10px",
          boxShadow: "1px 0px 2px 3px rgba(0, 0, 0, 0.1)",
          borderWidth: 0,
          fontSize: ".75vw",
          background: Theme.BACKGROUND,
          color: Theme.PRIMARY,
          outline: "none",
          textAlign: "center",
          fontWeight:"bold"
        }}
      />
      {error && (
        <p
          style={{
            position: "relative",
            // top: 55,
            color: "red",
            fontSize: ".75vw"
            // right: '25%'
            // alignSf: 'flex-start'
            // marginLeft: 12,
          }}
        >
          {error}
        </p>
      )}
    </div>
  );
};

export default NumeroInput;
