import React, { useState } from "react";
import { Col, Modal, Row, Typography } from "antd";
import { IoInformationCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

const { Text } = Typography;

function ResultsSectionTitle({ section }) {
  const [modalVisible, setModalVisible] = useState(false);
  const { Theme } = useSelector((state) => state.auth);

  return (
    <div
      style={{
        // marginBottom: "1%",
        width: "100%",
        fontWeight: "bold",
        position: "relative"
      }}
    >
      <Col>
        <Text
          style={{
            marginTop: 12,
            fontSize: 14,
            fontWeight: "600",
            alignSelf: "center",
            position: "relative",
            color: Theme.TEXT
          }}
        >
          {section.title.toUpperCase()}
        </Text>
      </Col>
      
    </div>
  );
}



export default ResultsSectionTitle;
