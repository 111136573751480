import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  Input,
  Button,
  Checkbox,
  Card,
  Row,
  Typography,
  Col,
  Spin
} from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import {
  createGroup,
  resetGroupStatus,
  getSingleGroup
} from "../../redux/actions/groups";
import { useHistory } from "react-router-dom";
import { css, StyleSheet } from "aphrodite";
import Theme from "../../constants/Theme";
import { IoPeopleOutline } from "react-icons/io5";
import { getGroups } from "../../redux/actions/groups";
import BootstrapInput from "../common/Input";
import { checkTeamLimit } from "../../constants/utils";
import { load_user } from "../../redux/actions/auth";

const { Text } = Typography;

const CreateGroupForm = ({ closeModal }) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [isLoading, setLoading] = useState(false);
  const { groupStatus } = useSelector((state) => state.groups);
  const { user, Theme } = useSelector((state) => state.auth);
  const [reachedLimit, setReachedLimit] = useState(false);
  const { subscriptionStatus, compedMembership } = useSelector(
    (state) => state.payment
  );
  const styles = createStyles(Theme);
  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    const data = new FormData();
    data.append("name", values.group_name);
    dispatch(createGroup(data));

    setLoading(false);
  };
  
  useEffect(() => {
    checkTeamLimit(setReachedLimit, user);
  }, []);

  useEffect(() => {
    checkTeamLimit(setReachedLimit, user);
  }, [subscriptionStatus, user]);

  useEffect(() => {
    if (groupStatus === 200) {
      formikRef.current?.resetForm();
      dispatch(load_user());
      dispatch(resetGroupStatus());
      closeModal();
    }
  }, [groupStatus]);

  return (
    <Col
      bordered={true}
      style={{
        zIndex: 100,
        // flex: 1,
        position: "relative"
      }}
      className="criclebox h-full"
    >
      {!reachedLimit ? (
        <div>
          <Formik
            initialValues={{
              group_name: ""
            }}
            onSubmit={handleSubmit}
            validationSchema={yup.object().shape({
              group_name: yup.string().required("Required")
            })}
          >
            {({ handleChange, handleSubmit, values, errors, touched }) => (
              <Col align="center" style={styles.registerContainer}>
                <div style={styles.block}>
                  <div style={styles.title}>
                    <p
                      style={styles.titleText}
                  
                    >
                      Create New Team
                    </p>
                  </div>

                  <Row
                    justify={"center"}
                    style={styles.row}
            
                  >
                    <div style={styles.column}>
                      <BootstrapInput
                        value={values.group_name}
                        placeholder="Team Name"
                        onChange={handleChange("group_name")}
                        Theme={Theme}
                        width={"15vw"}
                      />
                      {touched.group_name && errors.group_name && (
                        <p
                          style={{
                            position: "relative",
                            color: "red",
                            fontSize: ".75vw"
                          }}
                        >
                          {errors.group_name}
                        </p>
                      )}
                    </div>
                  </Row>
                  {!isLoading ? (
                    <Button
                      small
                      style={{
                        backgroundColor: Theme.BACKGROUND,
                        borderRadius: 5,
                        borderWidth: 0,
                        cursor: "pointer",
                        width: "10vw",
                        //  padding: "1.5%",
                        backgroundImage:
                          "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                        marginTop: "2vw",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)"
                      }}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      <Text style={styles.buttontext}>Submit</Text>
                    </Button>
                  ) : (
                    <Spin size="medium" style={{ marginTop: "1vw" }} />
                  )}
                  {groupStatus > 399 && (
                    <div
                      style={styles.row}
                      
                    >
                      <p style={styles.errorText}>
                        An error has occured - Please try again or contact
                        support
                      </p>
                    </div>
                  )}
                </div>
              </Col>
            )}
          </Formik>
        </div>
      ) : (
        <Col justify="center">
          <Row align="center">
            <IoPeopleOutline
              style={{
                fontSize: "2vw",
                color: Theme.PRIMARY,
                marginBottom: "3%"
              }}
            />
          </Row>
          <Row align="center">
            <Text style={styles.movementTitle}>Team Limit Reached</Text>
          </Row>
          <Row align="center">
            <Text style={styles.clientDescr}>
              Contact us to increase your limit
            </Text>
          </Row>
        </Col>
      )}
    </Col>
  );
};

export default CreateGroupForm;

const createStyles = (theme) => ({
  container: {
    backgroundColor: "#FFF",
    alignContent: "center"
  },
  title: {
    color: "#232b2b",
    fontSize: 18,

    bottom: 25,
    fontWeight: "600",
    alignSelf: "center"
  },
  input: {
    backgroundColor: theme.BLACK,
    borderBottomColor: "gray",
    borderColor: "white",
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
    margin: 10,
    width: "15vw",
    height: "2vw",
    fontSize: "1vw"
  },
  smallInput: {
    backgroundColor: theme.BACKGROUND,
    placeholder: {
      color: theme.TEXT
    },
    // borderBottomColor: "black",
    // borderColor: "white",
    // borderWidth: 5,
    color: theme.TEXT,
    borderRadius: 10,
    padding: 10,
    margin: 10,
    width: "15vw",
    height: "2vw",
    fontSize: "1vw",
    ":focus": {
      borderColor: theme.WHITE // Change the border color as needed
    }
  },
  checkboxText: {
    fontSize: ".75vw",
    width: "80%"
  },
  registerContainer: {
    backgroundColor: "transparent",
    borderRadius: 10,
    shadowColor: theme.BLACK,
    shadowOffset: {
      width: 0,
      height: 4
    },
    justifyContent: "center",
    shadowRadius: 8,
    shadowOpacity: 0.3,
    elevation: 1
    // height: "15vw",
    // width: "15vw",
    // marginTop: 10,
  },
  inputIcons: {
    marginRight: 12
  },
  titleText: { fontSize: "1vw", color: theme.TEXT },
  createButton: {
    width: "5vw",
    marginTop: "5%",
    backgroundImage: "linear-gradient(45deg, #C04848 0%, #480048 80%)",
    borderWidth: 0,
    borderRadius: 10,
    cursor: "pointer"
  },
  buttontext: {
    color: theme.WHITE,
    fontWeight: "bold",

    fontSize: ".75vw"

    // paddingHorizontal: 5,
  },
  errors: {
    position: "relative",
    // top: 55,
    color: "red",
    fontSize: ".75vw"
    // right: '25%'
    // alignSf: 'flex-start'
    // marginLeft: 12,
  },
  checkbox: {
    // top: 9,
    color: theme.PRIMARY,
    borderRadius: 5,
    width: 15,
    height: 15,
    position: "relative",
    right: "5%",
    top: "10%"
  },
  avatar: {
    height: 100,
    width: 100,
    borderRadius: 62,
    borderWidth: 0,
    alignSelf: "center",
    top: 20
  },
  movementTitle: {
    alignSelf: "center",
    fontSize: "1.3vw",
    fontWeight: "bold",
    color: theme.TEXT,
    textAlign: "center"
  },
  clientDescr: {
    alignSelf: "center",
    fontSize: ".75vw",
    // fontWeight: "bold",
    color: theme.TEXT2,
    textAlign: "center"
  }
});
