export default {
    "Squat": {
        description: [
            'Set range of motion ranges for hip, knee and ankles during a squat',
            'These ranges apply to both Overhead Squat and Standard Squat'
        ]
    },
    "CMJ": {
        description: [
            'The landing parameters apply to the pass fail data points of the CMJ',
            'Height ranges set what jump height in inches should be considered 100% by age and gender',
            'Knee valus sets the threshold of how far the knees can bend inwards before failing',
            'Hip Flexion IC sets the minimum amount the hip should be flexed at Initial Contact',
            'Knee Flexion IC sets the minimum degree the knee should be flexed at Initial Contact',
            'Trunk Flexion IC is the minimum amount the trunk should be leaned forward at Initial Contact',
            'Hip Flexion Change measures the minimum amount the hip degree should change from Initial Contact to Max Knee Flexion',
            'Knee Flexion Change measures the minimum degree the knee degree should change from Initial Contact to Max Knee Flexion',
            'Trunk Flexion Change measures the minimum amount the trunk degree should change from Initial Contact to Max Knee Flexion'
        ]
    },
    "Push": {
        description: [
            'Set range of motion ranges for elbows and shoulders as they raise their hands overhead'
        ]
    },
    "Reverse Lunge": {
        description: [
            'Set range of motion ranges for hips and knees as they step back into a lunge'
        ]
    },
    "Lateral Lunge": {
        description: [
            'Set range of motion ranges for hips, knees, ankles and hip abduction'
        ]
    },
    "Single Leg Hinge": {
        description: [
            'Set range of motion ranges for the hips',
            'The rest of the movement focuses on stability'
        ]
    },
    "Bilateral Hinge": {
        description: [
            'Set range of motion ranges for the hips',
            'The rest of the movement focuses on stability'
        ]
    },
    "Cervical": {
        description: [
            'Set the end angles for each of the isolated cervical movements in joint comparisons',
            'Neutral starts at 90° for each movement making the absolute value of 90° minus the end angle your range of motion'
        ]
    },
    "Shoulder": {
        description: [
            'Set the end angles for each of the isolated shoulder movements in joint comparisons',
            'Neutral starts at 0° for each movement (besides Horizontal Abduction) making the end angle the same as the range of motion',
            'For Horizontal Abduction, neutral starts at 90° making the absolute value of 90° minus the end angle your range of motion'
        ]
    },
    "Thoracic": {
        description: [
            'Set the end angle for trunk rotation',
            'Neutral starts at 0° making the end angle the same as the range of motion'
        ]
    },
    "Hip": {
        description: [
            'Hip flexion and extension are the total movement from starting position to ending position',
            'Hip abduction, internal rotation, and external rotation all have a neutral position of 90° making the absolute value of 90° minus the end angle you set your range of motion'
        ]
    },
    "Knee": {
        description: [
            'Set the end angles for each of the isolated knee movements in joint comparisons',
            'Neutral starts at 90° for knee extension making the absolute value of 90° minus the end angle your range of motion',
            'Knee flexion is the total movement from starting position to ending position'
        ]
    }
};
