import axios from "axios";
import {
  GET_ASSESSMENTS,
  CREATE_ASSESSMENT_SUCCESS,
  CREATE_ASSESSMENT_FAIL,
  GET_SINGLE_ASSESSMENT,
  CREATE_SQUAT_RESULT,
  RESET_MOVEMENTS,
  CREATE_POSTURE_RESULT,
  CREATE_PUSH_RESULT,
  CREATE_RIGHT_BALANCE_RESULT,
  CREATE_LEFT_BALANCE_RESULT,
  CREATE_RIGHT_LUNGE_RESULT,
  CREATE_LEFT_LUNGE_RESULT,
  CREATE_RIGHT_RDL_RESULT,
  CREATE_LEFT_RDL_RESULT,
  CREATE_FREE_RESULT,
  FAIL_SQUAT_RESULT,
  FAIL_POSTURE_RESULT,
  FAIL_PUSH_RESULT,
  FAIL_RIGHT_BALANCE_RESULT,
  FAIL_LEFT_BALANCE_RESULT,
  FAIL_RIGHT_LUNGE_RESULT,
  FAIL_LEFT_LUNGE_RESULT,
  FAIL_RIGHT_RDL_RESULT,
  FAIL_LEFT_RDL_RESULT,
  RESET_SQUAT_STATUS,
  RESET_POSTURE_STATUS,
  RESET_PUSH_STATUS,
  RESET_BALANCE_STATUS,
  RESET_LUNGE_STATUS,
  RESET_RDL_STATUS,
  RESET_FREE_STATUS,
  FAIL_FREE_RESULT,
  RESET_COUNTER,
  GET_FREE_RECORDS,
  PATCH_ASSESSMENT_SUCCESS,
} from "./types";
 
import { MOVE_ANALYSIS_URL, REACT_APP_API_URL } from "../../env";
 

// Get Assessments
export const getAssessments = (clientID) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("access");
    var config = {
      headers: {
        Authorization: "JWT " + token,
        "Content-Type": "application/json",
      },
      params: {
        id: clientID,
      },
    };
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/api/assessmentlist/`,
        config
      );
      if (res.data) {
        dispatch({
          type: GET_ASSESSMENTS,
          payload: res,
        });
      }
    } catch (err) {
    }
  };
};

// Get Free Records
export const getFreeRecords = (clientID) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("access");
    var config = {
      headers: {
        Authorization: "JWT " + token,
        "Content-Type": "application/json",
      },
      params: {
        id: clientID,
      },
    };
    try {
      const res = await axios.get(
        `${REACT_APP_API_URL}/api/freerecords/`,
        config
      );
      if (res.data) {
        dispatch({
          type: GET_FREE_RECORDS,
          payload: res,
        });
      }
    } catch (err) {
    }
  };
};

// Create assessment
export const createAssessment = (data) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };
  try {
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/assessments/`,
      data,
      config
    );
   
    dispatch({
      type: CREATE_ASSESSMENT_SUCCESS,
      payload: res,
    });
  } catch (err) {

    dispatch({
      type: CREATE_ASSESSMENT_FAIL,
      payload: err.response.status,
    });
  }
};

// Get single assessment
export const getSingleAssessment = (assessID, clientID) => async (dispatch) => {
     
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + token,
        },
      };
      
      try {
        const res = await axios.get(
          `${REACT_APP_API_URL}/api/assess/?id=${assessID}&client=${clientID}`,
          config
        );
        if (res.data) {
          
          dispatch({
            type: GET_SINGLE_ASSESSMENT,
            payload: res,
          });
        }
      } catch (err) {
  
      }
    

};

// Reset assessment
export const resetAssessment = () => ({
  type: "RESET_ASSESSMENT",
});

export const resetAssessmentStatus = () => ({
  type: "RESET_ASSESSMENT_STATUS",
});

export const resetCounter = () => ({
  type: RESET_COUNTER,
});


// Movement Analysis
export const resetMovements = () => ({
  type: RESET_MOVEMENTS,
});


export const createFree = () => ({
  type: CREATE_FREE_RESULT,
});

export const sendVideoForAnalysis = (data, movement) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          "'Content-Type'": "multipart/form-data",
        },
      };
      data.append('jwt_token', `JWT ${token}`)
      try {
  
        const res = await axios.post(`${MOVE_ANALYSIS_URL}`, data, config);
        if (res.data) {

          if (movement === "squat") {
            dispatch({
              type: CREATE_SQUAT_RESULT,
              payload: res.data,
              status: res.status,
            });
          }
          if (movement === "posture") {
            dispatch({
              type: CREATE_POSTURE_RESULT,
              payload: res.data,
              status: res.status,
            });
          }
          if (movement === "push") {
            dispatch({
              type: CREATE_PUSH_RESULT,
              payload: res.data,
              status: res.status,
            });
          }
          if (movement === "r_balance") {
            dispatch({
              type: CREATE_RIGHT_BALANCE_RESULT,
              payload: res.data,
              status: res.status,
            });
          }
          if (movement === "l_balance") {
            dispatch({
              type: CREATE_LEFT_BALANCE_RESULT,
              payload: res.data,
              status: res.status,
            });
          }
          if (movement === "r_lunge") {
            dispatch({
              type: CREATE_RIGHT_LUNGE_RESULT,
              payload: res.data,
              status: res.status,
            });
          }
          if (movement === "l_lunge") {
            dispatch({
              type: CREATE_LEFT_LUNGE_RESULT,
              payload: res.data,
              status: res.status,
            });
          }
          if (movement === "r_rdl") {
            dispatch({
              type: CREATE_RIGHT_RDL_RESULT,
              payload: res.data,
              status: res.status,
            });
          }
          if (movement === "l_rdl") {
            dispatch({
              type: CREATE_LEFT_RDL_RESULT,
              payload: res.data,
              status: res.status,
            });
          }
          if (movement === "free") {
            dispatch({
              type: CREATE_FREE_RESULT,
              payload: res.data,
              status: res.status,
            });
          }
        }
      } catch (err) {
    
        if (movement === "squat") {
          dispatch({
            type: FAIL_SQUAT_RESULT,
            payload: err.response.status,
          });
        }
        if (movement === "posture") {
          dispatch({
            type: FAIL_POSTURE_RESULT,
            payload: err.response.status,
          });
        }
        if (movement === "push") {
          dispatch({
            type: FAIL_PUSH_RESULT,
            payload: err.response.status,
          });
        }
        if (movement === "r_balance") {
          dispatch({
            type: FAIL_RIGHT_BALANCE_RESULT,
            payload: err.response.status,
          });
        }
        if (movement === "l_balance") {
          dispatch({
            type: FAIL_LEFT_BALANCE_RESULT,
            payload: err.response.status,
          });
        }
        if (movement === "r_lunge") {
          dispatch({
            type: FAIL_RIGHT_LUNGE_RESULT,
            payload: err.response.status,
          });
        }
        if (movement === "l_lunge") {
          dispatch({
            type: FAIL_LEFT_LUNGE_RESULT,
            payload: err.response.status,
          });
        }
        if (movement === "r_rdl") {
          dispatch({
            type: FAIL_RIGHT_RDL_RESULT,
            payload: err.response.status,
          });
        }
        if (movement === "l_rdl") {
          dispatch({
            type: FAIL_LEFT_RDL_RESULT,
            payload: err.response.status,
          });
        }
        if (movement === "free") {
          dispatch({
            type: FAIL_FREE_RESULT,
            payload: err.response.status,
          });
        }
      }
    };
  } catch (err) {

    
  }
};

export const resetStatuses = (movement) => {

    return async (dispatch) => {
      try {
        if (movement === "squat") {
          dispatch({
            type: RESET_SQUAT_STATUS,
          });
        }
        if (movement === "posture") {
          dispatch({
            type: RESET_POSTURE_STATUS,
          });
        }
        if (movement === "push") {
          dispatch({
            type: RESET_PUSH_STATUS,
          });
        }
        if (movement === "balance") {
          dispatch({
            type: RESET_BALANCE_STATUS,
          });
        }
        if (movement === "lunge") {
          dispatch({
            type: RESET_LUNGE_STATUS,
          });
        }
        if (movement === "rdl") {
          dispatch({
            type: RESET_RDL_STATUS,
          });
        }
        if (movement === "free") {
          dispatch({
            type: RESET_FREE_STATUS,
          });
        }
      } catch (err) {
    
      }
    };
};


// delete single assessment
export const deleteSingleAssessment = (id, clientID) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };


  try {
    const res = await axios.delete(
      `${REACT_APP_API_URL}/api/assessments/${id}/`,
      config
    );

    if(res.status === 204){
      dispatch(getAssessments(clientID))
    }
  } catch (err) {
  
  
  }
};


export const patchAssessment = (data, id) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };
  try {
    const res = await axios.patch(
      `${REACT_APP_API_URL}/api/updateass/${id}/`,
      data,
      config
    );
   if(res.data){
    dispatch({
      type: PATCH_ASSESSMENT_SUCCESS,
      payload: res.data,
    });}
  } catch (err) {

  }
};