import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Input, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { FaRegComments } from "react-icons/fa6";
import { updateExercise } from "../../redux/actions/workouts";
import BootstrapInput from "../common/Input";

const { Text } = Typography;

const ExerciseComments = ({ exercise }) => {
  const dispatch = useDispatch();
  const { user, Theme } = useSelector((state) => state.auth);
  const [exerciseComments, setExerciseComments] = useState(exercise.comments);
  const [newComment, setNewComment] = useState("");

  const formatDate = (dateString, formatType) => {
    const formattedDate = new Date(dateString);
    const splitDate = formattedDate.toLocaleString().split(",")[0].split("-");
    return splitDate[0];
  };

  useEffect(() => {
    setExerciseComments(exercise.comments);
  }, [exercise]);

  const handleAddComment = () => {
    const date = new Date();
    const commentToAdd = {
      name: `${user.profile?.first_name} ${user.profile?.last_name}`,
      date: formatDate(date),
      comment: newComment,
    };
    const comments = [...exerciseComments, commentToAdd];

    dispatch(updateExercise(exercise.id, { comments: comments }));
    setExerciseComments([...exerciseComments, commentToAdd]);
    setNewComment("");
  };

  return (
    <Row justify="center">
      <Col span={20} style={{ textAlign: "left" }}>
        <Row gutter={[16, 16]} align="middle" style={{marginBottom:"1vw"}}>
          <Col>
            <FaRegComments color={Theme.PRIMARY} size={"1vw"} />
          </Col>
          <Col flex="auto">
            <Text
              style={{ color: Theme.TEXT, fontSize: 18, fontWeight: "bold" }}
            >
              {exercise.library_exercise.name}
            </Text>
          </Col>
        </Row>
        <Col align="middle" style={{position:"relative", top:"100%"}}>
        {exerciseComments.length === 0 && (
                <Col>
                <FaRegComments color={Theme.BLUE} size={"2vw"} />
              <div>
          <Text style={{ color: Theme.TEXT, fontSize: "1vw" }}>No Comments</Text>
          </div>
          </Col>
        ) }</Col>
        <div style={{ maxHeight: "25vw", overflowY: "auto", overflowX: "clip", marginBottom: "1vw", paddingBottom:"1vw", }}>
          {exerciseComments.map((comment, index) => (
            <Col
              key={index}
              style={{ borderBottom: "1px dotted #ccc", paddingBottom: "1vw", paddingTop: "1vw" }}
            >
              <Row gutter={[16, 16]} align="middle">
                <Col>
                  <Text style={{ fontWeight: "bold", color: Theme.TEXT, fontSize: ".6vw" }}>
                    {comment.name}
                  </Text>
                </Col>
                <Col>
                  <Text style={{ color: Theme.PRIMARY, fontWeight: "bold", fontSize: ".6vw" }}>
                    {comment.date}
                  </Text>
                </Col>
              </Row>
              <Col flex="auto">
                <Text style={{ color: Theme.TEXT2, fontSize: ".85vw" }}>{comment.comment}</Text>
              </Col>
            </Col>
          ))}
        </div>
     
      </Col>
      <Row
        gutter={[16, 16]}
        align="middle"
        style={{ position: "absolute", bottom: "1vw" }}
      >
        <Col flex="auto">
          <BootstrapInput
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            placeholder="Add a comment..."
            width={"25vw"}
          />
        </Col>
        <Col>
          <Button type="primary" onClick={handleAddComment}>
            Add
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default ExerciseComments;
