export const compKeys = [
  {
    key: "1",
    compensation: "E-Lean",
    description:
      "Weakened ability to extend the trunk and keep the back upright. Can cause low back pain, imbalance the hips and knee pain",
    title: "Excessive Forward Lean",
    overactive: "Psoas Major, Iliacus, Gastrocnemius",
    underactive: "Erector Spinae",
  },
  {
    key: "2",
    compensation: "R-Arm Fwd",
    description:
      "Possible tightness in the upper back and weakness in the shoulder region preventing stability of arms during movement. This can lead to chronic low back pain",
    title: "Right Arm Fell Forward",
    overactive: "Latissimus Dorsi, Teres Major, Pectoralis Major and Minor",
    underactive:
      "Mid/Lower Trapezius, Rhomboids, Rotator Cuff, Posterior Deltoid",
  },
  {
    key: "3",
    compensation: "L-Arm Fwd",
    description:
      "Possible tightness in the upper back and weakness in the shoulder region preventing stability of arms during movement. This can lead to chronic low back pain",
    title: "Left Arm Fell Forward",
    overactive: "Latissimus Dorsi, Teres Major, Pectoralis Major and Minor",
    underactive:
      "Mid/Lower Trapezius, Rhomboids, Rotator Cuff, Posterior Deltoid",
  },
  {
    key: "4",
    compensation: "R-Knee In",
    description:
      "Inability to keep the knees in-line with joint structure placing excessive stress on the ACL/MCL and causes pain in the knees and ankles",
    title: "Right Knee Moved Inwards",
    overactive: "Adductor Longus, Adductor Brevis, Adductor Magnus, Gracillis",
    underactive:
      "Gluteus Maximus, Gluteus Medius, Gluteus Minimus, and Tensor Fasciae Latae",
  },
  {
    key: "5",
    compensation: "L-Knee In",
    description:
      "An inability to keep the knees in-line with joint structure placing excessive stress on the ACL and MCL and also cause pain in the knees and ankles",
    title: "Left Knee Moved Inwards",
    overactive: "Adductor Longus, Adductor Brevis, Adductor Magnus, Gracillis",
    underactive:
      "Gluteus Maximus, Gluteus Medius, Gluteus Minimus, and Tensor Fasciae Latae",
  },
  {
    key: "6",
    compensation: "L-Heel Up",
    description:
      "Tightness in the calf causing an inability to reach full range of motion of the ankle while keeping the foot flat on the ground ",
    title: "Left Heel Raised",
    overactive: "Soleus",
    underactive: "Anterior Tibialis",
  },
  {
    key: "7",
    compensation: "R-Heel Up",
    description:
      "Tightness in the calf causing an inability to reach full range of motion of the ankle while keeping the foot flat on the ground ",
    title: "Right Heel Raised",
    overactive: "Soleus",
    underactive: "Anterior Tibialis",
  },
  {
    key: "8",
    compensation: "R-Toe Up",
    description:
      "Indication of weakened ability to stabilize the body under load, placing incorrect joints under pressure and activating or deactivating incorrect muscle during movements",
    title: "Right Toe Raised",
    overactive: "None",
    underactive:
      "Muscles of Pelvic Floor, Internal/External Obliques, Rectus Abdominis, Erector Spinae, Multifidus, Transverse Abdominis, Diaphragm",
  },
  {
    key: "9",
    compensation: "L-Toe Up",
    description:
      "Indication of weakened ability to stabilize the body under load, placing incorrect joints under pressure and activating or deactivating incorrect muscle during movements",
    title: "Left Toe Raised",
    overactive: "None",
    underactive:
      "Muscles of Pelvic Floor, Internal/External Obliques, Rectus Abdominis, Erector Spinae, Multifidus, Transverse Abdominis, Diaphragm",
  },
  {
    key: "10",
    compensation: "Hip to Shoulder",
    description:
      "Indication of weak stability in the back can cause improper muscle to activate when lowering or raising a load significantly increasing potential for injury in the back, knees, and/or glutes",
    title: "Shoulders Went Below Hips",
    overactive: "Psoas Major, Iliacus, Gastrocnemius",
    underactive: "Erector Spinae",
  },
  {
    key: "11",
    compensation: "Knee to Hip",
    description:
      "Overactive hamstrings can throw the balance of strength production off between the anterior and posterior muscles, increasing the potential for injury in the knees, glutes and hips",
    title: "Hips Went Below Knees",
    overactive: "Hamstrings, Psoas Major & Minor, Erector Spinae ",
    underactive: "Quadriceps, Trapezius, Gluteus Maximus",
  },
  {
    key: "12",
    compensation: "R-Should Up",
    description:
      "Lifting the shoulder shows a weakness in the force production of the triceps due to a lack of balance in the shoulder, this can lead to injured shoulders and also upper back problems or pain",
    title: "Right Shoulder Raised",
    overactive: "Upper and Mid Trapezius, Levator Scapula",
    underactive:
      " Lattisimus Dorsi, Pectoralis Major and Minor, Lower Trapezius",
  },
  {
    key: "13",
    compensation: "L-Should Up",
    description:
      "Lifting the shoulder shows a weakness in the force production of the triceps due to a lack of balance in the shoulder, this can lead to injured shoulders and also upper back problems or pain",
    title: "Left Shoulder Raised",
    overactive: "Upper and Mid Trapezius, Levator Scapula",
    underactive:
      " Lattisimus Dorsi, Pectoralis Major and Minor, Lower Trapezius",
  },
  {
    key: "14",
    compensation: "Head Fwd",
    description:
      "Lack of strength in the neck when pushing overhead shows an imbalance between the larger mover muscles and the stabilizing muscles when under a load trying to move",
    title: "Head Fell Forward",
    overactive:
      "Suboccipitals, Sternocleidomastoid, Levator Scapula, Upper Trapezius, Pectoral Muscles",
    underactive:
      "Posterior (deep) Neck Flexors, Upper Erector Spinae, Mid and Lower Trapezius",
  },
  {
    key: "15",
    compensation: "Excessive Hip Imbalance",
    description:
      "Unbalanced hips can affect the length of the leg muscles changing the symmetry of force production and can also affect the curvature of the spine causing low back pain",
    title: "Excessive Hip Imbalance",
    overactive:
      "Gluteus maximus and minimus, Hamstrings, Iliocostalis Lomborum",
    underactive: "Iliopsoas, Piriformis, Internal Pelvic complex",
  },
  {
    key: "16",
    compensation: "Excessive Hand Movement",
    description:
      "Loss of balance can indicate a weak core or lack of proprioception. These items can affect movement in general and contribute to diminished performance in other movements.",
    title: "Excessive Hand Movement",
    overactive: "Not Applicable",
    underactive: "Not Applicable",
  },
  {
    key: "17",
    compensation: "Knee Drop",
    description:
      "Loss of balance can indicate a weak core or lack of proprioception. These items can affect movement in general and contribute to diminished performance in other movements.",
    title: "Knee Drop",
    overactive: "Not Applicable",
    underactive: "Not Applicable",
  },
  {
    key: "18",
    compensation: "Knee-In",
    description:
      "Inability to keep the knees in-line with joint structure placing excessive stress on the ACL/MCL and causes pain in the knees and ankles",
    title: "Planted Knee Moved Inwards",
    overactive: "Adductor Longus, Adductor Brevis, Adductor Magnus, Gracillis",
    underactive:
      "Gluteus Maximus, Gluteus Medius, Gluteus Minimus, and Tensor Fasciae Latae",
  },
  {
    key: "19",
    compensation: "Excessive Shoulder Imbalance",
    description:
      "With an imbalance in posture of the shoulders, force used during movements requiring both shoulders will be different causing an imbalanced stress response potentially increasing the variance from left to right.",
    title: "Excessive Shoulder Imbalance",
    overactive: "Upper, and mid Trapezius, Levator scapula",
    underactive:
      "Lattisimus Dorsi, Pectoralis Major and Minor, Lower Trapezius",
  },
  {
    key: "20",
    compensation: "Weak Posterior Chain",
    description:
      "Inability to maintain a stable, rigid body line during movement. Leads to an imbalance with the core and hip flexors creating an anterior hip tilt that can lead to back pain as well as limited hip ROM.",
    title: "Weak Posterior Chain",
    overactive: "Psoas Major and minor, Iliacus, Gastrocnemius ",
    underactive: "Erector Spinae, Hamstrings, Gluteus Maximus",
  },
  {
    key: "21",
    compensation: "Knee-Out",
    description:
      "Knee movement outside of optimal, in-line track places increased amounts of tension and stress on the connective tissue in the hips, knee and ankles, causing pain and potentially injury.",
    title: "Planted Knee Moved Outwards",
    overactive:
      "Gluteus Maximus, Gluteus Medius, Gluteus Minimus, and Tensor Fasciae Latae",
    underactive:
      "Gracilis, Obturator Externus, Adductor Brevis, Adductor Longus and Adductor Magnus",
  },
  {
    key: "22",
    compensation: "Left Weak Hamstring",
    description:
      "Inability to maintain a stable, rigid body line during movement. Leads to an imbalance with the core and hip flexors creating an anterior hip tilt that can lead to back pain as well as limited hip ROM.",
    title: "Weak Left Hamstring",
    overactive:
      "Psoas Major (also psoas minor if present), Iliacus, Gastrocnemius ",
    underactive:
      "Erector Spinae, Hamstrings, Gluteus Maximus",
  },
  {
    key: "23",
    compensation: "Right Weak Hamstring",
    description:
      "Inability to maintain a stable, rigid body line during movement. Leads to an imbalance with the core and hip flexors creating an anterior hip tilt that can lead to back pain as well as limited hip ROM.",
    title: "Weak Left Hamstring",
    overactive:
      "Psoas Major (also psoas minor if present), Iliacus, Gastrocnemius ",
    underactive:
      "Erector Spinae, Hamstrings, Gluteus Maximus",
  },
];
