import { useState, useEffect } from "react";
import { Col, Grid, Modal, Row, Tabs, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSingleClient } from "../redux/actions/clients";
import { deleteSingleAssessment } from "../redux/actions/assessments";
import AssessmentList from "../components/AssessmentList";
import ClientHeader from "../components/ClientHeader";
import FreeRecordList from "../components/FreeRecords";
import Progress from "../components/Progress";
import WorkoutSchedule from "../components/workouts/WorkoutSchedule";
import Cycles from "../components/workouts/Cycles";
import { analytics } from "../firebase-config";
import { logEvent } from "firebase/analytics";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getExercises, getMesocycles, getWorkouts } from "../redux/actions/workouts";
import { IoChevronBackOutline } from "react-icons/io5";
import {
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";

const { Text } = Typography;
const { confirm } = Modal;
const { Title } = Typography;
const { TabPane } = Tabs;

const { useBreakpoint } = Grid;

const ClientDetails = () => {
  const [chooseModalVisible, setChooseModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { assessments, assessmentStatus } = useSelector(
    (state) => state.assessments
  );
  const { Theme } = useSelector((state) => state.auth);

  const { client } = useSelector((state) => state.clients);
  const dispatch = useDispatch();
  const fetchClient = () => dispatch(getSingleClient(client.id));
  const [editAssessments, setEditAssessments] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user, isAuthenticated } = useSelector((state) => state.auth);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState("assessments");

  useEffect(() => {
    if (location.state && location.state.fromWorkout) {
      setActiveTab("workouts");
 
    } else {
      setActiveTab("assessments");
    }
  }, [location.state]);

  // useEffect(() => {
  //   dispatch(getExercises("", [], 1, client.id));
  // }, [client]);

  useEffect(() => {
    logEvent(analytics, "page_view", { title: "shit piss" });
    setTimeout(() => setLoading(false), 1500);
  }, []);

  useEffect(() => {
    if (assessmentStatus === "success") {
      fetchClient(client.id);
    }
  }, [assessmentStatus]);

  const editAssessmentShow = (show) => {
    setEditAssessments(show);
  };

  const goToResults = () => {
    // Implement your logic for navigating to results
  };

  const deleteAssessment = (id) => {
    confirm({
      title: "Delete Assessment",
      content: "Are you sure you want to delete this assessment?",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        dispatch(deleteSingleAssessment(id));
      },
    });
  };
  const [isFadedIn, setIsFadedIn] = useState(false);

  useEffect(() => {
    // Trigger the fade-in effect once the component is mounted
    setIsFadedIn(true);
  }, []);
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const flexDirection = isRowLayout ? "row" : "column";

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const handleGoToHome = () => {
    history.push("/");
  };

  return (
    
    <Row style={{ minHeight: "100vh", marginRight: "15vw" }}>
    
      <Col justify={"center"} align={"center"}>
        <Col
          style={{
            marginBottom: "2rem",
            position: "relative",
          }}
          className={`fade-in-slide-up-element ${
            isFadedIn ? "fade-in-slide-up-animation" : ""
          }`}
        >
          <Row
        onClick={handleGoToHome}
        style={{ right: "6vw", bottom: "0vw", position: "relative", cursor: "pointer" }}
      >
        <IoChevronBackOutline
          style={{
            top: ".2vw",
            marginRight: ".5%",
            position: "relative",
            color: Theme.TEXT,
          }}
        />
        <div>
        <Text style={{ color: Theme.TEXT }}>Back</Text>
        </div>
      </Row>
          <Row
            justify="center"
            style={{
              position: "relative",
            }}
          >
            <ClientHeader clientID={client.id} />
          </Row>
          <Tabs
            activeKey={activeTab}
            onChange={setActiveTab}
            tabBarStyle={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              position: "relative",
              right: "6vw",
              bottom: "1.5vw",
              color: Theme.TEXT,
            }}
          >
            <TabPane tab="Assessments" key="assessments">
              <Col style={{ bottom: "1vw" }}>
                <Row
                  style={{
                    display: "flex",
                    flexDirection: flexDirection,
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <Col
                    span={12}
                    style={{
                      right: isRowLayout ? "8vw" : null,
                      position: "relative",
                      animation: "fade-in 10s forwards",
                    }}
                  >
                    <AssessmentList
                      clientID={client.id}
                      assessments={assessments}
                      editAssessmentShow={editAssessmentShow}
                      deleteAssessment={deleteAssessment}
                      goToResults={goToResults}
                    />
                  </Col>
                  {!client.isGuest ? (
                    <Col
                      justify={"center"}
                      align={"center"}
                      span={12}
                      style={{
                        position: "relative",
                        // bottom: isRowLayout ? null : "3vw"
                      }}
                    >
                      <Progress clientID={client.id} />
                    </Col>
                  ) : (
                    <Col
                      justify={"center"}
                      align={"center"}
                      span={12}
                      style={{
                        position: "relative",
                        // bottom: isRowLayout ? null : "3vw"
                      }}
                    >
                      <FreeRecordList
                        clientID={client.id}
                        assessments={assessments}
                        editAssessmentShow={editAssessmentShow}
                        deleteAssessment={deleteAssessment}
                        goToResults={goToResults}
                      />
                    </Col>
                  )}
                </Row>
                {/* {!client.isGuest && (
                  <Row
                    justify={"center"}
                    align={"center"}
                    style={{
                      position: "relative",
                      marginTop: isRowLayout ? "2rem" : "1rem",
                    }}
                  >
                    <FreeRecordList
                      clientID={client.id}
                      assessments={assessments}
                      editAssessmentShow={editAssessmentShow}
                      deleteAssessment={deleteAssessment}
                      goToResults={goToResults}
                    />
                  </Row>
                )} */}
              </Col>
            </TabPane>

            {!client.isGuest && (
              <TabPane tab="Training" key="workouts">
                <Col style={{ bottom: "1vw" }}>
                  <Row
                    style={{
                      display: "flex",
                      flexDirection: flexDirection,
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <Col
                      span={12}
                      style={{
                        right: isRowLayout ? "8vw" : null,
                        position: "relative",
                        animation: "fade-in 10s forwards",
                      }}
                    >
                      <WorkoutSchedule
                        clientID={client.id}
                        assessments={assessments}
                        editAssessmentShow={editAssessmentShow}
                        deleteAssessment={deleteAssessment}
                        goToResults={goToResults}
                      />
                    </Col>
                    <Col
                      justify={"center"}
                      align={"center"}
                      span={12}
                      style={{
                        position: "relative",
                        left: "2vw",
                        // bottom: isRowLayout ? null : "3vw"
                      }}
                    >
                      <Cycles />
                    </Col>
                  </Row>
                  <Row
                    justify={"center"}
                    align={"center"}
                    style={{
                      position: "relative",
                      marginTop: isRowLayout ? "2rem" : "1rem",
                    }}
                  >
                    {/* <FreeRecordList
                    clientID={client.id}
                    assessments={assessments}
                    editAssessmentShow={editAssessmentShow}
                    deleteAssessment={deleteAssessment}
                    goToResults={goToResults}
                  /> */}
                  </Row>
                </Col>
              </TabPane>
            )}
          </Tabs>
        </Col>
      </Col>
    </Row>
  );
};

export default ClientDetails;
