import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  Input,
  Button,
  Checkbox,
  Card,
  Row,
  Spin,
  Typography,
  Col,
} from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import {
  createTrainer,
  getTrainers,
  resetTrainerStatus,
} from "../../redux/actions/trainers";
import {
  checkClientLimit,
  checkTrainerLimit,
  FREE_CLIENTS,
} from "../../constants/utils";
import { useHistory } from "react-router-dom";
import { IoPeopleOutline } from "react-icons/io5";
import BootstrapInput from "../common/Input";
import CustomSelect from "../common/DropDown";
import { load_user } from "../../redux/actions/auth";

const { Text } = Typography;

const CreateTrainerForm = ({ closeModal }) => {
  const dispatch = useDispatch();
  const formikRef = useRef(null);
  const { user, Theme } = useSelector((state) => state.auth);

  const [reachedLimit, setReachedLimit] = useState(false);
  const { subscriptionStatus, compedMembership } = useSelector(
    (state) => state.payment
  );
  const { groups } = useSelector((state) => state.groups);

  const [isLoading, setLoading] = useState(false);
  const [shitty, setShitty] = useState(false)
  const { trainerStatus } = useSelector((state) => state.trainers);
  const history = useHistory();
  const styles = createStyles(Theme);

  const handleSubmit = async (values, { resetForm }) => {
    const data = new FormData();
    setShitty(true);
    data.append("first_name", values.first_name);
    data.append("last_name", values.last_name);
    data.append("email", values.email.toLowerCase());
    if (values.team_id != null) {
      data.append("team_id", values.team_id);
    }
    if (groups.length == 1) {
      data.append("team_id", groups[0].id);
    }
    dispatch(createTrainer(data));
   
    setLoading(false);
  };

  useEffect(() => {
  if(shitty == true){  setLoading(true)
    setShitty(false)}
  }, [shitty]);

  useEffect(() => {
    checkTrainerLimit(setReachedLimit, user);
  }, []);

  useEffect(() => {
    checkTrainerLimit(setReachedLimit, user);
  }, [subscriptionStatus, user]);

  useEffect(() => {
    if (trainerStatus === 200) {
      setLoading(true);
      dispatch(getTrainers());
      
      dispatch(load_user());
      setTimeout(() => {
        history.push("/admin");
        setLoading(false);
        dispatch(resetTrainerStatus());
        closeModal(false);
        formikRef.current?.resetForm();
      }, 250);
    }
  }, [trainerStatus]);

  const getValidationSchema = () => {
    let schema = {
      first_name: yup.string().required("Required"),
      last_name: yup.string().required("Required"),
      email: yup.string().email("Must be a valid email address").required("Required"),
    };

    if (user?.profile?.account_type === 1 && groups.length > 1) {
      schema.team_id = yup.number().required("Required");
    } else {
      schema.team_id = yup.number().nullable(true);
    }

    return yup.object().shape(schema);
  };

  return (
    <Col
      bordered={false}
      style={{
        zIndex: 100,
        // flex: 1,
        position: "relative",
        background: 'transparent'
      }}
      className="criclebox h-full"
    >
      {!reachedLimit ? (
        <div>
          <Formik
            initialValues={{
              first_name: "",
              last_name: "",
              email: "",
              team_id: null,

            }}
            innerRef={formikRef}
            onSubmit={handleSubmit}
            context={{ accountType: user?.profile?.account_type }}
            validationSchema={getValidationSchema()}
          >
            {({ handleChange, handleSubmit, values, errors, touched }) => (
              <Col align="center" style={styles.registerContainer}>
                <div style={styles.block}>
                  <div style={styles.title}>
                    <p style={styles.titleText}>Add New Trainer</p>
                  </div>

                  <BootstrapInput
                    styles={styles}
                    value={values.first_name}
                    placeholder="First Name"
                    onChange={handleChange("first_name")}
                    error={touched.first_name && errors.first_name}
                    Theme={Theme}
                    width={"15vw"}
                  />
                  <BootstrapInput
                    styles={styles}
                    value={values.last_name}
                    placeholder="Last Name"
                    onChange={handleChange("last_name")}
                    error={touched.last_name && errors.last_name}
                    Theme={Theme}
                    width={"15vw"}
                  />
                  <BootstrapInput
                    styles={styles}
                    value={values.email}
                    placeholder="Email"
                    onChange={handleChange("email")}
                    error={touched.email && errors.email}
                    Theme={Theme}
                    width={"15vw"}

                  />
                  <Col style={{ marginTop: ".5vw" }}>
                    {
                      user?.profile?.account_type === 1 && groups.length > 1 && (
                        <>
                          <CustomSelect
                            defaultValue={values.team_id}
                            width="10vw"
                            onChange={handleChange("team_id")}
                            placeholder={
                              <span
                                style={{
                                  color: Theme.BACKGROUND === "#000" ? Theme.TEXT2 : "#000",
                                  fontSize: ".65vw",
                                  top: ".05vw",
                                  position: "relative",
                                  fontWeight: "normal",
                                }}
                              >
                                Assign Team
                              </span>
                            }
                            options={groups.map((group) => ({
                              value: group.id.toString(),
                              label: group.name,
                            }))}
                          />
                          {touched.team_id && errors.team_id && (
                            <p style={{ position: "relative", color: "red", fontSize: ".75vw" }}>
                              Required
                            </p>
                          )}
                        </>
                      )
                    }
                  </Col>


                  <Button
                    small
                    style={{
                      backgroundColor: Theme.BACKGROUND,
                      borderRadius: 5,
                      borderWidth: 0,
                      cursor: "pointer",
                      width: "10vw",
                      //  padding: "1.5%",
                      backgroundImage:
                        "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                      marginTop: "2vw",
                      boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)",
                    }}
                    type="submit"
                    onClick={handleSubmit}

                  >
                    {!isLoading ?

                      <Text style={styles.buttontext}>Submit</Text>
                      : (
                        <Spin size="small" />
                      )}
                  </Button>

                  {trainerStatus > 399 && (
                    <div
                      style={styles.row}

                    >
                      <p style={styles.errorText}>
                        Trainer with this email already exists
                      </p>
                    </div>
                  )}
                </div>
              </Col>
            )}
          </Formik>
        </div>
      ) : (
        <Col justify="center">
          <Row align="center">
            <IoPeopleOutline
              style={{
                fontSize: "2vw",
                color: Theme.PRIMARY,
                marginBottom: "3%",
              }}
            />
          </Row>
          <Row align="center">
            <Text style={styles.movementTitle}>
              Trainer Limit Reached
            </Text>
          </Row>
          <Row align="center">
            <Text style={styles.clientDescr}>
              Contact us regarding enterprise solutions for trainer upgrades
            </Text>
          </Row>
        </Col>
      )}
    </Col>
  );
};

export default CreateTrainerForm;

const createStyles = (theme) => ({
  container: {
    backgroundColor: "#FFF",
    alignContent: "center",
  },
  title: {
    color: "#232b2b",
    fontSize: 18,

    bottom: 25,
    fontWeight: "600",
    alignSelf: "center",
  },
  input: {
    backgroundColor: theme.BLACK,
    borderBottomColor: "gray",
    borderColor: "white",
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
    margin: 10,
    width: "15vw",
    height: "2vw",
    fontSize: "1vw",
  },
  smallInput: {
    backgroundColor: theme.BACKGROUND,
    placeholder: {
      color: theme.TEXT,
    },
    // borderBottomColor: "black",
    // borderColor: "white",
    // borderWidth: 5,
    color: theme.TEXT,
    borderRadius: 10,
    padding: 10,
    margin: 10,
    width: "15vw",
    height: "2vw",
    fontSize: "1vw",
    ":focus": {
      borderColor: theme.WHITE, // Change the border color as needed
    },
  },
  checkboxText: {
    fontSize: ".75vw",
    width: "80%",
  },
  registerContainer: {
    backgroundColor: "transparent",
    borderRadius: 10,
    shadowColor: theme.BLACK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    justifyContent: "center",
    shadowRadius: 8,
    shadowOpacity: 0.3,
    elevation: 1,
    // height: "15vw",
    // width: "15vw",
    // marginTop: 10,
  },
  inputIcons: {
    marginRight: 12,
  },
  titleText: { fontSize: "1vw", color: theme.TEXT },
  createButton: {
    width: "5vw",
    marginTop: "5%",
    backgroundImage: "linear-gradient(45deg, #C04848 0%, #480048 80%)",
    borderWidth: 0,
    borderRadius: 10,
    cursor: "pointer"
  },
  buttontext: {
    color: theme.WHITE,
    fontWeight: "bold",

    fontSize: ".75vw",

    // paddingHorizontal: 5,
  },
  errors: {
    position: "relative",
    // top: 55,
    color: "red",
    fontSize: ".75vw",
    // right: '25%'
    // alignSf: 'flex-start'
    // marginLeft: 12,
  },
  checkbox: {
    // top: 9,
    color: theme.PRIMARY,
    borderRadius: 5,
    width: 15,
    height: 15,
    position: "relative",
    right: "5%",
    top: "10%",
  },
  avatar: {
    height: 100,
    width: 100,
    borderRadius: 62,
    borderWidth: 0,
    alignSelf: "center",
    top: 20,
  },
  movementTitle: {
    alignSelf: "center",
    fontSize: "1.3vw",
    fontWeight: "bold",
    color: theme.TEXT,
    textAlign: "center",
  },
  clientDescr: {
    alignSelf: "center",
    fontSize: ".75vw",
    // fontWeight: "bold",
    color: theme.TEXT2,
    textAlign: "center",
  },
});
