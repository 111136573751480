import {
  CREATE_TRAINER_SUCCESS,
  CREATE_TRAINER_FAIL,
  GET_TRAINERS,
  GET_SINGLE_TRAINER,
  RESET_TRAINER_STATUS,
  UPDATE_TRAINER,
  DELETE_TRAINER,
  SEARCH_TRAINERS,
  UPDATE_TRAINER_PROFILE,
  GET_SINGLE_TRAINER_FAILED,
  RESET_TRAINER
} from "../actions/types";

const initialState = {
  trainers: [],
  trainer: {"user_account":{
    "first_name": " ",
    "last_name": " "
  }},
  trainerStatus: null,
  trainerId: null,
  total_trainers: 0,
  curr_trainers: 0,
};

function trainersReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRAINERS:
      return {
        ...state,
        trainers: action.payload,
        total_trainers: action.total_count,
        curr_trainers: action.total_count
      };
    case SEARCH_TRAINERS:
      return {
        ...state,
        trainers: action.payload,
      };
    case GET_SINGLE_TRAINER:
      return {
        ...state,
        trainer: action.payload,
      };
      case GET_SINGLE_TRAINER_FAILED:
        return {
          ...state,
          trainer: {"user_account":{
            "first_name": " ",
            "last_name": " "
          }}
        }
    case CREATE_TRAINER_SUCCESS:
      return {
        ...state,
        //trainers: [...state.trainers, action.payload.data],
        trainerStatus: action.payload.status,
        trainerId: action.payload.data.id
      };
    case CREATE_TRAINER_FAIL:
      return {
        ...state,
        trainerStatus: action.payload,
      };
    case UPDATE_TRAINER:
      return{
        ...state,
        trainer: action.payload
      }
    case UPDATE_TRAINER_PROFILE:
      const trainerIndex = state.trainers.findIndex((trainer) => trainer.user_account.id === action.payload.id);
      const updateTrainers = [...state.trainers];

      updateTrainers[trainerIndex] = {
        ...state.trainers[trainerIndex],
        user_account: {
          ...state.trainers[trainerIndex].user_account,
          first_name: action.payload.first_name,
          last_name: action.payload.last_name,
          email: action.payload.email,
        },
      }
      return {
        ...state,
        trainers: updateTrainers
      }
      case DELETE_TRAINER:
        const updatedTrainers = state.trainers.filter(trainer => trainer.user_account.id !== action.payload.id);
        return{
          ...state,
          trainers: updatedTrainers,
          trainer: {"user_account":{
            "first_name": " ",
            "last_name": " "
          }}
        }
    case RESET_TRAINER_STATUS:
      return {
        ...state,
        trainerStatus: null,
      };
    case RESET_TRAINER:
      return {
        ...state,
        trainer: initialState.trainer,
      }
    default:
      return state;
  }
}

export default trainersReducer;
