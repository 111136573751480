import React from 'react';
import { Col, Row, Card, Input, Switch, Typography, Form, Button, Modal } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";

import {
    InfoCircleOutlined,
  } from "@ant-design/icons";
  import range_descriptions from './range_descriptions';
import NumeroInput from '../common/NumberInput';
const { Title, Text } = Typography;

const RangeTemplates = ({rangeProfile, setRangeProfile}) => {

const { Theme } = useSelector((state) => state.auth);

const capitalize = (str) => {
    return str.replace(/\b\w/g, char => char.toUpperCase());
  };

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState({ name: '', description: '' });

const handleRangeChange = (category, subsection, value) => {
    setRangeProfile((prev) => {
      const keys = subsection.split('.');
      let updatedProfile = { ...prev };
      let currentLevel = updatedProfile[category];
  
      for (let i = 0; i < keys.length - 1; i++) {
        currentLevel = currentLevel[keys[i]];
      }
  
      if (keys[0] === 'Height') {
        currentLevel[keys[keys.length - 1]] = Number(value); // Directly set the number for the age ranges
      } else {
        // Update the range value for other sections
        currentLevel[keys[keys.length - 1]].range = Number(value);
      }
  
      return {
        ...prev,
        [category]: { ...updatedProfile[category] },
      };
    });
  };
  
  const handleSwitchChange = (category, subsection, checked) => {
    setRangeProfile((prev) => {
      const keys = subsection.split('.');
      let updatedProfile = { ...prev };
      let currentLevel = updatedProfile[category];
  
      // Traverse the keys to reach the target property
      for (let i = 0; i < keys.length - 1; i++) {
        currentLevel = currentLevel[keys[i]];
      }
  
      // Update the type (L or O)
      currentLevel[keys[keys.length - 1]].type = checked ? 'L' : 'O';
  
      return {
        ...prev,
        [category]: { ...updatedProfile[category] },
      };
    });
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };


  if (!rangeProfile || Object.keys(rangeProfile).length === 0) {
    return null; // Return nothing if rangeProfile is empty or undefined
  }

  const showModal = (category) => {
    const descriptionData = range_descriptions[category];
    if (descriptionData) {
      setModalContent({ name: category, description: descriptionData.description });
      setIsModalVisible(true);
    }
  };

  const orderedKeys = ['CMJ', 'Squat', 'Lateral Lunge', 'Push', 'Single Leg Hinge', 'Bilateral Hinge', 'Reverse Lunge', 'Knee', 'Thoracic', 'Shoulder', 'Hip', 'Cervical'];

  return orderedKeys.map((category) => {
    const subkeys = Object.keys(rangeProfile[category]);

    if (category === 'CMJ') {
      return (
        <Col span={24} key={category} style={{ marginBottom: "2rem" }}>
          <Card
            title={
              <Title level={4} style={{ color: Theme.TEXT }}>
                {category}
              </Title>
            }
            bordered={false}
            style={{
              backgroundColor: Theme.CARD,
              borderRadius: "10px",
              padding: "1.5rem",
              minHeight: "30vw"
            }}
          >
             <Button
            style={{
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
            position: 'relative',
            boxShadow: "none", 
            outline: "none", 
            bottom: "0.5vw"
            }}
            onClick={() => showModal(category)}
        >
            <InfoCircleOutlined style={{ color: Theme.PRIMARY, fontSize: '1.2rem' }} />
        </Button>
            {/* Landing fields */}
            <Row gutter={[16, 16]} justify="center">
              {Object.keys(rangeProfile[category]["Landing"]).map(
                (subsection) => (
                  <Col
                    span={6}
                    key={subsection}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <Card
                      bordered={false}
                      style={{
                        backgroundColor: Theme.CARD,
                        borderRadius: "20px",
                        padding: "1rem",
                        textAlign: "center",
                        width: "100%"
                      }}
                    >
                      <Text
                        strong
                        style={{ fontSize: "1.09rem", color: Theme.TEXT }}
                      >
                        {subsection}
                      </Text>
                      <NumeroInput
                        value={
                          rangeProfile[category]["Landing"][subsection].range
                        }
                        onChange={(e) =>
                          handleRangeChange(
                            category,
                            `Landing.${subsection}`,
                            e.target.value
                          )
                        }
                        width={"100%"}
                      />

                      <div
                        style={{
                          height: "2px",
                          backgroundColor: Theme.CARD,
                          margin: "0.5rem 0"
                        }}
                      />

                      {/* Optimal/Linear Switch */}
                      <Row justify="center" align="middle">
                        <Text
                          style={{
                            color: Theme.TEXT,
                            marginRight: "0.3rem",
                            fontSize: "0.8vw"
                          }}
                        >
                          Optimal
                        </Text>
                        <Switch
                          checked={
                            rangeProfile[category]["Landing"][subsection]
                              .type === "L"
                          }
                          onChange={(checked) =>
                            handleSwitchChange(
                              category,
                              `Landing.${subsection}`,
                              checked
                            )
                          }
                        />
                        <Text
                          style={{
                            color: Theme.TEXT,
                            marginLeft: "0.3rem",
                            fontSize: "0.8vw"
                          }}
                        >
                          Linear
                        </Text>
                      </Row>
                    </Card>
                  </Col>
                )
              )}
            </Row>

            {/* Height fields */}
            <Title level={4} style={{ color: Theme.TEXT, marginTop: "2rem", position:'relative', top:"2vw", zIndex: 999 }}>
              Jump Height
            </Title>
            <Row gutter={[16, 0]} justify="center">
              {/* Male Section */}
              <Col span={12}>
                <Card
                  bordered={false}
                  style={{
                    backgroundColor: Theme.CARD,
                    borderRadius: "10px",
                    padding: "1.5rem",
                    textAlign: "center"
                  }}
                >
                  <Title
                    level={5}
                    style={{ color: Theme.TEXT, fontSize: "1vw" }}
                  >
                    Male
                  </Title>
                  {Object.keys(rangeProfile[category]["Height"]["Male"]).map(
                    (ageRange) => (
                      <Form.Item key={ageRange}>
                        <Text style={{ color: Theme.TEXT }}>{ageRange}</Text>
                        <NumeroInput
                          type="number"
                          value={
                            rangeProfile[category]["Height"]["Male"][ageRange]
                          }
                          onChange={(e) =>
                            handleRangeChange(
                              category,
                              `Height.Male.${ageRange}`,
                              e.target.value
                            )
                          }
                        />
                      </Form.Item>
                    )
                  )}
                </Card>
              </Col>

              {/* Female Section */}
              <Col span={12}>
                <Card
                  bordered={false}
                  style={{
                    backgroundColor: Theme.CARD,
                    borderRadius: "10px",
                    padding: "1.5rem",
                    textAlign: "center"
                  }}
                >
                  <Title
                    level={5}
                    style={{ color: Theme.TEXT, fontSize: "1vw" }}
                  >
                    Female
                  </Title>
                  {Object.keys(rangeProfile[category]["Height"]["Female"]).map(
                    (ageRange) => (
                      <Form.Item key={ageRange}>
                        <Text style={{ color: Theme.TEXT }}>{ageRange}</Text>
                        <NumeroInput
                          type="number"
                          value={
                            rangeProfile[category]["Height"]["Female"][ageRange]
                          }
                          onChange={(e) =>
                            handleRangeChange(
                              category,
                              `Height.Female.${ageRange}`,
                              e.target.value
                            )
                          }
                        />
                      </Form.Item>
                    )
                  )}
                </Card>
              </Col>
            </Row>
          </Card>
        </Col>
      );
    }

    return (
      <Col span={12} key={category} style={{ marginBottom: '2rem' }}>
       
        <Card
          title={<Title level={4} style={{ color: Theme.TEXT }}>{category}</Title>}
          bordered={false}
          style={{
            backgroundColor: Theme.CARD,
            borderRadius: '10px',
            padding: '1.5rem',
            minHeight: '30vw',
          }}
        >
            
    <Button
            style={{
            border: 'none',
            background: 'transparent',
            cursor: 'pointer',
            position: 'relative',
            boxShadow: "none", 
            outline: "none", 
            bottom: "0.5vw"
            }}
            onClick={() => showModal(category)}
        >
            <InfoCircleOutlined style={{ color: Theme.PRIMARY, fontSize: '1.2rem' }} />
        </Button>
          <Row gutter={[16, 16]} justify={'center'} style={{ backgroundColor: 'transparent' }}>
            {subkeys.map((subsection) => (
              <Col span={12} key={subsection} style={{ display: 'flex', justifyContent: 'center' }}>
                <Card
                  bordered={false}
                  style={{
                    backgroundColor: Theme.CARD,
                    borderRadius: '20px',
                    padding: '0.5rem',
                    textAlign: 'center',
                    minHeight: '10vw',
                    width: '100%',
                  }}
                >
                  <Text strong style={{ fontSize: '1.09rem', color: Theme.TEXT }}>
                    {capitalize(subsection)}
                  </Text>

                  <NumeroInput
                        value={
                            rangeProfile[category][subsection].range
                        }
                        onChange={(e) =>handleRangeChange(category, subsection, e.target.value)}
                        width={"100%"}
                    />

                  <div
                    style={{
                      height: '2px',
                      backgroundColor: Theme.CARD,
                      margin: '0.5rem 0',
                    }}
                  />

                  <Row justify="center" align="middle">
                    <Text style={{ color: Theme.TEXT, marginRight: '0.3rem', fontSize: "0.8vw" }}>
                      Optimal
                    </Text>
                    <Switch
                      checked={rangeProfile[category][subsection].type === 'L'}
                      onChange={(checked) => handleSwitchChange(category, subsection, checked)}
                    />
                    <Text style={{ color: Theme.TEXT, marginLeft: '0.3rem', fontSize: "0.8vw" }}>
                      Linear
                    </Text>
                  </Row>
                </Card>
              </Col>
            ))}
             <Modal
        visible={isModalVisible}
        onCancel={handleModalClose}
        width={modalContent.name === 'CMJ' ? '50vw' : '25vw'} 
        bodyStyle={{
          paddingBottom: "10vw",
          paddingTop: "1vw",
          paddingLeft: "1vw", 
          paddingRight: "1vw",
          minHeight: "25vw",
        }}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
        footer={[<div></div>]}
      >
        <Title style={{color: Theme.TEXT}} level={3}>{modalContent.name}</Title>
        {modalContent.description && modalContent.description.map((desc, index) => (
          <p key={index} style={{ fontSize: '1.5rem', color: Theme.TEXT }}>
            {desc}
          </p>
        ))}
      </Modal>
          </Row>
          
        </Card>
       
      </Col>
    );
  });
};

export default RangeTemplates;
