import React, { useEffect, useState } from "react";
import { Col, Row, Card, Typography, Modal } from "antd"; // import your UI library components here
import { IoPencilSharp } from "react-icons/io5"; // import any necessary icons
import customblank from "../../assets/images/customblank.jpg";
import {
  completeExercise,
  completeWorkout,
  getSingleWorkout,
  getWorkouts,
  resetWorkoutStatus,
} from "../../redux/actions/workouts";
import { useDispatch, useSelector } from "react-redux";
import EditWorkoutForm from "./EditWorkoutForm";
import { LuDumbbell } from "react-icons/lu";
import { GoCheckCircle, GoCheckCircleFill } from "react-icons/go";
import { FaComments, FaList, FaRegComments } from "react-icons/fa6";
import ExerciseModal from "./ExerciseInfoModal";
import ExerciseComments from "./ExerciseComments";
import moment from "moment";
import { Button } from "react-bootstrap";

const { Text } = Typography;

const WorkoutDetails = ({ workoutData, onBlock }) => {
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.clients);
  const { workout, workoutStatus } = useSelector((state) => state.workouts);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedExercise, setSelectedExercise] = useState(null);
  const [infoModalVisible, setInfoModalVisible] = useState(false);
  const [commentsModalVisible, setCommentsModalVisible] = useState(false);
  const { Theme } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getSingleWorkout(client.id, workoutData.id));
  }, [workoutData]);

  const handleEditModal = () => {
    setEditModalOpen(false);

  };


  useEffect(() => {
    if (
      workoutStatus == "success" ||
      workoutStatus == "updated" ||
      workoutStatus == "deleted"
    ) {
      dispatch(getSingleWorkout(client.id, workoutData.id)).then(
        (response) => dispatch(getWorkouts(client.id)),
        dispatch(resetWorkoutStatus())
      );
    }
  }, [workoutStatus]);

  const handleInfoClick = (exercise) => {
    setSelectedExercise(exercise);
    setInfoModalVisible(true);
  };

  const handleInfoModalClose = () => {
    setInfoModalVisible(false);
    setCommentsModalVisible(false);
    setSelectedExercise(null);
  };

  const handleCommentsClick = (exercise) => {
    setSelectedExercise(exercise);
    setCommentsModalVisible(true);
  };

  return (
    <Col
      style={{
        borderRadius: 20,
        marginTop: "1vw",
        paddingBottom: "1vw",
      }}
      align={"middle"}
      justify={"center"}
    >
      {workout && workout[0] && (
        <Col align={"middle"} justify={"center"}>
          <Row
            align={"middle"}
            justify={"center"}
            style={{
              textAlign: "center",
              marginBottom: "1vw",

              position: "relative",
              bottom: "1vw",
            }}
          >
            <Col>
              <Col align={"middle"} justify={"center"}>
                <div>
                  {workout[0].date && (
                    <div style={{ marginTop: ".5vw" }}>
                      <Text
                        style={{
                          fontSize: ".75vw",
                          fontWeight: "bold",
                          color: Theme.PRIMARY,
                        }}
                      >
                        {moment(workout[0].date).format("MMMM DD, YYYY")}
                      </Text>
                    </div>
                  )}
                </div>

                <Text
                  style={{
                    fontSize: "1.2vw",
                    fontWeight: "bold",
                    color: Theme.TEXT,
                  }}
                >
                  {workout[0].title}{" "}
                </Text>

                <div style={{ width: "30vw", marginTop: ".15vw" }}>
                  <Text style={{ fontSize: ".65vw", color: Theme.TEXT2 }}>
                    {workout[0].notes}
                  </Text>
                </div>
              </Col>
            </Col>
            <Col style={{ marginLeft: "5vw", width: "60%" }}></Col>
          </Row>
          <Row
            align={"start"}
            style={{
              position: "relative",
              left: "2.5vw",
            }}
          >
            <Button
              style={{
                width: "7vw",
                backgroundColor: "#000",
                marginRight: "1vw",
                background: "#000",
                borderRadius: 5,
                borderWidth: 0,
                cursor: "pointer",
                backgroundImage:
                  "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                padding: ".25vw",
              }}
              onClick={() =>
                dispatch(
                  completeWorkout(workout[0].id, {
                    completed: !workout[0].completed,
                  })
                )
              }
            >
              <div style={{ flexDirection: "column" }}>
                <Row align={"middle"} justify={"center"}>
                  {workout[0].completed ? (
                    <Row align={"middle"} justify={"center"}>
                      <GoCheckCircleFill
                        style={{
                          position: "relative",
                          right: "5%",
                          // marginRight: ".5%",
                        }}
                        size={".55vw"}
                        color={Theme.WHITE}
                      />
                      <div>
                        <Text
                          style={{
                            color: Theme.WHITE,
                            fontSize: ".65vw",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Completed
                        </Text>
                      </div>
                    </Row>
                  ) : (
                    <Col>
                      <GoCheckCircle
                        color={Theme.WHITE}
                        style={{
                          position: "relative",

                          right: "5%",
                          // marginRight: ".5%",
                        }}
                        size={".55vw"}
                      />
                      <Text
                        style={{
                          color: Theme.WHITE,
                          fontSize: ".65vw",
                          textAlign: "center",
                          fontWeight: "bold",
                        }}
                      >
                        Complete
                      </Text>
                    </Col>
                  )}
                </Row>
              </div>
            </Button>
            <Button
              // color={"PRIMARY"}
              style={{
                // marginLeft: ".5vw",
                cursor: "pointer",
                // height: "2vw",
                width: "7vw",
                marginRight: "1vw",
                // top: "1.85vw",
                backgroundImage:
                  "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                borderRadius: 5,
                borderWidth: 0,
                // opacity: 0.9,
              }}
              onClick={() => setEditModalOpen(true)}
            >
              <Text
                style={{
                  color: "white",
                  fontSize: ".65vw",
                  textAlign: "center",

                  fontWeight: "bold",
                }}
              >
                Update
              </Text>
            </Button>
          </Row>
          {!workout[0].exercses && workout[0].exercises?.length < 1 && (
            <Card
              style={{
                marginTop: "1vw",
                background: Theme.CARD,
                borderWidth: 0,
                width: "90%",
                height: "10vw",
                boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)",
                paddingBottom: ".5vw",
              }}
            >
              <Col style={{ marginTop: "2.5vw" }}>
                <LuDumbbell size={"1.5vw"} style={{ color: Theme.PRIMARY }} />
                <div>
                  <Text style={{ fontSize: "1vw", color: Theme.TEXT }}>
                    No Exercises
                  </Text>
                </div>
              </Col>
            </Card>
          )}
          {workout[0].exercises &&
              workout[0].exercises
              .sort((a, b) => a.order - b.order) // Sort the exercises based on the order field
              .map((exercise) => (
                exercise.isSuperset ? (
                  <Card
                  key={exercise.id}
                  style={{
                    marginTop: "1vw",
                    background: onBlock ? Theme.CARD : Theme.BACKGROUND,
                    borderWidth: 0,
                    width: "90%",
                    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)",
                    paddingBottom: ".5vw",
                  }}
                >
                <>
                <Col style={{marginTop: "3%", position:"absolute", right: '2vw', top:".15vw"}} align="right">
                <Text style={{ color: Theme.PRIMARY, fontWeight: 'bold', fontSize: ".95vw", }}>
                  Superset
                </Text>
                </Col>
                {exercise.exercises
                .sort((a, b) => a.order - b.order) 
                .map((exercise, subIndex) => (
                 
                  <Col align="middle" justify="center" style={{marginTop:"5%"}}>
                    <Col style={{margin: "1vw"}} align="start">
                      <Text
                        style={{
                          color: Theme.TEXT,
                          fontSize: ".9vw",
                          position: "relative",
                          fontWeight: "bold",
                          alignSelf: "flex-start",
                          // marginLeft: "1vw",

                        }}
                      >
                        {exercise.library_exercise.name}
                      </Text>
                    </Col>
                    <Row
                      align="top"
                      justify="center"
                      style={{ marginTop: "1vw" }}
                    >
                      <Col align="middle" justify="center">
                        {exercise.library_exercise.image ? (
                          <div>
                            <img
                              style={{
                                height: "8vw",
                                width: "12vw",

                                borderRadius: 10,
                                objectFit: "cover",
                              }}
                              src={exercise.library_exercise.image}
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              style={{
                                height: "4.5vw",
                                width: "12vw",
                                borderRadius: 10,
                                objectFit: "cover",
                              }}
                              src={customblank}
                            />
                          </div>
                        )}
                        <Col align="middle" justify="center">
                          <Button
                            // color={"PRIMARY"}
                            style={{
                              // marginLeft: ".5vw",
                              cursor: "pointer",
                              // height: "2vw",
                              width: "12vw",
                              marginTop: ".25vw",

                              // top: "1.85vw",
                              background: onBlock ? Theme.BACKGROUND : Theme.CARD,
                              borderRadius: 5,
                              borderWidth: 0,
                              padding: "5%",
                            }}
                            onClick={() =>
                              dispatch(
                                completeExercise(exercise.id, {
                                  completed: !exercise.completed,
                                })
                              )
                            }
                          >
                            <div style={{ flexDirection: "column" }}>
                              {exercise.completed ? (
                                <Row align={"middle"} justify={"center"}>
                                  <GoCheckCircleFill
                                    size={".65vw"}
                                    style={{
                                      position: "relative",
                                      right: "2.5%",
                                    }}
                                    color={Theme.BLUE}
                                  />
                                  <div>
                                    <Text
                                      style={{
                                        color: Theme.TEXT,
                                        fontSize: ".65vw",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Completed
                                    </Text>
                                  </div>
                                </Row>
                              ) : (
                                <Col>
                                  <GoCheckCircle
                                    color={Theme.PRIMARY}
                                    size={".65vw"}
                                    style={{
                                      position: "relative",
                                      top: ".05vw",
                                      right: "2.5%",
                                    }}
                                  />
                                  <Text
                                    style={{
                                      color: Theme.TEXT,
                                      fontSize: ".65vw",
                                      textAlign: "center",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Complete
                                  </Text>
                                </Col>
                              )}
                            </div>
                          </Button>
                          <div>
                            <Button
                              // color={"PRIMARY"}
                              style={{
                                // marginLeft: ".5vw",
                                cursor: "pointer",
                                // height: "2vw",
                                width: "12vw",
                                marginTop: ".25vw",
                                // top: "1.85vw",
                                background: onBlock ? Theme.BACKGROUND : Theme.CARD,
                                borderRadius: 5,
                                borderWidth: 0,
                                padding: "5%",
                                // opacity: 0.9,
                              }}
                              onClick={() => handleInfoClick(exercise)}
                            >
                              <div style={{ flexDirection: "column" }}>
                                <Row
                                  align="middle"
                                  justify={"center"}
                                  style={{ flexDirection: "row" }}
                                >
                                  <FaList
                                    color={Theme.PRIMARY}
                                    style={{
                                      position: "relative",
                                      right: "2.5%",
                                    }}
                                    size={".55vw"}
                                  />
                                  <Text
                                    style={{
                                      color: Theme.TEXT,
                                      fontSize: ".65vw",
                                      textAlign: "center",

                                      fontWeight: "bold",
                                    }}
                                  >
                                    Instructions
                                  </Text>
                                </Row>
                              </div>
                            </Button>
                          </div>
                          <Button
                            // color={"PRIMARY"}
                            style={{
                              // marginLeft: ".5vw",
                              cursor: "pointer",
                              // height: "2vw",
                              width: "12vw",
                              marginTop: ".25vw",
                              // top: "1.85vw",
                              background: onBlock ? Theme.BACKGROUND : Theme.CARD,
                              borderRadius: 5,
                              borderWidth: 0,
                              padding: "5%",
                              // opacity: 0.9,
                            }}
                            onClick={() => handleCommentsClick(exercise)}
                          >
                            <div style={{ flexDirection: "column" }}>
                              <Row
                                align="middle"
                                justify={"center"}
                                style={{ flexDirection: "row" }}
                              >
                                {exercise.comments?.length === 0 ? (
                                  <FaRegComments
                                    color={Theme.BLUE}
                                    size={".65vw"}
                                    style={{
                                      position: "relative",
                                      right: "2.5%",
                                    }}
                                  />
                                ) : (
                                  <FaComments
                                    color={Theme.BLUE}
                                    size={".65vw"}
                                    style={{
                                      position: "relative",
                                      right: "2.5%",
                                    }}
                                  />
                                )}
                                <Text
                                  style={{
                                    color: Theme.TEXT,
                                    fontSize: ".65vw",
                                    textAlign: "center",

                                    fontWeight: "bold",
                                  }}
                                >
                                  Comments
                                </Text>
                              </Row>
                            </div>
                          </Button>
                        </Col>
                        <div>
                          <Text
                            style={{
                              color: Theme.TEXT2,
                              fontSize: ".65vw",
                            }}
                          ></Text>
                        </div>
                        <Modal
                          open={commentsModalVisible}
                          onCancel={handleInfoModalClose}
                          width={"35vw"}
                          bodyStyle={{
                            paddingTop: "1vw",
                            paddingLeft: "1vw",
                            paddingRight: "1vw",
                            height: "31vw",
                          }}
                          className={
                            Theme.BACKGROUND === "#000"
                              ? "customAntdModalDark"
                              : "customAntdModalLight"
                          }
                          footer={null}
                        >
                          <ExerciseComments exercise={selectedExercise} />
                        </Modal>
                      </Col>
                      <ExerciseModal
                        infoModalVisible={infoModalVisible}
                        handleInfoModalClose={handleInfoModalClose}
                        handleInfoClick={handleInfoClick}
                        selectedExercise={selectedExercise}
                        Theme={Theme}
                      />
                      <Col>
                        {exercise.sets.map((set, setIndex) => (
                          <React.Fragment key={setIndex}>
                            <Row
                              style={{
                                marginBottom: "1vw",
                              }}
                            >
                              <Row
                                style={{
                                  paddingBottom: "2.5%",
                                  paddingTop: setIndex === 0 ? "2.5%" : null,
                                  borderBottom: "1px solid",
                                  borderBottomColor: Theme.TEXT2,
                                  borderTop:
                                    setIndex === 0 ? "1px solid" : null,
                                  borderTopColor:
                                    setIndex === 0 ? Theme.TEXT2 : null,
                                  marginLeft: "4vw",
                                  width: "30vw",
                                }}
                              >
                                <Col align="start">
                                  <Text
                                    style={{
                                      color: Theme.TEXT,
                                      fontSize: ".75vw",
                                      marginRight: "1vw",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Set {setIndex + 1}
                                  </Text>
                                </Col>

                                <Col
                                  align="middle"
                                  style={{
                                    marginRight: "3vw",
                                    marginLeft: "3vw",
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: Theme.TEXT,
                                      fontSize: ".75vw",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {set.weight.type}
                                  </Text>
                                  <div>
                                    <Text
                                      style={{
                                        color: Theme.PRIMARY,
                                        fontSize: ".8vw",
                                      }}
                                    >
                                      {set.weight.value}
                                    </Text>
                                  </div>
                                </Col>
                                {set.params.map((param, paramIndex) => (
                                  <Col
                                    key={paramIndex}
                                    align="middle"
                                    style={{ marginRight: "3vw" }}
                                  >
                                    <Text
                                      style={{
                                        color: Theme.TEXT,
                                        fontSize: ".75vw",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {param.type}
                                    </Text>
                                    <div>
                                      {" "}
                                      <Text
                                        style={{
                                          color: Theme.PRIMARY,
                                          fontSize: ".8vw",
                                        }}
                                      >
                                        {param.value}
                                      </Text>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </Row>
                          </React.Fragment>
                        ))}
                      </Col>
                    </Row>
                  </Col>
               
                ))}
                </>
                </Card>
                ): (
                <Card
                  key={exercise.id}
                  style={{
                    marginTop: "1vw",
                    background: onBlock ? Theme.CARD : Theme.BACKGROUND,
                    borderWidth: 0,
                    width: "90%",
                    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)",
                    paddingBottom: ".5vw",
                  }}
                >
                  <Col align="middle" justify="center">
                    <Col align="start">
                      <Text
                        style={{
                          color: Theme.TEXT,
                          fontSize: ".9vw",
                          position: "relative",
                          marginBottom: "1vw",
                          fontWeight: "bold",
                          alignSelf: "flex-start",
                          marginLeft: "1vw",
                        }}
                      >
                        {exercise.library_exercise.name}
                      </Text>
                    </Col>
                    <Row
                      align="top"
                      justify="center"
                      style={{ marginTop: "1vw" }}
                    >
                      <Col align="middle" justify="center">
                        {exercise.library_exercise.image ? (
                          <div>
                            <img
                              style={{
                                height: "8vw",
                                width: "12vw",

                                borderRadius: 10,
                                objectFit: "cover",
                              }}
                              src={exercise.library_exercise.image}
                            />
                          </div>
                        ) : (
                          <div>
                            <img
                              style={{
                                height: "4.5vw",
                                width: "12vw",
                                borderRadius: 10,
                                objectFit: "cover",
                              }}
                              src={customblank}
                            />
                          </div>
                        )}
                        <Col align="middle" justify="center">
                          <Button
                            // color={"PRIMARY"}
                            style={{
                              // marginLeft: ".5vw",
                              cursor: "pointer",
                              // height: "2vw",
                              width: "12vw",
                              marginTop: ".25vw",

                              // top: "1.85vw",
                              background: onBlock ? Theme.BACKGROUND : Theme.CARD,
                              borderRadius: 5,
                              borderWidth: 0,
                              padding: "5%",
                            }}
                            onClick={() =>
                              dispatch(
                                completeExercise(exercise.id, {
                                  completed: !exercise.completed,
                                })
                              )
                            }
                          >
                            <div style={{ flexDirection: "column" }}>
                              {exercise.completed ? (
                                <Row align={"middle"} justify={"center"}>
                                  <GoCheckCircleFill
                                    size={".65vw"}
                                    style={{
                                      position: "relative",
                                      right: "2.5%",
                                    }}
                                    color={Theme.BLUE}
                                  />
                                  <div>
                                    <Text
                                      style={{
                                        color: Theme.TEXT,
                                        fontSize: ".65vw",
                                        textAlign: "center",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Completed
                                    </Text>
                                  </div>
                                </Row>
                              ) : (
                                <Col>
                                  <GoCheckCircle
                                    color={Theme.PRIMARY}
                                    size={".65vw"}
                                    style={{
                                      position: "relative",
                                      top: ".05vw",
                                      right: "2.5%",
                                    }}
                                  />
                                  <Text
                                    style={{
                                      color: Theme.TEXT,
                                      fontSize: ".65vw",
                                      textAlign: "center",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Complete
                                  </Text>
                                </Col>
                              )}
                            </div>
                          </Button>
                          <div>
                            <Button
                              // color={"PRIMARY"}
                              style={{
                                // marginLeft: ".5vw",
                                cursor: "pointer",
                                // height: "2vw",
                                width: "12vw",
                                marginTop: ".25vw",
                                // top: "1.85vw",
                                background: onBlock ? Theme.BACKGROUND : Theme.CARD,
                                borderRadius: 5,
                                borderWidth: 0,
                                padding: "5%",
                                // opacity: 0.9,
                              }}
                              onClick={() => handleInfoClick(exercise)}
                            >
                              <div style={{ flexDirection: "column" }}>
                                <Row
                                  align="middle"
                                  justify={"center"}
                                  style={{ flexDirection: "row" }}
                                >
                                  <FaList
                                    color={Theme.PRIMARY}
                                    style={{
                                      position: "relative",
                                      right: "2.5%",
                                    }}
                                    size={".55vw"}
                                  />
                                  <Text
                                    style={{
                                      color: Theme.TEXT,
                                      fontSize: ".65vw",
                                      textAlign: "center",

                                      fontWeight: "bold",
                                    }}
                                  >
                                    Instructions
                                  </Text>
                                </Row>
                              </div>
                            </Button>
                          </div>
                          <Button
                            // color={"PRIMARY"}
                            style={{
                              // marginLeft: ".5vw",
                              cursor: "pointer",
                              // height: "2vw",
                              width: "12vw",
                              marginTop: ".25vw",
                              // top: "1.85vw",
                              background: onBlock ? Theme.BACKGROUND : Theme.CARD,
                              borderRadius: 5,
                              borderWidth: 0,
                              padding: "5%",
                              // opacity: 0.9,
                            }}
                            onClick={() => handleCommentsClick(exercise)}
                          >
                            <div style={{ flexDirection: "column" }}>
                              <Row
                                align="middle"
                                justify={"center"}
                                style={{ flexDirection: "row" }}
                              >
                                {exercise.comments?.length === 0 ? (
                                  <FaRegComments
                                    color={Theme.BLUE}
                                    size={".65vw"}
                                    style={{
                                      position: "relative",
                                      right: "2.5%",
                                    }}
                                  />
                                ) : (
                                  <FaComments
                                    color={Theme.BLUE}
                                    size={".65vw"}
                                    style={{
                                      position: "relative",
                                      right: "2.5%",
                                    }}
                                  />
                                )}
                                <Text
                                  style={{
                                    color: Theme.TEXT,
                                    fontSize: ".65vw",
                                    textAlign: "center",

                                    fontWeight: "bold",
                                  }}
                                >
                                  Comments
                                </Text>
                              </Row>
                            </div>
                          </Button>
                        </Col>
                        <div>
                          <Text
                            style={{
                              color: Theme.TEXT2,
                              fontSize: ".65vw",
                            }}
                          ></Text>
                        </div>
                        <Modal
                          open={commentsModalVisible}
                          onCancel={handleInfoModalClose}
                          width={"35vw"}
                          bodyStyle={{
                            paddingTop: "1vw",
                            paddingLeft: "1vw",
                            paddingRight: "1vw",
                            height: "31vw",
                          }}
                          className={
                            Theme.BACKGROUND === "#000"
                              ? "customAntdModalDark"
                              : "customAntdModalLight"
                          }
                          footer={null}
                        >
                          <ExerciseComments exercise={selectedExercise} />
                        </Modal>
                      </Col>
                      <ExerciseModal
                        infoModalVisible={infoModalVisible}
                        handleInfoModalClose={handleInfoModalClose}
                        handleInfoClick={handleInfoClick}
                        selectedExercise={selectedExercise}
                        Theme={Theme}
                      />
                      <Col>
                        {exercise.sets.map((set, setIndex) => (
                          <React.Fragment key={setIndex}>
                            <Row
                              style={{
                                marginBottom: "1vw",
                              }}
                            >
                              <Row
                                style={{
                                  paddingBottom: "2.5%",
                                  paddingTop: setIndex === 0 ? "2.5%" : null,
                                  borderBottom: "1px solid",
                                  borderBottomColor: Theme.TEXT2,
                                  borderTop:
                                    setIndex === 0 ? "1px solid" : null,
                                  borderTopColor:
                                    setIndex === 0 ? Theme.TEXT2 : null,
                                  marginLeft: "4vw",
                                  width: "30vw",
                                }}
                              >
                                <Col align="start">
                                  <Text
                                    style={{
                                      color: Theme.TEXT,
                                      fontSize: ".75vw",
                                      marginRight: "1vw",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Set {setIndex + 1}
                                  </Text>
                                </Col>

                                <Col
                                  align="middle"
                                  style={{
                                    marginRight: "3vw",
                                    marginLeft: "3vw",
                                  }}
                                >
                                  <Text
                                    style={{
                                      color: Theme.TEXT,
                                      fontSize: ".75vw",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {set.weight.type}
                                  </Text>
                                  <div>
                                    <Text
                                      style={{
                                        color: Theme.PRIMARY,
                                        fontSize: ".8vw",
                                      }}
                                    >
                                      {set.weight.value}
                                    </Text>
                                  </div>
                                </Col>
                                {set.params.map((param, paramIndex) => (
                                  <Col
                                    key={paramIndex}
                                    align="middle"
                                    style={{ marginRight: "3vw" }}
                                  >
                                    <Text
                                      style={{
                                        color: Theme.TEXT,
                                        fontSize: ".75vw",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {param.type}
                                    </Text>
                                    <div>
                                      {" "}
                                      <Text
                                        style={{
                                          color: Theme.PRIMARY,
                                          fontSize: ".8vw",
                                        }}
                                      >
                                        {param.value}
                                      </Text>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </Row>
                          </React.Fragment>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Card>
                )
              ))}
          <Modal
            open={editModalOpen}
            onCancel={handleEditModal}
            cancelButtonProps={{ style: { display: "none" } }}
            closeIcon={<span></span>}
            footer={[<Col align={"center"} justify={"center"}></Col>]}
            width={"90vw"}
            style={{ top: "2vw" }}
            className={
              Theme.BACKGROUND === "#000"
                ? "customAntdModalDark"
                : "customAntdModalLight"
            }
          >
            <EditWorkoutForm
              closeModal={handleEditModal}
              workoutData={workout[0]}
 
            />
          </Modal>
        </Col>
      )}
    </Col>
  );
};

export default WorkoutDetails;
