import { useEffect, useState } from "react";

import { Col, Card, Typography, Input, Modal } from "antd";
import { updatePhoto } from "../redux/actions/clients";
import { useDispatch, useSelector } from "react-redux";
import { load_user, updateEmail } from "../redux/actions/auth";
import { deleteGroup, updateGroups } from "../redux/actions/groups";

import genericPofile from "../assets/images/profilepic.jpg";
import { IoPencilSharp } from "react-icons/io5";

import Theme from "../constants/Theme";
import { Button } from "react-bootstrap";
import { StyleSheet, css } from "aphrodite";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import * as yup from "yup";
import BootstrapInput from "./common/Input";

function GroupProfile({ group, closeModal }) {
  const { user, Theme } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { groupStatus } = useSelector((state) => state.trainers);

  const [loading, setLoading] = useState(false);
  const [hideButtons, setHideButtons] = useState(false);
  const [emailFormVisible, setEmailFormVisible] = useState(false);
  const [passFormVisible, setPassFormVisible] = useState(false);
  const [deactFormVisible, setDeactFormVisible] = useState(false);
  const [new_email, setEmailInputValue] = useState("");
  const [re_new_email, setReEmailInputValue] = useState("");
  const [new_password, setNewPassInputValue] = useState("");
  const [re_new_password, setNewRePassInputValue] = useState("");
  const [current_password, setPassInputValue] = useState("");
  const [first_name, setFirstNameValue] = useState(group.name);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [firstNamePlaceHolder, setFirstNamePlaceholder] = useState(first_name);
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");

  const fetchUser = () => dispatch(load_user());
  useEffect(() => {
    fetchUser();
  }, []);
  const { Text } = Typography;

  const submitDelete = async () => {
    dispatch(deleteGroup(group.id));
    setDeactFormVisible(false);
    setHideButtons(false);
    closeModal();
    //dispatch(deleteTrainer(trainer.user_account.id)).then(response => {if (response.status == 204) {setDeactFormVisible(false)}})
  };

  const cancelUpdate = () => {
    setEmailFormVisible(false);
    setPassFormVisible(false);
    setDeactFormVisible(false);
    setHideButtons(false);
    setEmailInputValue("");
    setReEmailInputValue("");
    setNewPassInputValue("");
    setNewRePassInputValue("");
    setPassInputValue("");
  };

  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1300);

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 768);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  var trainerPhotoPath = genericPofile;
  const [trainerPhoto, setTrainerPhoto] = useState(trainerPhotoPath);

  useEffect(() => {
    setFirstNameValue(group.name);
    setFirstNamePlaceholder(group.name);
    //setLastNameValue(trainer.user_account.last_name);
    //setLastNamePlaceholder(trainer.user_account.last_name);
    //setEmailValue(trainer.user_account.email);
    //setEmailPlaceHolder(trainer.user_account.email);
  }, [group]);

  const handleCancel = () => {
    setEditModalVisible(false);
  };

  const onFirstNameChange = (e) => {
    // Update the first_name state variable here
    setFirstNamePlaceholder(e.target.value);
  };

  const submitUpdate = async (e) => {
    e.preventDefault(); // Prevent form submission

    try {
      // Define validation schema
      const validationSchema = yup.object().shape({
        firstName: yup.string().required("First Name is required"),
      });

      // Create an object with the values to validate
      const valuesToValidate = {
        firstName: firstNamePlaceHolder,
      };

      // Validate the values
      await validationSchema.validate(valuesToValidate, { abortEarly: false });

      // If validation succeeds, update the state and dispatch the update action
      setFirstNameValue(firstNamePlaceHolder);

      dispatch(
        updateGroups(group.id, {
          name: firstNamePlaceHolder,
        })
      );
      setFirstNameError("");
      setEditModalVisible(false);
    } catch (error) {
      // Handle validation errors
      const validationErrors = {};
      if (error.inner) {
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });
      }
      if (validationErrors.firstName) {
        setFirstNameError(validationErrors.firstName);
      }
    }
  };
  return (
    <>
      <Col
        bordered={false}
        className="header-solid h-full"
        style={{ height: "12vw" }}
      >
        <div style={{ position: "relative", top: "1vw" }}>
          <Col align={"center"}>
            <div flex>
              <div middle style={{ marginTop: "5%" }}>
                {!hideButtons && (
                  <Text bold style={{ fontSize: "1vw", color: Theme.TEXT }}>
                    {first_name}
                    <span
                      style={{
                        top: 0,
                        left: ".25vw",
                        position: "relative",
                        color: Theme.PRIMARY,
                        cursor: "pointer",
                      }}
                      onClick={() => setEditModalVisible(true)}
                    >
                      <IoPencilSharp size={".75vw"} />
                    </span>
                  </Text>
                )}
                {!hideButtons && (
                  <div>
                    <Text
                      size={18}
                      style={{ fontSize: "1vw", color: Theme.TEXT }}
                    >
                      {}
                    </Text>
                  </div>
                )}

                {deactFormVisible && (
                  <div style={{ top: "8%" }}>
                    <div style={styles.fieldrow}>
                      <h3 style={{ color: Theme.TEXT }}>
                        Are you sure you want to delete this Team?
                      </h3>
                    </div>
                    <Col
                      middle
                      row
                      space="evenly"
                      style={{ top: "1vw", position: "relative" }}
                    >
                      <Button
                        small
                        style={{
                          backgroundColor: Theme.BACKGROUND,
                          borderRadius: 5,
                          borderWidth: 0,
                          padding: "2%",
                          marginRight: ".5vw",
                          minWidth: "4vw",
                          boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)", // Adding a shadow effect
                          cursor: "pointer",
                        }}
                        onClick={(e) => submitDelete(e)}
                      >
                        <Text
                          style={{
                            color: Theme.TEXT,
                            fontWeight: "bold",
                          }}
                        >
                          Delete
                        </Text>
                      </Button>
                      <Button
                        small
                        style={{
                          backgroundColor: Theme.BACKGROUND,
                          borderRadius: 5,
                          borderWidth: 0,
                          padding: "2%",
                          marginRight: ".5vw",
                          minWidth: "4vw",
                          boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)", // Adding a shadow effect
                          cursor: "pointer",
                        }}
                        onClick={() => cancelUpdate()}
                      >
                        <Text
                          style={{
                            color: Theme.TEXT,
                            fontWeight: "bold",
                          }}
                        >
                          Cancel
                        </Text>
                      </Button>
                    </Col>
                  </div>
                )}
              </div>

              {!hideButtons && (
                <div center column style={{ position: "relative", top: "1vw" }}>
                  <div center row>
                    <Button
                      small
                      style={{
                        backgroundColor: Theme.BACKGROUND,
                        borderRadius: 5,
                        borderWidth: 0,
                        padding: "2%",
                        boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)", // Adding a shadow effect
                      }}
                      onClick={() => {
                        setDeactFormVisible(true);
                        setHideButtons(true);
                      }}
                    >
                      <Text
                        style={{
                          color: Theme.TEXT,
                          fontWeight: "bold",
                          fontSize: ".75vw",
                          padding: 10,
                          textAlign: "center",
                          cursor: "pointer",
                        }}
                      >
                        Delete Team
                      </Text>
                    </Button>
                    {groupStatus > 399 && (
                      <div
                        className={css(styles.row, styles.center)}
                        style={{ top: 25 }}
                      >
                        <p className={css(styles.errorText)}>
                          Delete failed. Please try again.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </Col>
        </div>

        <div middle style={styles.legalCard}></div>
        <div style={{ height: 200 }}></div>
        <Modal
          open={editModalVisible}
          onCancel={handleCancel}
          width={"32vw"}
          bodyStyle={{ minHeight: "8vw" }}
          footer={[null]}
          className={
            Theme.BACKGROUND === "#000"
              ? "customAntdModalDark"
              : "customAntdModalLight"
          }
        >
          <form
            style={{ background: Theme.CARD, paddingBottom: "3vw" }}
            onSubmit={(e) => submitUpdate(e)}
          >
            <Col align="center" style={{ top: "1vw", position: "relative" }}>
              <h3 style={{ color: Theme.TEXT }}>Edit Group Name</h3>
              <Col style={styles.fieldrow}>
                <BootstrapInput
                  name="current password"
                  placeholder={firstNamePlaceHolder}
                  value={firstNamePlaceHolder}
                  onChange={(e) => onFirstNameChange(e)}
                  defaultValue={firstNamePlaceHolder}
                  Theme={Theme}
                  width={"15vw"}
                />
                <div style={{ marginTop: ".5vw" }}>
                  <Text style={{ color: "red" }}>{firstNameError}</Text>
                </div>
              </Col>
              <div
                middle
                row
                space="evenly"
                style={{ top: "1vw", position: "relative" }}
              >
                <Button
                  small
                  style={{
                    backgroundColor: "white",
                    borderRadius: 5,
                    borderWidth: 0,
                    padding: "2%",
                    marginRight: ".5vw",
                    minWidth: "4vw",
                    cursor: "pointer",
                    boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.175)", // Adding a shadow effect
                  }}
                  onClick={(e) => submitUpdate(e)}
                >
                  <Text
                    style={{
                      color: Theme.BLACK,
                      fontWeight: "bold",
                    }}
                  >
                    Save
                  </Text>
                </Button>
              </div>
            </Col>
          </form>
        </Modal>
      </Col>
    </>
  );
}

export default GroupProfile;

const styles = StyleSheet.create({
  profile: {
    // marginBottom: 50,
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.1,
    // justifyContent: "flex-start",.
    flex: 1,
  },
  profileContainer: {
    // height: '100%',
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5,
    elevation: 2,
    zIndex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  profileBackground: {
    // width: width,
    // height: height / 2,
  },
  profileCard: {
    // position: "relative",
    // paddingTop: 25,
    marginBottom: "5%",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    // backgroundColor: Theme.COLORS.WHITE,
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5,
    zIndex: 2,
    elevation: 2,
  },
  legalCard: {
    // position: "relative",
    // paddingTop: 25,
    marginBottom: "25%",
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
    // backgroundColor: Theme.COLORS.WHITE,
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5,
    zIndex: 2,
    elevation: 2,
  },
  info: {
    paddingHorizontal: 40,
    elevation: 2,
  },

  avatar: {
    height: "45%",
    width: "40%",
    borderRadius: 62,
    borderWidth: 0,
    bottom: "15%",
  },
  nameInfo: {
    // marginTop: '4%',
    bottom: "25%",
  },
  divider: {
    width: "90%",
    borderWidth: 1,
    borderColor: "#E9ECEF",
  },
  scroll: {
    borderRadius: 5,
    height: "200%",
    top: 50,
    // marginBottom: 500,
  },
  button: {
    backgroundColor: Theme.COLORS.WHITE,
    height: 45,
    width: 90,
    marginBottom: "10%",
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5,
  },
  saveButton: {
    backgroundColor: Theme.COLORS.WHITE,
    height: 45,
    width: "40%",
    marginBottom: "10%",
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5,
  },
  legalText: {
    color: Theme.COLORS.BLACK,
    marginVertical: "2%",
  },
  input: {
    // backgroundColor: "#fff",
    borderBottomColor: Theme.COLORS.PRIMARY,
    borderColor: "transparent",
    borderWidth: 1,
    paddingHorizontal: 10,
    paddingBottom: 10,
    // margin: 10,
    // borderRadius: 5,
    color: Theme.COLORS.WHITE,
    maxWidth: "100%",
    minWidth: "70%",
  },
  bottomInputs: {
    // backgroundColor: "#fff",
    borderBottomColor: Theme.COLORS.PRIMARY,
    borderColor: "transparent",
    borderWidth: 1,
    padding: 10,
    // margin: 10,
    // borderRadius: 5,

    color: Theme.COLORS.BLACK,
    maxWidth: "100%",
    minWidth: "70%",
  },
  fieldrow: {
    flexDirection: "row",
    alignItems: "center",
  },
  modalView: {
    backgroundColor: "black",
    backgroundColor: "white",
    borderRadius: 10,
    // alignItems: "center",
    top: 15,
    // alignSelf: "center",
  },
  bottomView: {
    flex: 1,
    // justifyContent: "flex-end",
    top: 25,
    // alignSelf: "center",
  },
});
