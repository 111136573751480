import {
  Row,
  Col,
  Card,
  Radio,
  Table,
  Upload,
  message,
  Progress,
  Button,
  Avatar,
  Typography,
} from "antd";

import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getClients } from "../redux/actions/clients";
import { checkAuthenticated } from "../redux/actions/auth";




function Tables() {
  const { clients } = useSelector((state) => state.clients);

  const dispatch = useDispatch();
  const tokenCheck = () => dispatch(checkAuthenticated());


  return (
    <>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col xs="24" xl={24}>
          
             
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Tables;
