import axios from "axios";
import {
  CHECK_SUBSCRIPTION, RESET_CHECK,
} from "./types";
 
import { REACT_APP_API_URL } from "../../env";
 


export const checkStatus = async (dispatch) => {
  try {    
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          Authorization: "JWT " + token,
        }
      };
      const res = await axios.post(`${REACT_APP_API_URL}/api/status/`,{},config);
      if (res.data) {

        dispatch({
          type: CHECK_SUBSCRIPTION,
          payload: res.data,
        });
      } else {
  
      }
  } catch (err) {

  }
};


// export const validateIAP = async (planId, payment_method_id) => {
//   try {    
//       const body = JSON.stringify({ "receipt": data.receipt, "type": data.type});
//       const token = await localStorage.getItem("access");
//       const config = {
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: "JWT " + token,
//         }
//       };

//       const res = await axios.post(`${REACT_APP_API_URL}/api/payment/`, body, config);
//       if (res.data) {
//         data.onSucess(true);
//       } else {
  
//       }
//   } catch (err) {

//   }
// };

export const resetCheck = () => ({
  type: RESET_CHECK
});








