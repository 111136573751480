import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, Col, Row, Typography, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createWorkout,
  getExercises,
  getMesocycles,
  getWorkouts,
  resetWorkoutStatus,
} from "../../redux/actions/workouts";
import moment from "moment";
import ExerciseList from "./ExerciseList";
import Library from "./Library";
import CustomSelect from "../common/DropDown";
import BlockDrop from "../common/BlockDrop";

import "react-datepicker/dist/react-datepicker.css";
import StyledDatePickerComponent from "../common/DatePickerWorkouts";
const { Text } = Typography;
const { Option } = Select;

const CreateWorkoutForm = ({
  initialDate,
  closeModal,
  closeModalSuccess,
  meso,
  initExercises,
}) => {
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.clients);
  const { Theme } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  const [chosenExercises, setChosenExercises] = useState([]);

  const { workouts, workoutStatus, mesocycles } = useSelector(
    (state) => state.workouts
  );
  const [isDatePickerDisabled, setIsDatePickerDisabled] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedMesocycle, setSelectedMesocycle] = useState(
    meso ? [{ id: meso[0].id }] : null
  );
  const [selecteDate, setSelectedDate] = useState(
    initialDate ? initialDate : null
  );

  useEffect(() => {
    if (initExercises && initExercises.length > 0) {
      setChosenExercises(initExercises);
    } else {
      setChosenExercises([]);
    }
    dispatch(getMesocycles(client.id));
  }, []);

  useEffect(() => {
    setSelectedDate(initialDate ? initialDate : null);
  }, [initialDate]);

  useEffect(() => {
    if (meso) {
      setSelectedMesocycle([{ id: meso[0].id }]);
    }
  }, [meso]);

  useEffect(() => {
    setChosenExercises(initExercises);
  }, [initExercises]);

  useEffect(() => {
    if (moment(initialDate, "YYYY-MM-DD").isValid()) {
      form.setFieldsValue({
        date: moment(initialDate, "YYYY-MM-DD"),
      });
      setIsDatePickerDisabled(true);
    } else {
      setIsDatePickerDisabled(false);
    }
  }, [initialDate, form]);

  const handleSubmit = () => {
    if (closeModalSuccess != null) {
      closeModalSuccess();
    } else {
      closeModal();
    }
    setSelectedDate(null);
  };

  const handleCloseModal = () => {
    closeModal();
    setSelectedDate(null);
    form.resetFields();
    setSelectedMesocycle(null);
  };

  const handleExerciseSelect = (exercises, isSupersetChecked = false) => {
    setChosenExercises(prevExercises => {
        const currentExercises = JSON.parse(JSON.stringify(Array.isArray(prevExercises) ? prevExercises : [])); // Deep copy and ensure it's always an array
        let newExercises = Array.isArray(exercises) ? exercises : [exercises]; // Ensure it's always an array

        newExercises = newExercises.map((exercise, index) => ({
            ...exercise,
            order: currentExercises.length + index, // Update order based on existing exercises count
        }));

        if (isSupersetChecked) {
            // If it's a superset, add the new exercises as a single superset entry
            return [...currentExercises, { isSuperset: true, exercises: newExercises, order: currentExercises.length }];
        } else {
            // If not a superset, just concatenate the new exercises
            return [...currentExercises, ...newExercises];
        }
    });
};


  const handleAddSet = (exerciseIndex, sourceSetIndex, subIndex) => {
    setChosenExercises((prevExercises) => {
      let updatedExercises = JSON.parse(JSON.stringify(prevExercises));
      // Define a function to create a new set based on the existing source set or default values
      const createNewSet = (sourceSet) => {
        return sourceSet ? {
          weight: { ...sourceSet.weight },
          params: sourceSet.params.map(param => ({ ...param })),
        } : {
          weight: { type: "% Difficulty", value: "" },
          params: [{ type: "Reps", value: "" },{ type: "Rest", value: "" }],
        };
      };
  
      if (typeof subIndex !== 'undefined') {
        // Handling for sub-exercise within a superset
        const superset = updatedExercises[exerciseIndex];
        if (superset && superset.exercises && superset.exercises[subIndex]) {
          const subExercise = superset.exercises[subIndex];
          if (!subExercise.sets) {
            subExercise.sets = [];
          }
          const sourceSet = subExercise.sets[sourceSetIndex];
          subExercise.sets.push(createNewSet(sourceSet));
        } 
      } else {
        const exercise = updatedExercises[exerciseIndex];
        if (exercise && exercise.sets) {
          const sourceSet = exercise.sets[sourceSetIndex];
          exercise.sets.push(createNewSet(sourceSet));
        } 
      }
  
      return updatedExercises;
    });
  };

  const handleRemoveSet = (index, setIndex, subIndex) => {
    setChosenExercises((prevExercises) => {
      let updatedExercises = JSON.parse(JSON.stringify(prevExercises));
        if (typeof subIndex !== 'undefined' && updatedExercises[index]?.exercises[subIndex]?.sets) {
          updatedExercises[index].exercises[subIndex].sets.splice(setIndex, 1);
      } else if (updatedExercises[index]?.sets) {
          updatedExercises[index].sets.splice(setIndex, 1);
      }
  
      return updatedExercises;
    });
  };

  const handleRemoveExercise = (index, subIndex) => {

    setChosenExercises((prevExercises) => {
      let updatedExercises = JSON.parse(JSON.stringify(prevExercises));

      if (typeof subIndex !== 'undefined') {
        const targetExercise = updatedExercises[index];
        
        if (targetExercise.isSuperset) {
          targetExercise.exercises = targetExercise.exercises.filter((_, si) => si !== subIndex);
          
          if (targetExercise.exercises.length === 0) {
            return updatedExercises.filter((_, i) => i !== index);
          } else {
            updatedExercises[index] = targetExercise;
            return updatedExercises;
          }
        }
      } else {
        updatedExercises = updatedExercises.filter((_, i) => i !== index);
      }
      // Return the previous exercises by default
      return updatedExercises
    });
  };

  const handleParamTypeChange = (exerciseIndex, setIndex, paramIndex, value, subIndex) => {
    setChosenExercises((prevExercises) => {
      const updatedExercises = [...prevExercises];
  
      if (typeof subIndex !== 'undefined') {
        if (updatedExercises[exerciseIndex]?.exercises[subIndex]?.sets[setIndex]?.params) {
          updatedExercises[exerciseIndex].exercises[subIndex].sets[setIndex].params[paramIndex].type = value;
        }
      } else {
        if (updatedExercises[exerciseIndex]?.sets[setIndex]?.params) {
          updatedExercises[exerciseIndex].sets[setIndex].params[paramIndex].type = value;
        }
      }
  
      return updatedExercises;
    });
  };

  const handleRemoveParamFromSet = (exerciseIndex, setIndex, paramIndex, subIndex) => {
    setChosenExercises((prevExercises) => {
      const updatedExercises = [...prevExercises];
  
      if (typeof subIndex !== 'undefined') {
        updatedExercises[exerciseIndex].exercises[subIndex].sets[setIndex].params.splice(paramIndex, 1);
      } else {
        updatedExercises[exerciseIndex].sets[setIndex].params.splice(paramIndex, 1);
      }
  
      return updatedExercises;
    });
  };

  const handleFormSubmit = (values) => {
    setError(false);
    setLoading(true);
    const newDate = new Date(selecteDate);
    const formattedDate = newDate.toISOString();
    const indexOfT = formattedDate.indexOf("T");
    const dateBeforeT = formattedDate.substring(0, indexOfT);

    const workoutData = {
      title: values.title,
      notes: values.notes,
      date: dateBeforeT,
      exercises: chosenExercises,
      client: client.id,
      meso: meso ? meso[0].id : selectedMesocycle ? selectedMesocycle.id : null,
    };

    dispatch(createWorkout(workoutData));
    setLoading(false);
  };

  const handleReorder = (dragIndex, hoverIndex) => {
    const draggedExercise = chosenExercises[dragIndex];
    setChosenExercises((prevExercises) => {
      const updatedExercises = [...prevExercises];
      updatedExercises.splice(dragIndex, 1);
      updatedExercises.splice(hoverIndex, 0, draggedExercise);
      return updatedExercises;
    });
  };

  const handleExerciseInputChange = (index, field, value) => {
    setChosenExercises((prevExercises) =>
      prevExercises.map((exercise, i) =>
        i === index
          ? { ...exercise, [field]: { ...exercise[field], value: value } }
          : exercise
      )
    );
  };

  const handleParamValueChange = (exerciseIndex, setIndex, paramIndex, value, subIndex) => {
    setChosenExercises((prevExercises) => {
      let updatedExercises = JSON.parse(JSON.stringify(prevExercises));
      if (typeof subIndex !== 'undefined') {
        updatedExercises[exerciseIndex].exercises[subIndex].sets[setIndex].params[paramIndex].value = value;
      } else {
        updatedExercises[exerciseIndex].sets[setIndex].params[paramIndex].value = value;
      }
  
      return updatedExercises;
    });
  };

  const handleAddParamToSet = (exerciseIndex, setIndex, subIndex) => {
    setChosenExercises((prevExercises) => {
      let updatedExercises = JSON.parse(JSON.stringify(prevExercises));
  
      // Determine the new parameter type based on the number of existing parameters
      const getNewParamType = (params) => {
        switch (params.length) {
          case 0: return "Reps";
          case 1: return "Rest";
          case 2: return "Time";
          default: return "Reps"; // Default or adjust as needed
        }
      };
  
      if (typeof subIndex !== 'undefined') {
        const subExercise = updatedExercises[exerciseIndex].exercises[subIndex];
        const newParamType = getNewParamType(subExercise.sets[setIndex].params);
        subExercise.sets[setIndex].params.push({ type: newParamType, value: "" });
      } else {
        // Adding new param to a regular exercise set
        const exercise = updatedExercises[exerciseIndex];
        const newParamType = getNewParamType(exercise.sets[setIndex].params);
        exercise.sets[setIndex].params.push({ type: newParamType, value: "" });
      }
  
      return updatedExercises;
    });
  };

  const handleWeightTypeChange = (exerciseIndex, setIndex, value, subIndex) => {
    setChosenExercises((prevExercises) => {
       let updatedExercises = JSON.parse(JSON.stringify(prevExercises));
        if (typeof subIndex !== 'undefined') {
        if (updatedExercises[exerciseIndex]?.exercises[subIndex]?.sets[setIndex]?.weight) {
          updatedExercises[exerciseIndex].exercises[subIndex].sets[setIndex].weight.type = value;
        }
      } else {
        if (updatedExercises[exerciseIndex]?.sets[setIndex]?.weight) {
          updatedExercises[exerciseIndex].sets[setIndex].weight.type = value;
        }
      }
  
      return updatedExercises;
    });
  };

  const handleWeightValueChange = (exerciseIndex, setIndex, value, subIndex) => {
    setChosenExercises((prevExercises) => {
      let updatedExercises = JSON.parse(JSON.stringify(prevExercises));
  
      if (typeof subIndex !== 'undefined') {
        if (updatedExercises[exerciseIndex]?.exercises[subIndex]?.sets[setIndex]?.weight) {
          updatedExercises[exerciseIndex].exercises[subIndex].sets[setIndex].weight.value = value;
        }
      } else {
        if (updatedExercises[exerciseIndex]?.sets[setIndex]?.weight) {
          updatedExercises[exerciseIndex].sets[setIndex].weight.value = value;
        }
      }
  
      return updatedExercises;
    });
  };

  useEffect(() => {
    const handleEffect = async () => {
      if (workoutStatus === "success") {
        handleSubmit();
        dispatch(resetWorkoutStatus());
        dispatch(getWorkouts(client.id));
        form.resetFields();
        setChosenExercises([]);
      }
      if (workoutStatus === "fail") {
        setError(true);
      }
    };

    handleEffect();
  }, [workoutStatus, closeModal]);

  return (
    <Form
      style={{ background: Theme.CARD, marginTop: "1.5vw" }}
      form={form}
      onFinish={handleFormSubmit}
    >
      <Col justify="center" align="middle">
        <Text
          style={{
            fontWeight: "bold",
            fontSize: "1dvw",
            bottom: "1vw",
            position: "relative",
            color: Theme.TEXT,
          }}
        >
          Create Workout
        </Text>
        <Col align={"end"} style={{ position: "relative" }}>
          <Row align={"end"} style={{ position: "relative", bottom: "2.5vw" }}>
            <Button
              style={{
                marginRight: "1vw",
              }}
              type="primary"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            <Form.Item>
              {!loading ? (
                <Button
                  style={{
                    backgroundImage:
                      "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                    border: 0,
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  Create
                </Button>
              ) : (
                <Button
                  style={{
                    backgroundImage:
                      "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                    border: 0,
                  }}
                  type="primary"
                  htmlType="submit"
                >
                  <Spin size="small" />
                </Button>
              )}
            </Form.Item>
          </Row>

          <div>
            {error && (
              <Text
                style={{
                  fontSize: ".65vw",
                  color: "red",
                  bottom: "3vw",
                  position: "relative",
                }}
              >
                Error - Please Try Again
              </Text>
            )}
          </div>
        </Col>
        <Row
          align="middle"
          justify={"center"}
          style={{ position: "relative", bottom: "2vw" }}
          span={12}
        >
          <Row justify={"center"} align={"middle"}>
            <Form.Item
              name="title"
              style={{
                // marginBottom: "1.5vw",
                marginRight: "1vw",
              }}
              rules={[{ required: true, message: "Title is required" }]}
            >
              <Input
                placeholder="Title"
                className={
                  Theme.BACKGROUND === "#000"
                    ? "custom-placeholder-color-dark"
                    : "custom-placeholder-color-light"
                }
                style={{
                  height: "2vw",
                  color: Theme.TEXT,
                  fontSize: ".65vw",
                  background: Theme.BACKGROUND,
                  borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1,
                }}
              />
            </Form.Item>

            <Form.Item
              style={{ marginLeft: "2vw" }}
              name="date"
              rules={[{ required: true, message: "Date is required" }]}
            >
              <StyledDatePickerComponent
                selected={selecteDate}
                onChange={(date) => setSelectedDate(date)}
                disabled={isDatePickerDisabled}
                placeholder={"Select Date"}
                workouts={workouts ? workouts : null}
              />
            </Form.Item>
          </Row>
          <Form.Item
            name="notes"
            style={{ width: "30vw", position: "relative", marginLeft: "3vw" }}
          >
            <Input.TextArea
              className={
                Theme.BACKGROUND === "#000"
                  ? "custom-placeholder-color-dark"
                  : "custom-placeholder-color-light"
              }
              style={{
                color: Theme.TEXT,
                background: Theme.BACKGROUND,
                fontSize: ".65vw",
                height: "2vw",
                borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1,
              }}
              placeholder="Instructions"
            />
          </Form.Item>
          <Form.Item
            name="meso"
            style={{
              marginBottom: "1.5vw",

              marginLeft: "3vw",
            }}
          >
            {!meso && (
              <BlockDrop
                placeholder={
                  <span
                    style={{
                      color: Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                      fontSize: ".65vw",
                      top: ".05vw",
                      position: "relative",
                      fontWeight: "normal",
                    }}
                  >
                    Select Block
                  </span>
                }
                value={selectedMesocycle ? selectedMesocycle.id : null} // Set value to the ID of selected mesocycle
                options={mesocycles.map((mesocycle) => ({
                  id: mesocycle.id,
                  label: mesocycle.name,
                }))}
                onChange={(value) => setSelectedMesocycle({ id: value })} // Update the state with the selected ID
                width="15vw"
                Theme={Theme}
              />
            )}
          </Form.Item>
        </Row>
        <Row
          justify={"center"}
          align={"center"}
          style={{ position: "relative", bottom: "2vw", width: "85vw" }}
        >
          <Col
            style={{ minHeight: "20vw", width: "39vw", marginBottom: "1vw" }}
          >
            <ExerciseList
              chosenExercises={chosenExercises}
              Theme={Theme}
              handleExerciseInputChange={handleExerciseInputChange}
              handleWeightTypeChange={handleWeightTypeChange}
              handleWeightValueChange={handleWeightValueChange}
              handleParamTypeChange={handleParamTypeChange}
              handleParamValueChange={handleParamValueChange}
              handleAddParamToSet={handleAddParamToSet}
              handleRemoveExercise={handleRemoveExercise}
              handleAddSet={handleAddSet}
              handleRemoveSet={handleRemoveSet}
              handleRemoveParamFromSet={handleRemoveParamFromSet}
              setChosenExercises={setChosenExercises}
            />
          </Col>
          <Col span={12} style={{ marginRight: "1vw" }}>
            <Library
              Theme={Theme}
              handleExerciseSelect={handleExerciseSelect}
              clientID={client.id}
            />
          </Col>
        </Row>
      </Col>
    </Form>
  );
};

export default CreateWorkoutForm;
