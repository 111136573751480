import React from 'react';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';

const BootstrapSearch = ({ placeholder, onSearch, onChange, width, background, value}) => {
    const { Theme } = useSelector((state) => state.auth);

  return (
    <Form.Control
      type="text"
      placeholder={placeholder}
      onChange={onChange}
      onSearch={onSearch}
      value={value}
      style={{
        width: width || "100%", // Set width to 100% if not provided
        height: "1.5vw",
        borderRadius: "5px",
        // boxShadow: "1px 0px 2px 3px rgba(0, 0, 0, 0.1)",
        borderWidth: 0,
        border: Theme.BACKGROUND === "#000" ? null : ".5px solid #d9d9d9",
        fontSize: ".7vw",
        background: background ? background : Theme.BACKGROUND,
        color: Theme.TEXT,
        outline: "none",
        opacity: 1,
      }}
      
     
    />
  );
};

export default BootstrapSearch;
