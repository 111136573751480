import axios from "axios";
import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAIL,
  PASSWORD_RESET_CONFIRM_SUCCESS,
  PASSWORD_RESET_CONFIRM_FAIL,
  SIGNUP_SUCCESS,
  SIGNUP_FAIL,
  ACTIVATION_SUCCESS,
  ACTIVATION_FAIL,
  GOOGLE_AUTH_SUCCESS,
  GOOGLE_AUTH_FAIL,
  FACEBOOK_AUTH_SUCCESS,
  FACEBOOK_AUTH_FAIL,
  LOGOUT,
  RESET_AUTH_STATUS,
  REFRESH_TOKEN,
  UPDATE_EMAIL,
  DELETE_ACCOUNT,
  CHECK_SUBSCRIPTION,
  RESET_CHECK,
  UPDATE_PROFILE,
  RESET_PASS_SUCCESS,
  SET_THEME,

} from "./types";
import { REACT_APP_API_URL } from "../../env";

export function generatePassword(length) {
 
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789@#$%^&*!@#$%^&*()_++_)(";
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const load_user = () => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        Authorization: "JWT " + token,
      },
    };
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/trainer/`,
      {},
      config
    );
    if (res.data) {
      dispatch({
        type: USER_LOADED_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: USER_LOADED_FAIL,
    });
  }
};

export const checkAuthenticated = () => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const token = await localStorage.getItem("access");
  const refresh = await localStorage.getItem("refresh");

  const body = JSON.stringify({ token: token });
  const bodyTwo = JSON.stringify({ refresh: refresh });
  try {
    const res = await axios.post(
      `${REACT_APP_API_URL}/auth/jwt/verify/`,
      body,
      config
    );

    if (res.data.code !== "token_not_valid") {
      dispatch({
        type: AUTHENTICATED_SUCCESS,
      });
    }
  } catch (err) {
    try {
      const res = await axios.post(
        `${REACT_APP_API_URL}/auth/jwt/refresh/`,
        bodyTwo,
        config
      );
      if (res.data.code !== "token_not_valid") {
        dispatch({
          type: REFRESH_TOKEN,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: AUTHENTICATED_FAIL,
      });
    }
  }
};

export const login = (email, password) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email, password });
  try {
    const res = await axios.post(
      `${REACT_APP_API_URL}/auth/jwt/create/`,
      body,
      config
    );
    dispatch({
      type: LOGIN_SUCCESS,
      payload: res.data,
    });

    dispatch(load_user());
  } catch (err) {
    dispatch({
      type: LOGIN_FAIL,
      payload: err.response.status,
    });
  }
};

export const signup =
  (
    first_name,
    last_name,
    email,
    password,
    re_password,
    account_type,
    refferal_link,
    phone_number,
    profession,
    measurement
  ) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      first_name,
      last_name,
      email,
      password,
      re_password,
      account_type,
      refferal_link,
      phone_number,
      profession,
      measurement
    });
  
    try {
      const res = await axios.post(
        `${REACT_APP_API_URL}/auth/users/`,
        body,
        config
      );

      dispatch({
        type: SIGNUP_SUCCESS,
        payload: res,
      });
    } catch (err) {
      dispatch({
        type: SIGNUP_FAIL,
        payload: err.response.status,
      });
    }
  };

export const signupClient =
  (first_name, last_name, email, password, re_password, account_type) =>
  async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({
      first_name,
      last_name,
      email,
      password,
      re_password,
      account_type,
    });

    try {
      const res = await axios.post(
        `${REACT_APP_API_URL}/auth/users/`,
        body,
        config
      );
    } catch (err) {}
  };

  export const signupTrainer =
  (first_name, last_name, email, password, re_password, account_type) =>
  async (dispatch) => {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };

    const body = JSON.stringify({
      first_name,
      last_name,
      email,
      password,
      re_password,
      account_type,
    });

    try {
      const res = await axios.post(
        `${REACT_APP_API_URL}/api/trainers/create`,
        body,
        config
      );
      return res
    } catch (err) {}
  };

export const verify = (uid, token) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ uid, token });

  try {
    await axios.post(
      `${REACT_APP_API_URL}/auth/users/activation/`,
      body,
      config
    );

    dispatch({
      type: ACTIVATION_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: ACTIVATION_FAIL,
    });
  }
};

export const reset_password = (email) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };

  const body = JSON.stringify({ email });

  try {
    const res = await axios.post(
      `${REACT_APP_API_URL}/auth/users/reset_password/`,
      body,
      config
    );
    dispatch({
      type: PASSWORD_RESET_SUCCESS,
    });
  } catch (err) {
    dispatch({
      type: PASSWORD_RESET_FAIL,
    });
  }
};

export const reset_password_confirm =
  (uid, token, new_password, re_new_password) => async (dispatch) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    try {
        const res = await axios.post(
          `${REACT_APP_API_URL}/auth/users/reset_password_confirm/`,
          body,
          config
        );

    dispatch({
            type: PASSWORD_RESET_CONFIRM_SUCCESS,
            payload: res.status
        });
    } catch (err) {
      
        dispatch({
            type: PASSWORD_RESET_CONFIRM_FAIL,
            payload: 400
        });
    }
  };

export const updateEmail =
  (new_email, re_new_email, current_password) => async (dispatch) => {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
    const body = JSON.stringify({ new_email, re_new_email, current_password });

    try {
      const res = await axios.post(
        `${REACT_APP_API_URL}/auth/users/set_email/`,
        body,
        config
      );

      dispatch({
        type: UPDATE_EMAIL,
        payload: res.data.status,
      });
    } catch (err) {}

  }

export const updatePass =
  (new_password, re_new_password, current_password) => async () => {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
    const body = JSON.stringify({
      new_password,
      re_new_password,
      current_password,
    });


    try {
      const res = await axios.post(
        `${REACT_APP_API_URL}/auth/users/set_password/`,
        body,
        config
      );
    } catch (err) {}
  };

  export const resetPass = (email) => async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const body = JSON.stringify({
      email
    });

    try {
      const res = await axios.post(
        `${REACT_APP_API_URL}/auth/users/reset_password/`,
        body,
        config
      );
    } catch (err) {}
  };

export const updateProfile = (id, data) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };
  const body = JSON.stringify(data);

 
  try {
    const res = await axios.patch(
      `${REACT_APP_API_URL}/api/updateaccount/${id}/`,
      body,
      config
    );

    if (res.data) {
      dispatch({
        type: UPDATE_PROFILE,
        payload: res.data,
      });
    }
  } catch (err) {
  }
};

export const deleteAccount = (current_password) => async (dispatch) => {
  const token = await localStorage.getItem("access");

  let data = new FormData();
  data.append("current_password", current_password);
  data.append("current_password", current_password);

  let config = {
    headers: {
      "'Content-Type'": "application/json",
      Authorization: `JWT ${token}`,
    headers: {
      "'Content-Type'": "application/json",
      Authorization: `JWT ${token}`,
    },
    data: data,
  }
  }

  try {
    const res = await axios.delete(
      `${REACT_APP_API_URL}/auth/users/me/`,
      config
    
    );
    dispatch({
      type: DELETE_ACCOUNT,
    });
  } catch (err) {}

};

export const logout = () => ({
  type: LOGOUT,
  type: LOGOUT,
});

export const resetStatus = () => ({
  type: RESET_AUTH_STATUS,
});

export const resetPassSuccess = () => ({
  type: RESET_PASS_SUCCESS
});

export const checkStatus = async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        Authorization: "JWT " + token,
      },
    };
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/status/`,
      {},
      config
    );
    if (res.data) {
      dispatch({
        type: CHECK_SUBSCRIPTION,
        payload: res.data,
      });
    } else {
    }
  } catch (err) {}
};

export const asyncCheckStatus = () => async (dispatch) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        Authorization: "JWT " + token,
      },
    };

    const res = await axios.post(
      `${REACT_APP_API_URL}/api/status/`,
      {},
      config
    );

    // If response is valid, dispatch the result
    if (res.data) {
      dispatch({
        type: CHECK_SUBSCRIPTION,
        payload: res.data,
      });
    }
  } catch (err) {
    console.error("Error checking async status", err);
  }
};


export const validateIAP = async (data) => {
  try {
    const body = JSON.stringify({ receipt: data.receipt, type: data.type });
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };

    const res = await axios.post(
      `${REACT_APP_API_URL}/api/payment/`,
      body,
      config
    );
    if (res.data) {
      data.onSucess(true);
    } else {
    }
  } catch (err) {}
};

export const setTheme = (data) => async (dispatch) => {
  dispatch({
    type: SET_THEME,
    payload: data
  });
};
