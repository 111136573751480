import { 
  CREATE_JOINT_COMPARISON_FAIL, 
  CREATE_JOINT_COMPARISON_SUCCESS, 
  GET_JOINT_COMPARISONS, 
  GET_SINGLE_JOINT_COMPARISON, 
  PATCH_JOINT_COMPARISON_SUCCESS, 
  RESET_JOINT_COMPARISON, 
  RESET_JOINT_COMPARISON_STATUS ,
  RESET_JOINT_COUNTER
} from "../actions/types";
const initialState = {
  jointComparisonStatus: null,
  jointComparisons: [],
  jointComparison: null,
  resultCounter: 0,
};

function jointComparisonsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_JOINT_COMPARISONS:
      return {
        ...state,
        jointComparisons: action.payload.data,
      };
    case CREATE_JOINT_COMPARISON_SUCCESS:
      return {
        ...state,
        jointComparisons: [...state.jointComparisons, action.payload.data],
        jointComparison: action.payload.data,
        jointComparisonStatus: "success",
      };
    case PATCH_JOINT_COMPARISON_SUCCESS:
      return {
        ...state,
        jointComparison: action.payload,
      };
    case CREATE_JOINT_COMPARISON_FAIL:
      return {
        ...state,
        jointComparisonStatus: "failed",
      };
    case GET_SINGLE_JOINT_COMPARISON:
      return {
        ...state,
        jointComparison: action.payload,
      };
    case RESET_JOINT_COMPARISON_STATUS:
      return {
        ...state,
        jointComparisonStatus: "reset",
      };
    case RESET_JOINT_COMPARISON:
      return {
        ...state,
        jointComparison: {
          overallScore: 0,
          l_hip_abduction: null,
          r_hip_abduction: null,
        },
      };
    case RESET_JOINT_COUNTER:
      return {
        ...state,
        resultCounter: 0,
      };
    default:
      return state;
  }
}


export default jointComparisonsReducer;
