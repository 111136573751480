import React from "react";
import { Card as AntdCard, Col } from "antd";

import { IoCheckmarkCircleSharp } from "react-icons/io5";
import Text from "antd/lib/typography/Text";
import Theme from "../constants/Theme";
import { useSelector } from "react-redux";


const mq = window.matchMedia("(min-width: 1000px)");

const SelectionCard = ({ item, selected, muted }) => {
  const {Theme} = useSelector(state => state.auth)
  return (
    <Col>
      <div
        style={{
          width: '6.0vw',
          height: '6vw',
          boxShadow: "0px 3px 6px rgba(0, 0, 0, 0.175)",
          opacity: muted ? 0.5 : 1,
          marginLeft: '1vw',
          marginRight: '1vw',
          background: Theme.BACKGROUND,
          borderRadius: 10
        }}
 
      >
      <div style={{ position: "relative" }}>
            {selected && (
              <IoCheckmarkCircleSharp 
                style={{
                  display: "block",
                  margin: "0 auto",
                  position: "absolute",
                  zIndex: 60,
                  top: "1vw",
                  left: "40%",
                  fontSize: "1.2vw", 
                  color: "#00b86e",
                }}
              />
            )}
            <img
              alt={item.title}
              src={item.image}
              style={{
                display: "block",
                margin: "0 auto",
                marginBottom: '15%',
                position: "relative",
                top: mq.matches ? "1vw" : '1vw',
                height: '2vw',
                width: '2vw'
              }}
            />
          </div>
        <div style={{ width: "100%",  textAlign: "center", position: 'relative' }}>
          <Text
            style={{
              fontWeight: "600",
              fontSize:'.6vw',
              color: Theme.TEXT,
              width: "150%",
              position: 'relative',
              top: mq.matches ? "1vw" : 0,
             
            }}
          >
            {item.title}
          </Text>
        </div>
      </div>
    </Col>
  );
};

export default SelectionCard;
