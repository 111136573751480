import axios from "axios";

import {
  GET_TRAINER_GROUPS,
  CREATE_TRAINER_GROUP_FAIL,
  CREATE_TRAINER_GROUP_SUCCESS,
  GET_SINGLE_TRAINER_GROUP,
  UPDATE_TRAINER_GROUP,
  DELETE_TRAINER_GROUP,
  SEARCH_TRAINER_GROUPS,
  RESET_TRAINER_GROUP_STATUS,
  TRAINER_GROUP_MOVEMENTS
} from "./types";

 
import { REACT_APP_API_URL } from "../../env";


// Create trainer group
export const createTrainerGroup = (data) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };
 
  try {
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/trainergroups/`,
      data,
      config
    );
    if (res.data) {
      dispatch({
        type: CREATE_TRAINER_GROUP_SUCCESS,
        payload: res,
      });
    }
    
  } catch (err) {
    dispatch({
      type: CREATE_TRAINER_GROUP_FAIL,
      payload: err.response.status,
    });
   
  }
};

// Get Trainer
export const getTrainerGroups = () => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: "JWT " + token,
        },
      };
      let url = `${REACT_APP_API_URL}/api/trainergroups`;
      const res = await axios.get(url, config);
      if (res.data) {
        dispatch({
          type: GET_TRAINER_GROUPS,
          payload: res.data,
        });
      } else {

      }
    };
  } catch (err) {
  
  }
};

// Get trainer group
export const getSingleTrainerGroup = (id) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + token,
        },
      };

      try {
        const res = await axios.get(
          `${REACT_APP_API_URL}/api/trainergroups/${id}/`,
          config
        );
        if (res.data) {
          dispatch({
            type: GET_SINGLE_TRAINER_GROUP,
            payload: res.data,
          });
        }
      } catch (err) {
    
      }
    };
  } catch (err) {

  }
};

// Find latest movements for clients in group
export const getTrainerGroupMovements = (data) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + token
        }
      };
      const body = JSON.stringify(data);
      try {
        const res = await axios.post(
          `${REACT_APP_API_URL}/api/trainergroupmovements/`,
          body,
          config
        );
        if (res.data) {
          dispatch({
            type: TRAINER_GROUP_MOVEMENTS,
            payload: res.data
          });
        }
      } catch (err) {}
    };
  } catch (err) {}
};

// Search trainer
export const searchTrainerGroups = (searchTerm) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: "JWT " + token,
        },
      };
      const res = await axios.get(
        `${REACT_APP_API_URL}/api/trainergroups?search=${searchTerm}`,
        config
      );
      if (res.data) {
        dispatch({
          type: SEARCH_TRAINER_GROUPS,
          payload: res.data,
        });
      } else {
      }
    };
  } catch (err) {

  }
};

// TODO: Update Trainer Group
export const updateTrainerGroups = (id, data) =>
  async (dispatch) => {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
  
    try {
      const res = await axios.patch(
        `${REACT_APP_API_URL}/api/trainergroups/${id}/`,
        data,
        config
      );
      if (res.data) {
        dispatch({
          type: UPDATE_TRAINER_GROUP,
          payload: res,
        });
      }
    } catch (err) {
  
    }
  };

// Delete trainer group
export const deleteTrainerGroup = (id) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };


  try {
    const res = await axios.delete(
      `${REACT_APP_API_URL}/api/trainergroups/${id}/`,
      config
    );
    dispatch({
      type: DELETE_TRAINER_GROUP,
      payload: {res, id},
    });
  } catch (err) {

  }
};

export const resetTrainerGroupStatus = () => ({
  type: "RESET_TRAINER_GROUP_STATUS",
});


export const massAssignAssessment = (id, data) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };

  const body = {
    trainer_group: id,
    assigned_assessment: data,
    assigned_assessment_type: "functional"
  };

  try {
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/clients/massasign`,
      body,
      config
    );
    dispatch({
      type: UPDATE_TRAINER_GROUP,
      payload: res.data,
    });
  } catch (err) {

  }
};

export const massAssignRanges = (id, rangeProfileData) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };

  const body = {
    trainer_group: id, 
    ranges: rangeProfileData,
  };

  try {
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/clients/massranges`, 
      body,
      config
    );
   
  } catch (err) {
    console.error("Error assigning ranges:", err);
  }
};



