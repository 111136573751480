import React, {  } from "react";
import { Col, Row, Typography } from "antd";
import { useSelector } from "react-redux";
import Compensations from "./Compensations";
import JointDisplacement from "./JointDiscplacement";
import ScoreCircle from "./ScoreCircle";
const { Text } = Typography;

const PushResults = ({ compensations, push }) => {
  const { Theme } = useSelector((state) => state.auth);
  const styles = createStyles(Theme);

return (
  <Col
    style={{
      alignItems: "center",
      display: "flex",
      top: "5%",
      position: "relative"
    }}
  >
    <Col align="center" justify="center">
      <Row
        style={{ alignSelf: "center", position: "relative" }}
        gutter={[16, 0]}
      >
        <Col align="center">
          <Col
            // bordered={true}
            align="center"
            style={{
              zIndex: 100,
              flex: 1,
              position: "relative",
              width: 300,
              display: "flex",
              borderRadius: 10,
              backgroundColor: Theme.CARD,
              boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.025)",
              marginBottom: "15%"
            }}
            className="criclebox h-full"
          >
            <Col
              align="start"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                top: "3vw",
                position: "relative",
                width: 325,
                top: 55
              }}
            >
              <Row
                align="center"
                style={{ position: "relative", width: "28vw" }}
              >
                <Col
                  align={"start"}
                  style={{ position: "relative", right: "17.5%" }}
                >
                  <Text style={styles.movementTitle}>OVERHEAD</Text>
                  <div style={{ bottom: ".25vw", position: "relative" }}>
                    <Text style={styles.movementTitle}>PUSH</Text>
                  </div>
                </Col>
                <Col
                  align="middle"
                  style={{
                    position: "relative",
                    left: "20%",
                    bottom: "1vw"
                  }}
                >
                  <ScoreCircle
                    score={Math.round(push.push_overall_score)}
                    height={55}
                  />
                  <div>
                    <Text style={styles.messageText}>Overall</Text>
                  </div>
                </Col>
              </Row>

              <Row
                name="scores"
                align="center"
                justify="center"
                style={{
                  marginBottom: "6.5vw",
                  position: "relative",
                  top: ".5vw"
                }}
              >
                <Col align="center" justify="center">
                  <Col
                    style={{
                      top: ".5vw",
                      position: "relative"
                    }}
                  >
                    <Text style={styles.messageText}>
                      Right Shoulder Mobility
                    </Text>
                  </Col>
                  <Row
                    align="middle"
                    justify="center"
                    style={{ marginTop: "1vw" }}
                  >
                    <div>
                      <ScoreCircle
                        score={Math.round(push?.right_shoulder_score)}
                        height={55}
                      />
                    </div>
                  </Row>

                  {/* Right elbow */}
                  <Col
                    style={{
                      marginTop: "2vw",
                      position: "relative"
                    }}
                  >
                    <Text style={styles.messageText}>Right Elbow Mobility</Text>
                  </Col>
                  <Row
                    align="middle"
                    justify="center"
                    style={{ marginTop: "1vw" }}
                  >
                    <div>
                      <ScoreCircle
                        score={Math.round(push?.right_elbow_score)}
                        height={55}
                      />
                    </div>
                  </Row>

                  {/* Right hip */}
                  <Col
                    style={{
                      marginTop: "2vw",
                      position: "relative"
                    }}
                  >
                    <Text style={styles.messageText}>Right Hip Stability</Text>
                  </Col>
                  <Row
                    align="middle"
                    justify="center"
                    style={{ marginTop: "1vw" }}
                  >
                    <div>
                      <ScoreCircle
                        score={Math.round(push?.right_hip_score)}
                        height={55}
                      />
                    </div>
                  </Row>
                </Col>
                {/* Right Side */}
                <Col
                  align="center"
                  justify="center"
                  style={{ marginLeft: "5.5vw" }}
                >
                  <Col
                    style={{
                      top: ".5vw",
                      position: "relative"
                    }}
                  >
                    <Text style={styles.messageText}>
                      Left Shoulder Mobility
                    </Text>
                  </Col>
                  <Row
                    align="middle"
                    justify="center"
                    style={{ marginTop: "1vw" }}
                  >
                    <div>
                      <ScoreCircle
                        score={Math.round(push?.left_shoulder_score)}
                        height={55}
                      />
                    </div>
                  </Row>

                  {/* Right elbow */}
                  <Col
                    style={{
                      marginTop: "2vw",
                      position: "relative"
                    }}
                  >
                    <Text style={styles.messageText}>Left Elbow Mobility</Text>
                  </Col>
                  <Row
                    align="middle"
                    justify="center"
                    style={{ marginTop: "1vw" }}
                  >
                    <div>
                      <ScoreCircle
                        score={Math.round(push?.left_elbow_score)}
                        height={55}
                      />
                    </div>
                  </Row>

                  {/* Right hip */}
                  <Col
                    style={{
                      marginTop: "2vw",
                      position: "relative"
                    }}
                  >
                    <Text style={styles.messageText}>Left Hip Stability</Text>
                  </Col>
                  <Row
                    align="middle"
                    justify="center"
                    style={{ marginTop: "1vw" }}
                  >
                    <div>
                      <ScoreCircle
                        score={Math.round(push?.left_hip_score)}
                        height={55}
                      />
                    </div>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Col>
        </Col>

        <Col align="middle" justify="center">
          <Col
            align="center"
            justify="center"
            style={{
              zIndex: 100,
              flex: 1,
              position: "relative",
              width: 225,
              borderRadius: 10,
              background: Theme.CARD,
              borderWidth: 0,
              boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.025)"
            }}
            className="criclebox h-full"
          >
            <Compensations
              compensations={compensations}
              score={push.compensation_score}
            />
          </Col>
        </Col>
        <Col>
          <Col
            style={{
              zIndex: 100,
              flex: 1,
              position: "relative",
              width: 200,
              borderRadius: 10,
              background: Theme.CARD,
              borderWidth: 0,
              boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.025)"
            }}
            className="criclebox h-full"
          >
            <JointDisplacement movement={push} />
          </Col>
        </Col>
      </Row>
    </Col>
  </Col>
);
};

export default PushResults;

let textSize = 14;
let modalText = 16;
let compTitle = 20;
let modalMargin = 0;
let minHeight = 160;

const createStyles = (theme) => ({
  container: {
    alignSelf: "center"
  },
  card: {
    backgroundColor: theme.WHITE,
    //   marginVertical: Theme.SIZES.BASE,
    borderWidth: 0,
    minHeight: minHeight,
    marginBottom: 1,
    marginHorizontal: 1
  },

  cardTitle: {
    flex: 1,
    alignSelf: "center",
    fontWeight: "500"
    // paddingTop: 5,
  },
  cardDescription: {
    // paddingTop: 5,
  },
  imageContainer: {
    borderRadius: 3,
    elevation: 1,
    overflow: "hidden",
    minHeight: 80
  },

  horizontalImage: {
    width: "40%",
    height: "40%"
    // position: "absolute",
    // left: "5%",
    // bottom: "1vw",
  },
  videoText: {
    color: theme.BLACK,
    fontSize: 12,
    left: "5.5%",
    marginTop: "1%",
    maxWidth: "60%",
    maxHeight: "50%",
    fontWeight: "bold"
  },
  horizontalStyles: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  verticalStyles: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
  },
  fullImage: {
    height: 215
  },

  messageText: {
    color: theme.TEXT,
    fontSize: 11,
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  typeText: {
    color: theme.TEXT,
    fontSize: ".65vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".65vw"
  },
  depthText: {
    color: theme.TEXT,
    fontSize: ".65vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  messageTextVideo: {
    color: theme.WHITE,
    fontSize: ".5vw",
    fontWeight: "bold",
    paddingVertical: 8,
    paddingRight: 4,
    fontFamily: "Arial"
  },
  compScoreText: {
    color: theme.BLACK,
    fontSize: textSize,
    fontWeight: "bold",
    // paddingVertical: 8,
    // paddingRight: 4,
    fontFamily: "Arial",
    // alignSelf: 'flex-end',

    top: 275
  },
  resultText: {
    color: theme.PRIMARY,
    fontSize: ".75vw",
    position: "relative",

    fontWeight: "bold"
    // marginRight: "5%",
  },
  sectionText: {
    fontWeight: "500",
    fontSize: 19,
    color: theme.BLACK
  },
  compensationTitle: {
    fontWeight: "500",
    fontSize: compTitle,
    marginTop: 30,
    marginBottom: 5,
    color: theme.POP
  },
  scoreText: {
    fontWeight: "500",
    fontSize: ".75vw",
    color: theme.PRIMARY
  },
  movementTitle: {
    textAlign: "left",
    fontSize: 15,
    fontWeight: "bold",
    // fontFamily: "ArialBold",
    color: theme.TEXT,
    position: "relative",
    // alignSelf: "center",
    left: ".5%"
  },
  movementScore: {
    fontWeight: "bold",
    fontSize: ".75vw",
    // alignSelf: "center",
    color: theme.PRIMARY,
    left: ".45vw",
    position: "relative"
    // marginTop: 5,
  },
  list: {
    position: "absolute",
    top: 20,
    zIndex: 20,
    flex: 1
  },

  modalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    top: modalMargin
    // elevation: 5,
  },
  bottomView: {
    flex: 1,
    justifyContent: "flex-end",
    top: 20,
    alignSelf: "center"
  },
  infoModalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4
    // elevation: 5,
  },
  button: {
    marginVertical: 15,
    width: 250,
    borderRadius: 15,
    // position: "relative",
    backgroundColor: "white"
  },
  buttontext: {
    color: theme.PRIMARY,
    fontWeight: "bold",
    paddingHorizontal: 5
  },
  modalText: {
    color: theme.WHITE,
    maxWidth: "85%",
    marginBottom: 20,
    textAlign: "center",
    fontSize: modalText,
    marginTop: 5
  },
  angleText: {
    color: theme.BLUE,
    fontSize: ".75vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".65vw"
  },
  video: {
    alignSelf: "center",
    width: "100%",
    height: "95%",
    marginVertical: "10%",
    marginHorizontal: "2%",
    bottom: "8%",

    backgroundColor: "black"
  }
});
