import React, { useEffect, useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { IoMdFitness } from "react-icons/io";
import { Button, Row, Col, Typography } from "antd";
import { useSelector } from "react-redux";
import { RxDragHandleHorizontal } from "react-icons/rx";

const { Text } = Typography;

const ReorderModal = ({ chosenExercises, updateExerciseOrder, onClose }) => {
  const [reorderedExercises, setReorderedExercises] = useState(chosenExercises);
  const { Theme } = useSelector((state) => state.auth);

  useEffect(() => {
    setReorderedExercises(chosenExercises);
  }, [chosenExercises]);

  const moveExercise = (dragIndex, hoverIndex) => {
    const draggedExercise = reorderedExercises[dragIndex];
    setReorderedExercises((prevState) => {
      const exercises = [...prevState];
      exercises.splice(dragIndex, 1);
      exercises.splice(hoverIndex, 0, draggedExercise);
      return exercises;
    });
  };

  const Exercise = ({ exercise, index }) => {
    const [{ isDragging }, drag] = useDrag({
      type: "exercise",
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const [{ isOver }, drop] = useDrop({
      accept: "exercise",
      drop: (item) => moveExercise(item.index, index),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    });

    const opacity = isDragging ? 0.5 : 1;

    return (
      <div
        ref={(node) => drag(drop(node))}
        style={{
          opacity,
          background: Theme.BACKGROUND,
          borderRadius: "10px",
          marginTop: "1vw",
          paddingLeft: "1vw",
          minHeight: "3vw",
          cursor: "grab",
          overflow: "hidden",
        }}
      >
        {exercise.isSuperset ? (
          <Col style={{alignItems: "center", // Aligns items vertically in the center
          
          padding: "1vw",}}>
          
            {exercise.exercises.map((subExercise, subIndex) => (
              <Row key={`sub-${subIndex + 1}`} style={{  display: "flex",
              }}>
                {subIndex === 0 ? <Row>
                  <RxDragHandleHorizontal style={{ color: Theme.PRIMARY, fontSize: "1vw", marginRight: '1vw' }} />
             <div>
              <Text style={{
                color: Theme.TEXT,
           
                fontSize: ".75vw",
                marginRight: "1vw",
              }}>
                {index + 1}
              
              </Text>
              </div>
              </Row> : 
              <Row>
              <RxDragHandleHorizontal style={{ color: Theme.BACKGROUND, fontSize: "1vw", marginRight: '1vw' }} />
         <div>
          <Text style={{
            color: Theme.BACKGROUND,
            marginRight: "1vw",
            fontSize: ".75vw",
          }}>
            {index + 1}
          
          </Text>
          </div>
          </Row>
              }
                <Text
                  style={{
                    color: Theme.TEXT,
                    fontSize: ".75vw",
                    marginLeft:"1vw"
                  }}
                >
                  {` ${subExercise.name || subExercise.library_exercise.name}`}
                </Text>
              </Row>
            ))}
          </Col>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              padding: "1vw",
            }}
          >
            <RxDragHandleHorizontal
              style={{
                color: Theme.PRIMARY,
                fontSize: "1vw",
                marginRight: "1vw",
              }}
            />
            <Text
              style={{
                color: Theme.TEXT,
                marginRight: "5%",
                fontSize: ".85vw",
              }}
            >
              {index + 1}
            </Text>
            <Text
              style={{
                color: Theme.TEXT,
                width: "80%",
                fontSize: ".75vw",
                textAlign: "left",
              }}
            >
              {exercise.name || exercise.library_exercise.name}
            </Text>
          </div>
        )}
      </div>
    );
  };
  const handleSave = () => {
    updateExerciseOrder(reorderedExercises);
    onClose();
  };

  return (
    <Row justify="center" style={{ minHeight: "15vw" }}>
      <Col span={20} style={{ marginTop: "1vw", paddingBottom: "5vw" }}>
        <Row style={{ marginBottom: "1vw" }}>
          <Text
            style={{
              fontSize: 18,
              fontWeight: "bold",
              marginBottom: 10,
              color: Theme.TEXT,
            }}
          >
            Drag and Drop Reorder
          </Text>
          <Row justify="end" style={{ position: "absolute", right: 0 }}>
            <Button
              type="secondary"
              style={{
                backgroundColor: Theme.BACKGROUND,
                borderRadius: 10,
                width: "5vw",
                color: Theme.TEXT,
              }}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              // type="primary"
              style={{
                backgroundImage:
                  "linear-gradient(45deg, #C04848 0%, #480048 80%)",
                borderRadius: 10,
                width: "5vw",
                border: 0,
                color: Theme.WHITE,
                marginLeft: "1vw",
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </Row>
        </Row>

        <div
          style={{
            minHeight: "75%",
            maxHeight: "75%",
            width: "100%",
            position: "relative",
            top: "1.5vw",
          }}
        >
          {chosenExercises === undefined && (
            <Col style={{ textAlign: "center", marginTop: "12.5%" }}>
              <IoMdFitness size={"3vw"} color={Theme.PRIMARY} />
              <div>
                <Text style={{ fontSize: "1vw", color: Theme.TEXT }}>
                  No Exercises
                </Text>
              </div>
            </Col>
          )}
          {reorderedExercises &&
            reorderedExercises.map((exercise, index) => (
              <Exercise key={index} exercise={exercise} index={index} />
            ))}
        </div>
      </Col>
    </Row>
  );
};

export default ReorderModal;
