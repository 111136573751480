import React, { useState } from "react";
import { Modal, Col, Spin, Button, Typography } from "antd";
import { useSelector } from "react-redux";
import ResultsSectionTitle from "./ResultsSectionTitle";
import sections from "../../constants/sections";

const { Text } = Typography;

const VideoModal = ({ videoUrl }) => {
  const [videoModal, setVideoModal] = useState(false);
 const { Theme } = useSelector((state) => state.auth);
  return (
    <>
      <Button
        onClick={() => setVideoModal(true)}
        style={{
          color: "white",
          borderWidth: 0,
          borderRadius: 12,
          width: "15vw",
          fontWeight: "bold",
          fontSize: ".85vw",
          cursor: "pointer",
          background: Theme.PRIMARY
        }}
      >
        Replay
      </Button>

      <Modal
        open={videoModal}
        onCancel={() => setVideoModal(false)}
        footer={null}
        bodyStyle={{ height: "40vw", padding: "1vw" }}
        style={{ position: "relative", bottom: "2vw" }}
        width={"30vw"}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Col
          style={{
            fontWeight: "bold",
            position: "relative",
            alignItems: "center"
          }}
          align="middle"
          justify="center"
        >
          <ResultsSectionTitle section={sections[10]} />
          {videoUrl ? (
            <video
              controls
              src={videoUrl}
              style={{
                width: "15vw",
                height: "30vw",
                objectFit: "contain",
                marginTop: "1vw",
                position: "relative"
              }}
            />
          ) : (
            <div center style={{ top: "5vw", position: "relative" }}>
              <div>
                <Spin size="large" style={{ marginBottom: "1vw" }} />
              </div>
              <Text>Video Processing</Text>
              <div>
                <Text style={{}}>Check Back Soon</Text>
              </div>
            </div>
          )}
        </Col>
      </Modal>
    </>
  );
};

export default VideoModal;
