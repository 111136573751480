import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteSingleAssessment,
  getAssessments,
} from "../redux/actions/assessments";
import { getSingleGroup, updateGroups } from "../redux/actions/groups";
import clientpic from "../assets/images/profilepic.jpg";
import {
  Card,
  Col,
  Modal,
  Input,
  Row,
  Typography,
  Menu,
  Dropdown,
  Spin,
} from "antd";
import { DownOutlined, SmileOutlined } from "@ant-design/icons";

import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  SlidersOutlined,
} from "@ant-design/icons";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import AssignAssessments from "./AssignAssesments";
import { Button } from "react-bootstrap";
import { IoAddCircleOutline, IoChevronBackOutline } from "react-icons/io5";
import AddTrainerGroupForm from "./results/AddTrainerGroupForm";
import BootstrapSearch from "./common/Search";
import { searchTrainers } from "../redux/actions/trainers";

const { Text } = Typography;

const GroupMemberList = ({ group_type }) => {
  const { assessments } = useSelector((state) => state.assessments);
  const dispatch = useDispatch();
  const { user,Theme } = useSelector((state) => state.auth);

  const { id } = useParams();
  const [query, setQuery] = useState("");
  const [assigned, setAssigned] = useState(false);
  const { group } = useSelector((state) => state.groups);
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const [addTrainerModalVisible, setAddTrainerModalVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (group.id != null) {
      setLoading(false);
    }
  }, [group]);

  useEffect(() => {
    dispatch(getSingleGroup(id));
  }, []);

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };
  const history = useHistory();

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  //const combinedTrainers = group.data.trainers.concat(group.data.group_admins);

  const handleTrainerSearch = (text) => {
    dispatch(searchTrainers(text));
    setQuery(text);
  };

  const onClick = ({ key }) => {
    var data = {};
    const keyArr = key.split(",");

    if (keyArr[2] == "admin") {
      if (key[0] == 1) {
        data = {
          remove_admins: [keyArr[1]],
        };
      } else {
        data = {
          remove_admins: [keyArr[1]],
          add_trainers: [keyArr[1]],
        };
      }
    } else {
      if (keyArr[0] == 1) {
        data = {
          remove_trainers: [keyArr[1]],
        };
      } else {
        data = {
          remove_trainers: [keyArr[1]],
          add_admins: [keyArr[1]],
        };
      }
    }
    dispatch(updateGroups(id, data));
  };

  const generateTraineritems = (userId) => [
    {
      key: [1, userId, "trainer"],
      label: <a>Remove</a>,
    },
    {
      key: [2, userId, "trainer"],
      label: <a>Make Admin</a>,
    },
  ];

  const generateAdminItems = (userId) => [
    {
      key: [1, userId, "admin"],
      label: <a>Remove</a>,
    },
    {
      key: [2, userId, "admin"],
      label: <a>Make Trainer</a>,
    },
  ];
  const isFadedIn = true;
  const handleGoToAdmin = () => {
    history.push("/admin", { fromGroups: true });
  };
  return (
    <div
      style={{ marginTop: "10vw", position: "absolute" }}
      className={`fade-in-slide-up-element ${
        isFadedIn ? "fade-in-slide-up-animation" : ""
      }`}
    >
      <div
        onClick={handleGoToAdmin}
        style={{ bottom: "10vw", position: "relative", cursor: "pointer" }}
      >
        {" "}
        <IoChevronBackOutline
          style={{
            top: ".1vw",
            marginRight: ".5%",
            position: "relative",
            color: Theme.TEXT
          }}
        />
        <Text style={{ color: Theme.TEXT }}>Back</Text>
      </div>
      <Card
        style={{
          width: "50vw",
          position: "relative",
          bottom: "7.5vw",
          minHeight: "50vh",
          background: Theme.CARD,
          borderWidth: 0
        }}
      >
        {loading ? (
          <Spin size="large" style={{ marginBottom: "1vw" }} />
        ) : (
          <div>
            <Row className="project-ant" style={{ marginBottom: "1.5vw" }}>
              <BootstrapSearch
                placeholder="Search Trainers"
                onSearch={handleTrainerSearch}
                onChange={(e) => handleTrainerSearch(e.target.value)}
                width="15vw" // Set the width here
              />
              {( user?.profile?.account_type == 2 &&
              <Button
                onClick={() => setAddTrainerModalVisible(true)}
                style={{
                  color: "white",
                  borderWidth: 0,
                  borderRadius: 12,
                  height: "2vw",
                  width: "7vw",
                  fontWeight: "bold",
                  fontSize: ".75vw",
                  cursor: "pointer",
                  backgroundImage:
                    "linear-gradient(45deg, #C04848 0%, #480048 80%)"
                }}
              >
                <IoAddCircleOutline
                  style={{ top: "5%", marginRight: "4%", position: "relative" }}
                />
                Add Trainer
              </Button>
              )}
            </Row>
            <div
              style={{ paddingLeft: "4vw" }}
              className="ant-list-box table-responsive"
            >
              <table className="width-100">
                <thead>
                  <tr>
                    <th
                      style={{
                        fontSize: ".85vw",
                        position: "relative",
                        right: "1.25vw",
                        color: Theme.TEXT2
                      }}
                    >
                      TRAINER
                    </th>{" "}
                    <th
                      style={{
                        fontSize: ".85vw",
                        color: Theme.TEXT2,
                        right: "3vw",
                        position: "relative"
                      }}
                    >
                      TOTAL {user?.profile?.client_label.toUpperCase()}S
                    </th>{" "}
                    <th
                      style={{
                        fontSize: ".85vw",
                        position: "relative",
                        right: ".3vw",
                        color: Theme.TEXT2
                      }}
                    >
                      ROLE
                    </th>{" "}
                  </tr>
                </thead>
                <tbody>
                  {group.group_admins
                    .filter((admin) => {
                      const fullName = `${admin.user_account.first_name} ${admin.user_account.last_name}`;
                      return fullName
                        .toLowerCase()
                        .includes(query.toLowerCase());
                    })
                    .map((d, index) => {
                      var trainerPhotoPath = clientpic;
                      if (d.profile_picture != null) {
                        trainerPhotoPath = d.profile_picture;
                      }
                      return (
                        <tr
                          key={index}
                          style={{ right: "1.75vw", position: "relative" }}
                          //onClick={() => handleClick(d.id)}
                        >
                          <td
                            style={{
                              fontSize: ".75vw",
                              position: "relative",
                              right: "1vw"
                            }}
                          >
                            {" "}
                            <Row style={{ fontSize: "1vw" }}>
                              <img
                                src={trainerPhotoPath}
                                alt=""
                                className="avatar-sm mr-10"
                                style={{
                                  height: "3vw", // Adjust the image height using vw units
                                  width: "3vw",
                                  position: "relative",
                                  borderRadius: 100
                                }}
                              />
                              {"   "}
                              <p
                                style={{
                                  position: "relative",
                                  left: "1vw",
                                  fontSize: ".85vw",
                                  color: Theme.TEXT
                                }}
                              >
                                {" "}
                                {d.user_account.first_name}{" "}
                                {d.user_account.last_name}
                              </p>
                            </Row>
                          </td>

                          <td style={{ fontSize: ".85vw" }}>
                            <span
                              style={{ color: Theme.TEXT }}
                              className="text-xs font-weight-bold"
                            >
                              {d.client_count}{" "}
                            </span>
                          </td>

                          <td style={{ fontSize: ".85vw" }}>
                            <span
                              style={{ color: Theme.TEXT }}
                              className="text-xs font-weight-bold"
                            >
                              {"TEAM ADMIN"}
                            </span>
                          </td>
                          <td>
                            <Dropdown
                              menu={{
                                items: generateAdminItems(d.user_account.id),
                                onClick
                              }}
                            >
                              <a onClick={(e) => e.preventDefault()}>Edit</a>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}

                  {group.trainers
                    .filter((trainer) => {
                      const fullName = `${trainer.user_account.first_name} ${trainer.user_account.last_name}`;
                      return fullName
                        .toLowerCase()
                        .includes(query.toLowerCase());
                    })
                    .map((d, index) => {
                      var trainerPhotoPath = clientpic;
                      if (d.profile_picture != null) {
                        trainerPhotoPath = d.profile_picture;
                      }
                      return (
                        <tr
                          key={index}
                          style={{ right: "1.75vw", position: "relative" }}
                          //onClick={() => handleClick(d.id)}
                        >
                          <td
                            style={{
                              fontSize: ".75vw",
                              position: "relative",
                              right: "1vw"
                            }}
                          >
                            <Row style={{ fontSize: "1vw" }}>
                              <img
                                src={trainerPhotoPath}
                                alt=""
                                className="avatar-sm mr-10"
                                style={{
                                  height: "3vw", // Adjust the image height using vw units
                                  width: "3vw",
                                  position: "relative",
                                  borderRadius: 100
                                }}
                              />
                              {""}
                              <p
                                style={{
                                  position: "relative",
                                  left: "1vw",
                                  fontSize: ".85vw",
                                  color: Theme.TEXT
                                }}
                              >
                                {" "}
                                {d.user_account.first_name}{" "}
                                {d.user_account.last_name}
                              </p>
                            </Row>
                          </td>

                          <td style={{ fontSize: ".85vw" }}>
                            <span
                              style={{ color: Theme.TEXT }}
                              className="text-xs font-weight-bold"
                            >
                              {d.client_count}
                            </span>
                          </td>

                          <td style={{ fontSize: ".85vw" }}>
                            <span
                              style={{ color: Theme.TEXT }}
                              className="text-xs font-weight-bold"
                            >
                              {"TRAINER"}
                            </span>
                          </td>
                          <td>
                            <Dropdown
                              menu={{
                                items: generateTraineritems(d.user_account.id),
                                onClick
                              }}
                            >
                              <a onClick={(e) => e.preventDefault()}>
                                Edit
                                {/* <DownOutlined /> */}
                              </a>
                            </Dropdown>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>

            <Modal
              open={addTrainerModalVisible}
              onCancel={() => setAddTrainerModalVisible(false)}
              footer={null}
              width={"30vw"}
              className={
                Theme.BACKGROUND === "#000"
                  ? "customAntdModalDark"
                  : "customAntdModalLight"
              }
            >
              <Row style={{ padding: "5%" }}>
                <Col span={24}>
                  <AddTrainerGroupForm
                    group={group}
                    closeModal={setAddTrainerModalVisible}
                  />
                </Col>
              </Row>
            </Modal>
          </div>
        )}
      </Card>
    </div>
  );
};

export default GroupMemberList;
