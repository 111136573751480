import axios from "axios";
import {
  GET_ORG,
  UPDATE_ORG
} from "./types";
 
import { REACT_APP_API_URL } from "../../env";
import { checkAuthenticated } from "./auth";


// Get Org
export const getOrg = (id) => {
  return async (dispatch) => {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        'Authorization': 'JWT ' + token,
      },
    };

    try {
      // Fetch the organization using the id in the path
      const res = await axios.get(`${REACT_APP_API_URL}/api/organization/orgs/${id}/`, config);
      
      if (res.data) {
   
        dispatch({
          type: GET_ORG,
          payload: res.data,  
        });
      } else {
        console.error("No data returned from API");
      }
    } catch (err) {
      console.error("Error fetching organization:", err);
    }
  };
};




export const updateOrg = (id, data) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };

  try {
    // Use PATCH to update the organization with the provided data
    const res = await axios.patch(
      `${REACT_APP_API_URL}/api/organization/orgs/${id}/`,
      data,  // Ensure `data` contains the right fields
      config
    );

    // Dispatch the result to the Redux store
    dispatch({
      type: UPDATE_ORG,
      payload: res.data,
    });
  } catch (err) {
    console.error("Error updating organization:", err);
  }
};


