import {
  GET_WORKOUTS,
  CREATE_WORKOUT_SUCCESS,
  CREATE_WORKOUT_FAIL,
  UPDATE_WORKOUT,
  DELETE_WORKOUT,
  GET_EXERCISES,
  CREATE_EXERCISE_SUCCESS,
  CREATE_EXERCISE_FAIL,
  UPDATE_EXERCISE,
  DELETE_EXERCISE,
  RESET_WORKOUT_STATUS,
  RESET_EXERCISE_STATUS,
  GET_SINGLE_WORKOUT,
  CREATE_LIBRARY_EXERCISE_SUCCESS,
  CREATE_LIBRARY_EXERCISE_FAIL,
  CREATE_MESOCYCLE_SUCCESS,
  CREATE_MESOCYCLE_FAIL,
  GET_MEOSCYCLES,
  GET_SINGLE_MESOCYCLE,
  UPDATE_MESOCYCLE,
  DELETE_MESOCYCLE,
  RESET_MESOCYCLE_STATUS,
  CREATE_PROGRAM_FAIL,
  UPDATE_PROGRAM,
  GET_SINGLE_PROGRAM,
  RESET_PROGRAM_STATUS,
  DELETE_PROGRAM,
  CREATE_PROGRAM_SUCCESS,
  GET_PROGRAMS,
} from "../actions/types";

const initialState = {
  workouts: [],
  workout: null,
  exercises: [],
  workoutStatus: null,
  exerciseStatus: null,
  mesocycles: [], 
  mesocycle: null, 
  mesocycleStatus: null,
  programs: [], 
  program: null, 
  programStatus: null,
  total_exercises: 0,
};

const workoutsAndExercisesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_WORKOUTS:
      return {
        ...state,
        workouts: action.payload,
      };
      case GET_SINGLE_WORKOUT:
        return {
          ...state,
          workout: action.payload,
        };
    case CREATE_WORKOUT_SUCCESS:
      return {
        ...state,
        workoutStatus: "success",
        workouts: [...state.workouts, action.payload[0]],
        workout: action.payload
      };
    case CREATE_WORKOUT_FAIL:
      return {
        ...state,
        workoutStatus: "fail",
      };
    case UPDATE_WORKOUT:
      const updateIndex = state.workouts.findIndex(
        (workout) => workout.id === action.payload.id
      );
      
      if (updateIndex !== -1) {
        const updatedWorkouts = [...state.workouts];
        updatedWorkouts[updateIndex] = action.payload;
        return {
          ...state,
          workouts: updatedWorkouts,
          workout: action.payload,
          workoutStatus: "updated",
        };
      } else {
        return {
          ...state,
          workout: action.payload,
          workoutStatus: "updated"
        };
      }
      case DELETE_WORKOUT:
        const deleteIndex = state.workouts.findIndex(
          (workout) => workout.id === action.payload
        );
        if (deleteIndex !== -1) {
          const updatedWorkouts = [...state.workouts];
          updatedWorkouts.splice(deleteIndex, 1);
          return {
            ...state,
            workouts: updatedWorkouts,
            workoutStatus: "deleted"
          };
        } else {
          return {
            ...state,
            workoutStatus: "deleted"
          };
        }
    case GET_EXERCISES:
      return {
        ...state,
        exercises: action.payload,
        total_exercises: action.total_count,
      };
    case CREATE_EXERCISE_SUCCESS:
      return {
        ...state,
        exerciseStatus: "success",
      };
    case CREATE_EXERCISE_FAIL:
      return {
        ...state,
        exerciseStatus: "fail",
      };
      case CREATE_LIBRARY_EXERCISE_SUCCESS:
        return {
          ...state,
          exerciseStatus: "success",
        };
      case CREATE_LIBRARY_EXERCISE_FAIL:
        return {
          ...state,
          exerciseStatus: "fail",
        };
    case UPDATE_EXERCISE:
      // Handle the update exercise logic
      return state;
    case DELETE_EXERCISE:
      // Handle the delete exercise logic
      return state;
    case RESET_WORKOUT_STATUS:
      return {
        ...state,
        workoutStatus: null,
      };
    case RESET_EXERCISE_STATUS:
      return {
        ...state,
        exerciseStatus: null,
      };
      case CREATE_MESOCYCLE_SUCCESS:
      return {
        ...state,
        mesocycle: action.payload,
        mesocycleStatus: 'success',
      };

    case CREATE_MESOCYCLE_FAIL:
      return {
        ...state,
        mesocycleStatus: 'fail',
      };

    case GET_MEOSCYCLES:
      return {
        ...state,
        mesocycles: action.payload,
      };

    case GET_SINGLE_MESOCYCLE:
      return {
        ...state,
        mesocycle: action.payload,
      };

    case UPDATE_MESOCYCLE:
      return {
        ...state,
        mesocycle: action.payload,
        mesocycleStatus: 'updated'
      };

    case DELETE_MESOCYCLE:
      return {
        ...state,
        mesocycleStatus: 'deleted'
      };

    case RESET_MESOCYCLE_STATUS:
      return {
        ...state,
        mesocycleStatus: null,
      };
      case CREATE_PROGRAM_SUCCESS:
        return {
          ...state,
          program: action.payload,
          programStatus: 'success',
        };
  
      case CREATE_PROGRAM_FAIL:
        return {
          ...state,
          programStatus: 'fail',
        };
  
      case GET_PROGRAMS:
        return {
          ...state,
          programs: action.payload,
        };
  
      case GET_SINGLE_PROGRAM:
        return {
          ...state,
          program: action.payload,
        };
  
      case UPDATE_PROGRAM:
        return {
          ...state,
          programStatus: 'updated'
        };
  
      case DELETE_PROGRAM:
        return {
          ...state,
          programStatus: 'deleted'
        };
  
      case RESET_PROGRAM_STATUS:
        return {
          ...state,
          programStatus: null,
        };
    default:
      return state;
  }
};

export default workoutsAndExercisesReducer;
