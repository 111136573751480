export const aoc = [
  {
    key: "3",
    imbalance: "Right Elbow - Mobility",
    height: 40,
    width:  37,
    area: "Right Elbow",
    type: "Mobility",
    color: "#FF0026"
  },
  {
    key: "4",
    imbalance: "Left Elbow - Mobility",
    height: 60,
    width:  37,
    area: "Left Elbow",
    type: "Mobility",
    color: "#FF0026"
  },
  {
    key: "5",
    imbalance: "Left Hip - Stability",
    height: 53.5,
    width: 47,
    area: "Left Hip",
    type: "Stability",
    color: "#FF0026"
  },
  {
    key: "6",
    imbalance: "Right Hip - Stability",
    height: 46.5,
    width: 47,
    area: "Right Hip",
    type: "Stability",
    color: "#FF0026"
  },
  {
    key: "7",
    imbalance: "Left Ankle - Stability",
    height:  53.5,
    width: 93,
    area: "Left Ankle",
    type: "Stability",
    color: "#FF0026"
  },
  {
    key: "8",
    imbalance: "Right Ankle - Stability",
    height:  47,
    width: 93,
    area: "Right Ankle",
    type: "Stability",
    color: "#FF0026"
  },
  {
    key: "9",
    imbalance: "Left Hip - Mobility",
    height: 53.5,
    width: 47,
    area: "Left Hip",
    type: "Mobility",
    color: "#FF0026"
  },
  {
    key: "10",
    imbalance: "Right Hip - Mobility",
    height: 46.5,
    width: 47,
    area: "Right Hip",
    type: "Mobility",
    color: "#FF0026"
  },
  {
    key: "11",
    imbalance: "Left Ankle - Mobility",
    height:  53.5,
    width: 93,
    area: "Left Ankle",
    type: "Mobility",
    color: "#FF0026"
  },
  {
    key: "12",
    imbalance: "Right Ankle - Mobility",
    height:  47,
    width: 93,
    area: "Right Ankle",
    type: "Mobility",
    color: "#FF0026"
  },
  {
    key: "13",
    imbalance: "Right Knee - Mobility",
    height: 46,
    width: 70,
    area: "Right Knee",
    type: "Mobility",
    color: "#FF0026"
  },
  {
    key: "2",
    imbalance: "Left Shoulder - Mobility",
    height: 57,
    width: 24,
    area: "Left Shoulder",
    type: "Mobility",
    color: "#FF0026"
  },
  {
    key: "14",
    imbalance: "Left Knee - Mobility",
    height: 54,
    width: 70,
    area: "Left Knee",
    type: "Mobility",
    color: "#FF0026"
  },
  {
    key: "1",
    imbalance: "Right Shoulder - Mobility",
    height: 43,
    width: 24,
    area: "Right Shoulder",
    type: "Mobility",
    color: "#FF0026"
  },
  {
    key: "25",
    imbalance: "Right Knee - Stability",
    height: 46,
    width: 70,
    area: "Right Knee",
    type: "Stability",
    color: "#FF0026"
  },
  {
    key: "26",
    imbalance: "Left Knee - Stability",
    height: 54,
    width: 70,
    area: "Left Knee",
    type: "Stability",
    color: "#FF0026"
  },
  {
    key: "27",
    imbalance: "Right Hip Abduction - Mobility",
    height: 46.5,
    width: 47,
    area: "Right\nAbduction",
    type: "Mobility",
    color: "#FF0026"
  },
  {
    key: "28",
    imbalance: "Left Hip Abduction - Mobility",
    height: 53.5,
    width: 47,
    area: "Left\nAbduction",
    type: "Mobility",
    color: "#FF0026"
  },
];
