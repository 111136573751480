import React from "react";
import ProgressProvider from "./ProgressProvider.js";
import { CircularProgressbar } from "react-circular-progressbar";
import { useSelector } from "react-redux";

const ScoreCircle = ({ score, height }) => {
    const { Theme } = useSelector((state) => state.auth);
  return (
    <ProgressProvider valueStart={0} valueEnd={score}>
      {(value) => (
        <CircularProgressbar
          styles={{
            root: { height: height, marginBottom: ".5vw" },
            path: {
              stroke:
                score >= 80
                  ? Theme.GRAD6
                  : score >= 65
                  ? Theme.YELLOW
                  : Theme.RED,
              strokeLinecap: "butt",
              transition: "stroke-dashoffset 0.5s ease 0s",
              transformOrigin: "center center",
            },
            trail: {
                stroke: Theme.INPUT,
              strokeLinecap: "butt",
              transformOrigin: "center center",
            },
            text: {
              fill: Theme.TEXT,
              fontSize: 25,
            },
            background: {
              fill: "#3e98c7",
            },
          }}
          value={value}
          text={score}
        />
      )}
    </ProgressProvider>
  );
};

// Export the component
export default ScoreCircle;
