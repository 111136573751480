import { useState, useEffect, useRef } from "react";
import { Button, Col, Grid, Modal, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSingleClient } from "../redux/actions/clients";
import {
  getSingleAssessment,
  resetAssessment,
  resetAssessmentStatus
} from "../redux/actions/assessments";

import { load_user } from "../redux/actions/auth";
import ResultsSections from "../components/results/ResultsSections";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import LottieResults from "../components/loaders/LoadingResults";

import { getSingleJointComparison } from "../redux/actions/joint_comparisons";
import JCResultsSections from "../components/results/JCResultsSections";

const { Text } = Typography;
const { confirm } = Modal;
const { Title } = Typography;

const JCResults = ({ route, navigation }) => {
  const [loading, setLoading] = useState(false);
  const [isFadedIn, setIsFadedIn] = useState(false);
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const { id } = useParams();
  const { clientId } = useParams();
  const { client } = useSelector((state) => state.clients);

  var clientName = `${client.first_name} ${client.last_name}`;
  const { jointComparison, assessmentStatus } = useSelector(
    (state) => state.joint_comparisons
  );

  const dispatch = useDispatch();
  const fetchJC = () => dispatch(getSingleJointComparison(id, clientId));

  useEffect(() => {
    clientName = `${client.first_name} ${client.last_name}`;
  }, [client]);

  useEffect(() => {
    fetchJC();

    dispatch(getSingleClient(clientId));
    dispatch(resetAssessment());
  }, []);

  const navigateOption = () => {
    // else {
    navigation.navigate("Clients", {
      screen: "ClientDetailScreen",
      params: { params: { id: clientId } }
    });
    // }
  };

  useEffect(() => {
    setIsFadedIn(true);
  }, []);

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);



  return (
    <Row
      style={{
        display: "block",
        flexDirection: "column",
        alignSelf: "center", // Center the content horizontally
        // justifyContent: "center", // Center the content vertically
        marginBottom: "2rem",

        position: "relative",
        top: "2vw",
        transform: "translateX(-50%)" // Center horizontally using transform
      }}
      className={`fade-in-slide-up-element ${
        isFadedIn ? "fade-in-slide-up-animation" : ""
      }`}
    >
     {jointComparison && <JCResultsSections
        navigateOption={navigateOption}
        assessment={jointComparison}
        clientName={clientName}
        loading={loading}
      />}
    </Row>
  );
};

export default JCResults;
