import React, { useState, useEffect } from "react";
import { Col, Card, Row, Typography } from "antd";
import Theme from "../../constants/Theme";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { debounce } from "lodash";

const { Text } = Typography;

const ScoreIndicator = ({ score, size, showText, outerRadius }) => {
  const [chartWidth, setChartWidth] = useState("8vw"); // Set an initial width, e.g., 400 pixels
  const handleResize = debounce(() => {
    // Calculate the new width based on the container's size (e.g., 90% of the container width)
    const containerWidth = Math.min(Math.round(window.innerWidth * size)); // Limit the width to a maximum of 400 pixels
    setChartWidth(containerWidth);
  }, 200); // Set the debounce delay to 200 milliseconds

  useEffect(() => {
    handleResize(); // Call it initially to set the initial width

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const RADIAN = Math.PI / 180;
  const totalPossibleScore = 100;
  const data = [
    { name: "A", value: 70, color: Theme.COLORS.GRAD1 },
    { name: "B", value: 40, color: 'yellow'},
    // { name: "D", value: 20, color: "yellow" },
    { name: "C", value: 55, color: 'lightgreen'},
  ];
  const cx = chartWidth / 2;
  const cy = chartWidth / 1.8;
  const iR = chartWidth / 10;
  const oR = outerRadius;

  const needle = (value, data, cx, cy, iR, oR, color) => {
    const total = data.reduce((acc, curr) => acc + curr.value, 0);
    const percentage = value / total;
    const angle = 180 - percentage * 300;
    const length = (iR + oR) / 2;
    const sin = Math.sin(-RADIAN * angle);
    const cos = Math.cos(-RADIAN * angle);
    const r = chartWidth / 25;
    const x0 = cx;
    const y0 = cy;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
      <circle cx={cx} cy={cy} r={r} fill={color} stroke="none" />,
      <path
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        stroke="none"
        fill={color}
      />,
    ];
  };

  return (
    <>
      <div style={{width:'100%', top: '5%', position: "relative" }}>
        <ResponsiveContainer
          animationBegin={0}
          width={chartWidth * 1.1} // Set fixed width
          height={chartWidth}
          isAnimationActive={true}
          style={{ transition: "width 0.5s, height 0.5s", maxWidth: "100%" }} // Add maxWidth
        >
          <PieChart
            isAnimationActive={true}
            padding={{ top: 20, bottom: 20, left: 20, right: 20 }}
          >
            <Pie
              dataKey="value"
              startAngle={180}
              endAngle={0}
              data={data}
              cx={chartWidth / 2}
              cy={chartWidth / 2}
              innerRadius={chartWidth * 0.35}
              outerRadius={chartWidth * 0.5}
              fill="#8884d8"
              stroke="none"
              animationDuration={1000}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={entry.color} />
              ))}
            </Pie>
            {score !== "Not Applicable" &&
              needle(score, data, cx, cy, iR, oR, Theme.COLORS.BLACK)}
          </PieChart>
          
        </ResponsiveContainer>
        {showText && (
            <Text
            style={{
              fontWeight: "bold",
              fontSize: "1vw",
              color: Theme.COLORS.PRIMARY,
              fontWeight: "bold",
              bottom: "1.5vw",
              position: "relative",
              left: ".15vw",
            }}
          >
            {score}
            <span style={{ fontSize: ".75vw" }}>%</span>
          </Text>
          )}
      </div>
    </>
  );
};

export default ScoreIndicator;
