import React, {  } from "react";
import ResultsSectionTitle from "./ResultsSectionTitle";
import sections from "../../constants/sections";
import { Col, Row, Typography } from "antd";
import frontPost from "../../assets/images/Body.png";
import { useSelector } from "react-redux";

const { Text } = Typography;

const JointDisplacement = ({ movement, hideTitle }) => {
 const imageAspectRatio = 1.3 / 1;
 const imageHeightPx = (10 / 100) * window.innerWidth * 1.5;
  const { Theme } = useSelector((state) => state.auth);
  const mq = window.matchMedia("(min-width: 1400px)");
  const leftOrRight = (difference) => {
    var direction = "Left";
    if (difference > 0) {
      direction = "Right";
    } else if (difference == 0) {
      return "0° Difference";
    }
    return `${direction} +${Math.abs(difference.toFixed(2))}°`;
  };

  // Calculate the image width in pixels based on the aspect ratio and image height
  const imageWidthPx = imageAspectRatio * imageHeightPx;
  return (
    <div
      className="aoc"
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        // top: "1vw",
        position: "relative",
        // minHeight: "13vw",
        // height: 'auto'
        // paddingBottom: "2vw"
      }}
    >
      <Col
        type="flex"
        style={{ alignItems: "center", width: "100%" }}
        justify="center"
        gutter={10}
      >
        <Col
          align="center"
          span={24}
          style={{
            fontWeight: "bold",
            marginTop: 60,
            // left: "7.5%",
            width: "100%",
            position: "relative"
          }}
        >
          {!hideTitle && (
            <div
              style={{
                fontWeight: "bold",
                marginTop: "15%",
                right: "11%",

                position: "relative",
                alignItems: "center",
                width: "120%"
              }}
            >
              <ResultsSectionTitle section={sections[6]} />
            </div>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              // top: "3vw",
              position: "relative",
              width: "100%", // Adjust the width as needed
              right:5,
            }}
          >
            <div
              style={{
                position: "relative",
                width: "100%",
                height: 200,
              }}
            >
              <svg
                viewBox={`0 0 ${175} ${200}`}
                style={{
                  width: "100%",
                  // height: "100%",
                  position: "relative",
                  right:30,
                  top: 25,
                  backgroundColor: "transparent" // Make the background transparent
                }}
              >
                <defs>
                  <filter id="drop-shadow">
                    <feDropShadow
                      dx="2"
                      dy="6"
                      stdDeviation="6"
                      floodColor="rgba(0, 0, 0, 0.25)"
                    />
                  </filter>
                </defs>
                <image
                  href={frontPost}
                  alt="Body"
                  width={imageWidthPx}
                  height={imageHeightPx}
                  style={{
                    right:20,
                    position:"relative",
                    objectFit: "cover",
                  }}
                />
                <line
                  x1={(43 / 100) * imageWidthPx}
                  y1={
                    (20 / 100) * imageHeightPx - movement.shoulder_level_avg / 2
                  }
                  x2={(57 / 100) * imageWidthPx}
                  y2={
                    (20 / 100) * imageHeightPx + movement.shoulder_level_avg / 2
                  }
                  stroke={Theme.BLUE}
                  strokeWidth="4"
                />
                <line
                  x1={(46 / 100) * imageWidthPx}
                  y1={(50 / 100) * imageHeightPx - movement.hip_level_avg / 2}
                  x2={(54 / 100) * imageWidthPx}
                  y2={(50 / 100) * imageHeightPx + movement.hip_level_avg / 2}
                  stroke={Theme.BLUE}
                  strokeWidth="4"
                />

                <circle
                  cx={(43 / 100) * imageWidthPx}
                  cy={
                    (20 / 100) * imageHeightPx - movement.shoulder_level_avg / 2
                  }
                  r={(0.04 * imageWidthPx) / 2}
                  fill={Theme.BLUE}
                />
                <circle
                  cx={(57 / 100) * imageWidthPx}
                  cy={
                    (20 / 100) * imageHeightPx + movement.shoulder_level_avg / 2
                  }
                  r={(0.04 * imageWidthPx) / 2}
                  fill={Theme.BLUE}
                />
                <circle
                  cx={(46 / 100) * imageWidthPx}
                  cy={(50 / 100) * imageHeightPx - movement.hip_level_avg / 2}
                  r={(0.04 * imageWidthPx) / 2}
                  fill={Theme.BLUE}
                />
                <circle
                  cx={(54 / 100) * imageWidthPx}
                  cy={(50 / 100) * imageHeightPx + movement.hip_level_avg / 2}
                  r={(0.04 * imageWidthPx) / 2}
                  fill={Theme.BLUE}
                />
              </svg>
              <div style={{ marginTop: 35 }}>
                <Col
                  className="list"
                  style={{
                    position: "relative",
                    listStyle: "none",
                    width: "200%"
                  }}
                >
                  <Row align={"center"} style={{ width: "100%" }}>
                    <Text
                      style={{
                        color: Theme.TEXT,
                        fontSize: 13,
                        fontWeight: "bold"
                      }}
                    >
                      Shoulder Level Average
                    </Text>
                  </Row>

                  <div>
                    <Text
                      style={{
                        color: Theme.PRIMARY,
                        fontSize: 13,
                        fontWeight: "bold"
                      }}
                    >
                      {leftOrRight(movement.shoulder_level_avg)}
                    </Text>
                  </div>
                </Col>
                <Col
                  className="list"
                  style={{
                    position: "relative",
                    listStyle: "none",
                    width: "100%",
                    top: 15
                  }}
                >
                  <div>
                    <Text
                      style={{
                        color: Theme.TEXT,
                        fontSize:13,
                        fontWeight: "bold"
                        // fontFamily: "ArialBold",
                      }}
                    >
                      Hip Level Average
                    </Text>
                  </div>

                  <div>
                    <Text
                      style={{
                        color: Theme.PRIMARY,
                        fontSize:13,
                        fontWeight: "bold"
                      }}
                    >
                      {leftOrRight(movement.hip_level_avg)}
                    </Text>
                  </div>
                </Col>
              </div>
            </div>
          </div>
        </Col>
      </Col>
    </div>
  );
};

export default JointDisplacement;
