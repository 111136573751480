import { CHECK_SUBSCRIPTION, RESET_CHECK } from "../actions/types";

const initialState = {
  subscriptionStatus: null,
  freeTrial: null,
  compedMembership: null,
  statusChecked: null,
  isPromo: null,
  isFounder: null,
};

function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case CHECK_SUBSCRIPTION:
      return {
        ...state,
        subscriptionStatus: action.payload.active_subscription,
        freeTrial: action.payload.free_trial_available,
        compedMembership: action.payload.comped_membership,
        statusChecked: true,
        isPromo: action.payload.is_promo,
        isFounder: action.payload.is_founder,
      };
    case RESET_CHECK:
      return {
        ...state,
        subscriptionStatus: null,
        freeTrial: null,
        compedMembership: null,
        statusChecked: null,
        isPromo: null,
        isFounder: null,
      };
    default:
      return state;
  }
}

export default paymentReducer;
