import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import {
  Input,
  Button,
  Checkbox,
  Select,
  Card,
  Row,
  Typography,
  Col,
} from "antd";
import { Formik, Field } from "formik";
import * as yup from "yup";
import {
  updateGroups,
  resetGroupStatus,
  fetchTrainersList,
} from "../../redux/actions/groups";
import {
  createTrainer,
  getTrainers,
  searchTrainers,
} from "../../redux/actions/trainers";
import DebounceSelect from "../loaders/DebounceSelect";
import { css, StyleSheet } from "aphrodite";
import Theme from "../../constants/Theme";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { REACT_APP_API_URL } from "../../env";
import axios from "axios";
import CustomSelect from "../common/DropDown";

const AddTrainerGroupForm = ({ group, closeModal }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { user, Theme } = useSelector((state) => state.auth);


  const handleSubmit = async (values) => {
    var data = {};
    if (values.account_type === "group_admin") {
      data = {
        add_admins: values.trainer_id,
      };
    } else {
      data = {
        add_trainers: values.trainer_id,
      };
    }
    dispatch(updateGroups(id, data));
    closeModal();
  };

  async function fetchTrainersList(searchTerm) {
    if (!searchTerm.trim()) {
      return [];
    }
    try {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          Authorization: `JWT ${token}`,
        },
      };

      const response = await axios.get(
        `${REACT_APP_API_URL}/api/organization/trainers?search=${searchTerm}`,
        config
      );

      const combinedTrainers = group.trainers.concat(group.group_admins);
      const filteredTrainers = response.data.results.filter((trainer) => {
        return !combinedTrainers.some((groupTrainer) => {
          return groupTrainer.user_account.id === trainer.user_account.id;
        });
      });

      return filteredTrainers.map((trainer) => ({
        label: `${trainer.user_account.first_name} ${trainer.user_account.last_name}`,
        value: trainer.user_account.id.toString(),
      }));
    } catch (error) {
      return [];
    }
  }

  return (
    <Col
      bordered={true}
      style={{
        zIndex: 100,
        // flex: 1,
        position: "relative",
      }}
      className="criclebox h-full"
    >
      <div>
        <Formik
          initialValues={{
            trainer_id: [],
            account_type: "",
          }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validationSchema={yup.object().shape({
            trainer_id: yup.array().required("Required"),
            account_type: yup.string().required("Required"),
          })}
        >
          {({
            values,
            setFieldValue,
            handleChange,
            handleSubmit,
            resetForm,
            touched,
            errors,
          }) => (
            <Col align="center" className={css(styles.registerContainer)}>
              {( user?.profile?.account_type == 2 &&
              <div className={css(styles.block, styles.center)}>
                <div className={css(styles.title)}>
                  <p
                    className={css(styles.titleText)}
                    style={{ color: Theme.TEXT }}
                  >
                    Add Trainer(s)
                  </p>
                </div>

                <Row
                  justify={"center"}
                  className={css(styles.row)}
                  style={{ bottom: 25, marginBottom: ".75vw" }}
                >
                  <div className={css(styles.column, styles.margin5)}>
                    <DebounceSelect
                      style={{ width: "15vw", bottom: 5 }}
                      showSearch
                      suffixIcon={null}
                      filterOption={false}
                      placeholder={
                        <span
                          style={{
                            color: Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                            fontSize: ".65vw",
                            top: ".05vw",
                            position: "relative",
                            fontWeight: "normal",
                          }}
                        >
                         Search
                        </span>
                      }
                      mode="multiple"
                      tokenSeparators={[","]}
                      onChange={(selectedValues) => {
                        setFieldValue("trainer_id", selectedValues);
                      }}
                      fetchOptions={fetchTrainersList}
                      debounceTimeout={600}
                    />
                    {touched.trainer_id && errors.trainer_id && (
                      <p className={css(styles.errors)}>{errors.trainer_id}</p>
                    )}
                  </div>
                  
                </Row>
                <Row
                  justify={"center"}
                  className={css(styles.row)}
                  style={{ bottom: 25 }}
                >
                  <div className={css(styles.column, styles.margin5)}>
                  <CustomSelect
                      style={{ width: "10vw" }}
                      width="10vw"
                      placeholder={
                        <span
                          style={{
                            color: Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                            fontSize: ".65vw",
                            top: ".05vw",
                            position: "relative",
                            fontWeight: "normal",
                          }}
                        >
                        Role Type
                        </span>
                      }
                      onChange={handleChange("account_type")}
                      options={[
                        {
                          value: "group_admin",
                          label: "Team Admin",
                        },
                        {
                          value: "trainer",
                          label: "Trainer",
                        },
                      
                      ]}
                    />
                    {touched.account_type && errors.account_type && (
                      <p className={css(styles.errors)}>
                        {errors.account_type}
                      </p>
                    )}
                  </div>
                </Row>
                <button
                  className={css(styles.createButton)}
                  type="submit"
                  onClick={handleSubmit}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <div className={css(styles.buttonGradient)}>
                    <p className={css(styles.buttontext)}>Submit</p>
                  </div>
                </button>
              </div>
                 )}
            </Col>
         
          )}
        </Formik>
      </div>
    </Col>
  );
};

export default AddTrainerGroupForm;

const styles = StyleSheet.create({
  title: {
    color: "#232b2b",
    fontSize: 18,

    bottom: 25,
    fontWeight: "600",
    alignSelf: "center",
  },
  registerContainer: {
    borderRadius: 10,
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    justifyContent: "center",
    shadowRadius: 8,
    shadowOpacity: 0.3,
    elevation: 1,
    // height: "15vw",
    // width: "15vw",
    // marginTop: 10,
  },
  inputIcons: {
    marginRight: 12,
  },
  titleText: { fontSize: "1vw" },
  createButton: {
    width: "5vw",
    marginTop: "5%",
    backgroundImage: "linear-gradient(45deg, #C04848 0%, #480048 80%)",
    borderWidth: 0,
    borderRadius: 10,
  },
  buttontext: {
    color: Theme.COLORS.WHITE,
    fontWeight: "bold",
    marginHorizontal: 5,
    // paddingHorizontal: 5,
  },
  errors: {
    position: "relative",
    // top: 55,
    color: "red",
    fontSize: ".75vw",
    // right: '25%'
    // alignSf: 'flex-start'
    // marginLeft: 12,
  },
  checkbox: {
    // top: 9,
    color: Theme.COLORS.PRIMARY,
    borderRadius: 5,
    width: 15,
    height: 15,
    position: "relative",
    right: "5%",
    top: "10%",
  },
  movementTitle: {
    alignSelf: "center",
    fontSize: "1.3vw",
    fontWeight: "bold",
    color: Theme.COLORS.BLACK,
    textAlign: "center",
  },
  clientDescr: {
    alignSelf: "center",
    fontSize: ".75vw",
    // fontWeight: "bold",
    color: Theme.COLORS.BLACK,
    textAlign: "center",
  },
});
