import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Col, Input, Modal, Row, Typography } from "antd";
import { IoPencilSharp } from "react-icons/io5";
import { patchAssessment } from "../../redux/actions/assessments";
import Recommendations from "./Recommendations";
import { IoChevronBackOutline } from "react-icons/io5";
import {
  useHistory, useLocation
} from "react-router-dom/cjs/react-router-dom.min";
import ScoreCircle from "../chart/ScoreCircle";
import ComparisonsResult from "./ComparisonsResult";
import { getSingleJointComparison, patchJointComparison } from "../../redux/actions/joint_comparisons";

const { Text } = Typography;
let bodyHeight = 400;
let modalMargin = 0;

const JCResultsSections = ({ navigateOption, clientName, loading }) => {
  
  const dispatch = useDispatch();
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const [isEditing, setIsEditing] = useState(false);
  const [pdfModalVisible, setPdfModalVisible] = useState(false)
  const [pdfMessage, setPdfMessage] = useState("")
  const { jointComparison, assessmentStatus } = useSelector(
    (state) => state.joint_comparisons
  );
  const [createdOn, setCreatedOn] = useState(null)
 
  const { Theme } = useSelector((state) => state.auth);
  const [notes, setNotes] = useState(jointComparison ? jointComparison.notes : null);
  const overallScore = parseFloat(jointComparison ? jointComparison.overall_score : null);
  const overall = [Math.round(overallScore)];
  const history = useHistory();
  const location = useLocation();

  const styles = createStyles(Theme);

  useEffect(() => {
    if (jointComparison) {
      setCreatedOn(new Date(jointComparison.date));
    }
  }, [jointComparison]);

  const handleSaveClick = () => {
    const formData = new FormData();
    formData.append("notes", notes);
    dispatch(patchJointComparison(formData, jointComparison.id))
    setIsEditing(false);
  };

  const cancelEdit = () => {
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const onNotesChange = (e) => {
    // Update the first_name state variable here
    setNotes(e.target.value);
  };



  const handleGoToClients = () => {
    const pathParts = location.pathname.split('/');
    const id = pathParts[2];
    history.push(`/client/${id}`);
  };

 

  if (loading === true) {
    
    // return <LoadingScreenAssess />;
  } else if (overall == 0) {
   
    return (
      <div style={{ backgroundColor: "white", height: "100%" }}>
        <div style={{ backgroundColor: "white" }}></div>
      </div>
    );
  } else {
    return (
      <>
        <Row
          onClick={handleGoToClients}
          style={{
            right: "6vw",
            bottom: "0vw",
            position: "relative",
            cursor: "pointer"
          }}
        >
          <IoChevronBackOutline
            style={{
              top: ".2vw",
              marginRight: ".5%",
              marginBottom: "2vw",
              position: "relative",
              color: Theme.TEXT
            }}
          />
          <div>
            <Text style={{ color: Theme.TEXT }}>Back</Text>
          </div>
        </Row>
        <Col
          align="center"
          justify="center"
          style={{
            alignItems: "center",
            display: "flex",
            top: "5%",
            position: "relative",
            marginBottom: "30vh",
            right: "10%"
          }}
        >
          <Col align="center" justify="center">
            <Row
              style={{
                alignSelf: "center",
                position: "relative",
                paddingBottom: "1vw"
              }}
              gutter={[10, 6]}
            >
              <Col>
                <Card
                  style={{
                    zIndex: 100,
                    flex: 1,
                    position: "relative",
                    width: isRowLayout ? "30.75vw" : "16vw",
                    background: Theme.CARD,
                    borderWidth: 0,
                    borderRadius: 10,
                    minHeight: "19vw"
                  }}
                  // className="criclebox h-full"
                >
                  <Col
                    style={{
                      alignSelf: "center",
                      position: "relative",
                      textAlign: "center",
                      top: "1vw"
                    }}
                  >
                    {jointComparison && jointComparison.overall_score && <ScoreCircle
                      score={Math.round(jointComparison.overall_score)}
                      height="4vw"
                    />}
                    <div style={{ marginTop: "1vw" }}>
                      <div>
                        {/* <p className={headerTitleClass}>Client:</p> */}
                        <Text
                          style={{
                            color: Theme.TEXT,
                            fontWeight: "bold",
                            // paddingLeft: "5%"
                            fontSize: "1.25vw",
                            position: "relative",
                            // bottom: 10,
                            shadowColor: Theme.TEXT,
                            shadowOffset: { width: 0, height: 2 },
                            shadowRadius: 4,
                            shadowOpacity: 0.2
                          }}
                        >
                          {clientName}
                        </Text>

                        <Col
                          style={{
                            marginBottom: "1.5vw",
                            marginTop: "1.5vw"
                          }}
                        >
                          <Text
                            style={{
                              color: Theme.TEXT,
                              fontSize: ".9vw",
                              fontWeight: "bold"
                            }}
                          >
                            Date of Assessment
                          </Text>
                          <Col>
                            {createdOn && <Text
                              style={{
                                fontSize: ".80vw",
                                marginTop: "0vw",
                                fontWeight: "600",
                                color: Theme.PRIMARY,
                                fontSize: ".75vw"
                              }}
                            >
                              {createdOn.toLocaleDateString()}
                            </Text>}
                          </Col>
                        </Col>

                        <Col
                          align="center"
                          style={{ marginTop: "1vw", position: "relative" }}
                        >
                          <Text
                            style={{
                              left: ".25vw",
                              position: "relative",
                              color: Theme.TEXT
                            }}
                          >
                            Notes{" "}
                            <span
                              style={{
                                top: 0,
                                color: Theme.PRIMARY,
                                cursor: "pointer"
                              }}
                              onClick={() => handleEditClick()}
                            >
                              <IoPencilSharp size={".55vw"} />
                            </span>
                          </Text>
                          {!isEditing && (
                            <Row
                              align={"center"}
                              style={{ width: "80%", marginTop: ".25vw" }}
                            >
                             {jointComparison && jointComparison.notes && <Text
                                style={{
                                  fontSize: ".8vw",
                                  // top: ".25vw",
                                  position: "relative",
                                  fontWeight: "600",

                                  color: Theme.PRIMARY
                                }}
                              >
                                {jointComparison.notes}
                              </Text>}
                            </Row>
                          )}
                          {isEditing && (
                            <form
                              onSubmit={(e) => handleSaveClick(e)}
                              style={{
                                background: "transparent",
                                paddingBottom: "10%"
                              }}
                            >
                              <Col
                                align="center"
                                justify="center"
                                style={{ top: "1vw", position: "relative" }}
                              >
                                <Col
                                  align="center"
                                  justify="center"
                                  style={styles.fieldrow}
                                >
                                  <Input
                                    className={
                                      Theme.BACKGROUND === "#000"
                                        ? "custom-placeholder-color-dark"
                                        : "custom-placeholder-color-light"
                                    }
                                    style={{
                                      width: "10vw",
                                      color: Theme.TEXT,
                                      background: Theme.BACKGROUND,
                                      borderWidth: 0
                                    }}
                                    placeholder={notes} // This sets the placeholder, not the default value
                                    name="notes"
                                    
                                    onChange={(e) => onNotesChange(e)}
                                    defaultValue={jointComparison.notes}
                                  />
                                </Col>

                                <div
                                  middle
                                  row
                                  space="evenly"
                                  style={{
                                    top: "1vw",
                                    position: "relative",
                                    marginBottom: "4vw"
                                  }}
                                >
                                  <Button
                                    
                                    style={{
                                      backgroundColor: Theme.BACKGROUND,
                                      borderRadius: 5,
                                      borderWidth: 0,

                                      marginRight: ".5vw",
                                      minWidth: "4vw",
                                      cursor: "pointer",
                                      boxShadow:
                                        "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                                    }}
                                    onClick={(e) => cancelEdit(e)}
                                  >
                                    <Text
                                      style={{
                                        color: Theme.TEXT,
                                        fontWeight: "bold"
                                      }}
                                    >
                                      Cancel
                                    </Text>
                                  </Button>
                                  <Button
                                    
                                    style={{
                                      backgroundColor: Theme.BACKGROUND,
                                      borderRadius: 5,
                                      borderWidth: 0,

                                      marginRight: ".5vw",
                                      minWidth: "4vw",
                                      cursor: "pointer",
                                      boxShadow:
                                        "0px 6px 12px rgba(0, 0, 0, 0.175)" // Adding a shadow effect
                                    }}
                                    onClick={(e) => handleSaveClick(e)}
                                  >
                                    <Text
                                      style={{
                                        color: Theme.TEXT,
                                        fontWeight: "bold"
                                      }}
                                    >
                                      Save
                                    </Text>
                                  </Button>
                                </div>
                              </Col>
                            </form>
                          )}
                        </Col>
                      </div>
                    </div>
                    <div></div>
                  </Col>
                </Card>
              </Col>

              <Col>
                <Card
                  style={{
                    zIndex: 100,
                    flex: 1,
                    position: "relative",
                    width: isRowLayout ? "40vw" : "40vw",
                    background: Theme.CARD,
                    borderRadius: 10,
                    // paddingBottom: "10%",
                    borderWidth: 0,
                    minHeight: "19vw"
                  }}
                >
                 {jointComparison && jointComparison.recommended_exercises && <Recommendations
                    exercises={jointComparison.recommended_exercises}
                  ></Recommendations>}
                </Card>
              </Col>
            </Row>
            {jointComparison && jointComparison.comparisons && Object.keys(jointComparison.comparisons).map((type, index) => (
              <ComparisonsResult
                key={index}
                leftComparison={
                  type == "cervical_movement"
                    ? jointComparison.comparisons[type].extension
                    : jointComparison.comparisons[type].left
                }
                rightComparison={
                  type == "cervical_movement"
                    ? jointComparison.comparisons[type].flexion
                    : jointComparison.comparisons[type].right
                }
              />
            ))}
          </Col>
        </Col>
      </>
    );
  }
};

export default JCResultsSections;

const createStyles = (theme) => ({
  container: {
    // flex: 1,

    backgroundColor: "#fff",

    // marginBottom: "10%"
  },
  scoreText: {
    // top: 50,
    paddingLeft: "5%",
    fontSize: 15,
    fontWeight: "bold",
    // paddingBottom: "3%"
    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.5,
  },
  sectionText: {
    marginVertical: 10,
    top: 15,
    alignSelf: "center",
    fontSize: 25,
    fontWeight: "bold",
    paddingBottom: "8%",
    // paddingLeft: '5%'
    fontFamily: "ArialBold",
    color: theme.BLACK,
  },
  headerTitle: {
    // top: 5,

    // alignSelf: "center",
    color: theme.BLACK,
    fontWeight: "600",
    fontSize: ".75vw",
    // paddingLeft: "5%"
    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.3,
    elevation: 2,
  },
  headerText: {
    // top: 5,
    // alignSelf: "center",
    color: theme.BLACK,
    fontWeight: "bold",
    // paddingLeft: "5%"
    fontSize: "1.5vw",
    position: "relative",
    // bottom: 10,
    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.2,
  },
  messageText: {
    color: "black",
    fontSize: 12,
    // top: 50,
    alignSelf: "center",
    fontSize: 15,
    paddingVertical: "3%",
    paddingHorizontal: "3%",
  },
  body: {
    // alignContent: 'center',
    // top: 50
    // width: "100%",
  },
  closeModal: {
    justifyContent: "flex-start",
    borderRadius: 10,
    // zIndex: 20,
  },

  resultText: {
    color: theme.PRIMARY,
    fontSize: 14,
    fontWeight: "bold",
    padding: 8,
  },
  header: {
    top: "20%",

    borderWidth: 0,

    flex: 1,
  },
  concernSection: {
    // top: -300,
    paddingTop: "5%",
    alignSelf: "center",
    // margin: 6,
    backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    borderWidth: 0,
    maxHeight: "100%",
    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.1,
    elevation: 2,
  },
  pages: {
    position: "relative",
    // paddingTop: "5%",
    alignSelf: "center",
    // margin: 6,
    // backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    height: 775,
    top: 50,

    borderWidth: 0,

    // elevation: 2,
    // elevation: 2,
  },
  body: {
    position: "relative",
    paddingTop: "5%",
    alignSelf: "center",
    margin: 6,
    // backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    height: bodyHeight,

    borderWidth: 0,

    width: "97.5%",
  },
  scoreSection: {
    // top: -300,
    paddingTop: "10%",
    alignSelf: "center",
    margin: 10,
    backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    borderWidth: 0,
    maxHeight: "85%",

    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.7,
    elevation: 2,
  },
  detailSection: {
    // top: -300,
    paddingTop: "10%",
    alignSelf: "center",
    margin: 10,
    backgroundColor: theme.WHITE,
    // marginVertical: theme.SIZES.BASE,
    borderWidth: 0,
    // maxHeight: '110%',

    shadowColor: theme.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.1,
    elevation: 2,
  },
  buttontext: {
    color: theme.WHITE,
    fontWeight: "bold",
    marginHorizontal: 5,
    // paddingHorizontal: 5,
  },
  video: {
    alignSelf: "center",

    height: 500,
    marginVertical: "10%",
    marginHorizontal: "2%",
  },
  tourvideo: {
    alignSelf: "center",
    width: 200,
    height: 200,
    bottom: "5%",
    // marginVertical: "10%",
    marginHorizontal: "2%",
  },
  modalView: {
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    top: modalMargin,
    // elevation: 5,
  },
  bottomView: {
    flex: 1,
    justifyContent: "flex-end",
    top: 20,
    alignSelf: "center",
  },

  messageText: {
    color: theme.PRIMARY,
    fontWeight: "bold",
    paddingVertical: 8,
    paddingRight: 4,
    top: 50,
    fontFamily: "Arial",
  },
  icon: {
    // alignSelf: 'center',
    position: "absolute",
    opacity: 1,

    bottom: "25%",
    // left: '10%'
    zIndex: 25,
  },
  lottieContainer: {
    flex: 1,
    alignSelf: "center",
    justifyContent: "center",

    position: "absolute",

    zIndex: 5,
  },
  lottieStyle: {
    flex: 1,
    alignSelf: "center",
    justifyContent: "center",
    // backgroundColor: "#8d93de",
    opacity: 0.25,
    position: "absolute",

    zIndex: 5,
  },
  modalText: {
    // color: theme.PRIMARY,
    flexWrap: "wrap",
    marginHorizontal: 5,
    left: 10,

    // textAlign: "center",
    alignSelf: "flex-start",
    paddingHorizontal: 10,
    marginVertical: 5,
    fontFamily: "ArgonExtra",
  },
  tourModal: {
    // backgroundColor: "black",
    backgroundColor: "white",
    borderRadius: 10,
    // padding: 150,
    bottom: 5,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  bottomView: {
    flex: 1,
    justifyContent: "center",
    top: 15,
    alignSelf: "center",
  },
});
