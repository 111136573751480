// Import necessary components and libraries
import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  DatePicker,
  Button,
  Row,
  Typography,
  Col,
  Modal,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  createMesocycle,
  deleteBlock,
  getPrograms,
  resetMesocycleStatus,
  updateMesocycle,
} from "../../redux/actions/workouts";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import { Select } from "antd";
import CustomSelect from "../common/DropDown";
import StyledDatePickerComponent from "../common/DatePickerWorkouts";

const { Option } = Select;

const { Text } = Typography;

const CreateBlockForm = (blockData, modalOpen) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { Theme } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.clients);
  const { mesocycleStatus, mesocycle, programs } = useSelector(
    (state) => state.workouts
  );
  const [selectedStartDate, setSelectedDate] = useState(
    blockData.start_date ? blockData.start_date : null
  );

  const [form] = Form.useForm();
  const [error, setError] = useState(false);
  const onFinish = async (values) => {
    try {
      setError(false);
      const { start_date, name, description, numOfWeeks, macro } = values;
      const newDate = new Date(selectedStartDate);
      const formattedDate = newDate.toISOString();
      const indexOfT = formattedDate.indexOf("T");
      const dateBeforeT = formattedDate.substring(0, indexOfT);

      const mesdata = {
        start_date: dateBeforeT,
        name,
        description,
        numOfWeeks: parseInt(numOfWeeks),
        client: client.id,
        macro: blockData.prog ? blockData.prog[0].id : macro,
      };

      if (blockData.blockData) {
        dispatch(updateMesocycle(blockData.blockData[0].id, mesdata));
      } else {
        dispatch(createMesocycle(mesdata));
      }
    } catch (error) {
      console.error("Error creating mesocycle:", error);
      setError(true);
    }
  };

  useEffect(() => {
    if (programs.length === 0) {
      dispatch(getPrograms(client.id));
    }
    if (blockData.blockData && blockData.blockData[0]) {
      setSelectedDate(blockData.blockData[0].start_date);
      form.setFieldsValue({
        name: blockData.blockData[0].name,
        description: blockData.blockData[0].description,
        start_date: blockData.blockData[0].start_date,
        numOfWeeks: blockData.blockData[0].numOfWeeks,
        macro: blockData.blockData[0].macro,
      });
    }
  }, [modalOpen, blockData]);

  useEffect(() => {
    if (
      (mesocycleStatus === "success") &&
      !blockData.prog
    ) {
      history.push(`/block/${client.id}/${mesocycle.id}`);
      dispatch(resetMesocycleStatus());
      form.resetFields();
    } else if(mesocycleStatus === "updated"){
      history.push(`/block/${client.id}/${mesocycle[0].id}`);
      dispatch(resetMesocycleStatus());
      form.resetFields();
    }
    else if (mesocycleStatus === "deleted") {
      history.push(`/client/${client.id}`, { fromWorkout: true });
      dispatch(resetMesocycleStatus());
      form.resetFields();
    } else if (
      (mesocycleStatus === "success" || mesocycleStatus === "updated") &&
      blockData.closeModal
    ) {
      blockData.closeModal();
      form.resetFields();
    }
  }, [mesocycleStatus]);

  const handleDeleteBlock = () => {
    Modal.confirm({
      title: "Delete Block",
      content: "Are you sure you want to delete this block?",
      onOk: () => {
        dispatch(deleteBlock(blockData.blockData[0].id));
      },
      onCancel: () => {},
      okButtonProps: {
        type: "primary",
      },
    });
  };
  return (
    <Form
      name="createMesocycle"
      onFinish={onFinish}
      initialValues={{ remember: true }}
      layout="vertical"
      align="middle"
      justify="center"
      form={form}
      style={{ background: Theme.CARD }}
    >
      <Row align="middle" justify="center">
        <Form.Item
          className="custom-form-item"
          name="start_date"
          style={{ fontSize: "0.75vw" }}
          rules={[{ required: true, message: "Please select the start date" }]}
        >
          <StyledDatePickerComponent
            selected={selectedStartDate}
            onChange={(date) => setSelectedDate(date)}
            placeholder={"Start Date"}
          />
        </Form.Item>
        <Form.Item
          className="custom-form-item"
          name="numOfWeeks"
          rules={[
            { required: true, message: "Please enter the number of weeks" },
          ]}
          style={{ marginLeft: "1vw" }}
        >
          <Input
            className={
              Theme.BACKGROUND === "#000"
                ? "custom-placeholder-color-dark"
                : "custom-placeholder-color-light"
            }
            placeholder="Number of Weeks"
            type="number"
            min="1"
            step="1"
            style={{
              color: Theme.TEXT,
              fontSize: ".75vw",
              height: "2vw",
              width: "10vw",
              background: Theme.BACKGROUND,
              borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1,
            }}
          />
        </Form.Item>
      </Row>
      <Col align="middle" justify="center">
        <Form.Item
          className="custom-form-item"
          //   label="Name"
          name="name"
          style={{ fontSize: "0.75vw", marginTop: ".5vw" }}
          rules={[
            { required: true, message: "Please enter the mesocycle name" },
          ]}
        >
          <Input
            placeholder="Name"
            className={
              Theme.BACKGROUND === "#000"
                ? "custom-placeholder-color-dark"
                : "custom-placeholder-color-light"
            }
            style={{
              width: "15vw",
              color: Theme.TEXT,
              background: Theme.BACKGROUND,
              borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1,
              fontSize: ".75vw",
            }}
          />
        </Form.Item>
      </Col>

      <Col align="middle" justify="center">
        <Form.Item
          className="custom-form-item"
          name="description"
          style={{ marginTop: ".5vw" }}
        >
          <Input.TextArea
            className={
              Theme.BACKGROUND === "#000"
                ? "custom-placeholder-color-dark"
                : "custom-placeholder-color-light"
            }
            placeholder="Description"
            autosize={{ minRows: 1, maxRows: 6 }}
            style={{
              width: "19vw",
              resize: "none",
              minHeight: "5vw",
              color: Theme.TEXT,
              fontSize: ".75vw",
              background: Theme.BACKGROUND,
              borderWidth: Theme.BACKGROUND === "#000" ? 0 : 1,
            }}
          />
        </Form.Item>
      </Col>
      <Form.Item name="macro">
        <CustomSelect
          defaultValue={
            blockData.blockData
              ? blockData.blockData[0]?.macro
              : blockData.prog
              ? blockData.prog[0].id
              : null
          }
          options={programs.map((macro) => ({
            value: macro.id,
            label: macro.name,
          }))}
          // onChange={handleChange} // assuming handleChange is your change handler function
          placeholder={
            <span
              style={{
                color: Theme.BACKGROUND === "#000" ? Theme.TEXT2 : "#000",
                fontSize: ".65vw",
                top: ".05vw",
                position: "relative",
                fontWeight: "normal",
              }}
            >
              Select Program
            </span>
          }
          lightModeBackground="#f1f1fb"
          darkModeBackground="#000"
          width={"15vw"}
        />
      </Form.Item>
      <Row style={{ marginTop: "1vw" }}>
        {blockData.blockData && (
          <Button
            type="primary"
            color={"PRIMARY"}
            style={{
              zIndex: 999,
              // backgroundImage:
              //   "linear-gradient(60deg, #C04848 25%, #480048 120%)",
              borderRadius: 10,
              borderWidth: 0,
              marginRight: "1vw",
              opacity: 0.9,
        
            }}
            onClick={() => handleDeleteBlock()}
          >
            <Text style={{ color: "white",      fontSize:".75vw"
       }}>Delete Block</Text>
          </Button>
        )}
        <div>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              color={"PRIMARY"}
              style={{
                zIndex: 999,
                backgroundImage:
                  "linear-gradient(60deg, #C04848 25%, #480048 120%)",
                borderRadius: 10,
                borderWidth: 0,
                opacity: 0.9,
          
              }}
            >
              {blockData.blockData ? (
                <Text style={{ color: "white",      fontSize:".75vw"
       }}>Update Block</Text>
              ) : (
                <Text style={{ color: "white",      fontSize:".75vw"
       }}>Create Block</Text>
              )}{" "}
            </Button>
          </Form.Item>
        </div>
      </Row>

      {error === true && (
        <Text style={{ fontsize: ".65vw", color: "red" }}>
          Error - Please Check the Fields and Try Again
        </Text>
      )}
    </Form>
  );
};

export default CreateBlockForm;
