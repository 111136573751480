import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { REACT_APP_API_URL } from "../env";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine
} from "recharts";
import { Card, Select, Spin, Col, Modal, Row, Typography } from "antd";
import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  LineChartOutlined
} from "@ant-design/icons";
import { getGroups } from "../redux/actions/groups";
import { mockComponent } from "react-dom/test-utils";
import CustomSelect from "./common/DropDown";

const { Text } = Typography;
const TrendReport = ({
  trend_reports,
  movements,
  title,
  chartColor,
  period,
  timeLoad,
  isJC
}) => {
  const dispatch = useDispatch();
  const { Option } = Select;
  const [state, setState] = React.useState({});
  const [variation, setVariation] = useState(movements[0]);
  const [movement_prefix, setPrefix] = useState(null);
  const [loading, setLoading] = useState(false);
  const { Theme } = useSelector((state) => state.auth);

  const formatDates = (unformattedDates) => {
    var dates = [];
    if (period === "monthly") {
      dates = unformattedDates.map((item) => {
        const dateObject = new Date(item);
        const year = dateObject.getFullYear();
        const months = [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec"
        ];
        const month = dateObject.getMonth() + 1;
        if (month === 12) {
          return months[0];
        } else {
          return months[dateObject.getMonth() + 1];
        }
      });
    } else if (period === "quarterly") {
      dates = unformattedDates.map((item) => item);
    } else if (period === "sixweeks") {
      dates = unformattedDates.map((item) => {
        const dateObject = new Date(item);
        const dateString = dateObject.toLocaleDateString();
        if (dateString[3] === "/") {
          return dateString.slice(0, 3);
        } else if (dateString[4] === "/") {
          return dateString.slice(0, 4);
        } else {
          return dateString.slice(0, 5);
        }
      });
    }
    return dates;
  };

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 500);
    if (isJC) {
      setState((prevState) => ({
        ...prevState,
        movementSelected: trend_reports?.[variation]?.["overall_score"],
        unit: "Score",
        unit_symbol: "%"
      }));
      setPrefix("overall_score");
      return;
    }
    var temp_prefix = "";

    if (variation == "aggregate") {
      temp_prefix = "overallScore";
    } else if (variation.slice(0, 2) == "l_" || variation.slice(0, 2) == "r_") {
      if (variation == "r_lat_lunge" || variation == "l_lat_lunge") {
        temp_prefix = "lunge_overall_score";
      } else {
        const sliced = variation.slice(2);
        temp_prefix = `${sliced}_overall_score`;
      }
    } else if (variation == "standard_squat") {
      temp_prefix = "squat_overall_score";
    } else {
      temp_prefix = `${variation}_overall_score`;
    }
    setState((prevState) => ({
      ...prevState,
      movementSelected: trend_reports?.[variation]?.[temp_prefix],
      unit: "Score",
      unit_symbol: "%"
    }));
    setPrefix(temp_prefix);
  }, [variation, trend_reports]);

  function getUnits(value) {
    var unit = "";
    var unit_symbol = "";
    if (
      value == "hip_level_avg" ||
      value == "shoulder_level_avg" ||
      value == "raw_angle" ||
      value == "start_angle" ||
      value == "end_angle"
    ) {
      unit = "Score";
      unit_symbol = "°";
    } else if (value == "flight_time_seconds") {
      unit = "Seconds";
      unit_symbol = "s";
    } else if (value == "max_jump_height_inches")
    {
        unit = "Inches";
        unit_symbol = '"';
    }
      else {
      unit = "Score";
      unit_symbol = "%";
    }
    return [unit, unit_symbol];
  }

  function formatJoints(key) {
    if (key.includes("overall")) {
      return "Overall";
    }
    if (key.includes("compensation")) {
      return "Compensation";
    } else if (variation === "aggregate") {
      const parts = key.split(/(?=S)/);
      const formattedParts = parts.map(
        (part) => part.charAt(0).toUpperCase() + part.slice(1)
      );
      return formattedParts.join(" ");
    } else {
      const parts = key.split("_");
      const formattedParts = parts
        .slice(0, -1)
        .map((part) => part.charAt(0).toUpperCase() + part.slice(1));
      return formattedParts.join(" ");
    }
  }

  function formatVariations(inputString) {
    const parts = inputString.split("_");
    let result = "";
    if (inputString === "squat") {
      return "Overhead";
    } else if (inputString === "standard_squat") {
      return "Standard";
    } else if (inputString === "l_lunge") {
      return "Reverse (L)";
    } else if (inputString === "r_lunge") {
      return "Reverse (R)";
    } else if (inputString === "l_lat_lunge") {
      return "Lateral (L)";
    } else if (inputString === "r_lat_lunge") {
      return "Lateral (R)";
    } else if (inputString === "l_rdl") {
      return "Single Leg (L)";
    } else if (inputString === "r_rdl") {
      return "Single Leg (R)";
    } else if (inputString === "hinge") {
      return "Bilateral";
    } else if (inputString === "l_balance") {
      return "Balance (L)";
    } else if (inputString === "r_balance") {
      return "Balance (R)";
    }
    if (parts[0] === "l" || parts[0] === "r") {
      const direction = parts.shift();
      result = parts
        .map((part) => {
          if (part === "rdl") {
            return "Single Leg";
          } else if (part === "hinge") {
            return "Bilateral";
          } else {
            return part.charAt(0).toUpperCase() + part.slice(1);
          }
        })
        .join(" ");
      result += ` (${direction.toUpperCase()})`;
    } else {
      result = parts
        .map((part) => {
          if (part === "hinge") {
            return "Bilateral";
          } else {
            return part.charAt(0).toUpperCase() + part.slice(1);
          }
        })
        .join(" ");
    }

    return result;
  }

  function handleChangeMovement(value) {
    let [unit, unit_symbol] = getUnits(value);
    setState((prevState) => ({
      ...prevState,
      movementSelected: trend_reports[variation][value],
      unit: unit,
      unit_symbol: unit_symbol
    }));
  }

  function handleChangeVariation(value) {
    setLoading(true);
    setVariation(value);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }

  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };
  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Card
      style={{
        flex: 1,
        background: Theme.CARD,
        position: "relative",
        width: isRowLayout ? "25vw" : "55vw",
        minHeight: "20vw",
        maxHeight: "45vw",
        borderWidth: 0
      }}
      className="criclebox h-full"
    >
      <Row style={{ position: "relative", bottom: "1vw" }}>
        <h3
          style={{
            marginLeft: 0,
            fontSize: "1vw",
            marginBottom: "1vw",
            position: "relative",
            color: Theme.TEXT
          }}
        >
          {title}
        </h3>

        {movements[0] !== "push" &&
          movements[0] !== "cmj" &&
          movements[0] !== "aggregate" &&
          trend_reports !== null && (
            <Col
              style={{
                width: "7vw",

                bottom: ".75vw",
                position: "relative",
                left: "45%",
                alignSelf: "flex-end"
              }}
            >
              <CustomSelect
                defaultValue={movements[0]}
                options={Object.keys(trend_reports).map((key) => ({
                  value: key,
                  label: formatVariations(key)
                }))}
                onChange={handleChangeVariation}
                width={"9vw"}
              />
            </Col>
          )}
      </Row>
      {trend_reports?.[variation] == null ||
      Object.keys(trend_reports[variation]).length === 0 ? (
        <div
          style={{
            maxWidth: "90%",
            margin: "auto", // Use auto for horizontal centering
            borderRadius: 10,
            cursor: "pointer",
            display: "flex",
            position: "relative",
            flexDirection: "column",
            alignItems: "center",
            left: isRowLayout ? "0vw" : "1vw",
            right: 0, // Set left and right to 0 for horizontal centering
            bottom: "1vw",
            transform: isRowLayout
              ? "translate(0%, 50%)"
              : "translate(-2.5%, 50%)"
          }}
        >
          {loading || timeLoad ? (
            <Spin
              size="large"
              color={Theme.TEXT}
              style={{ top: "5vw", position: "relative" }}
            />
          ) : (
            <Col align="middle" style={{ marginTop: "2vw" }}>
              <LineChartOutlined
                style={{ color: Theme.BLUE, fontSize: "1.5vw" }}
              />
              <div>
                <Text style={{ fontSize: "1vw", color: Theme.TEXT }}>
                  No Data
                </Text>
              </div>
            </Col>
          )}
        </div>
      ) : (
        <Col align="middle">
          {loading && (
            <Spin
              size="large"
              color={Theme.TEXT}
              style={{ top: "5vw", position: "relative" }}
            />
          )}
          {trend_reports &&
            trend_reports?.[variation][`${movement_prefix}`]?.length > 0 &&
            loading !== true &&
            timeLoad !== true && (
              <div>
                <ResponsiveContainer width="100%" aspect={2}>
                  <LineChart
                    width={500}
                    height={500}
                    data={state.movementSelected}
                    margin={{
                      top: 5,
                      right: 30
                    }}
                  >
                    <CartesianGrid
                      vertical={false}
                      horizontal={false}
                      yAxisTicks={[0, 25, 50, 75]}
                    />
                    <XAxis
                      dataKey="date"
                      axisLine={{ stroke: Theme.TEXT }}
                      tickFormatter={(value) => formatDates([value])[0]}
                    />
                    <YAxis
                      tickFormatter={(value) => `${value}${state.unit_symbol}`}
                      axisLine={{ stroke: Theme.TEXT }}
                    />
                    <Tooltip
                      formatter={(value, name, props) => {
                        if (name === "Inches" || name === "Seconds") {
                          return `${value.toFixed(2)}${state.unit_symbol}`;
                        } else {
                          return `${Math.round(value)}${state.unit_symbol}`;
                        }
                      }}
                      labelStyle={{ color: Theme.BLACK }}
                      itemStyle={{ color: Theme.BLACK }}
                    />
                    <Line
                      dataKey={state.unit}
                      stroke={chartColor}
                      fill={chartColor}
                      dot={{
                        fill: chartColor,
                        stroke: Theme.TEXT,
                        strokeWidth: 0,
                        r: 4,
                        className: "boxShadow"
                      }}
                    />
                    {state.movementSelected &&
                      state.movementSelected.map((entry, index) => (
                        <ReferenceLine
                          key={index}
                          x={entry.date}
                          stroke={Theme.TEXT}
                          strokeWidth={0.25}
                        />
                      ))}
                  </LineChart>
                </ResponsiveContainer>
                <Col
                  align={"center"}
                  justify={"center"}
                  style={{ marginTop: ".5vw" }}
                >
                  <CustomSelect
                    defaultValue={formatJoints(movement_prefix)}
                    options={Object.keys(trend_reports[variation]).map(
                      (key) => ({
                        value: key,
                        label: formatJoints(key)
                      })
                    )}
                    width={"9vw"}
                    onChange={handleChangeMovement}
                  />
                </Col>
              </div>
            )}
        </Col>
      )}
    </Card>
  );
};
export default TrendReport;
