export default [
  {
    title: "Foot Width at Initial Contact",
    description:
      "Measures the distance between the feet at the moment of initial contact. An optimal position is shoulder-width apart, while suboptimal positions are either too narrow or too wide",
    why: "Proper foot positioning ensures stability and balanced load distribution during landing. Incorrect positioning can lead to uneven stress on the joints and increase the risk of injury"
  },
  {
    title: "Foot Symmetry at Initial Contact",
    description:
      "Assesses if the feet touched down at the same time or one before the other. Symmetrical foot placement is optimal, while asymmetrical placement is suboptimal",
    why: "Crucial for balanced movement and reducing the likelihood of compensatory movements that could lead to injury. Asymmetry can cause uneven force distribution and increase the risk of strain or injury"
  },
  {
    title: "Foot Contact Pattern",
    description:
      "Observes how the foot makes contact with the ground. The optimal pattern is toe-to-heel, whereas flat or heel-to-toe contact is suboptimal",
    why: "Proper foot contact pattern helps in absorbing impact forces efficiently, reducing stress on the lower extremities. Suboptimal patterns can lead to inefficient shock absorption and increase the risk of injuries such as stress fractures"
  },
  {
    title: "Knee Flexion at Initial Contact",
    description:
      "Knee flexion at initial contact measures the angle of knee bend when the foot first touches the ground. An optimal knee flexion is greater than 45 degrees, while a suboptimal flexion is 45 degrees or less",
    why: "Adequate knee flexion is important for effective shock absorption and reducing the impact forces transmitted to the joints. Insufficient flexion can lead to higher impact forces and increased injury risk"
  },
  {
    title: "Hip Flexion at Initial Contact",
    description:
      "Assesses the degree of hip bend when the foot first makes contact with the ground. Optimal hip flexion is when the hips are flexed, while straight hips are suboptimal",
    why: "Proper hip flexion helps in distributing forces through the lower body and maintaining balance. Lack of hip flexion can compromise stability and increase the strain on other joints, leading to potential injuries"
  },
  {
    title: "Trunk Flexion at Initial Contact",
    description:
      "Measures the angle of the trunk relative to the ground. Neutral or flexed trunks are optimal, while extended trunks are suboptimal",
    why: "Maintaining appropriate trunk flexion is vital for overall balance and reducing the risk of lower back injuries. An extended trunk position can lead to instability and increased stress on the spine and lower extremities"
  },
  {
    title: "Trunk Lateral Flexion at Initial Contact",
    description:
      "Trunk lateral flexion at initial contact assesses the side-to-side bending of the trunk. An optimal position shows less than 5 degrees of lateral flexion, while 5 degrees or more is suboptimal",
    why: "Minimal trunk lateral flexion is important for maintaining balance and reducing asymmetric loads on the body. Excessive lateral flexion can lead to uneven force distribution and increase the risk of injury"
  },
  {
    title: "Maximum Knee Flexion Angle",
    description:
      "Measures the degree of knee bend at the point of maximum flexion during landing. Optimal displacement from initial contact is greater than 45 degrees, while less than 45 degrees is suboptimal",
    why: "Adequate knee flexion allows for effective shock absorption and reduces the forces transmitted to the joints. Insufficient knee flexion can increase the risk of impact-related injuries"
  },
  {
    title: "Knee Valgus",
    description:
      "Assesses the inward movement of the knee during landing. An optimal knee valgus is less than 10 degrees, while 10 degrees or more is suboptimal",
    why: "Minimizing knee valgus is crucial for preventing excessive stress on the knee ligaments, particularly the ACL. Excessive inward movement can lead to higher injury risks, including ligament tears"
  },
  {
    title: "Trunk Flexion at Maximum Knee Flexion",
    description:
      "Trunk flexion at maximum knee flexion measures the angle of trunk bend at the point of greatest knee flexion. An optimal range is 0-30 degrees, while less than 0 degrees or greater than 30 degrees is suboptimal",
    why: "Proper trunk flexion helps maintain balance and distribute forces effectively. Inadequate or excessive trunk flexion can compromise stability and increase the risk of injury"
  },
  {
    title: "Hip Flexion at Maximum Knee Flexion",
    description:
      "Hip flexion at maximum knee flexion assesses the degree of hip bend at the point of greatest knee flexion. An optimal range is 45-90 degrees, while less than 45 degrees or greater than 90 degrees is suboptimal",
    why: "Adequate hip flexion is essential for effective force distribution and shock absorption. Insufficient or excessive hip flexion can lead to imbalances and increase the risk of injuries during landing"
  }
];
