export const exerciseFilters = [
  {
    name: "General Movement Focus",
    options: ['Pull ', 'Push', 'Pull & Push', 'Push & Pull', 'Recovery & ROM', 'Stability']
  },
  {
    name: "Plane of Motion",
    options: ['Frontal', 'Multiplanar', 'Sagittal', 'Transverse']
  },
  {
    name: "Unilateral or Bilateral",
    options:  ['Bilateral', 'Bilateral to Unilateral', 'Unilateral', 'Unilateral to Bilateral']
  },
 
  {
    name: "Difficulty Level",
    options: ['Beginner', 'Beginner - Intermediate', 'Intermediate', 'Intermediate - Advanced', 'Advanced']

  },
  {
    name: "Body Segment",
    options: ['Center Trunk', 'Foot & Ankle', 'Forearm', 'Full Body', 'Grip & Forearms', 'Hips & Pelvis', 'Leg', 'Legs & Back', 'Legs & Shoulder', 'Lower Leg', 'Neck', 'Shoulders', 'Spine', 'Trunk & Pelvis', 
    'Trunk, Upper Leg, & Hips', 'Upper Arm', 'Upper Arm & Shoulder', 'Upper Leg', 'Upper Leg & Back', 'Upper Leg & Hips', 'Upper Leg & Shoulder', 'Anterior Upper Trunk', 
    'Posterior Upper Trunk', 'Posterior Chain', 'Wrist & Grip']
  },
  
  {
    name: "Primary & Secondary \nMuscle Groups",
    options: ['Abdominals', 'Adductors', 'Adductor-Hamstring Complex', 'Ankle Joint', 'Anterior Shoulder', 'Back', 'Biceps', 'Calves', 'Calf-Ankle Complex', 'Chest', 'Deltoids', 'Gastrocnemius ', 'Glutes', 
    'Glute Max', 'Glute Minimus & Medius', 'Glutes & Calf-Ankle Complex', 'Glute-Hamstring Complex', 'Glute-Hamstring-Calf Complex', 'Grip & Forearms', 'Hamstring-Calf Complex', 'Hamstrings', 'Hip Flexors', 
    'Hip Joint', 'Lateral Neck Flexors', 'Lateral Shoulder', 'Latissimus Dorsi', 'Lat-Serratus Complex', 'Legs', 'Neck Extensors', 'Neck Flexors', 'Pectoralis Major', 'Pectoralis Minor', 'Peroneals', 'Piriformis', 
    'Plantar Flexors', 'Posterior Shoulder', 'Psoas', 'Quadratus Lumborum', 'Quadriceps', 'Quad-Hip Flexors', 'Rotator Cuff', 'Serratus Anterior', 'Shoulder Joint', 'Soleus ', 'Spinal Erectors', 'Standing', 'Tensor Fasciae Latae', 
    'Teres Major', 'Thigh & Glutes', 'Tibialis Anterior', 'Triceps', 'Trunk', 'Upper Back', 'Upper-Middle Back', 'Wrist & Grip', 'Wrist Extensors', 'Wrist Flexors', 'Wrist Flexors & Extensors']
  },

  {
    name: "Action",
    options: ['Ankle Dorsiflexion', 'Ankle Plantar Flexion', 'Cervical Anti-Lateral Flexion', 'Cervical Extension', 'Cervical Flexion', 'Cervical Lateral Flexion', 'Cervical Lateral Flexion with Extension', 'Elbow Extension', 'Elbow Flexion', 
    'Elbow & Shoulder Flexion', 'Foot Flexion', 'Hand Flexion', 'Hip Abduction', 'Hip Adduction', 'Hip Abduction & Adduction', 'Hip Circumduction', 'Hip Extension', 'Hip Exterenal Rotation', 'Hip Flexion', 'Hip Abduction & Flexion', 
    'Hip & Shoulder Flexion', 'Hip Flexion & Extension', 'Hip Internal Rotation', 'Horizontal Pushing', 'Horizontal Pulling', 'Knee Extension', 'Knee Flexion', 'Knee Flexion & Extension', 'Hip & Knee Flexion', 'Hip, Knee, & Ankle Flexion', 
    'Hip, Knee, & Ankle Flexion-Extension', 'Hip Abduction & Knee Flexion', 'Overhead Press', 'Pelvic Tilt', 'Plantar Flexion', 'Pronation', 'Pronation-Supination', 'Shoulder Extension & Elbow Flexion', 'Shoulder Abduction', 'Shoulder Abduction-Internal Rotation', 
    'Shoulder Adduction', 'Shoulder Depression', 'Shoulder Extension', 'Shoulder External Rotation', 'Shoulder Flexion', 'Shoulder Flexion & Elbow Extension', 'Shoulder Flexion & Extension', 'Shoulder Horizontal Abduction', 'Shoulder Horizontal Adduction', 'Shoulder Internal Rotation', 
    'Shoulder Internal-External Rotation', 'Shoulder Protraction', 'Shoulder Retraction', 'Shoulder Protraction & Retraction', 'Spinal Anti-Extension', 'Spinal Anti-Lateral Flexion', 'Spinal Anti-Rotation', 'Spinal Extension', 'Spinal Flexion', 'Spinal Flexion & Extension', 
    'Spinal Lateral Flexion', 'Spinal Rotation', 'Supination', 'Toe Extension', 'Traction', 'Vertical Pushing', 'Vertical Pulling', 'Vertical Pull to Push', 'Who Body Stability', 'Whole Body Flexion & Extension', 'Wrist Extension', 'Wrist Flexion', 'Wrist Stability']
  },
  {
    name: "Body Position",
    options: ['Alternating Stance', 'Bench Straddle', 'Bent Over', 'Chest Supported', 'Climbs', 'Conventional Stance', 'Dip', 'Free Standing Split Stance', 'Frog', 'Glute Bridge', 
    'Half Kneeling', 'Hand and Knee Down', 'Hand Down', 'Hand Down Squared Stance', 'Hanging', 'Heel-to-Toe In-line Stance', 'Hinged', 'In-line Stance', 'Kneeling', 'Kneeling Plank', 'Kneeling Side Plank', 
    'Kneeling to Half Kneeling', 'Kneeling to Standing', 'Kneeling Tall Plank', 'Leaning', 'Plank', 'Prone', 'Prone Suspended', 'Quadruped', 'RFE Split Squat', 'Seated', 'Seated Feet Behind', 'Seated to Standing', 
    'Side Lying', 'Side Plank', 'Single Leg Stance', 'Sitting', 'Split Stance', 'Squat Stance', 'Staggered Stance', 'Standing', 'Step-Up Stance', 'Sumo Stance', 'Supine', 'Supine Feet Behind',
     'Supine Shoulders Elevated', 'Supine Suspended', 'Supine to Standing', 'Tall Kneeling', 'Tall Plank', 'Tall Side Plank', 'Wall Lean', 'Walking', 'Wide Stance']

  },
  
];

const unusedFilter = [
  
  {
    name: "Movement Genre",
    options: ['Adductor Squeeze', 'Activation', 'Ankling', 'Archer Row', 'Balance', 'Bent Over Row', 'Biceps Curl', 'Biceps Curl to Shoulder Press', 'Bike', 'Bound', 'Bound to Vertical Jump', 'Bosu Ball Jumps', 'Box Jump', 'Box Squat', 'Breakdown', 
    'Breakdown to Jump', 'Broad Jump', 'Chest Fly', 'Chest Press', 'Chin-Up', 'Clamshell', 'Clean', 'Clean to Power Jerk', 'Clean to Split Jerk', 'Cone Drill', 'Copenhagan Bridge', 'Copenhagen Plank', 'Cossack Squat', 'Crab Step', 'Crab Walk', 'Crossover Step', 
    'Curtsy Lunge', 'Curtsy Step-Up', 'Deadlift', 'Depth Drop', 'Dips', 'Drop Clean', 'Drop Press', 'Drop Jump', 'Endless Rope', 'Face Pull', 'Floor Press', 'Forward Curtsy Lunge', 'Forward Lunge', 'Forward Walking Lunge', 'Free Standing Row', 'Glute Bridge', 
    'Glute-Ham Development (GHD)', 'Grip & Forearms', 'Hamstring Curl', 'Hang Clean', 'Hang Clean to Power Jerk', 'Hang Clean to Split Jerk', 'Hang Muscle Snatch', 'Hang Power Clean', 'Hang Power Clean to Power Jerk', 'Hang Power Clean to Split Jerk', 'Hang Power Snatch', 
    'Hang Power Split Clean', 'Hang Snatch', 'Hang Power Split Snatch', 'Hang to High Pull', 'High Anchor Row', 'High Knees', 'Hip External Rotation', 'Hip Internal Rotation', 'Hops', 'Hop to Skier Jump', 'Hop to Lateral Bound', 'Hurdle Drill', 'Inverted Row',
     'Jammer Press', 'Jump Rope', 'Jump to Land', 'Jumping', 'Kettlebell Swing', 'Kip', 'Kip to Jump', 'Knee Extension', 'Land to Vertical Jump', 'Land to Shuffle', 'Land to Broad Jump', 'Land to Box Jump', 'Land to Skater Jump', 'Land to Skier Jump', 'Land to Sprint', 
     'Lateral Advancing Lunge', 'Lateral Jump', 'Lateral Lunge', 'Lateral Slider Squat', 'Lateral Step-Up', 'Load Carry', 'Low Anchor Row', 'Mid Anchor Row', 'Monster Walk', 'Muscle Clean', 'Muscle Snatch', 'Neck Extension', 'Neck Flexion', 'Neck Lateral Flexion', 'No Release Slam', 
     'No Release Slam to No Release Throw', 'No Release Slam to Throw', 'No Release Throw', 'Nordic Curl', 'Pause Squat', 'Pelvic Tilts', 'Pendlay Row', 'Plantar Flexion', 'Power Clean', 'Power Clean to Power Jerk', 'Power Clean to Split Jerk', 'Power Shrug', 'Power Snatch', 
     'Power Split Clean', 'Power Split Snatch', 'Pressure', 'Pull Down', 'Pull Over', 'Pull Through', 'Pull-Up', 'Push Jerk', 'Push Press', 'Push-Up', 'Quad-Hip Flexor Raise', 'Reverse Hyper', 'Reverse Lunge', 'Reverse Lunge Jump', 'Shoulder Reverse Fly', 'Reverse Slider Squat', 
     'Reverse to Forward Lunge', 'Reverse Walking Lunge', 'RFE Split Jump', 'RFE Split Squat', 'Roman Chair', 'Romanian Deadlift (RDL)', 'Row Ergometer', 'Run', 'Scap Pull', 'Seated Row', 'Shoulder Abduction', 'Shoulder Extension', 'Shoulder External Rotation', 
     'Shoulder Flexion', 'Shoulder Front Raise', 'Shoulder Internal Rotation', 'Shoulder Lateral Raise', 'Shoulder Rotation', 'Shoulder Stability', 'Shuffle', 'Single Arm Row', 'Single Leg Jump', 'Skater Jump', 'Skater to Vertical Jump', 'Skater Squat', 'Ski Egometer',
      'Skier Jump', 'Slam', 'Slam to Chest Press', 'Slam to No Release Throw', 'Slam to Throw', 'Sled Push', 'Snatch', 'Speed Ladder', 'Split Jerk', 'Split Jump', 'Split Squat', 'Sprint', 'Sprinter Start', 'Squat', 'Squat Jump', 'Squat to Row', 'Staggered Stance Squat', 
      'Step Down Squat', 'Step-Up', 'Step-Up Jump', 'Straight Arm Pull Down', 'Straight Leg Kick', 'Stretching', 'Strict Shoulder Press', 'Supported Row', 'Suspended Row', 'Swimmers', 'Throw', 'Thruster', 'Tire Flips', 'Transverse Jump', 'Open Step Lunge', 'Transverse Reverse Lunge', 
      'Step Across Lunge', 'Triceps Extension', 'Trunk Anti-Extension', 'Trunk Anti-Lateral Flexion', 'Trunk Anti-Rotation', 'Trunk Extension', 'Trunk Flexion', 'Trunk Rotation', 'Tuck Jump', 'Up-Down Walk Outs', 'Upright Row', 'Vertical Jump', 'Walk']
  },
  
  {
    name: "Training Tools",
    options: ['Ab Roller', 'Ab Straps', 'Adjustable Bench', 'Ankle Strap', 'Assult/Airdyne Bike', 'Aeropad', 'Barbell', 'Battle Rope', 'Bodyweight', 'Bosu Ball', 'Cable', 'Chains', 'Close Mag Grip', 
    'Neutral Close Handles', 'Cones', 'Dip Bars', 'Dowel', 'Dumbbells', 'Endless Rope', 'EZ Bar', 'Foam Roller', 'Glute Ham Development (GHD)', 'Hack Squat Machine', 'Handle', 'Handled Resistance Band', 
    'Hexagon Bar', 'Hurdles', 'Jump Rope', 'Kettlebells', 'Lacrosse Ball', 'Landmine', 'Leather Medicine Ball', 'Leg Press Machine', 'Leg Raise Rack', 'Long Foam Roller', 'Loop Resistance Band', 'Machine', 
    'Medicine Ball', 'Mid Mag Grip', 'Mini Band', 'Neutral Close Grip Bar', 'Neutral Mid Grip Bar', 'Neutral Wide Grip Bar', 'Peanut', 'Pendulum Squat', 'Plate', 'Plyo Box', 'Pull-Up Bar', 'Roman Chair', 'Rope', 'Row Ergometer',
     'Rubber Medicine Ball', 'Safety Bar', 'Sandbag', 'Ski Ergometer', 'Slam Ball', 'Sled', 'Slide Board', 'Slider', 'Smith Machine', 'Speed Ladder', 'Squat Rack', 'Stability Ball', 'Step', 'Step or Stair', 'Straight Bar', 
     'Tennis Ball', 'Theraband', 'Towel', 'Treadmill', 'TRX', 'V-Bar', 'Wood or Cement Wall', 'Wide Mag Grip', 'Yoga Block']
  },
  {
    name: "Loading Position",
    options:  ['Anterior-Distal', 'Anterior-Distal to Front Rack', 'Anterior-Distal to Front Rack to Overhead', 'Anterior-Distal to Overhead', 'Anterior-Distal to Goblet', 'Anterior-Distal to Goblet to Overhead', 'Anterior-Inferior', 
    'Anterior-Inferior to Front Rack', 'Anterior-Inferior to Front Rack to Overhead', 'Anterior-Inferior to Overhead', 'Anterior-Inferior to Goblet', 'Anterior-Inferior to Goblet to Overhead', 'Anterior-Lateral', 'Anterior-Posterior', 
    'Anterior-Superior', 'Anterior-Medial', 'Anterior to Overhead', 'Anteriorly', 'Back Rack', 'Bear Hug', 'Bodyweight Alternating Lateral to Lateral Loading', 'Bodyweight Alternating Posterior to Anterior Loading', 
    'Bodyweight Anterior Load and Return', 'Bodyweight Forward Locomotion', 'Bodyweight Lateral Load and Return', 'Bodyweight Posterior Load and Return', 'Bodyweight Reverse Locomotion', 'Bodyweight RFE Split Stance', 
    'Bodyweight Single Leg Stance', 'Bodyweight Split Stance', 'Bodyweight Squared Stance', 'Bodyweight Staggered Stance', 'Bodyweight Transverse Load and Return', 'Bottom-Up', 'Distally', 'Front Rack', 'Front Rack Lateral', 
    'Front Rack Single Arm Lateral', 'Front Rack-Bottom-Up', 'Front Rack-Overhead', 'Front Rack-Suitcase', 'Goblet', 'Hanging', 'High Anchor Anterior', 'High Anchor Lateral', 'Inferior-Superior', 'Inferior-Anterior', 'Inferiorly', 'Lateral-Distal', 
    'Lateral-Inferior', 'Laterally', 'Low Anchor Anterior', 'Low Anchor Lateral', 'Low Anchor Midline', 'Medial-Superior', 'Medially', 'Mid Anchor Anterior', 'Mid Anchor Lateral', 'Midline', 'Overhead', 'Overhead-Bottom-Up', 'Posterior-Inferior', 'Posterior-Superior', 
    'Posterior-Lateral', 'Posterior-Distal', 'Posteriorly', 'Proximal', 'Shoulder Rack', 'Single Arm Anterior', 'Single Arm Bottom-Up', 'Single Arm Front Rack', 'Single Arm Midline', 'Single Arm Overhead', 'Single Arm Posterior', 'Single Arm Suitcase', 'Suitcase', 
    'Suitcase-Overhead', 'Suitcase-Bottom-Up', 'Superior-Lateral', 'Superiorly', 'Zercher']

  },
  {
    name: "Exercise Order",
    options:   ['Prep', 'Prep or Recovery', 'Prep or Auxiliary', 'Prep, Auxiliary, or Recovery', 
    'Prep, Secondary, or Auxiliary', 'Primary', 'Primary or Secondary', 'Primary, Secondary, or Auxillary', 
    'Secondary', 'Secondary or Auxiliary', 'Auxiliary', 'Universal']
  },

  {
    name: "Exerices Classification",
    options:  ['Cardiovascular', 'Mobility', 'Plyometric', 'Stability-Balance', 'Strength-Corrective', 'Strength']
  },
  {
    name: "Single or Pair",
    options:  ['Pair', 'Single', 'Three', 'Four']
  },
  


]