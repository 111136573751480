import { useState, useEffect } from "react";
import { Col, Row } from "antd";
import GroupMemberList from "../components/GroupMemberList";

const GroupDetails = ({ route, navigation }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1500);
  }, []);

  const [isFadedIn, setIsFadedIn] = useState(false);

  useEffect(() => {
    setIsFadedIn(true);
  }, []);
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);

  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Row style={{ minHeight: "200vh", marginRight: '15vw' }}>
      <Col >
        <GroupMemberList/>
      </Col>
    </Row>
  );
};

export default GroupDetails;
