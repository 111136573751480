import React, { useRef, useState } from "react";
import { Card, Col, Row, Typography, Spin } from "antd";
import Theme from "../../constants/Theme";
import { useDispatch, useSelector } from "react-redux";
import { getSingleAssessment } from "../../redux/actions/assessments";
import ResultsSectionTitle from "./ResultsSectionTitle";
import sections from "../../constants/sections";
import { StyleSheet, css } from "aphrodite";
import ScoreIndicator from "../chart/ScoreIndicator";
import Compensations from "./Compensations";
import JointDisplacement from "./JointDiscplacement";
import ScoreCircle from "../chart/ScoreCircle";
const { Text } = Typography;

var filteredKeys = [];
var array = [];

var sectionModal;

const StandardSquatResults = ({ item, compensations, squat }) => {
  const { assessment } = useSelector((state) => state.assessments);
  const { Theme } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const fetchAssessment = () =>
    dispatch(getSingleAssessment(assessment.id, assessment.client.id));
  const styles = createStyles(Theme);

  const mq = window.matchMedia("(min-width: 1000px)");

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        top: "5%",
        position: "relative"
      }}
    >
      <Col justify="end">
        <Row
          style={{ alignSelf: "center", position: "relative" }}
          gutter={[16, 16]}
        >
          <Col align="center">
            <Col
              // bordered={true}
              align="center"
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: "20vw",
                display: "flex",
                borderRadius: 10,
                backgroundColor: Theme.CARD,
                boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.025)"
                // marginBottom: "10%",
              }}
              className="criclebox h-full"
            >
              <Col
                align="start"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  top: "3vw",
                  position: "relative",
                  width: "20vw"

                  // minHeight: "13vw",
                  // height: 'auto'
                }}
              >
                <Row
                  align="center"
                  style={{ position: "relative", width: "20vw" }}
                >
                  <Col
                    align={"start"}
                    style={{ position: "relative", right: "17.5%" }}
                  >
                    <Text style={styles.movementTitle}>STANDARD</Text>
                    <div style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={styles.movementTitle}>SQUAT</Text>
                    </div>
                  </Col>
                  <Col
                    align="middle"
                    style={{
                      position: "relative",
                      left: "20%",
                      bottom: "1vw"
                    }}
                  >
                    <ScoreCircle
                      score={Math.round(squat.squat_overall_score)}
                      height="4vw"
                    />
                    <div>
                      <Text style={styles.messageText}>Overall</Text>
                    </div>
                  </Col>
                </Row>

                <Col
                  name="scores"
                  align="center"
                  justify="center"
                  style={{
                    marginBottom: "7vw",
                    position: "relative",
                    bottom: ".25vw"
                    // width: "20vw",
                  }}
                >
                  <Row align="center" style={{ marginTop: "1vw" }}>
                    <Col
                      align="center"
                      justify="center"
                      style={{ marginRight: "3.5vw" }}
                    >
                      <Col align="center" justify="center">
                        <ScoreCircle
                          score={Math.round(squat.right_hip_score)}
                          height="4vw"
                        />

                        <Col style={{ top: ".5vw", position: "relative" }}>
                          <Text style={styles.messageText}>Right Hip</Text>
                          <div>
                            <Text style={styles.typeText}>Mobility</Text>
                          </div>
                        </Col>
                      </Col>
                      <Col
                        align="center"
                        justify="center"
                        style={{ marginTop: ".5vw" }}
                      >
                        <ScoreCircle
                          score={Math.round(squat.right_knee_score)}
                          height="4vw"
                        />

                        <Col style={{ top: ".5vw", position: "relative" }}>
                          <Text style={styles.messageText}>Right Knee</Text>
                          <div>
                            <Text style={styles.typeText}>Mobility</Text>
                          </div>
                        </Col>
                      </Col>
                      <Col
                        align="center"
                        justify="center"
                        style={{ marginTop: ".5vw" }}
                      >
                        <ScoreCircle
                          score={Math.round(squat.right_ankle_score)}
                          height="4vw"
                        />

                        <Col style={{ top: ".5vw", position: "relative" }}>
                          <Text style={styles.messageText}>Right Ankle</Text>
                          <div>
                            <Text style={styles.typeText}>Mobility</Text>
                          </div>
                        </Col>
                      </Col>
                    </Col>

                    <Col align="center" justify="center">
                      <Col align="center" justify="center">
                        <ScoreCircle
                          score={Math.round(squat.left_hip_score)}
                          height="4vw"
                        />

                        <Col style={{ top: ".5vw", position: "relative" }}>
                          <Text style={styles.messageText}>Left Hip</Text>
                          <div>
                            <Text style={styles.typeText}>Mobility</Text>
                          </div>
                        </Col>
                      </Col>
                      <Col
                        align="center"
                        justify="center"
                        style={{ marginTop: ".5vw" }}
                      >
                        <ScoreCircle
                          score={Math.round(squat.left_knee_score)}
                          height="4vw"
                        />

                        <Col style={{ top: ".5vw", position: "relative" }}>
                          <Text style={styles.messageText}>Left Knee</Text>
                          <div>
                            <Text style={styles.typeText}>Mobility</Text>
                          </div>
                        </Col>
                      </Col>
                      <Col
                        align="center"
                        justify="center"
                        style={{ marginTop: ".5vw" }}
                      >
                        <ScoreCircle
                          score={Math.round(squat.left_ankle_score)}
                          height="4vw"
                        />

                        <Col style={{ top: ".5vw", position: "relative" }}>
                          <Text style={styles.messageText}>Left Ankle</Text>
                          <div>
                            <Text style={styles.typeText}>Mobility</Text>
                          </div>
                        </Col>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Col>
            </Col>
          </Col>

          <Col>
            <Card
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: "16.3vw",
                borderRadius: 10,
                background: Theme.CARD,
                borderWidth: 0
              }}
              className="criclebox h-full"
            >
              <Compensations
                compensations={compensations}
                score={squat.compensation_score}
              
              />
              <Col
                style={{ position: "absolute", bottom: "5%", left: "28.5%" }}
              >
                <ScoreCircle
                  score={Math.round(squat.squat_depth_score)}
                  height={"3vw"}
                />
                <Col style={{ top: ".5vw", position: "relative" }}>
                  <Text style={styles.messageText}>Squat Depth</Text>
                  <div>
                    <Text style={styles.depthText}>
                      {Math.round(squat.squat_depth)}% from Starting
                    </Text>
                  </div>
                </Col>
              </Col>
            </Card>
          </Col>
          <Col>
            <Card
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: "16.3vw",
                borderRadius: 10,
                background: Theme.CARD,
                borderWidth: 0
              }}
              className="criclebox h-full"
            >
              <JointDisplacement movement={squat} />
            </Card>
          </Col>
          <Col>
            <Card
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: "16.3vw",
                borderRadius: 10,
                background: Theme.CARD,
                borderWidth: 0
              }}
              className="criclebox h-full"
            >
              <div
                className="aoc"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center"

                  // minHeight: "13vw",
                  // height: 'auto'
                }}
              >
                <Row
                  type="flex"
                  style={{ alignItems: "center" }}
                  justify="center"
                  gutter={10}
                >
                  <Col
                    align="center"
                    span={24}
                    style={{
                      fontWeight: "bold",
                      // marginTop: "5%",
                      // left: "7.5%",
                      width: "100%",
                      position: "relative"
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        marginTop: squat.video === null ? "23%" : "19%", // left: "21%",
                        // left: "21%",

                        position: "relative",
                        alignItems: "center"
                      }}
                    >
                      <ResultsSectionTitle section={sections[10]} />
                      {squat.video !== null && (
                        <video
                          controls
                          src={squat.video}
                          style={{
                            width: "10vw",
                            height: "19vw",
                            objectFit: "contain",
                            top: "1.5vw",
                            position: "relative"
                          }}
                        />
                      )}
                      {squat.video === null && (
                        <div
                          center
                          style={{ top: "5vw", position: "relative" }}
                        >
                          <div>
                            <Spin
                              size="large"
                              style={{ marginBottom: "1vw" }}
                            />
                          </div>
                          <Text>Video Processing</Text>
                          <div>
                            <Text className={styles.messageTextVideo}>
                              Check Back Soon
                            </Text>
                          </div>
                        </div>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default StandardSquatResults;

let textSize = 14;
let modalText = 16;
let compTitle = 20;
let compSectText = 18;
let modalMargin = 0;
let picLeft = "2%";
let avgRight = 160;
let minHeight = 160;

const createStyles = (theme) => ({
  container: {
    alignSelf: "center"
  },
  card: {
    backgroundColor: theme.WHITE,
    //   marginVertical: Theme.SIZES.BASE,
    borderWidth: 0,
    minHeight: minHeight,
    marginBottom: 1,
    marginHorizontal: 1
  },

  cardTitle: {
    flex: 1,
    alignSelf: "center",
    fontWeight: "500"
    // paddingTop: 5,
  },
  cardDescription: {
    // paddingTop: 5,
  },
  imageContainer: {
    borderRadius: 3,
    elevation: 1,
    overflow: "hidden",
    minHeight: 80
  },

  horizontalImage: {
    width: "40%",
    height: "40%"
    // position: "absolute",
    // left: "5%",
    // bottom: "1vw",
  },
  videoText: {
    color: theme.BLACK,
    fontSize: 12,
    left: "5.5%",
    marginTop: "1%",
    maxWidth: "60%",
    maxHeight: "50%",
    fontWeight: "bold"
  },
  horizontalStyles: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  verticalStyles: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
  },
  fullImage: {
    height: 215
  },

  messageText: {
    color: theme.TEXT,
    fontSize: ".75vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  typeText: {
    color: theme.TEXT,
    fontSize: ".65vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".65vw"
  },
  depthText: {
    color: theme.TEXT,
    fontSize: ".65vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  messageTextVideo: {
    color: theme.WHITE,
    fontSize: ".5vw",
    fontWeight: "bold",
    paddingVertical: 8,
    paddingRight: 4,
    fontFamily: "Arial"
  },
  compScoreText: {
    color: theme.BLACK,
    fontSize: textSize,
    fontWeight: "bold",
    // paddingVertical: 8,
    // paddingRight: 4,
    fontFamily: "Arial",
    // alignSelf: 'flex-end',

    top: 275
  },
  resultText: {
    color: theme.PRIMARY,
    fontSize: ".75vw",
    position: "relative",

    fontWeight: "bold"
    // marginRight: "5%",
  },
  sectionText: {
    fontWeight: "500",
    fontSize: 19,
    color: theme.BLACK
  },
  compensationTitle: {
    fontWeight: "500",
    fontSize: compTitle,
    marginTop: 30,
    marginBottom: 5,
    color: theme.POP
  },
  scoreText: {
    fontWeight: "500",
    fontSize: ".75vw",
    color: theme.PRIMARY
  },
  movementTitle: {
    textAlign: "left",
    fontSize: "1vw",
    fontWeight: "bold",
    // fontFamily: "ArialBold",
    color: theme.TEXT,
    position: "relative",
    // alignSelf: "center",
    left: ".5%"
  },
  movementScore: {
    fontWeight: "bold",
    fontSize: ".75vw",
    // alignSelf: "center",
    color: theme.PRIMARY,
    left: ".45vw",
    position: "relative"
    // marginTop: 5,
  },
  list: {
    position: "absolute",
    top: 20,
    zIndex: 20,
    flex: 1
  },

  modalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    top: modalMargin
    // elevation: 5,
  },
  bottomView: {
    flex: 1,
    justifyContent: "flex-end",
    top: 20,
    alignSelf: "center"
  },
  infoModalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4
    // elevation: 5,
  },
  button: {
    marginVertical: 15,
    width: 250,
    borderRadius: 15,
    // position: "relative",
    backgroundColor: "white"
  },
  buttontext: {
    color: theme.PRIMARY,
    fontWeight: "bold",
    paddingHorizontal: 5
  },
  modalText: {
    color: theme.WHITE,
    maxWidth: "85%",
    marginBottom: 20,
    textAlign: "center",
    fontSize: modalText,
    marginTop: 5
  },
  video: {
    alignSelf: "center",
    width: "100%",
    height: "95%",
    marginVertical: "10%",
    marginHorizontal: "2%",
    bottom: "8%",

    backgroundColor: "black"
  }
});
