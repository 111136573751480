import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Row, Typography, Modal, List, Spin } from "antd";
import Theme from "../../constants/Theme";
import { useDispatch, useSelector } from "react-redux";
import { getSingleAssessment } from "../../redux/actions/assessments";
import ResultsSectionTitle from "./ResultsSectionTitle";
import sections from "../../constants/sections";
import frontPost from "../../assets/images/FrontPosture.png";
import sidePost from "../../assets/images/SidePosture.png";
import { StyleSheet, css } from "aphrodite";
import ScoreIndicator from "../chart/ScoreIndicator";
import { Button } from "react-bootstrap";
import Compensations from "./Compensations";
import JointDisplacement from "./JointDiscplacement";
const { Text } = Typography;

var filteredKeys = [];
var array = [];

var sectionModal;

const Posture = ({ item, posture }) => {
  const { assessment } = useSelector((state) => state.assessments);

  const dispatch = useDispatch();
  const fetchAssessment = () =>
    dispatch(getSingleAssessment(assessment.id, assessment.client.id));
  const video = useRef(null);
  const [status, setStatus] = useState({});
  const [infoModalVisible, setInfoModalVisible] = useState(false);

  const imageHeightVw = 15; // Set th
  const imageAspectRatio = 1.5 / 1; // Set the aspect ratio
  // Calculate the image height in pixels based on the viewport width (10vw)
  const imageHeightPx = (10 / 100) * window.innerWidth;
  const imageWidthPx = imageAspectRatio * imageHeightPx;
  const leftOrRight = (difference) => {
    var direction = "Left";
    if (difference > 0) {
      direction = "Right";
    } else if (difference == 0) {
      return "0° Difference";
    }
    return `${direction} +${Math.abs(difference.toFixed(2))}°`;
  };
  const plusOrMinus = (position) => {
    if (position <= 0) {
      return `+${Math.abs(position).toFixed(2)}°`;
    } else {
      return `-${Math.abs(position).toFixed(2)}°`;
    }
  };
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        top: "5%",
        position: "relative",
      }}
    >
      <Row
        style={{ alignSelf: "center", position: "relative" }}
        gutter={[16, 16]}
      >
        <Col>
          <Card
            bordered={true}
            style={{
              zIndex: 100,
              flex: 1,
              position: "relative",
              width: "23.25vw",
              minHeight: '19vw',
              
              borderRadius: 10,
            }}
            className="criclebox h-full"
          >
            <Col align="center" style={{ position: "relative", top: '1vw' }}>
              <div
                style={{
                  // marginBottom: "1vw",
                  position: "relative",
                  // left: "7.4vw",
                  // right: "5%",
                  marginTop: "2vw",
                  bottom: "1vw",
                  width: "6vw",
                }}
              >
                <Text className={css(styles.movementTitle)}>Posture</Text>
              </div>
              <div style={{ position: "relative" }}>
                <ScoreIndicator
                  // style={{ position: "absolute" }}
                  score={Math.round(posture.posture_overall_score)}
                  size={0.05}
                  showText={true}
                  outerRadius={100}
                />
              </div>
            </Col>
          </Card>
        </Col>
        <Col>
          <Card
            bordered={true}
            style={{
              zIndex: 100,
              flex: 1,
              position: "relative",
              width: "23.25vw",
              minHeight: '19vw',

              borderRadius: 10,
            }}
            className="criclebox h-full"
          >
            <svg
              viewBox={`0 0 ${imageWidthPx} ${imageHeightPx}`}
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
                right: "4vw",
                top: '1.5vw',
                backgroundColor: "transparent", // Make the background transparent
              }}
            >
              <defs>
                <filter id="drop-shadow">
                  <feDropShadow
                    dx="2"
                    dy="6"
                    stdDeviation="6"
                    floodColor="rgba(0, 0, 0, 0.3)"
                  />
                </filter>
              </defs>
              <image
                href={frontPost}
                alt="Body"
                width={imageWidthPx}
                height={imageHeightPx}
                style={{
                  objectFit: "cover",
                  filter: "url(#drop-shadow)", // Apply the drop shadow filter
                }}
              />

              <line
                x1={(44 / 100) * imageWidthPx}
                y1={(25 / 100) * imageHeightPx - posture.shoulder_level_avg / 2}
                x2={(56.5 / 100) * imageWidthPx}
                y2={(25 / 100) * imageHeightPx + posture.shoulder_level_avg / 2}
                stroke={Theme.COLORS.SECONDARY}
                strokeWidth="4"
              />
              <line
                x1={(46 / 100) * imageWidthPx}
                y1={(50 / 100) * imageHeightPx - posture.hip_level_avg / 2}
                x2={(54 / 100) * imageWidthPx}
                y2={(50 / 100) * imageHeightPx + posture.hip_level_avg / 2}
                stroke={Theme.COLORS.SECONDARY}
                strokeWidth="4"
              />
              <line
                x1={(46 / 100) * imageWidthPx}
                y1={(70 / 100) * imageHeightPx - posture.knee_level_avg / 2}
                x2={(54 / 100) * imageWidthPx}
                y2={(70 / 100) * imageHeightPx + posture.knee_level_avg / 2}
                stroke={Theme.COLORS.SECONDARY}
                strokeWidth="4"
              />
              <circle
                cx={(44 / 100) * imageWidthPx}
                cy={(25 / 100) * imageHeightPx - posture.shoulder_level_avg / 2}
                r={(0.03 * imageWidthPx) / 2}
                fill={Theme.COLORS.GRAD4}
              />
              <circle
                cx={(56.5 / 100) * imageWidthPx}
                cy={(25 / 100) * imageHeightPx + posture.shoulder_level_avg / 2}
                r={(0.03 * imageWidthPx) / 2}
                fill={Theme.COLORS.GRAD4}
              />
              <circle
                cx={(46 / 100) * imageWidthPx}
                cy={(50 / 100) * imageHeightPx - posture.hip_level_avg / 2}
                r={(0.03 * imageWidthPx) / 2}
                fill={Theme.COLORS.GRAD4}
              />
              <circle
                cx={(54 / 100) * imageWidthPx}
                cy={(50 / 100) * imageHeightPx + posture.hip_level_avg / 2}
                r={(0.03 * imageWidthPx) / 2}
                fill={Theme.COLORS.GRAD4}
              />
              <circle
                cx={(46 / 100) * imageWidthPx}
                cy={(70 / 100) * imageHeightPx - posture.knee_level_avg / 2}
                r={(0.03 * imageWidthPx) / 2}
                fill={Theme.COLORS.GRAD4}
              />
              <circle
                cx={(54 / 100) * imageWidthPx}
                cy={(70 / 100) * imageHeightPx + posture.knee_level_avg / 2}
                r={(0.03 * imageWidthPx) / 2}
                fill={Theme.COLORS.GRAD4}
              />
            </svg>
            <Col style={{ position: "absolute", left: "13vw", bottom: "20%" }}>
              <div>
              <ResultsSectionTitle section={sections[4]} />
              </div>
              <div style={{  marginTop: "2.5%", marginBottom: "2.5%",  }}>
                <Text
                  style={{
                    color: Theme.COLORS.BLACK,
                    fontSize: ".75vw",
                    fontWeight: "bold",
                    // fontFamily: "ArialBold",
                  }}
                >
                  Shoulders
                </Text>
                <div>
                  <Text
                    style={{
                      color: Theme.COLORS.PRIMARY,
                      fontSize: ".75vw",
                      fontWeight: "bold",
                    }}
                  >
                    {leftOrRight(posture.shoulder_level_avg)}
                  </Text>
                </div>
              </div>
              <div style={{  marginTop: "2.5%", }}>
                <Text
                  style={{
                    color: Theme.COLORS.BLACK,
                    fontSize: ".75vw",
                    fontWeight: "bold",
                    // fontFamily: "ArialBold",
                  }}
                >
                  Hips
                </Text>
                <div>
                  <Text
                    style={{
                      color: Theme.COLORS.PRIMARY,
                      fontSize: ".75vw",
                      fontWeight: "bold",
                    }}
                  >
                    {leftOrRight(posture.hip_level_avg)}
                  </Text>
                </div>
              </div>
              <div style={{  marginTop: "2.5%",  }}>
                <Text
                  style={{
                    color: Theme.COLORS.BLACK,
                    fontSize: ".75vw",
                    fontWeight: "bold",
                    // fontFamily: "ArialBold",
                  }}
                >
                  Knees
                </Text>
                <div>
                  <Text
                    style={{
                      color: Theme.COLORS.PRIMARY,
                      fontSize: ".75vw",
                      fontWeight: "bold",
                    }}
                  >
                    {leftOrRight(posture.knee_level_avg)}
                  </Text>
                </div>
              </div>
            </Col>
          </Card>
        </Col>
        <Col>
          <Card
            bordered={true}
            style={{
              zIndex: 100,
              flex: 1,
              position: "relative",
              width: "23.25vw",
              minHeight: '19vw',

              borderRadius: 10,
            }}
            className="criclebox h-full"
          >
            <svg
              viewBox={`0 0 ${imageWidthPx} ${imageHeightPx}`}
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
                right: "4vw",
                top: '1.5vw',
                backgroundColor: "transparent", // Make the background transparent
              }}
            >
              <defs>
                <filter id="drop-shadow">
                  <feDropShadow
                    dx="2"
                    dy="6"
                    stdDeviation="6"
                    floodColor="rgba(0, 0, 0, 0.3)"
                  />
                </filter>
              </defs>
              <image
                href={sidePost}
                alt="Body"
                width={imageWidthPx}
                height={imageHeightPx}
                style={{
                  objectFit: "cover",
                  filter: "url(#drop-shadow)", // Apply the drop shadow filter
                }}
              />

              <line
                x1={(43 / 100) * imageWidthPx}
                y1={-10 * imageHeightPx - posture.shoulder_level_avg / 2}
                x2={(57 / 100) * imageWidthPx}
                y2={21 * imageHeightPx + posture.shoulder_level_avg / 2}
                stroke={Theme.COLORS.SECONDARY}
                strokeWidth="3"
              />

              <circle
                cx={0.48 * imageWidthPx}
                cy={0.93 * imageHeightPx}
                r={(0.03 * imageWidthPx) / 2}
                fill={Theme.COLORS.GRAD4}
              />
              <circle
                cx={0.48 * imageWidthPx - posture.side_knee_position / 2}
                cy={0.8 * imageHeightPx}
                r={(0.03 * imageWidthPx) / 2}
                fill={Theme.COLORS.GRAD4}
              />
              <circle
                cx={0.48 * imageWidthPx - posture.side_hip_position / 2}
                cy={0.5 * imageHeightPx}
                r={(0.03 * imageWidthPx) / 2}
                fill={Theme.COLORS.GRAD4}
              />
              <circle
                cx={0.48 * imageWidthPx - posture.side_shoulder_position / 2}
                cy={0.25 * imageHeightPx}
                r={(0.03 * imageWidthPx) / 2}
                fill={Theme.COLORS.GRAD4}
              />
              <circle
                cx={0.48 * imageWidthPx - posture.side_head_position / 2}
                cy={0.09 * imageHeightPx}
                r={(0.03 * imageWidthPx) / 2}
                fill={Theme.COLORS.GRAD4}
              />
            </svg>
            <Col style={{ position: "absolute", left: "12.25vw", bottom: "25%" }}>
              <ResultsSectionTitle section={sections[5]} />
              <Row>
              <div style={{  marginTop: "5%", marginBottom: "5%", }}>
                <Text
                  style={{
                    color: Theme.COLORS.BLACK,
                    fontSize: ".75vw",
                    fontWeight: "bold",
                
                  }}
                >
                  Head
                </Text>
                <div>
                  <Text
                    style={{
                      color: Theme.COLORS.PRIMARY,
                      fontSize: ".75vw",
                      fontWeight: "bold",
                    }}
                  >
                    {plusOrMinus(posture.side_head_position)}
                  </Text>
                </div>
                </div>
              <div style={{ marginTop: "5%", marginBottom: "5%", marginLeft: '1vw' }}>
                <Text
                  style={{
                    color: Theme.COLORS.BLACK,
                    fontSize: ".75vw",
                    fontWeight: "bold",
       
                  }}
                >
                  Shoulders
                </Text>
                <div>
                  <Text
                    style={{
                      color: Theme.COLORS.PRIMARY,
                      fontSize: ".75vw",
                      fontWeight: "bold",
                    }}
                  >
                    {plusOrMinus(posture.side_shoulder_position)}
                  </Text>
                </div>
              </div>
              </Row>
              <Row>
              <div style={{  marginTop: "5%", marginBottom: "5%",  }}>
                <Text
                  style={{
                    color: Theme.COLORS.BLACK,
                    fontSize: ".75vw",
                    fontWeight: "bold",
                    // fontFamily: "ArialBold",
                  }}
                >
                  Hips
                </Text>
                <div>
                  <Text
                    style={{
                      color: Theme.COLORS.PRIMARY,
                      fontSize: ".75vw",
                      fontWeight: "bold",
                    }}
                  >
                    {plusOrMinus(posture.side_hip_position)}
                  </Text>
                </div>
              </div>
              <div style={{ marginTop: "5%", marginLeft: '1vw'  }}>
                <Text
                  style={{
                    color: Theme.COLORS.BLACK,
                    fontSize: ".75vw",
                    fontWeight: "bold",
                    // fontFamily: "ArialBold",
                  }}
                >
                  Knees
                </Text>
                <div>
                  <Text
                    style={{
                      color: Theme.COLORS.PRIMARY,
                      fontSize: ".75vw",
                      fontWeight: "bold",
                    }}
                  >
                    {plusOrMinus(posture.side_knee_position)}
                  </Text>
                </div>
              </div>
              </Row>
            </Col>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Posture;

let textSize = 14;
let modalText = 16;
let compTitle = 20;
let compSectText = 18;
let modalMargin = 0;
let picLeft = "2%";
let avgRight = 160;
let minHeight = 160;

const styles = StyleSheet.create({
  container: {
    alignSelf: "center",
  },
  card: {
    backgroundColor: Theme.COLORS.WHITE,
    //   marginVertical: Theme.SIZES.BASE,
    borderWidth: 0,
    minHeight: minHeight,
    marginBottom: 1,
    marginHorizontal: 1,
  },

  cardTitle: {
    flex: 1,
    alignSelf: "center",
    fontWeight: "500",
    // paddingTop: 5,
  },
  cardDescription: {
    // paddingTop: 5,
  },
  imageContainer: {
    borderRadius: 3,
    elevation: 1,
    overflow: "hidden",
    minHeight: 80,
  },

  horizontalImage: {
    width: "40%",
    height: "40%",
    // position: "absolute",
    // left: "5%",
    // bottom: "1vw",
  },
  videoText: {
    color: Theme.COLORS.BLACK,
    fontSize: 12,
    left: "5.5%",
    marginTop: "1%",
    maxWidth: "60%",
    maxHeight: "50%",
    fontWeight: "bold",
  },
  horizontalStyles: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  verticalStyles: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
  },
  fullImage: {
    height: 215,
  },

  messageText: {
    color: Theme.COLORS.BLACK,
    fontSize: ".75vw",
    position: "relative",
    right: "35%",
    fontWeight: "bold",
    // paddingRight: 4,
  },
  messageTextVideo: {
    color: Theme.COLORS.BLACK,
    fontSize: ".5vw",
    fontWeight: "bold",
    paddingVertical: 8,
    paddingRight: 4,
    fontFamily: "Arial",
  },
  compScoreText: {
    color: Theme.COLORS.BLACK,
    fontSize: textSize,
    fontWeight: "bold",
    // paddingVertical: 8,
    // paddingRight: 4,
    fontFamily: "Arial",
    // alignSelf: 'flex-end',

    top: 275,
  },
  resultText: {
    color: Theme.COLORS.PRIMARY,
    fontSize: ".75vw",
    position: "relative",
    right: "35%",
    fontWeight: "bold",
    right: "50%",
  },
  sectionText: {
    fontWeight: "500",
    fontSize: 19,
    color: Theme.COLORS.BLACK,
  },
  compensationTitle: {
    fontWeight: "500",
    fontSize: compTitle,
    marginTop: 30,
    marginBottom: 5,
    color: Theme.COLORS.POP,
  },
  scoreText: {
    fontWeight: "500",
    fontSize: ".75vw",
    color: Theme.COLORS.PRIMARY,
  },
  movementTitle: {
    alignSelf: "center",
    fontSize: "1.5vw",
    fontWeight: "bold",
    // fontFamily: "ArialBold",
    color: Theme.COLORS.BLACK,

    alignSelf: "center",
  },
  movementScore: {
    fontWeight: "bold",
    fontSize: ".75vw",
    // alignSelf: "center",
    color: Theme.COLORS.PRIMARY,
    left: ".45vw",
    position: "relative",
    // marginTop: 5,
  },
  list: {
    position: "absolute",
    top: 20,
    zIndex: 20,
    flex: 1,
  },

  modalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    top: modalMargin,
    // elevation: 5,
  },
  bottomView: {
    flex: 1,
    justifyContent: "flex-end",
    top: 20,
    alignSelf: "center",
  },
  infoModalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    // elevation: 5,
  },
  button: {
    marginVertical: 15,
    width: 250,
    borderRadius: 15,
    // position: "relative",
    backgroundColor: "white",
  },
  buttontext: {
    color: Theme.COLORS.PRIMARY,
    fontWeight: "bold",
    paddingHorizontal: 5,
  },
  modalText: {
    color: Theme.COLORS.WHITE,
    maxWidth: "85%",
    marginBottom: 20,
    textAlign: "center",
    fontSize: modalText,
    marginTop: 5,
  },
  video: {
    alignSelf: "center",
    width: "100%",
    height: "95%",
    marginVertical: "10%",
    marginHorizontal: "2%",
    bottom: "8%",

    backgroundColor: "black",
  },
});
