import React, { useState } from "react";
import { Col, Row, Typography } from "antd";
import { useSelector } from "react-redux";
import { IoInformationCircleOutline } from "react-icons/io5";

import footwidth from "../../assets/images/footwidth.png";
import footsym from "../../assets/images/footsym.png";
import footcontact from "../../assets/images/footcontact.png";
import ScoreCircle from "./ScoreCircle";
import ProgressProvider from "../chart/ProgressProvider";
import { CircularProgressbar } from "react-circular-progressbar";
const { Text } = Typography;

const CMJResults = ({ cmj, onlyCmj }) => {
  const { user, Theme } = useSelector((state) => state.auth);
  const [modal, setModalVisible] = useState(false);
  const [landingModal, setLandingModalVisible] = useState(false);

  const styles = createStyles(Theme);
  const passOrFail = (item) => {
    return item === true ? "Pass" : "Fail";
  };

  return (
    <Col
      align="middle"
      justify="center"
      style={{
        alignItems: "center",
        display: "flex",
        top: "5%",
        position: "relative",
        left: ".5vw"
      }}
    >
      <Col align="center" justify="center">
        <Row
          style={{ alignSelf: "center", position: "relative" }}
          gutter={[16, 16]}
        >
          <Col align="center">
            <Col
              // bordered={true}
              align="center"
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: 300,
                display: "flex",
                borderRadius: 10,
                backgroundColor: Theme.CARD,
                boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.025)",
                marginBottom: "15%"
              }}
              className="criclebox h-full"
            >
              <Col
                align="start"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  top: "3vw",
                  position: "relative",
                  width: 325,
                  top: 55

                  // minHeight: "13vw",
                  // height: 'auto'
                }}
              >
                <Row
                  align="center"
                  style={{ position: "relative", width: "20vw" }}
                >
                  <Col
                    align={"start"}
                    style={{
                      position: "relative",
                      right: "10%",
                      marginRight: 20
                    }}
                  >
                    <Text style={styles.movementTitle}>COUNTER</Text>
                    <div style={{ bottom: ".15vw", position: "relative" }}>
                      <Text style={styles.movementTitle}>MOVEMENT</Text>
                    </div>
                    <div style={{ bottom: ".25vw", position: "relative" }}>
                      <Text style={styles.movementTitle}>JUMP</Text>
                    </div>
                  </Col>
                  <Col
                    align="middle"
                    style={{
                      position: "relative",
                      left: "10%",
                      bottom: ".5vw"
                    }}
                  >
                    <ScoreCircle
                      score={Math.round(cmj.cmj_overall_score)}
                      height={55}
                    />
                    <div>
                      <Text style={styles.messageText}>Overall</Text>
                    </div>
                  </Col>
                </Row>

                <Col
                  name="scores"
                  align="center"
                  justify="center"
                  style={{
                    marginBottom: "7vw",
                    position: "relative",
                    top: 15
                    // width: "20vw",
                  }}
                >
                  <Col
                    align="middle"
                    justify={"center"}
                    style={{ marginTop: "3vw" }}
                  >
                    <Col
                      align="middle"
                      justify={"center"}
                      style={{
                        position: "relative",

                        bottom: "1vw"
                      }}
                    >
                      {cmj.max_jump_score != -1 ? (
                        <ScoreCircle
                          score={Math.round(cmj.max_jump_score)}
                          height={65}
                        />
                      ) : (
                        <ProgressProvider valueStart={0} valueEnd={0}>
                          {(value) => (
                            <CircularProgressbar
                              styles={{
                                root: { width: "100%", height: "4.5vw" },
                                path: {
                                  stroke: "gray",
                                  strokeLinecap: "butt",
                                  transition: "stroke-dashoffset 0.5s ease 0s"
                                },
                                trail: {
                                  stroke: Theme.INPUT,
                                  strokeLinecap: "butt"
                                },
                                text: {
                                  fill: Theme.TEXT,
                                  fontSize: "1.5vw"
                                }
                              }}
                              value={value}
                              text={"N/A"}
                            />
                          )}
                        </ProgressProvider>
                      )}
                    </Col>
                    <Col
                      align="middle"
                      justify={"center"}
                      style={{
                        position: "relative",
                        top: ".25vw"
                      }}
                    >
                      <div>
                        <Text style={styles.mainMessageText}>Jump Height</Text>
                      </div>
                      <div>
                        {user?.profile?.measurement === "Imperial" ? (
                          <Text style={styles.angleText}>
                            {cmj.max_jump_height_inches.toFixed(2)}"
                          </Text>
                        ) : (
                          <Text style={styles.angleText}>
                            {(cmj.max_jump_height_inches * 2.54).toFixed(2)}cm
                          </Text>
                        )}
                      </div>
                    </Col>
                    <Col
                      align="middle"
                      justify={"center"}
                      style={{
                        position: "relative",
                        marginTop: 35
                        // left: "20%",
                      }}
                    >
                      <div>
                        <Text style={styles.mainMessageText}>Flight Time</Text>
                      </div>
                      <div>
                        <Text style={styles.angleText}>
                          {cmj.flight_time_seconds.toFixed(2)}s
                        </Text>
                      </div>
                    </Col>
                    <Col
                      align="middle"
                      justify={"center"}
                      style={{
                        position: "relative",
                        marginTop: 35
                        // left: "20%",
                      }}
                    >
                      <div>
                        <Text style={styles.mainMessageText}>
                          Takeoff Depth
                        </Text>
                      </div>
                      <div>
                        <Text style={styles.angleText}>
                          {cmj?.depth_percentage?.toFixed(2)}%
                        </Text>
                      </div>
                    </Col>
                  </Col>
                </Col>
              </Col>
            </Col>
          </Col>

          <Col>
            <Col
              style={{
                zIndex: 100,
                // flex: 1,
                position: "relative",
                width: 437.5,
                borderRadius: 10,
                background: Theme.CARD,
                borderWidth: 0,
                boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.025)",
                paddingBottom: 20
              }}
              align={"middle"}
              justify={"center"}
              className="criclebox h-full"
            >
              <Col
                align="middle"
                style={{
                  position: "absolute",
                  right: "2.5%",
                  top: 25
                }}
              >
                <ScoreCircle score={Math.round(cmj.LESS.score)} height={55} />
              </Col>

              <Col>
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: "600",
                    alignSelf: "center",
                    position: "relative",
                    color: Theme.TEXT,
                    top: 60
                  }}
                >
                  LANDING MECHANICS
                </Text>
              </Col>
              <Row
                align={"center"}
                justify={"center"}
                style={{
                  position: "relative",
                  marginTop: 85,
                  paddingBottom: 10
                }}
              >
                {/* left column */}
                <Col align={"center"} justify={"center"}>
                  <Col column center middle style={{}}>
                    <img
                      src={footwidth}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Foot Width</Text>
                    </Col>
                    <Col style={{ bottom: 10, position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Initial Contact</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: 10, position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.stance_width
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: 13
                        }}
                      >
                        {cmj.LESS.stance_description}
                      </Text>
                    </Col>
                  </Col>
                  <Col column center middle style={{ marginTop: ".5vw" }}>
                    <img
                      src={require("../../assets/images/knee.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Knee Flexion</Text>
                    </Col>
                    <Col style={{ bottom: 10, position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Initial Contact</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: 10, position: "relative" }}
                    >
                      {cmj.LESS.knee_flexion_IC ? (
                        <Text
                          style={{
                            fontSize: 13,
                            color: cmj.LESS.knee_flexion_IC
                              ? Theme.GRAD6
                              : Theme.RED,
                            fontWeight: "bold"
                          }}
                        >
                          Flexed
                        </Text>
                      ) : (
                        <Text
                          style={{
                            fontSize: 13,
                            color: cmj.LESS.knee_flexion_IC
                              ? Theme.GRAD6
                              : Theme.RED,
                            fontWeight: "bold"
                          }}
                        >
                          Extended
                        </Text>
                      )}
                    </Col>
                    <Col style={{ bottom: 15, position: "relative" }}></Col>
                  </Col>
                  <Col column center middle style={{ marginTop: ".5vw" }}>
                    <img
                      src={require("../../assets/images/trunklatflex.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Trunk Lateral</Text>
                    </Col>
                    <Col style={{ bottom: 10, position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Initial Contact</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: 10, position: "relative" }}
                    ></Col>
                    <Col style={{ bottom: 15, position: "relative" }}>
                      {cmj.LESS.trunk_lateral_flexion_IC ? (
                        <Text
                          style={{
                            color: cmj.LESS.trunk_lateral_flexion_IC
                              ? Theme.GRAD6
                              : Theme.RED,
                            fontWeight: "bold",
                            fontSize: 13
                          }}
                        >
                          Absent
                        </Text>
                      ) : (
                        <Text
                          style={{
                            color: cmj.LESS.trunk_lateral_flexion_IC
                              ? Theme.GRAD6
                              : Theme.RED,
                            fontWeight: "bold",
                            fontSize: 13
                          }}
                        >
                          Present
                        </Text>
                      )}
                    </Col>
                  </Col>
                  <Col
                    column
                    center
                    middle
                    style={{ marginTop: ".5vw", left: "4vw" }}
                  >
                    <img
                      src={require("../../assets/images/maxtrunkflex.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>
                        Trunk Flexion at
                      </Text>
                    </Col>
                    <Col style={{ bottom: 10, position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>
                        Max Knee Flexion
                      </Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: 10, position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.trunk_flexion_max
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: 13
                        }}
                      >
                        {Math.round(cmj.LESS.trunk_flexion_displacement)}°
                      </Text>
                    </Col>
                  </Col>
                </Col>
                {/* middle column */}
                <Col style={{ marginLeft: "3vw", marginRight: "3vw" }}>
                  <Col column center middle style={{}}>
                    <img
                      src={footsym}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Foot Symmetry</Text>
                    </Col>
                    <Col style={{ bottom: 10, position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Initial Contact</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: 10, position: "relative" }}
                    >
                      {cmj.LESS.stance_symmetrical ? (
                        <Text
                          style={{
                            color: cmj.LESS.stance_symmetrical
                              ? Theme.GRAD6
                              : Theme.RED,
                            fontWeight: "bold",
                            fontSize: 13
                          }}
                        >
                          Symmetrical
                        </Text>
                      ) : (
                        <Text
                          style={{
                            color: cmj.LESS.stance_symmetrical
                              ? Theme.GRAD6
                              : Theme.RED,
                            fontWeight: "bold",
                            fontSize: 13
                          }}
                        >
                          Asymmetrical
                        </Text>
                      )}
                    </Col>
                    <Col style={{ bottom: 15, position: "relative" }}></Col>
                  </Col>
                  <Col column center middle style={{ marginTop: ".5vw" }}>
                    <img
                      src={require("../../assets/images/hippie.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Hip Flexion</Text>
                    </Col>
                    <Col style={{ bottom: 10, position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Initial Contact</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: 10, position: "relative" }}
                    >
                      {cmj.LESS.hip_flexion_IC ? (
                        <Text
                          style={{
                            color: cmj.LESS.hip_flexion_IC
                              ? Theme.GRAD6
                              : Theme.RED,
                            fontWeight: "bold",
                            fontSize: 13
                          }}
                        >
                          Flexed
                        </Text>
                      ) : (
                        <Text
                          style={{
                            color: cmj.LESS.hip_flexion_IC
                              ? Theme.GRAD6
                              : Theme.RED,
                            fontWeight: "bold",
                            fontSize: 13
                          }}
                        >
                          Extended
                        </Text>
                      )}
                    </Col>
                    <Col style={{ bottom: 15, position: "relative" }}></Col>
                  </Col>
                  <Col column center middle style={{ marginTop: ".5vw" }}>
                    <img
                      src={require("../../assets/images/kneemax.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Max Knee</Text>
                    </Col>
                    <Col style={{ bottom: 10, position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Flexion</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: 10, position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.knee_flexion_max
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: 13
                        }}
                      >
                        {Math.round(cmj.LESS.knee_flexion_displacement)}°
                      </Text>
                    </Col>
                  </Col>
                </Col>

                {/* right column */}
                <Col>
                  <Col column center middle style={{}}>
                    <img
                      src={footcontact}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Foot Contact</Text>
                    </Col>
                    <Col style={{ bottom: 10, position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Pattern</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: 10, position: "relative" }}
                    >
                      {cmj.LESS.toe_to_heel ? (
                        <Text
                          style={{
                            color: cmj.LESS.toe_to_heel
                              ? Theme.GRAD6
                              : Theme.RED,
                            fontWeight: "bold",
                            fontSize: 13
                          }}
                        >
                          Toe
                        </Text>
                      ) : (
                        <Text
                          style={{
                            color: cmj.LESS.toe_to_heel
                              ? Theme.GRAD6
                              : Theme.RED,
                            fontWeight: "bold",
                            fontSize: 13
                          }}
                        >
                          Heel
                        </Text>
                      )}
                    </Col>
                    <Col style={{ bottom: 15, position: "relative" }}></Col>
                  </Col>
                  <Col column center middle style={{ marginTop: ".5vw" }}>
                    <img
                      src={require("../../assets/images/trunk.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Trunk Flexion</Text>
                    </Col>
                    <Col style={{ bottom: 10, position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Initial Contact</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: 10, position: "relative" }}
                    >
                      {cmj.LESS.trunk_flexion_IC ? (
                        <Text
                          style={{
                            color: cmj.LESS.trunk_flexion_IC
                              ? Theme.GRAD6
                              : Theme.RED,
                            fontWeight: "bold",
                            fontSize: 13
                          }}
                        >
                          Flexed
                        </Text>
                      ) : (
                        <Text
                          style={{
                            color: cmj.LESS.trunk_flexion_IC
                              ? Theme.GRAD6
                              : Theme.RED,
                            fontWeight: "bold",
                            fontSize: 13
                          }}
                        >
                          Extended
                        </Text>
                      )}
                    </Col>
                    <Col style={{ bottom: 15, position: "relative" }}>
                      <Text style={{ color: Theme.TEXT2, fontSize: 10 }}></Text>
                    </Col>
                  </Col>
                  <Col column center middle style={{ marginTop: ".5vw" }}>
                    <img
                      src={require("../../assets/images/valgus.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Total Knee</Text>
                    </Col>
                    <Col style={{ bottom: 10, position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>Valgus</Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: 10, position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.knee_valgus_max
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: 13
                        }}
                      >
                        L - {Math.round(cmj.LESS.knee_valgus_displacement[0])}
                        °, R -{" "}
                        {Math.round(cmj.LESS.knee_valgus_displacement[1])}°
                      </Text>
                    </Col>
                  </Col>
                  <Col
                    column
                    center
                    middle
                    style={{ marginTop: ".5vw", right: "4vw" }}
                  >
                    <img
                      src={require("../../assets/images/hipmaxflex.png")}
                      style={styles.horizontalImage}
                      resizeMode="contain"
                    />
                    <Col>
                      <Text style={styles.mechanicsTitle}>Hip Flexion at</Text>
                    </Col>
                    <Col style={{ bottom: 10, position: "relative" }}>
                      <Text style={styles.mechanicsTitle}>
                        Max Knee Flexion
                      </Text>
                    </Col>
                    <Col
                      column
                      center
                      middle
                      style={{ bottom: 10, position: "relative" }}
                    >
                      <Text
                        style={{
                          color: cmj.LESS.hip_flexion_max
                            ? Theme.GRAD6
                            : Theme.RED,
                          fontWeight: "bold",
                          fontSize: 13
                        }}
                      >
                        {Math.round(cmj.LESS.hip_flexion_displacement)}°
                      </Text>
                    </Col>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Col>
          <Col></Col>
        </Row>
        {onlyCmj && (
          <Col
            align={"middle"}
            justify={"center"}
            style={{ marginTop: 35, marginRight: 12 }}
          >
            <Col
              align={"middle"}
              justify={"center"}
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: 750,
                borderRadius: 10,
                background: Theme.CARD,
                borderWidth: 0
              }}
              className="criclebox h-full"
            >
              <Col align="start" justify="left">
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: "600",
                    alignSelf: "start",
                    position: "relative",
                    color: Theme.TEXT,
                    top: 30,
                    left: 75
                  }}
                >
                  LANDING ANGLES
                </Text>
              </Col>
              <Row
                align={"middle"}
                justify={"center"}
                style={{ marginTop: 20, paddingBottom: 20 }}
              >
                <Row align={"middle"} justify={"center"}>
                  <Row
                    align={"middle"}
                    justify={"center"}
                    style={{ marginTop: "1vw", marginRight: 15 }}
                  >
                    <Col align="start">
                      <Text style={styles.rawMessageText}>&nbsp;</Text>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Knee (L)</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Knee (R)</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Hip (L)</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Hip (R)</Text>
                      </Col>
                      {/* <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Ankle (L)</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Ankle (R)</Text>
                      </Col> */}
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Trunk Flexion</Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawMessageText}>Trunk Lateral</Text>
                      </Col>
                    </Col>
                    <Col
                      align="center"
                      style={{
                        marginLeft: "3vw",
                        marginTop: ".75vw",
                        marginRight: 15
                      }}
                    >
                      <Text style={styles.rawMessageText}>Initial Contact</Text>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.initial_contact.l_knee_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.initial_contact.r_knee_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.initial_contact.l_hip_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.initial_contact.r_hip_flexion)}°
                        </Text>
                      </Col>
                      {/* <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.initial_contact.l_ankle)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.initial_contact.r_ankle)}°
                        </Text>
                      </Col> */}
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.initial_contact.trunk_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(
                            cmj.initial_contact.trunk_lateral_flexion
                          )}
                          °
                        </Text>
                      </Col>
                    </Col>
                  </Row>
                  <Row
                    align={"middle"}
                    justify={"center"}
                    style={{ marginTop: "1vw", marginRight: 15 }}
                  >
                    <Col
                      align="center"
                      style={{ marginLeft: "3vw", marginTop: "1vw" }}
                    >
                      <Text style={styles.rawMessageText}>Max Knee Flex</Text>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.l_knee_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.r_knee_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.l_hip_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.r_hip_flexion)}°
                        </Text>
                      </Col>
                      {/* <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.l_ankle)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.r_ankle)}°
                        </Text>
                      </Col> */}
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.trunk_flexion)}°
                        </Text>
                      </Col>
                      <Col style={{ marginTop: ".25vw" }}>
                        <Text style={styles.rawResultText}>
                          {Math.round(cmj.max_flexion.trunk_lateral_flexion)}°
                        </Text>
                      </Col>
                    </Col>
                  </Row>
                </Row>
              </Row>
            </Col>
          </Col>
        )}
      </Col>
    </Col>
  );
};

export default CMJResults;

let textSize = 14;
let modalText = 16;
let compTitle = 20;
let modalMargin = 0;
let minHeight = 160;

const createStyles = (theme) => ({
  container: {
    alignSelf: "center"
  },
  card: {
    backgroundColor: theme.WHITE,
    //   marginVertical: Theme.SIZES.BASE,
    borderWidth: 0,
    minHeight: minHeight,
    marginBottom: 1,
    marginHorizontal: 1
  },

  cardTitle: {
    flex: 1,
    alignSelf: "center",
    fontWeight: "500"
    // paddingTop: 5,
  },
  cardDescription: {
    // paddingTop: 5,
  },
  imageContainer: {
    borderRadius: 3,
    elevation: 1,
    overflow: "hidden",
    minHeight: 80
  },

  horizontalImage: {
    width: 35,
    height: 35,
    top: ".25vw",
    position: "relative"
    // position: "absolute",
    // left: "5%",
    // bottom: "1vw",
  },
  videoText: {
    color: theme.BLACK,
    fontSize: 12,
    left: "5.5%",
    marginTop: "1%",
    maxWidth: "60%",
    maxHeight: "50%",
    fontWeight: "bold"
  },
  horizontalStyles: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  verticalStyles: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
  },
  fullImage: {
    height: 215
  },

  messageText: {
    color: theme.TEXT,
    fontSize: 15,
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  mainMessageText: {
    color: theme.TEXT,
    fontSize: 15,
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  mechanicsTitle: {
    color: theme.TEXT,
    fontSize: 12,
    position: "relative",

    fontWeight: "bold"
  },
  rawMessageText: {
    color: theme.TEXT,
    fontSize: 14,
    position: "relative",

 
  },
  rawResultText: {
    color: theme.BLUE,
    fontSize: 15,
    position: "relative",

    fontWeight: "bold"
  },
  angleText: {
    color: theme.BLUE,
    fontSize: 15,
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  typeText: {
    color: theme.TEXT,
    fontSize: ".55vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".75vw"
  },
  messageTextVideo: {
    color: theme.WHITE,
    fontSize: ".5vw",
    fontWeight: "bold",
    paddingVertical: 8,
    paddingRight: 4,
    fontFamily: "Arial"
  },
  compScoreText: {
    color: theme.BLACK,
    fontSize: textSize,
    fontWeight: "bold",
    // paddingVertical: 8,
    // paddingRight: 4,
    fontFamily: "Arial",
    // alignSelf: 'flex-end',

    top: 275
  },
  resultText: {
    color: theme.PRIMARY,
    fontSize: ".75vw",
    position: "relative",

    fontWeight: "bold"
    // marginRight: "5%",
  },
  sectionText: {
    fontWeight: "500",
    fontSize: 19,
    color: theme.BLACK
  },
  compensationTitle: {
    fontWeight: "500",
    fontSize: compTitle,
    marginTop: 30,
    marginBottom: 5,
    color: theme.POP
  },
  scoreText: {
    fontWeight: "500",
    fontSize: ".75vw",
    color: theme.PRIMARY
  },
  movementTitle: {
    textAlign: "left",
    fontSize: 15,
    fontWeight: "bold",
    // fontFamily: "ArialBold",
    color: theme.TEXT,
    position: "relative",
    // alignSelf: "center",
    left: ".5%"
  },
  movementScore: {
    fontWeight: "bold",
    fontSize: ".75vw",
    // alignSelf: "center",
    color: theme.PRIMARY,
    left: ".45vw",
    position: "relative"
    // marginTop: 5,
  },
  list: {
    position: "absolute",
    top: 20,
    zIndex: 20,
    flex: 1
  },

  modalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    top: modalMargin
    // elevation: 5,
  },
  bottomView: {
    flex: 1,
    justifyContent: "flex-end",
    top: 20,
    alignSelf: "center"
  },
  infoModalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4
    // elevation: 5,
  },
  button: {
    marginVertical: 15,
    width: 250,
    borderRadius: 15,
    // position: "relative",
    backgroundColor: "white"
  },
  buttontext: {
    color: theme.PRIMARY,
    fontWeight: "bold",
    paddingHorizontal: 5
  },
  modalText: {
    color: theme.WHITE,
    maxWidth: "85%",
    marginBottom: 20,
    textAlign: "center",
    fontSize: modalText,
    marginTop: 5
  },
  video: {
    alignSelf: "center",
    width: "100%",
    height: "95%",
    marginVertical: "10%",
    marginHorizontal: "2%",
    bottom: "8%",

    backgroundColor: "black"
  },
  paragraphHeight: {
    fontSize: ".85vw",
    color: theme.TEXT
  }
});
