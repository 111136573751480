import React, { useEffect, useState } from "react";
//import { Container, Row, Col } from "react-bootstrap";
import BlogCard from "./blog-card";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getProgress, getProgressJC } from "../redux/actions/clients";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts";
import { Card, Select, Spin, Col, Modal, Row, Typography, Tabs } from "antd";

import {
  DeleteOutlined,
  ExclamationCircleOutlined,
  LineChartOutlined
} from "@ant-design/icons";
import CustomSelect from "./common/DropDown";
import NestedCustomSelect from "./common/NestedDropDown";

const { Text } = Typography;
const Progress = ({ clientID }) => {
  const { TabPane } = Tabs;

  const dispatch = useDispatch();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const { progress } = useSelector((state) => state.clients);
  const { progress_jc } = useSelector((state) => state.clients);
  const { user, Theme } = useSelector((state) => state.auth);
  const { Option } = Select;
  const [state, setState] = React.useState({});
  const { client } = useSelector((state) => state.clients);
  const [selectedProgType, setSelectedProgType] = useState("functional");
  const [selectedComparison, setSelectedComparison] = useState("hip_abduction");
  const [selectedMetric, setSelectedMetric] = useState("overall_score");
  const [formattedData, setFormattedData] = useState([]);

  useEffect(() => {
    dispatch(getProgress(id));
    dispatch(getProgressJC(id));
  }, []);
  useEffect(() => {
    if (progress.length > 0) {
      setLoading(false);
      setState((prevState) => ({
        ...prevState,
        cmjSelected: chartResults["cmj_report"]["cmj_overall_score"],
        squatSelected: chartResults["squat_report"]["squat_overall_score"],
        standardSquatSelected:
          chartResults["standard_squat_report"]["squat_overall_score"],
        pushSelected: chartResults["push_report"]["push_overall_score"],
        lBalanceSelected:
          chartResults["l_balance_report"]["balance_overall_score"],
        rBalanceSelected:
          chartResults["r_balance_report"]["balance_overall_score"],
        lLungeSelected: chartResults["l_lunge_report"]["lunge_overall_score"],
        rLungeSelected: chartResults["r_lunge_report"]["lunge_overall_score"],
        lLatLungeSelected:
          chartResults["l_lat_lunge_report"]["lunge_overall_score"],
        rLatLungeSelected:
          chartResults["r_lat_lunge_report"]["lunge_overall_score"],
        lRDLSelected: chartResults["l_rdl_report"]["rdl_overall_score"],
        rRDLSelected: chartResults["r_rdl_report"]["rdl_overall_score"],
        hingeSelected: chartResults["hinge_report"]["hinge_overall_score"],
        aggregateSelected: chartResults["overall_score"],
        selectedMovement: "aggregate_score",
        unit: "Score",
        unit_symbol: "%"

        //postureSelected: chartResults["posture_report"]["posture_overall_score"],
      }));
    }
  }, [progress]);

  useEffect(() => {
    const formatData = (progressData, movement, metric) => {
      const dates = progressData[movement]?.dates || [];
      const leftData = progressData[movement]?.[`left_${metric}`] || [];
      const rightData = progressData[movement]?.[`right_${metric}`] || [];

      return dates.map((date, index) => ({
        date,
        Left: leftData[index] || null,
        Right: rightData[index] || null
      }));
    };

    setFormattedData(
      formatData(progress_jc, selectedComparison, selectedMetric)
    );
  }, [progress_jc, selectedComparison, selectedMetric]);

  useEffect(() => {
    // Override console.error to suppress specific warning
    const originalError = console.error;
    console.error = (...args) => {
      if (
        args.some(
          (arg) =>
            typeof arg === "string" &&
            arg.includes(
              "ResizeObserver loop completed with undelivered notifications"
            )
        )
      ) {
        // Ignore the specific warning
        return;
      }
      // Otherwise, log the warning
      originalError.call(console, ...args);
    };

    // Cleanup the override when the component is unmounted
    return () => {
      console.error = originalError;
    };
  }, []);
  const formatDates = (array) => {
    var dateArray = [];
    for (date in array) {
      var date = new Date(array[date]).toLocaleDateString();
      if (date[3] === "/") {
        dateArray.push(date.slice(0, 3));
      } else if (date[4] === "/") {
        dateArray.push(date.slice(0, 4));
      } else {
        dateArray.push(date.slice(0, 5));
      }
    }
    if (dateArray.length > 40) {
      const slicedArray = dateArray.slice(dateArray.length - 39);

      slicedArray.unshift(dateArray[0]);
      return slicedArray;
    }
    return dateArray;
  };

  const formatScores = (array) => {
    var scoreArray = [];
    for (var score in array) {
      var num = array[score];
      scoreArray.push(Math.round((num + Number.EPSILON) * 100) / 100);
    }
    if (scoreArray.length > 40) {
      const slicedArray = scoreArray.slice(-39);
      slicedArray.unshift(scoreArray[0]);
      return slicedArray;
    }
    return scoreArray;
  };

  const chartResults = {};

  if (progress.length > 0) {
    progress[0].dates = formatDates(progress[0].dates);
    for (const report_key of Object.keys(progress[0])) {
      if (report_key == "client" || report_key == "dates") {
        continue;
      }
      const report = progress[0][report_key];
      const last_undersore = report_key.lastIndexOf("_");

      var firstPart = report_key.slice(0, last_undersore);
      const secondPart = report_key.slice(last_undersore + 1);
      // Reports
      if (secondPart == "report") {
        chartResults[report_key] = {};
        if (firstPart.includes("_")) {
          firstPart = firstPart.split("_")[1];
        }
        for (const key of Object.keys(report)) {
          if (key == "id" || key == "dates") {
            continue;
          }
          chartResults[report_key][key] = [];
          report[key] = formatScores(report[key]);
          report.dates = formatDates(report.dates);
          var dataKey = "";
          if (key == "hip_level_avg" || key == "shoulder_level_avg") {
            dataKey = "Displacement";
          } else {
            dataKey = "Score";
          }
          for (let i = 0; i < report.dates.length; i++) {
            chartResults[report_key][key].push({
              date: report.dates[i],
              [dataKey]: report[key][i]
            });
          }
        }
      } else {
        chartResults[report_key] = [];
        for (let i = 0; i < progress[0].dates.length; i++) {
          if (report[i] != null) {
            chartResults[report_key].push({
              date: progress[0].dates[i],
              Score: report[i]
            });
          }
        }
      }
    }
  }

  function getUnits(value) {
    var unit = "";
    var unit_symbol = "";
    if (value == "hip_level_avg" || value == "shoulder_level_avg") {
      unit = "Displacement";
      unit_symbol = "°";
    } else {
      unit = "Score";
      unit_symbol = "%";
    }
    return [unit, unit_symbol];
  }

  function handleChangeSquat(value) {
    let [unit, unit_symbol] = getUnits(value);
    setState((prevState) => ({
      ...prevState,
      squatSelected: chartResults["squat_report"][value],
      unit: unit,
      unit_symbol: unit_symbol
    }));
  }

  function handleChangeCmj(value) {
    let [unit, unit_symbol] = getUnits(value);
    setState((prevState) => ({
      ...prevState,
      cmjSelected: chartResults["cmj_report"][value],
      unit: unit,
      unit_symbol: unit_symbol
    }));
  }

  function handleChangeLLunge(value) {
    let [unit, unit_symbol] = getUnits(value);
    setState((prevState) => ({
      ...prevState,
      lLungeSelected: chartResults["l_lunge_report"][value],
      unit: unit,
      unit_symbol: unit_symbol
    }));
  }

  function handleChangeRLunge(value) {
    let [unit, unit_symbol] = getUnits(value);
    setState((prevState) => ({
      ...prevState,
      rLungeSelected: chartResults["r_lunge_report"][value],
      unit: unit,
      unit_symbol: unit_symbol
    }));
  }

  function handleChangeLLatLunge(value) {
    let [unit, unit_symbol] = getUnits(value);
    setState((prevState) => ({
      ...prevState,
      lLatLungeSelected: chartResults["l_lat_lunge_report"][value],
      unit: unit,
      unit_symbol: unit_symbol
    }));
  }

  function handleChangeRLatLunge(value) {
    let [unit, unit_symbol] = getUnits(value);
    setState((prevState) => ({
      ...prevState,
      rLatLungeSelected: chartResults["r_lat_lunge_report"][value],
      unit: unit,
      unit_symbol: unit_symbol
    }));
  }

  function handleChangeHinge(value) {
    let [unit, unit_symbol] = getUnits(value);
    setState((prevState) => ({
      ...prevState,
      hingeSelected: chartResults["hinge_report"][value],
      unit: unit,
      unit_symbol: unit_symbol
    }));
  }
  function handleChangeLBalance(value) {
    let [unit, unit_symbol] = getUnits(value);
    setState((prevState) => ({
      ...prevState,
      lBalanceSelected: chartResults["l_balance_report"][value],
      unit: unit,
      unit_symbol: unit_symbol
    }));
  }

  function handleChangeRBalance(value) {
    let [unit, unit_symbol] = getUnits(value);
    setState((prevState) => ({
      ...prevState,
      rBalanceSelected: chartResults["r_balance_report"][value],
      unit: unit,
      unit_symbol: unit_symbol
    }));
  }

  function handleChangeLRDL(value) {
    let [unit, unit_symbol] = getUnits(value);
    setState((prevState) => ({
      ...prevState,
      lRDLSelected: chartResults["l_rdl_report"][value],
      unit: unit,
      unit_symbol: unit_symbol
    }));
  }

  function handleChangeRRDL(value) {
    let [unit, unit_symbol] = getUnits(value);
    setState((prevState) => ({
      ...prevState,
      rRDLSelected: chartResults["r_rdl_report"][value],
      unit: unit,
      unit_symbol,
      unit_symbol
    }));
  }

  function handleChangePush(value) {
    let [unit, unit_symbol] = getUnits(value);
    setState((prevState) => ({
      ...prevState,
      pushSelected: chartResults["push_report"][value],
      unit: unit,
      unit_symbol: unit_symbol
    }));
  }

  function handleChangeAggregate(value) {
    setState((prevState) => ({
      ...prevState,
      aggregateSelected: chartResults[value]
    }));
  }

  function handleChangeMovement(value) {
    setState((prevState) => ({
      ...prevState,
      selectedMovement: value,
      unit: "Score",
      unit_symbol: "%"
    }));
  }


  const handleChangeProgType = (value) => {

      setSelectedProgType(value)
    
  };
  // JC

  const handleComparisonChange = (value) => {
    if (value) {
      setSelectedComparison(value[1]);
    }
  };

  const handleMetricChange = (value) => {
    setSelectedMetric(value);
  };

  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const flexDirection = isRowLayout ? "row" : "column";

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };
  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  const options = [
    { value: "aggregate_score", label: "Aggregates" },
    ...(chartResults.cmj_report?.cmj_overall_score.length > 0
      ? [{ value: "cmj", label: "Countermovement Jump" }]
      : []),
    ...(chartResults.squat_report?.squat_overall_score.length > 0
      ? [{ value: "squat", label: "Squat" }]
      : []),
    ...(chartResults.standard_squat_report?.squat_overall_score.length > 0
      ? [{ value: "standard_squat", label: "Standard Squat" }]
      : []),
    ...(chartResults.push_report?.push_overall_score.length > 0
      ? [{ value: "push", label: "Push" }]
      : []),
    ...(chartResults.l_balance_report?.balance_overall_score.length > 0
      ? [{ value: "l_balance", label: "Balance (L)" }]
      : []),
    ...(chartResults.r_balance_report?.balance_overall_score.length > 0
      ? [{ value: "r_balance", label: "Balance (R)" }]
      : []),
    ...(chartResults.l_lunge_report?.lunge_overall_score.length > 0
      ? [{ value: "l_lunge", label: "Reverse Lunge (L)" }]
      : []),
    ...(chartResults.r_lunge_report?.lunge_overall_score.length > 0
      ? [{ value: "r_lunge", label: "Reverse Lunge (R)" }]
      : []),
    ...(chartResults.l_lat_lunge_report?.lunge_overall_score.length > 0
      ? [{ value: "l_lat_lunge", label: "Lateral Lunge (L)" }]
      : []),
    ...(chartResults.r_lat_lunge_report?.lunge_overall_score.length > 0
      ? [{ value: "r_lat_lunge", label: "Lateral Lunge (R)" }]
      : []),
    ...(chartResults.l_rdl_report?.rdl_overall_score.length > 0
      ? [{ value: "l_rdl", label: "Single Leg Hinge (L)" }]
      : []),
    ...(chartResults.r_rdl_report?.rdl_overall_score.length > 0
      ? [{ value: "r_rdl", label: "Single Leg Hinge (R)" }]
      : []),
    ...(chartResults.hinge_report?.hinge_overall_score.length > 0
      ? [{ value: "hinge", label: "Bilateral Hinge" }]
      : [])
  ];

  const progressKeys = Object.keys(progress_jc);
  const jcOptions = [];

  const addOptionsIfExist = (category, label, keys) => {
    const children = keys
      .filter((key) => key in progress_jc)
      .map((key) => ({
        value: key,
        label: key
          .split("_")
          .slice(1)
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ")
      }));

    if (children.length > 0) {
      jcOptions.push({ value: category, label: label, children: children });
    }
  };

  addOptionsIfExist("hip", "Hip", [
    "hip_abduction",
    "hip_extension",
    "hip_flexion",
    "hip_external_rotation",
    "hip_internal_rotation"
  ]);

  addOptionsIfExist("shoulder", "Shoulder", [
    "shoulder_abduction",
    "shoulder_flexion",
    "shoulder_horizontal_abduction",
    "shoulder_internal_rotation",
    "shoulder_external_rotation"
  ]);

  addOptionsIfExist("knee", "Knee", ["knee_extension", "knee_flexion"]);

  addOptionsIfExist("cervical", "Cervical", [
    "cervical_flexion_extension",
    "cervical_lateral_flexion",
    "cervical_rotation"
  ]);

  addOptionsIfExist("thoracic", "Trunk", ["thoracic_rotation"]);

  return (
    <Card
      style={{
        zIndex: 100,
        flex: 1,
        background: Theme.CARD,
        borderWidth: 0,
        position: "relative",
        width: isRowLayout ? "38vw" : "55vw",
        minHeight: "15vw",
        left: isRowLayout ? "1.1vw" : null
      }}
      className="criclebox h-full"
    >
      {loading || client.isGuest ? (
        <Spin size="large" style={{ top: "5vw", position: "relative" }} />
      ) : (
        <div style={{ marginTop: "1vw" }}>
          {selectedProgType === "functional" ? (
            <div>
              <Col align={"center"} style={{ marginBottom: "2%" }}>
                <h3
                  style={{
                    marginLeft: 0,
                    fontSize: "1.1vw",
                    marginBottom: "4vw",
                    position: "relative",
                    color: Theme.TEXT
                  }}
                >
                  Progress Report
                </h3>
                <Row>
                  <Col
                    style={{ position: "relative", bottom: "2vw", left: "1vw" }}
                  >
                    {(progress[0]?.overall_score?.length > 0 ||
                      progressKeys.length > 0) && (
                      <CustomSelect
                        defaultValue="functional"
                        options={[
                          { value: "functional", label: "Functional" },
                          {
                            value: "jc",
                            label: "Joint Comparisons"
                          }
                        ]}
                        onChange={handleChangeProgType}
                        width="10vw"
                        placeholder="Select an option"
                        lightModeBackground="#f1f1fb"
                        darkModeBackground="#000"
                      />
                    )}
                  </Col>
                  {progress[0]?.overall_score?.length > 0 && (
                    <Col
                      style={{
                        position: "relative",
                        bottom: "2vw",
                        left: "15vw"
                      }}
                    >
                      <CustomSelect
                        defaultValue="aggregate_score"
                        options={options}
                        onChange={handleChangeMovement}
                        width="10vw"
                        placeholder="Select an option"
                        lightModeBackground="#f1f1fb"
                        darkModeBackground="#000"
                      />
                    </Col>
                  )}
                </Row>
                {progress[0]?.overall_score?.length === 0 &&
                  client.first_name !== "Guest" &&
                  client.last_name !== "Assessments" && (
                    <div
                      style={{
                        maxWidth: "90%",
                        margin: "auto", // Use auto for horizontal centering
                        borderRadius: 10,
                        cursor: "pointer",
                        display: "flex",
                        position: "relative",
                        flexDirection: "column",
                        alignItems: "center",
                        left: isRowLayout ? "0vw" : "1vw",
                        right: 0, // Set left and right to 0 for horizontal centering
                        bottom:
                          progress[0]?.overall_score?.length === 0 &&
                          progressKeys.length === 0
                            ? "4vw"
                            : "2vw",
                        transform: isRowLayout
                          ? "translate(0%, 50%)"
                          : "translate(-2.5%, 50%)"
                      }}
                    >
                      <LineChartOutlined
                        style={{ color: Theme.PRIMARY, fontSize: "1.5vw" }}
                      />
                      <Text
                        style={{
                          fontSize: "1.25vw",
                          marginTop: 5,
                          color: Theme.TEXT
                        }}
                      >
                        No Data
                      </Text>
                      <Text
                        style={{
                          fontSize: ".75vw",
                          marginTop: "5",
                          color: Theme.TEXT
                        }}
                      >
                        Complete this {user?.profile?.client_label}'s 1st
                        Functional Assessment
                      </Text>
                    </div>
                  )}
              </Col>
              {state.selectedMovement == "aggregate_score" &&
                progress[0]?.overall_score?.length > 0 && (
                  <Col>
                    <ResponsiveContainer width="100%" height="100%" aspect={3}>
                      <LineChart
                        // width={500}
                        // height={300}
                        style={{ marginBottom: "2vw" }}
                        data={state.aggregateSelected}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          axisLine={{ stroke: "#000000" }}
                          dataKey="date"
                        />
                        <YAxis
                          axisLine={{ stroke: "#000000" }}
                          tickFormatter={(value) =>
                            `${value}${state.unit_symbol}`
                          }
                        />
                        <Tooltip
                          formatter={(value) =>
                            `${Math.round(value)}${state.unit_symbol}`
                          }
                          labelStyle={{ color: Theme.BLACK }}
                          itemStyle={{ color: Theme.BLACK }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey={state.unit}
                          stroke={Theme.BLUE}
                          dot={{
                            fill: Theme.BLUE,
                            stroke: Theme.BLUE,
                            strokeWidth: 2,
                            r: 4,
                            className: "boxShadow"
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <CustomSelect
                      defaultValue="overall_score"
                      options={[
                        { value: "overall_score", label: "Overall" },
                        { value: "compensation_score", label: "Compensation" },
                        { value: "mobility_score", label: "Mobility" },
                        { value: "stability_score", label: "Stability" }
                      ]}
                      onChange={handleChangeAggregate}
                      width="8vw"
                      placeholder="Select an option"
                      style={{
                        width: "8vw",
                        marginTop: "5.5%",
                        position: "relative"
                      }}
                    />
                  </Col>
                )}
              {state.selectedMovement == "cmj" &&
                progress[0]?.overall_score?.length > 0 && (
                  <div>
                    <ResponsiveContainer width="100%" aspect={3}>
                      <LineChart
                        width={500}
                        height={300}
                        style={{ marginBottom: "2vw" }}
                        data={state.cmjSelected}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis
                          tickFormatter={(value) =>
                            `${value}${state.unit_symbol}`
                          }
                        />
                        <Tooltip
                          formatter={(value) =>
                            `${Math.round(value)}${state.unit_symbol}`
                          }
                          labelStyle={{ color: Theme.BLACK }}
                          itemStyle={{ color: Theme.BLACK }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey={state.unit}
                          stroke={Theme.GRAD6}
                          fill={Theme.GRAD6}
                          dot={{
                            fill: Theme.GRAD6,
                            stroke: Theme.GRAD6,
                            strokeWidth: 2,
                            r: 4,
                            className: "boxShadow"
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <CustomSelect
                      defaultValue="cmj_overall_score"
                      options={[
                        { value: "cmj_overall_score", label: "Overall" },
                        { value: "max_jump_score", label: "Jump Height Score" },
                        { value: "landing_score", label: "Landing Score" }
                      ]}
                      onChange={handleChangeCmj}
                      width="8vw"
                      placeholder="Select an option"
                      style={{
                        width: "8vw",
                        marginTop: "5.5%",
                        position: "relative"
                      }}
                    />
                  </div>
                )}
              {state.selectedMovement == "squat" &&
                progress[0]?.overall_score?.length > 0 && (
                  <div>
                    <ResponsiveContainer width="100%" aspect={3}>
                      <LineChart
                        width={500}
                        height={300}
                        style={{ marginBottom: "2vw" }}
                        data={state.squatSelected}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis
                          tickFormatter={(value) =>
                            `${value}${state.unit_symbol}`
                          }
                        />
                        <Tooltip
                          formatter={(value) =>
                            `${Math.round(value)}${state.unit_symbol}`
                          }
                          labelStyle={{ color: Theme.BLACK }}
                          itemStyle={{ color: Theme.BLACK }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey={state.unit}
                          stroke={Theme.GRAD6}
                          fill={Theme.GRAD6}
                          dot={{
                            fill: Theme.GRAD6,
                            stroke: Theme.GRAD6,
                            strokeWidth: 2,
                            r: 4,
                            className: "boxShadow"
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <CustomSelect
                      defaultValue="squat_overall_score"
                      options={[
                        { value: "squat_overall_score", label: "Overall" },
                        { value: "compensation_score", label: "Compensation" },
                        { value: "mobility_score", label: "Mobility" },
                        { value: "left_hip_score", label: "Left Hip" },
                        { value: "right_hip_score", label: "Right Hip" },
                        { value: "left_knee_score", label: "Left Knee" },
                        { value: "right_knee_score", label: "Right Knee" },
                        { value: "left_ankle_score", label: "Left Ankle" },
                        { value: "right_ankle_score", label: "Right Ankle" },
                        { value: "hip_level_avg", label: "Hip Level" },
                        { value: "shoulder_level_avg", label: "Shoulder Level" }
                      ]}
                      onChange={handleChangeSquat}
                      width="8vw"
                      placeholder="Select an option"
                      style={{
                        width: "8vw",
                        marginTop: "5.5%",
                        position: "relative"
                      }}
                    />
                  </div>
                )}
              {state.selectedMovement == "standard_squat" &&
                progress[0]?.overall_score?.length > 0 && (
                  <div>
                    <ResponsiveContainer width="100%" aspect={3}>
                      <LineChart
                        width={500}
                        height={300}
                        style={{ marginBottom: "2vw" }}
                        data={state.standardSquatSelected}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis
                          tickFormatter={(value) =>
                            `${value}${state.unit_symbol}`
                          }
                        />
                        <Tooltip
                          formatter={(value) =>
                            `${Math.round(value)}${state.unit_symbol}`
                          }
                          labelStyle={{ color: Theme.BLACK }}
                          itemStyle={{ color: Theme.BLACK }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey={state.unit}
                          stroke={Theme.YELLOW}
                          fill={Theme.YELLOW}
                          dot={{
                            fill: Theme.YELLOW,
                            stroke: Theme.YELLOW,
                            strokeWidth: 2,
                            r: 4,
                            className: "boxShadow"
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <CustomSelect
                      defaultValue="squat_overall_score"
                      options={[
                        { value: "squat_overall_score", label: "Overall" },
                        { value: "compensation_score", label: "Compensation" },
                        { value: "mobility_score", label: "Mobility" },
                        { value: "left_hip_score", label: "Left Hip" },
                        { value: "right_hip_score", label: "Right Hip" },
                        { value: "left_knee_score", label: "Left Knee" },
                        { value: "right_knee_score", label: "Right Knee" },
                        { value: "left_ankle_score", label: "Left Ankle" },
                        { value: "right_ankle_score", label: "Right Ankle" },
                        { value: "hip_level_avg", label: "Hip Level" },
                        { value: "shoulder_level_avg", label: "Shoulder Level" }
                      ]}
                      onChange={handleChangeSquat}
                      width="8vw"
                      placeholder="Select an option"
                    />
                  </div>
                )}
              {state.selectedMovement == "hinge" &&
                progress[0]?.overall_score?.length > 0 && (
                  <div>
                    <ResponsiveContainer width="100%" aspect={3}>
                      <LineChart
                        width={500}
                        height={300}
                        style={{ marginBottom: "2vw" }}
                        data={state.hingeSelected}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis
                          tickFormatter={(value) =>
                            `${value}${state.unit_symbol}`
                          }
                        />
                        <Tooltip
                          formatter={(value) =>
                            `${Math.round(value)}${state.unit_symbol}`
                          }
                          labelStyle={{ color: Theme.BLACK }}
                          itemStyle={{ color: Theme.BLACK }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey={state.unit}
                          stroke="#7E57C2"
                          fill="#7E57C2"
                          dot={{
                            fill: "#7E57C2",
                            stroke: "#7E57C2",
                            strokeWidth: 2,
                            r: 4,
                            className: "boxShadow"
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <CustomSelect
                      defaultValue="hinge_overall_score"
                      options={[
                        { value: "hinge_overall_score", label: "Overall" },
                        { value: "compensation_score", label: "Compensation" },
                        { value: "mobility_score", label: "Mobility" },
                        { value: "left_hip_score", label: "Left Hip" },
                        { value: "right_hip_score", label: "Right Hip" },
                        { value: "left_knee_score", label: "Left Knee" },
                        { value: "right_knee_score", label: "Right Knee" },
                        { value: "left_ankle_score", label: "Left Ankle" },
                        { value: "right_ankle_score", label: "Right Ankle" },
                        { value: "hip_level_avg", label: "Hip Level" },
                        { value: "shoulder_level_avg", label: "Shoulder Level" }
                      ]}
                      onChange={handleChangeHinge}
                      width="8vw"
                      placeholder="Select an option"
                    />
                  </div>
                )}
              {state.selectedMovement == "push" &&
                progress[0]?.overall_score?.length > 0 && (
                  <div>
                    <ResponsiveContainer width="100%" aspect={3}>
                      <LineChart
                        width={500}
                        height={300}
                        style={{ marginBottom: "2vw" }}
                        data={state.pushSelected}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis
                          tickFormatter={(value) =>
                            `${value}${state.unit_symbol}`
                          }
                        />
                        <Tooltip
                          formatter={(value) =>
                            `${Math.round(value)}${state.unit_symbol}`
                          }
                          labelStyle={{ color: Theme.BLACK }}
                          itemStyle={{ color: Theme.BLACK }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey={state.unit}
                          stroke="#EF5350"
                          dot={{
                            fill: "#EF5350",
                            stroke: "#EF5350",
                            strokeWidth: 2,
                            r: 4,
                            className: "boxShadow"
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <CustomSelect
                      defaultValue="push_overall_score"
                      options={[
                        { value: "push_overall_score", label: "Overall" },
                        { value: "compensation_score", label: "Compensation" },
                        { value: "mobility_score", label: "Mobility" },
                        { value: "stability_score", label: "Stability" },
                        {
                          value: "left_shoulder_score",
                          label: "Left Shoulder"
                        },
                        {
                          value: "right_shoulder_score",
                          label: "Right Shoulder"
                        },
                        { value: "left_elbow_score", label: "Left Elbow" },
                        { value: "right_elbow_score", label: "Right Elbow" },
                        { value: "left_hip_score", label: "Left Hip" },
                        { value: "right_hip_score", label: "Right Hip" },
                        { value: "hip_level_avg", label: "Hip Level" },
                        { value: "shoulder_level_avg", label: "Shoulder Level" }
                      ]}
                      onChange={handleChangePush}
                      width="8vw"
                      placeholder="Select an option"
                    />
                  </div>
                )}
              {state.selectedMovement == "l_balance" &&
                progress[0]?.overall_score?.length > 0 && (
                  <div>
                    <ResponsiveContainer width="100%" aspect={3}>
                      <LineChart
                        width={500}
                        height={300}
                        style={{ marginBottom: "2vw" }}
                        data={state.lBalanceSelected}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis
                          tickFormatter={(value) =>
                            `${value}${state.unit_symbol}`
                          }
                        />
                        <Tooltip
                          formatter={(value) =>
                            `${Math.round(value)}${state.unit_symbol}`
                          }
                          labelStyle={{ color: Theme.BLACK }}
                          itemStyle={{ color: Theme.BLACK }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey={state.unit}
                          stroke="#42A5F5"
                          dot={{
                            fill: "#42A5F5",
                            stroke: "#42A5F5",
                            strokeWidth: 2,
                            r: 4,
                            className: "boxShadow"
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <CustomSelect
                      defaultValue="balance_overall_score"
                      options={[
                        { value: "balance_overall_score", label: "Overall" },
                        { value: "stability_score", label: "Stability" },
                        {
                          value: "shoulder_level_avg",
                          label: "Shoulder Level"
                        },
                        { value: "hip_level_avg", label: "Hip Level" }
                      ]}
                      onChange={handleChangeLBalance}
                      width="8vw"
                      placeholder="Select an option"
                    />
                  </div>
                )}
              {state.selectedMovement == "r_balance" &&
                progress[0]?.overall_score?.length > 0 && (
                  <div>
                    <ResponsiveContainer width="100%" aspect={3}>
                      <LineChart
                        width={500}
                        height={300}
                        style={{ marginBottom: "2vw" }}
                        data={state.rBalanceSelected}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis
                          tickFormatter={(value) =>
                            `${value}${state.unit_symbol}`
                          }
                        />
                        <Tooltip
                          formatter={(value) =>
                            `${Math.round(value)}${state.unit_symbol}`
                          }
                          labelStyle={{ color: Theme.BLACK }}
                          itemStyle={{ color: Theme.BLACK }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey={state.unit}
                          stroke="#42A5F5"
                          dot={{
                            fill: "#42A5F5",
                            stroke: "#42A5F5",
                            strokeWidth: 2,
                            r: 4,
                            className: "boxShadow"
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <CustomSelect
                      defaultValue="balance_overall_score"
                      options={[
                        { value: "balance_overall_score", label: "Overall" },
                        { value: "stability_score", label: "Stability" },
                        {
                          value: "shoulder_level_avg",
                          label: "Shoulder Level"
                        },
                        { value: "hip_level_avg", label: "Hip Level" }
                      ]}
                      onChange={handleChangeRBalance}
                      width="8vw"
                      placeholder="Select an option"
                    />
                  </div>
                )}
              {state.selectedMovement == "l_lunge" &&
                progress[0]?.overall_score?.length > 0 && (
                  <div>
                    <ResponsiveContainer width="100%" aspect={3}>
                      <LineChart
                        width={500}
                        height={300}
                        style={{ marginBottom: "2vw" }}
                        data={state.lLungeSelected}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis
                          tickFormatter={(value) =>
                            `${value}${state.unit_symbol}`
                          }
                        />
                        <Tooltip
                          formatter={(value) =>
                            `${Math.round(value)}${state.unit_symbol}`
                          }
                          labelStyle={{ color: Theme.BLACK }}
                          itemStyle={{ color: Theme.BLACK }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey={state.unit}
                          stroke="#26A69A"
                          dot={{
                            fill: "#26A69A",
                            stroke: "#26A69A",
                            strokeWidth: 2,
                            r: 4,
                            className: "boxShadow"
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <CustomSelect
                      defaultValue="lunge_overall_score"
                      options={[
                        { value: "lunge_overall_score", label: "Overall" },
                        { value: "compensation_score", label: "Compensation" },
                        { value: "mobility_score", label: "Mobility" },
                        { value: "stability_score", label: "Stability" },
                        { value: "hip_score", label: "Hip" },
                        { value: "knee_score", label: "Knee" },
                        { value: "ankle_score", label: "Ankle" },
                        { value: "hip_level_avg", label: "Hip Level" },
                        { value: "shoulder_level_avg", label: "Shoulder Level" }
                      ]}
                      onChange={handleChangeLLunge}
                      width="8vw"
                      placeholder="Select an option"
                    />
                  </div>
                )}
              {state.selectedMovement == "r_lunge" &&
                progress[0]?.overall_score?.length > 0 && (
                  <div>
                    <ResponsiveContainer width="100%" aspect={3}>
                      <LineChart
                        width={500}
                        height={300}
                        style={{ marginBottom: "2vw" }}
                        data={state.rLungeSelected}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis
                          tickFormatter={(value) =>
                            `${value}${state.unit_symbol}`
                          }
                        />
                        <Tooltip
                          formatter={(value) =>
                            `${Math.round(value)}${state.unit_symbol}`
                          }
                          labelStyle={{ color: Theme.BLACK }}
                          itemStyle={{ color: Theme.BLACK }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey={state.unit}
                          stroke="#26A69A"
                          dot={{
                            fill: "#26A69A",
                            stroke: "#26A69A",
                            strokeWidth: 2,
                            r: 4,
                            className: "boxShadow"
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <CustomSelect
                      defaultValue="lunge_overall_score"
                      options={[
                        { value: "lunge_overall_score", label: "Overall" },
                        { value: "compensation_score", label: "Compensation" },
                        { value: "mobility_score", label: "Mobility" },
                        { value: "stability_score", label: "Stability" },
                        { value: "hip_score", label: "Hip" },
                        { value: "knee_score", label: "Knee" },
                        { value: "ankle_score", label: "Ankle" },
                        { value: "hip_level_avg", label: "Hip Level" },
                        { value: "shoulder_level_avg", label: "Shoulder Level" }
                      ]}
                      onChange={handleChangeRLunge}
                      width="8vw"
                      placeholder="Select an option"
                    />
                  </div>
                )}
              {state.selectedMovement == "l_lat_lunge" &&
                progress[0]?.overall_score?.length > 0 && (
                  <div>
                    <ResponsiveContainer width="100%" aspect={3}>
                      <LineChart
                        width={500}
                        height={300}
                        style={{ marginBottom: "2vw" }}
                        data={state.lLatLungeSelected}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis
                          tickFormatter={(value) =>
                            `${value}${state.unit_symbol}`
                          }
                        />
                        <Tooltip
                          formatter={(value) =>
                            `${Math.round(value)}${state.unit_symbol}`
                          }
                          labelStyle={{ color: Theme.BLACK }}
                          itemStyle={{ color: Theme.BLACK }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey={state.unit}
                          stroke="#26A69A"
                          dot={{
                            fill: "#26A69A",
                            stroke: "#26A69A",
                            strokeWidth: 2,
                            r: 4,
                            className: "boxShadow"
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <CustomSelect
                      defaultValue="lunge_overall_score"
                      options={[
                        { value: "lunge_overall_score", label: "Overall" },
                        { value: "compensation_score", label: "Compensation" },
                        { value: "mobility_score", label: "Mobility" },
                        { value: "stability_score", label: "Stability" },
                        { value: "hip_score", label: "Hip" },
                        {
                          value: "hip_abduction_score",
                          label: "Hip Abduction"
                        },
                        { value: "knee_score", label: "Knee" },
                        { value: "ankle_score", label: "Ankle" },
                        { value: "hip_level_avg", label: "Hip Level" },
                        { value: "shoulder_level_avg", label: "Shoulder Level" }
                      ]}
                      onChange={handleChangeLLatLunge}
                      width="8vw"
                      placeholder="Select an option"
                    />
                  </div>
                )}
              {state.selectedMovement == "r_lat_lunge" &&
                progress[0]?.overall_score?.length > 0 && (
                  <div>
                    <ResponsiveContainer width="100%" aspect={3}>
                      <LineChart
                        width={500}
                        height={300}
                        style={{ marginBottom: "2vw" }}
                        data={state.rLatLungeSelected}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis
                          tickFormatter={(value) =>
                            `${value}${state.unit_symbol}`
                          }
                        />
                        <Tooltip
                          formatter={(value) =>
                            `${Math.round(value)}${state.unit_symbol}`
                          }
                          labelStyle={{ color: Theme.BLACK }}
                          itemStyle={{ color: Theme.BLACK }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey={state.unit}
                          stroke="#26A69A"
                          dot={{
                            fill: "#26A69A",
                            stroke: "#26A69A",
                            strokeWidth: 2,
                            r: 4,
                            className: "boxShadow"
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <CustomSelect
                      defaultValue="lunge_overall_score"
                      options={[
                        { value: "lunge_overall_score", label: "Overall" },
                        { value: "compensation_score", label: "Compensation" },
                        { value: "mobility_score", label: "Mobility" },
                        { value: "stability_score", label: "Stability" },
                        { value: "hip_score", label: "Hip" },
                        {
                          value: "hip_abduction_score",
                          label: "Hip Abduction"
                        },
                        { value: "knee_score", label: "Knee" },
                        { value: "ankle_score", label: "Ankle" },
                        { value: "hip_level_avg", label: "Hip Level" },
                        { value: "shoulder_level_avg", label: "Shoulder Level" }
                      ]}
                      onChange={handleChangeRLatLunge}
                      width="8vw"
                      placeholder="Select an option"
                    />
                  </div>
                )}
              {state.selectedMovement == "l_rdl" &&
                progress[0]?.overall_score?.length > 0 && (
                  <div>
                    <ResponsiveContainer width="100%" aspect={3}>
                      <LineChart
                        width={500}
                        height={300}
                        style={{ marginBottom: "2vw" }}
                        data={state.lRDLSelected}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis
                          tickFormatter={(value) =>
                            `${value}${state.unit_symbol}`
                          }
                        />
                        <Tooltip
                          formatter={(value) =>
                            `${Math.round(value)}${state.unit_symbol}`
                          }
                          labelStyle={{ color: Theme.BLACK }}
                          itemStyle={{ color: Theme.BLACK }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey={state.unit}
                          stroke="#8D6E63"
                          dot={{
                            fill: "#8D6E63",
                            stroke: "#8D6E63",
                            strokeWidth: 2,
                            r: 4,
                            className: "boxShadow"
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <CustomSelect
                      defaultValue="rdl_overall_score"
                      options={[
                        { value: "rdl_overall_score", label: "Overall" },
                        { value: "compensation_score", label: "Compensation" },
                        { value: "mobility_score", label: "Mobility" },
                        { value: "stability_score", label: "Stability" },
                        { value: "hip_score", label: "Hip" },
                        { value: "knee_score", label: "Knee" },
                        { value: "ankle_score", label: "Ankle" },
                        { value: "hip_level_avg", label: "Hip Level" },
                        { value: "shoulder_level_avg", label: "Shoulder Level" }
                      ]}
                      onChange={handleChangeLRDL}
                      width="8vw"
                      placeholder="Select an option"
                      lightModeBackground="#f1f1fb"
                      darkModeBackground="#000"
                    />
                  </div>
                )}
              {state.selectedMovement == "r_rdl" &&
                progress[0]?.overall_score?.length > 0 && (
                  <div>
                    <ResponsiveContainer width="100%" aspect={3}>
                      <LineChart
                        width={500}
                        height={300}
                        style={{ marginBottom: "2vw" }}
                        data={state.rRDLSelected}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 10,
                          bottom: 5
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis
                          tickFormatter={(value) =>
                            `${value}${state.unit_symbol}`
                          }
                        />
                        <Tooltip
                          formatter={(value) =>
                            `${Math.round(value)}${state.unit_symbol}`
                          }
                          labelStyle={{ color: Theme.BLACK }}
                          itemStyle={{ color: Theme.BLACK }}
                        />
                        <Legend />
                        <Line
                          type="monotone"
                          dataKey={state.unit}
                          stroke="#8D6E63"
                          dot={{
                            fill: "#8D6E63",
                            stroke: "#8D6E63",
                            strokeWidth: 2,
                            r: 4,
                            className: "boxShadow"
                          }}
                        />
                      </LineChart>
                    </ResponsiveContainer>
                    <CustomSelect
                      defaultValue="rdl_overall_score"
                      options={[
                        { value: "rdl_overall_score", label: "Overall" },
                        { value: "compensation_score", label: "Compensation" },
                        { value: "mobility_score", label: "Mobility" },
                        { value: "stability_score", label: "Stability" },
                        { value: "hip_score", label: "Hip" },
                        { value: "knee_score", label: "Knee" },
                        { value: "ankle_score", label: "Ankle" },
                        { value: "hip_level_avg", label: "Hip Level" },
                        { value: "shoulder_level_avg", label: "Shoulder Level" }
                      ]}
                      onChange={handleChangeRRDL}
                      width="8vw"
                      placeholder="Select an option"
                      lightModeBackground="#f1f1fb"
                      darkModeBackground="#000"
                    />
                  </div>
                )}
            </div>
          ) : (
            <div>
              <Col align={"center"} style={{ marginBottom: "2%" }}>
                <h3
                  style={{
                    marginLeft: 0,
                    fontSize: "1.1vw",
                    marginBottom: "4vw",
                    position: "relative",
                    color: Theme.TEXT
                  }}
                >
                  Progress Report
                </h3>
                <Row>
                  <Col
                    style={{ position: "relative", bottom: "2vw", left: "1vw" }}
                  >
                    {(progress[0]?.overall_score?.length > 0 ||
                      progressKeys.length > 0) && (
                      <CustomSelect
                        defaultValue="jc"
                        options={[
                          { value: "functional", label: "Functional" },
                          {
                            value: "jc",
                            label: "Joint Comparisons"
                          }
                        ]}
                        onChange={handleChangeProgType}
                        width="10vw"
                        placeholder="Select an option"
                        lightModeBackground="#f1f1fb"
                        darkModeBackground="#000"
                      />
                    )}
                  </Col>
                  <Col
                    style={{
                      position: "relative",
                      bottom: "2vw",
                      left: "15vw"
                    }}
                  >
                    {progressKeys.length > 0 && (
                      <NestedCustomSelect
                        defaultValue="hip"
                        options={jcOptions}
                        width="10vw"
                        placeholder="Select an option"
                        lightModeBackground="#f1f1fb"
                        darkModeBackground="#000"
                        onChange={handleComparisonChange}
                      />
                    )}
                  </Col>
                </Row>
                {progressKeys.length == 0 &&
                  client.first_name !== "Guest" &&
                  client.last_name !== "Assessments" && (
                    <div
                      style={{
                        maxWidth: "90%",
                        margin: "auto", // Use auto for horizontal centering
                        borderRadius: 10,
                        cursor: "pointer",
                        display: "flex",
                        position: "relative",
                        flexDirection: "column",
                        alignItems: "center",
                        left: isRowLayout ? "0vw" : "1vw",
                        right: 0, // Set left and right to 0 for horizontal centering
                        // bottom: "2vw",
                        transform: isRowLayout
                          ? "translate(0%, 50%)"
                          : "translate(-2.5%, 50%)"
                      }}
                    >
                      <LineChartOutlined
                        style={{ color: Theme.PRIMARY, fontSize: "1.5vw" }}
                      />
                      <Text
                        style={{
                          fontSize: "1.25vw",
                          marginTop: 5,
                          color: Theme.TEXT
                        }}
                      >
                        No Data
                      </Text>
                      <Text
                        style={{
                          fontSize: ".75vw",
                          marginTop: "5",
                          color: Theme.TEXT
                        }}
                      >
                        Complete this {user?.profile?.client_label}'s 1st Joint
                        Comparison
                      </Text>
                    </div>
                  )}
              </Col>

              {progressKeys.length > 0 && (
                <>
                  <ResponsiveContainer width="100%" aspect={3}>
                    <LineChart
                      width={500}
                      height={300}
                      style={{ marginBottom: "2vw" }}
                      data={formattedData}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 10,
                        bottom: 5
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="date" />
                      <YAxis tickFormatter={(value) => `${value}`} />
                      <Tooltip
                        formatter={(value) => `${Math.round(value)}`}
                        labelStyle={{ color: Theme.BLACK }}
                        itemStyle={{ color: Theme.BLACK }}
                      />
                      <Legend />
                      <Line
                        type="monotone"
                        dataKey="Left"
                        stroke="#8D6E63"
                        dot={{
                          fill: "#8D6E63",
                          stroke: "#8D6E63",
                          strokeWidth: 2,
                          r: 4,
                          className: "boxShadow"
                        }}
                      />
                      <Line
                        type="monotone"
                        dataKey="Right"
                        stroke="#6E8D8D"
                        dot={{
                          fill: "#6E8D8D",
                          stroke: "#6E8D8D",
                          strokeWidth: 2,
                          r: 4,
                          className: "boxShadow"
                        }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                  <CustomSelect
                    defaultValue="overall_score"
                    options={[
                      { value: "overall_score", label: "Overall Score" },
                      { value: "end_angle", label: "End Angle" }
                    ]}
                    onChange={handleMetricChange}
                    width="8vw"
                    style={{
                      width: "8vw",
                      marginTop: "5.5%",
                      position: "relative"
                    }}
                  />
                </>
              )}
            </div>
          )}
        </div>
      )}
    </Card>
  );
};
export default Progress;
