// ExerciseList.js

import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Select,
  Input,
  Typography,
  Modal,
} from "antd";
import { PlusCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import customblank from "../../assets/images/customblank.jpg";
import { IoMdFitness } from "react-icons/io";
import CustomSelect from "../common/DropDown";
import ReorderModal from "./ExerciseReorder";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { Button } from "react-bootstrap";
import { useForm, Controller } from 'react-hook-form';


const { Option } = Select;
const { Text } = Typography;

const ExerciseList = ({
  chosenExercises,
  Theme,
  handleWeightTypeChange,
  handleWeightValueChange,
  handleParamTypeChange,
  handleParamValueChange,
  handleAddParamToSet,
  handleRemoveExercise,
  handleAddSet,
  handleRemoveSet,
  handleRemoveParamFromSet,
  setChosenExercises,
}) => {
  const [reorderModal, setReorderModal] = useState(false);
  const updateExerciseOrder = (reorderedExercises) => {
    const updatedExercises = reorderedExercises.map((exercise, index) => ({
      ...exercise,
      order: index,
    }));
    setChosenExercises(updatedExercises);
  };

  const handleCloseModalReorder = () => {
    setReorderModal(false);
  };

  const { control, setError, reset} = useForm({
    defaultValues: {
      exercises: chosenExercises,
    },
  });


  useEffect(() => {
    reset({ exercises: chosenExercises });
}, [chosenExercises, reset]);

  return (
    <div
      style={{
        overflowY: "auto",
        minHeight: "33vw",
        maxHeight: "33vw",
        width: "105%",
        border: Theme.BACKGROUND === "#000" ? null : "1px solid #ccc",
        borderRadius: "8px",
        padding: "1vw",
        marginTop: ".75vw",
        background:
          Theme.BACKGROUND === "#000" ? Theme.BACKGROUND : Theme.CARD,
      }}
    >
      <Col
        justify="center"
        align="middle"
        style={{
          marginBottom: "1vw",
          borderBottom: chosenExercises?.length > 0 ? ".5px solid #ccc" : null,
          paddingBottom: "3vw" ,
        }}
      >
        <Text
          style={{
            color: Theme.TEXT2,
            fontWeight: "bold",
            fontSize: ".75vw",
          }}
        >
          Exercises
        </Text>

     {chosenExercises?.length > 0 &&  <Button
          style={{
            backgroundImage: "linear-gradient(45deg, #C04848 0%, #480048 80%)",
            border: 0,
            fontSize: ".55vw",
            height: "1.5vw",
            borderRadius: 10,
            color: "white",
            paddingLeft: "2.5%",
            paddingRight: "2.5%",
            fontWeight: "bold",
            cursor: "pointer",
            position: "absolute",
            left: 0,
            top:"1.9vw"
          }}
          type="button"
          onClick={() => setReorderModal(true)}
        >
          Update Order
        </Button>}
      </Col>

      {chosenExercises === undefined && (
        <Col style={{ textAlign: "center", marginTop: "25%" }}>
          <IoMdFitness size={"3vw"} color={Theme.PRIMARY} />
          <div>
            <Text style={{ fontSize: "1vw", color: Theme.TEXT }}>
              No Exercises
            </Text>
          </div>
        </Col>
      )}
      {chosenExercises &&
        chosenExercises
          .sort((a, b) => a.order - b.order)
          .map((exercise, index) =>
            exercise.isSuperset ? (
              <>
                <Col
                  style={{ marginTop: "3%", marginBottom: "2%" }}
                  align="left"
                >
                  <Text
                    style={{
                      color: Theme.PRIMARY,
                      fontWeight: "bold",
                      fontSize: ".85vw",
                    }}
                  >
                    Superset
                  </Text>
                </Col>
                {exercise.exercises
                  .sort((a, b) => a.order - b.order)
                  .map((superExercise, subIndex) => (
                    <Col
                      style={{
                        marginBottom: "1vw",
                        borderBottom:
                          subIndex !== exercise?.exercises?.length - 1
                            ? "1px dotted #C04848"
                            : "1px solid #ccc",
                        paddingBottom: "1vw",
                        // left: "5%"
                      }}
                      key={index}
                    >
                      <Row style={{ marginBottom: ".75vw" }}>
                        <div>
                          <Text
                            style={{
                              color: Theme.TEXT,
                              fontWeight: "bold",
                              fontSize: ".75vw",
                            }}
                          >
                            {superExercise.name ||
                              superExercise.library_exercise.name}
                          </Text>
                        </div>
                        <Col
                          align="middle"
                          justify="center"
                          style={{ position: "relative", left: ".25vw" }}
                        >
                          <CloseCircleOutlined
                            onClick={() =>
                              handleRemoveExercise(index, subIndex)
                            }
                            style={{ color: Theme.PRIMARY, cursor: "pointer" }}
                          />
                        </Col>
                      </Row>
                      {superExercise.sets &&
                        superExercise.sets.map((set, setIndex) => (
                          <Row
                            key={setIndex}
                            style={{
                              marginBottom: setIndex !== 0 ? ".5vw" : null,
                            }}
                          >
                            <Col
                              align="middle"
                              justify="center"
                              style={{ marginRight: "1vw" }}
                            >
                              {setIndex === 0 && superExercise.photo ? (
                                <div>
                                  <img
                                    style={{
                                      height: "6vw",
                                      width: "9vw",
                                      borderRadius: 10,
                                      objectFit: "cover",
                                    }}
                                    // alt={superExercise.name}
                                    src={superExercise.photo}
                                  />
                                </div>
                              ) : superExercise.library_exercise?.image &&
                                setIndex === 0 ? (
                                <div>
                                  <img
                                    style={{
                                      height: "6vw",
                                      width: "9vw",
                                      borderRadius: 10,
                                    }}
                                    // alt={exercise.name}
                                    src={superExercise.library_exercise.image}
                                  />
                                </div>
                              ) : superExercise.image && setIndex === 0 ? (
                                <div>
                                  <img
                                    style={{
                                      height: "6vw",
                                      width: "9vw",
                                      borderRadius: 10,
                                    }}
                                    // alt={exercise.name}
                                    src={superExercise.image}
                                  />
                                </div>
                              ) : (
                                <div
                                  style={{
                                    height: "6vw",
                                    width: "9vw",
                                    borderRadius: 10,
                                    backgroundColor: Theme.BACKGROUND === "#000"
                                    ? Theme.BACKGROUND
                                    : Theme.CARD
                                  }}
                                />
                                
                              )}
                            </Col>
                            <Row
                              // align="middle"
                              // justify="center"
                              style={{ marginRight: ".65dvw" }}
                            >
                              <Text
                                style={{ color: Theme.TEXT, fontSize: ".75vw" }}
                              >
                                Set {setIndex + 1}
                              </Text>
                              <Col style={{ marginLeft: ".5vw" }}>
                                <div>
                                  <PlusCircleOutlined
                                    style={{
                                      padding: 0,
                                      position: "relative",
                                      fontSize: ".7vw",
                                      color: Theme.BLUE,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleAddSet(index, setIndex, subIndex)
                                    }
                                  />
                                </div>
                                {setIndex !== 0 && (
                                  <CloseCircleOutlined
                                    onClick={() =>
                                      handleRemoveSet(index, setIndex, subIndex)
                                    }
                                    style={{
                                      color: Theme.PRIMARY,
                                      cursor: "pointer",
                                      fontSize: ".7vw",
                                      position: "relative",
                                    }}
                                  />
                                )}
                              </Col>
                            </Row>
                            <Controller
                                name={`exercises[${index}].exercises[${subIndex}].sets[${setIndex}].weight.value`}
                                control={control}
                                rules={{
                                  validate: value => (value === "" || /^\d+$/.test(value)) || "Must be number"
                                }}
                                defaultValue={set.weight.value}
                                render={({ field: { onChange, value }, fieldState: { error } }) => (
                                  <Col>
                                    <CustomSelect
                                      value={set.weight.type}
                                      onChange={(value) => {
                                        handleWeightTypeChange(
                                          index,
                                          setIndex,
                                          value,
                                          subIndex
                                        )
                                      }}
                                      width="6vw"
                                      options={[
                                        { value: "% Difficulty", label: "% Difficulty" },
                                        { value: "%1RM", label: "% 1RM" },
                                        { value: "Weight", label: "Weight" },
                                      ]}
                                      Theme={Theme}
                                      darkModeBackground={Theme.CARD}
                                      lightModeBackground={Theme.CARD}
                                    />
                                    <div>
                                      <Input
                                        value={value}
                                        style={{
                                          width: "6vw",
                                          color: Theme.TEXT,
                                          background: Theme.CARD,
                                       
                                          fontSize: ".75vw",
                                          paddingRight: "2vw",
                                          border:  Theme.BACKGROUND === "#000" ? "0px solid #ccc" : "1px solid #ccc"
                                        }}
                                        className={
                                          Theme.BACKGROUND === "#000"
                                            ? "custom-placeholder-color-dark"
                                            : "custom-placeholder-color-light"
                                        }
                                        onChange={e => {
                                          handleWeightValueChange(index, setIndex, e.target.value, subIndex); 
                                          onChange(e.target.value); 
                                          
                                        }}
                                      />
                                      {error && <p style={{fontSize: "12px", color: 'red' }}>{error.message}</p>}
                                    </div>
                                  </Col>
                                )}
                                />
                            {set.params.map((param, paramIndex) => (
                              <Col key={paramIndex}>
                                <CustomSelect
                                  value={param.type}
                                  onChange={(value) =>
                                    handleParamTypeChange(
                                      index,
                                      setIndex,
                                      paramIndex,
                                      value,
                                      subIndex
                                    )
                                  }
                                  darkModeBackground={Theme.CARD}
                                  lightModeBackground={Theme.CARD}
                                  width="6vw"
                                  options={[
                                    { value: "Reps", label: "Reps" },
                                    { value: "Rest", label: "Rest" },
                                    { value: "Time", label: "Time" },
                                    { value: "Distance", label: "Distance" },
                                    { value: "Height", label: "Height" },
                                    { value: "Velocity", label: "Velocity" },
                                    { value: "Power", label: "Power" },
                                    { value: "Force", label: "Force" },
                                    { value: "HR", label: "HR" },
                                    { value: "HRZ", label: "HRZ" },
                                    { value: "RPE", label: "RPE" },
                                  ]}
                                  Theme={Theme}
                                />
                                <Controller
                                  name={`exercises[${index}].exercises[${subIndex}].sets[${setIndex}].params[${paramIndex}].value`}
                                  control={control}
                                  defaultValue={param.value}
                                  render={({ field: { onChange, value } }) => (
                                    <div style={{ position: "relative" }}>
                                      <Input
                                        style={{
                                          width: "6vw",
                                          color: Theme.TEXT,
                                          background: Theme.CARD,
                                          borderWidth: 0,
                                          fontSize: ".75vw",
                                          paddingRight: "2vw",
                                          border:  Theme.BACKGROUND === "#000" ? "0px solid #ccc" : "1px solid #ccc"

                                        }}
                                        value={value}
                                        maxLength={9}
                                        onChange={(e) => {
                                          // Here, call your custom method for state update
                                          handleParamValueChange(index, setIndex, paramIndex, e.target.value, subIndex);
                                          // Then, call React Hook Form's onChange method to ensure form state is updated
                                          onChange(e.target.value);
                                        }}
                                      />
                                      {paramIndex > 0 && (
                                        <CloseCircleOutlined
                                          onClick={() => handleRemoveParamFromSet(index, setIndex, paramIndex, subIndex)}
                                          style={{
                                            position: "absolute",
                                            right: "8px",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                            color: Theme.TEXT,
                                            cursor: "pointer",
                                            fontSize: ".75vw",
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                                />        
                              </Col>
                            ))}
                            {set.params.length < 3 && (
                              <Col
                                align="middle"
                                justify="center"
                                style={{
                                  cursor: "pointer",
                                  position: "relative",
                                  top: ".25dvw",
                                  left: ".5vw",
                                }}
                                onClick={() =>
                                  handleAddParamToSet(index, setIndex, subIndex)
                                }
                              >
                                <PlusCircleOutlined
                                  style={{
                                    padding: 0,
                                    position: "relative",
                                    marginRight: ".25vw",
                                    fontSize: ".65vw",
                                    color: Theme.TEXT,
                                  }}
                                />
                                <span
                                  style={{
                                    padding: 0,
                                    fontSize: ".65vw",
                                    position: "relative",
                                    color: Theme.TEXT,
                                  }}
                                >
                                  Add
                                </span>
                                {/* </Button> */}
                              </Col>
                            )}
                          </Row>
                        ))}
                    </Col>
                  ))}
              </>
            ) : (
              <Col
                style={{
                  marginBottom: "1vw",
                  borderBottom: "1px solid #ccc",
                  paddingBottom: "1vw",
                }}
                key={index}
              >
                <Row style={{ marginBottom: ".75vw" }}>
                  <div>
                    <Text
                      style={{
                        color: Theme.TEXT,
                        fontWeight: "bold",
                        fontSize: ".75vw",
                      }}
                    >
                      {exercise.name || exercise.library_exercise.name}
                    </Text>
                  </div>
                  <Col
                    align="middle"
                    justify="center"
                    style={{ position: "relative", left: ".25vw" }}
                  >
                    <CloseCircleOutlined
                      onClick={() => handleRemoveExercise(index)}
                      style={{ color: Theme.PRIMARY, cursor: "pointer" }}
                    />
                  </Col>
                </Row>
                {exercise.sets &&
                  exercise.sets.map((set, setIndex) => (
                    <Row
                      key={`${index}-${setIndex}`}
                      style={{ marginBottom: setIndex !== 0 ? ".5vw" : null }}
                    >
                      <Col
                        align="middle"
                        justify="center"
                        style={{ marginRight: "1vw" }}
                      >
                        {setIndex === 0 && exercise.photo ? (
                          <div>
                            <img
                              style={{
                                height: "6vw",
                                width: "9vw",
                                borderRadius: 10,
                                objectFit: "cover",
                              }}
                              // alt={exercise.name}
                              src={exercise.photo}
                            />
                          </div>
                        ) : exercise.library_exercise?.image &&
                          setIndex === 0 ? (
                          <div>
                            <img
                              style={{
                                height: "6vw",
                                width: "9vw",
                                borderRadius: 10,
                              }}
                              // alt={exercise.name}
                              src={exercise.library_exercise.image}
                            />
                          </div>
                        ) : exercise.image && setIndex === 0 ? (
                          <div>
                            <img
                              style={{
                                height: "6vw",
                                width: "9vw",
                                borderRadius: 10,
                              }}
                              // alt={exercise.name}
                              src={exercise.image}
                            />
                          </div>
                        ) : (
                          <div
                            style={{
                              height: "6vw",
                              width: "9vw",
                              borderRadius: 10,
                             
                              backgroundColor: Theme.BACKGROUND === "#000"
                              ? Theme.BACKGROUND
                              : Theme.CARD
                            }}
                            >
                            <Text>&nbsp;</Text>
                          </div>
                        )}
                      </Col>
                      <Row
                        // align="middle"
                        // justify="center"
                        style={{ marginRight: ".65dvw" }}
                      >
                        <Text style={{ color: Theme.TEXT, fontSize: ".75vw" }}>
                          Set {setIndex + 1}
                        </Text>
                        <Col style={{ marginLeft: ".5vw" }}>
                          <div>
                            <PlusCircleOutlined
                              style={{
                                padding: 0,
                                position: "relative",
                                fontSize: ".7vw",
                                color: Theme.BLUE,
                                cursor: "pointer",
                              }}
                              onClick={() => handleAddSet(index, setIndex)}
                            />
                          </div>
                          {setIndex !== 0 && (
                            <CloseCircleOutlined
                              onClick={() => handleRemoveSet(index, setIndex)}
                              style={{
                                color: Theme.PRIMARY,
                                cursor: "pointer",
                                fontSize: ".7vw",
                                position: "relative",
                              }}
                            />
                          )}
                        </Col>
                      </Row>
                      <Controller
                          name={`exercises[${index}].sets[${setIndex}].weight.value`}
                          control={control}
                         
                          defaultValue={set.weight.value}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Col>
                              <CustomSelect
                                value={set.weight.type}
                                onChange={(value) => {
                                  handleWeightTypeChange(index, setIndex, value);
                                }}
                                width="6vw"
                                options={[
                                  { value: "% Difficulty", label: "% Difficulty" },
                                  { value: "%1RM", label: "% 1RM" },
                                  { value: "Weight", label: "Weight" },
                                ]}
                                Theme={Theme}
                                darkModeBackground={Theme.CARD}
                                lightModeBackground={Theme.CARD}
                              />
                              <div>
                                <Input
                                  value={value}
                                  style={{
                                    width: "6vw",
                                    color: Theme.TEXT,
                                    background: Theme.CARD,
                                    borderWidth: 0,
                                    fontSize: ".75vw",
                                    paddingRight: "2vw",
                                    border:  Theme.BACKGROUND === "#000" ? "0px solid #ccc" : "1px solid #ccc"

                                  }}
                                  className={
                                    Theme.BACKGROUND === "#000"
                                      ? "custom-placeholder-color-dark"
                                      : "custom-placeholder-color-light"
                                  }
                                  onChange={e => {
                                    handleWeightValueChange(index, setIndex, e.target.value); 
                                    onChange(e.target.value); 
                                  
                                  }}
                                />
                                {error && <p style={{ fontSize: "12px", color: 'red' }}>{error.message}</p>}
                              </div>
                            </Col>
                          )}
                        />
                      {set.params.map((param, paramIndex) => (
                        <Col>
                          <CustomSelect
                            value={param.type}
                            onChange={(value) =>
                              handleParamTypeChange(
                                index,
                                setIndex,
                                paramIndex,
                                value
                              )
                            }
                            darkModeBackground={Theme.CARD}
                            lightModeBackground={Theme.CARD}
                            width="6vw"
                            options={[
                              { value: "Reps", label: "Reps" },
                              { value: "Rest", label: "Rest" },
                              { value: "Time", label: "Time" },
                              { value: "Distance", label: "Distance" },
                              { value: "Height", label: "Height" },
                              { value: "Velocity", label: "Velocity" },
                              { value: "Power", label: "Power" },
                              { value: "Force", label: "Force" },
                              { value: "HR", label: "HR" },
                              { value: "HRZ", label: "HRZ" },
                              { value: "RPE", label: "RPE" },
                            ]}
                            Theme={Theme}
                          />
                      <Controller
                      name={`exercises[${index}].sets[${setIndex}].params[${paramIndex}].value`}
                      control={control}
                      defaultValue={param.value}
                      render={({ field: { onChange, value } }) => (
                        <div style={{ position: "relative" }}>
                          <Input
                            style={{
                              width: "6vw",
                              color: Theme.TEXT,
                              background: Theme.CARD,
                              borderWidth: 0,
                              fontSize: ".75vw",
                              paddingRight: "2vw",
                              border:  Theme.BACKGROUND === "#000" ? "0px solid #ccc" : "1px solid #ccc"

                            }}
                            value={value}
                            maxLength={9}
                            onChange={(e) => {
                              // Here, call your custom method for state update
                              handleParamValueChange(index, setIndex, paramIndex, e.target.value);
                              // Then, call React Hook Form's onChange method to ensure form state is updated
                              onChange(e.target.value);
                            }}
                          />
                          {paramIndex > 0 && (
                            <CloseCircleOutlined
                              onClick={() => handleRemoveParamFromSet(index, setIndex, paramIndex)}
                              style={{
                                position: "absolute",
                                right: "8px",
                                top: "50%",
                                transform: "translateY(-50%)",
                                color: Theme.TEXT,
                                cursor: "pointer",
                                fontSize: ".75vw",
                              }}
                            />
                          )}
                        </div>
                      )}
                    />                         
                      </Col>
                      ))}
                      {set.params.length < 3 && (
                        <Col
                          align="middle"
                          justify="center"
                          style={{
                            cursor: "pointer",
                            position: "relative",
                            top: ".25dvw",
                            left: ".5vw",
                          }}
                          onClick={() => handleAddParamToSet(index, setIndex)}
                        >
                          <PlusCircleOutlined
                            style={{
                              padding: 0,
                              position: "relative",
                              marginRight: ".25vw",
                              fontSize: ".65vw",
                              color: Theme.TEXT,
                            }}
                          />
                          <span
                            style={{
                              padding: 0,
                              fontSize: ".65vw",
                              position: "relative",
                              color: Theme.TEXT,
                            }}
                          >
                            Add
                          </span>
                          {/* </Button> */}
                        </Col>
                      )}
                    </Row>
                  ))}
              </Col>
            )
          )}
      <Modal
        open={reorderModal}
        onCancel={handleCloseModalReorder}
        cancelButtonProps={{ style: { display: "none" } }}
        closeIcon={<span></span>}
        footer={[<Col align={"center"} justify={"center"}></Col>]}
        width={"45vw"}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <DndProvider backend={HTML5Backend}>
          <ReorderModal
            chosenExercises={chosenExercises}
            updateExerciseOrder={updateExerciseOrder}
            onClose={() => setReorderModal(false)}
          />
        </DndProvider>
      </Modal>
    </div>
  );
};

export default ExerciseList;
