import React, { useEffect, useRef, useState } from "react";
import { aoc } from "../../constants/aocKeys";
import frontPost from "../../assets/images/Body.png";
import { Card, Col, Row, Typography, Modal, List, Button } from "antd";
import { useSelector } from "react-redux";

const { Text } = Typography;

const JointDisplacementBalance = ({ movement, hideTitle }) => {
  const { Theme } = useSelector((state) => state.auth);
  const imageHeightVw = 15; // Set th
  const imageAspectRatio = 1.2 / 1; // Set the aspect ratio
  // Calculate the image height in pixels based on the viewport width (10vw)
  const imageHeightPx = (10 / 100) * window.innerWidth;
  const mq = window.matchMedia("(min-width: 1400px)");
  const leftOrRight = (difference) => {
    var direction = "Left";
    if (difference > 0) {
      direction = "Right";
    } else if (difference == 0) {
      return "0° Difference";
    }
    return `${direction} +${Math.abs(difference.toFixed(2))}°`;
  };

  // Calculate the image width in pixels based on the aspect ratio and image height
  const imageWidthPx = imageAspectRatio * imageHeightPx * .9;
  return (
    <div
    className="aoc"
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
     
      // minHeight: "13vw",
      // height: 'auto'
    }}
  >
    <Row
      type="flex"
      style={{ alignItems: "center" }}
      justify="center"
      gutter={10}
      
    >
      <Col
              align="center"
        span={24}
        style={{
          fontWeight: "bold",
          // marginTop: "5%",
          // left: "7.5%",
          width: "100%",
          position: "relative",
       
        }}
      >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              bottom: '1.75vw',
              position: "relative",
              width: "75%", // Adjust the width as needed
            }}
          >
            <div
              style={{
                position: "relative",

                width: "100%",
                height: `${imageHeightVw}vw`,
              }}
            >
              <svg
                viewBox={`0 0 ${imageWidthPx} ${imageHeightPx}`}
                style={{
                  width: "100%",
                  height: "100%",
                  position: "relative",
                  right: "4vw",
                  backgroundColor: "transparent", // Make the background transparent
                }}
              >
                 <defs>
                <filter id="drop-shadow">
                  <feDropShadow
                    dx="2"
                    dy="6"
                    stdDeviation="6"
                    floodColor="rgba(0, 0, 0, 0.25)"
                  />
                </filter>
              </defs>
              <image
                href={frontPost}
                alt="Body"
                width={imageWidthPx}
                height={imageHeightPx}
                style={{
                  objectFit: "cover",
                  filter: "url(#drop-shadow)", // Apply the drop shadow filter
                }}
              />

                <line
                  x1={(43 / 100) * imageWidthPx }
                  y1={(20 / 100) * imageHeightPx - movement.shoulder_level_avg /2}
                  x2={(57 / 100) * imageWidthPx}
                  y2={(20 / 100) * imageHeightPx + movement.shoulder_level_avg /2}
                  stroke={Theme.BLUE}
                  strokeWidth="4"
                />
                <line
                  x1={(46 / 100) * imageWidthPx}
                  y1={(50 / 100) * imageHeightPx - movement.hip_level_avg /2}
                  x2={(54 / 100) * imageWidthPx}
                  y2={(50 / 100) * imageHeightPx  + movement.hip_level_avg /2}
                  stroke={Theme.BLUE}
                  strokeWidth="4"
                />
             
                <circle
                  cx={(43 / 100) * imageWidthPx}
                  cy={(20 / 100) * imageHeightPx - movement.shoulder_level_avg /2}
                  r={(0.04 * imageWidthPx) / 2}
                  fill={Theme.BLUE}
                />
                <circle
                  cx={(57 / 100) * imageWidthPx}
                  cy={(20 / 100) * imageHeightPx +  movement.shoulder_level_avg /2}
                  r={(0.04 * imageWidthPx) / 2}
                  fill={Theme.BLUE}
                />
                <circle
                  cx={(46 / 100) * imageWidthPx}
                  cy={(50 / 100) * imageHeightPx - movement.hip_level_avg /2}
                  r={(0.04 * imageWidthPx) / 2}
                  fill={Theme.BLUE}
                />
                <circle
                  cx={(54 / 100) * imageWidthPx}
                  cy={(50 / 100) * imageHeightPx + movement.hip_level_avg /2}
                  r={(0.04 * imageWidthPx) / 2}
                  fill={Theme.BLUE}
                />
             
              </svg>
              <div style={{position: 'absolute', top: '25%'}}>
              <Col
                className="list"
                style={{
                  position: "relative",
                  listStyle: "none",
                  width: "100%",
                  left: "6vw",
                  marginBottom: '5%'
                }}
              >
                <div>
                  <Text
                    style={{
                      color: Theme.TEXT,
                      fontSize: ".75vw",
                      fontWeight: "bold",
                      // fontFamily: "ArialBold",
                    }}
                  >
                    Shoulder Level Average
                  </Text>
                </div>

                <div>
                  <Text
                    style={{
                      color: Theme.PRIMARY,
                      fontSize: ".75vw",
                      fontWeight: "bold",
                    }}
                  >
                    {leftOrRight(movement.shoulder_level_avg)}
                  </Text>
                </div>
              </Col>
              <Col
                className="list"
                style={{
                  position: "relative",
                  listStyle: "none",
                  width: "100%",
                  left: "6vw",
                  marginTop: '15%'
                }}
              >
                <div>
                  <Text
                    style={{
                      color: Theme.TEXT,
                      fontSize: ".75vw",
                      fontWeight: "bold",
                      // fontFamily: "ArialBold",
                    }}
                  >
                    Hip Level Average
                  </Text>
                </div>

                <div>
                  <Text
                    style={{
                      color: Theme.PRIMARY,
                      fontSize: ".75vw",
                      fontWeight: "bold",
                    }}
                  >
                         {leftOrRight(movement.hip_level_avg)}
                  </Text>
                </div>
              </Col>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default JointDisplacementBalance;
