import React, { useMemo, useRef, useState } from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';
import styled, { css } from 'styled-components'; // Import styled-components
import { useSelector } from 'react-redux';

const { Option } = Select;

function DebounceSelect({ fetchOptions, debounceTimeout = 800, staticOptions =[], ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState(staticOptions);
  const fetchRef = useRef(0);
  const { Theme } = useSelector((state) => state.auth);

  const conditionalClassName = useMemo(() => {
    return Theme.BACKGROUND === "#000" ? "customDarkMode" : "customLightMode";
  }, [Theme.BACKGROUND]);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          return;
        }
        const combinedOptions = [...staticOptions, ...newOptions];
        setOptions(combinedOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout, staticOptions]);

  return (
    <StyledSelect
      className={conditionalClassName}
      filterOption={false}
      onSearch={debounceFetcher}
      placeholder={"Search"}
      notFoundContent={fetching ? <Spin size="small" /> :  null}
      {...props}
      options={options}
    />
  );
}

const StyledSelect = styled(Select)`
  // Common styles for both light and dark modes
  .ant-select-selector {
    font-weight: bold;
    border: none;
  }

  .ant-select-selection-item,
  .ant-select-item-option-content,
  .ant-select-dropdown {
    align-self: center !important;
    text-align: center !important;
  }

  .ant-select-selector .ant-select-selection-placeholder,
  .ant-select-selector .ant-select-selection-item {
    text-align: left !important;
    font-weight: normal;
  }

  .ant-picker-input > input::placeholder,
  ::placeholder {
    align-self: center !important;
    text-align: center !important;
    color: ${({ darkModeBackground }) =>
      darkModeBackground ? "#fff" : "#000"};
  }

  .ant-select-multiple .ant-select-selection-item-remove svg {
    color: white;
  }

  // Styles specific to dark mode
  &.customDarkMode {
    .ant-select-selector {
      background-color: ${({ darkModeBackground }) =>
        darkModeBackground || "#000"};
      color: #f1f1fb;
    }
    .ant-select-selection-item,
    .ant-select-item-option-content,
    .ant-select-dropdown,
    .ant-select-item-option {
      color: #f1f1fb;
    }
    .ant-select-item-option:hover,
    .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${({ darkModeBackground }) =>
        darkModeBackground || "#000"};
    }
    .ant-select-selection-item-remove {
      color: white;
    }
    :where(.css-dev-only-do-not-override-byeoj0).ant-select:not(.ant-select-customize-input) .ant-select-selector {
      border: none
    }
  }

  // Styles specific to light mode
  &.customLightMode {
    .ant-select-selector {
      background-color: ${({ lightModeBackground }) =>
        lightModeBackground || "#f1f1fb"};
      color: #000;
    }
    .ant-select-selection-item,
    .ant-select-item-option-content,
    .ant-select-dropdown,
    .ant-select-item-option {
      color: #000;
    }
    .ant-select-item-option:hover,
    .ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
      background-color: ${({ lightModeBackground }) =>
        lightModeBackground || "#f1f1fb"};
    }
    .ant-select-selection-placeholder {
      color: black;
    }
  }
`;

export default DebounceSelect;
