import React, { useState } from "react";
import { Col, Modal, Row, Typography } from "antd";
import { IoInformationCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

const { Text } = Typography;

function ResultsSectionTitle({ section }) {
  const [modalVisible, setModalVisible] = useState(false);
  const { Theme } = useSelector((state) => state.auth);

  return (
    <div
      style={{
        // marginBottom: "1%",
        width: "100%",
        fontWeight: "bold",
        position: "relative",
    
      }}
    >
      <Col>
      <Text style={{    marginTop: 12,
    fontSize: "1vw",
    fontWeight: "600",
    alignSelf: "center",
    position: "relative",
    color: Theme.TEXT}}>{(section.title).toUpperCase()}</Text>
<div>
      <IoInformationCircleOutline
        onClick={() => setModalVisible(true)}
        color={Theme.PRIMARY}
        size={".8vw"}
        style={{
          alignSelf: "center",
          // left: "2%",
          // top: ".2vw",
          zIndex: 80,
          cursor: "pointer",
          position: "relative",
        }}
      />
     </div>
      </Col>
      <Modal
        open={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        bodyStyle={{ height: "17vw", padding: "1vw" }}
        width={"30vw"}
        className={
          Theme.BACKGROUND === "#000"
            ? "customAntdModalDark"
            : "customAntdModalLight"
        }
      >
        <Row justify="center">
          <Col span={24}>
            <h1 style={{ position: "relative", bottom: "1vw", color: Theme.TEXT }}>
              {section.title}
            </h1>
            <Text
              style={{ fontSize: "1vw", position: "relative", bottom: "1vw", color: Theme.TEXT2 }}
            >
              {section.text}
            </Text>
          </Col>
        </Row>
      </Modal>
    </div>
  );
}



export default ResultsSectionTitle;
