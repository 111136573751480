import React, { useEffect, useRef, useState } from "react";
import { aoc } from "../../constants/aocKeys";
import { Col, Row, Typography } from "antd";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import frontPost from "../../assets/images/Body.png";
import { useSelector } from "react-redux";
const { Text } = Typography;

const Body = ({ areasOfConcerns, movements }) => {
  const [array, setArray] = useState([]);
  const [filteredKeys, setFilteredKeys] = useState([]);
  const { Theme } = useSelector((state) => state.auth);
  const filterConcerns = () => {
    const result = aoc.filter((concern) => {
      return array.find((selected) => selected === concern.imbalance);
    });
    setFilteredKeys(result);
  };
  useEffect(() => {
    let arr = [];
    for (const area in areasOfConcerns) {
      arr.push(areasOfConcerns[area]);
    }
    setArray(arr);
    setFilteredKeys();
  }, [areasOfConcerns]);

  useEffect(() => {
    filterConcerns();
  }, [array]);

  if (array.length > Object.keys(areasOfConcerns).length) {
    let arr = [];
    for (const area in areasOfConcerns) {
      arr.push(areasOfConcerns[area]);
    }
    setArray(arr);
  }
  const imageHeightVw = 10; // Set th
  const imageAspectRatio = 1.3 / 1; // Set the aspect ratio
  // Calculate the image height in pixels based on the viewport width (10vw)
  const imageHeightPx = (10 / 100) * window.innerWidth * 1.35;
  const mq = window.matchMedia("(min-width: 1100px)");

  // Calculate the image width in pixels based on the aspect ratio and image height
  const imageWidthPx = imageAspectRatio * imageHeightPx;
  return (
    <Col
      className="aoc"
      style={{
        flexDirection: "column",
        alignItems: "center",
        top: "0vw",
        minHeight: "9vw"
      }}
      justify="center"
      align="center"
    >
      <Row
        justify="start"
        align="middle"
        style={{ width: "100%", marginLeft: 25 }}
      >
        {/* Text always centered */}
        <Col>
          <h1
            style={{
              fontSize: 17.5,
              color: Theme.TEXT,
              textAlign: "left"
            }}
          >
            Areas of Concern
          </h1>
        </Col>
      </Row>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          top: 17.5,
          position: "relative",
          width: "75%" // Adjust the width as needed
        }}
      >
        <div
          style={{
            position: "relative",

            width: "100%",
            height: 250,
            right: 25
          }}
        >
          <svg
            viewBox={`0 0 ${250} ${250}`}
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              right: 50,
              bottom: 5,
              backgroundColor: "transparent" // Make the background transparent
            }}
          >
            <defs>
              <filter id="drop-shadow">
                <feDropShadow
                  dx="2"
                  dy="6"
                  stdDeviation="6"
                  floodColor="rgba(0, 0, 0, 0.25)"
                />
              </filter>
            </defs>
            <image
              href={frontPost}
              alt="Body"
              width={imageWidthPx}
              height={imageHeightPx}
              style={{
                objectFit: "cover"
              }}
            />
            {filteredKeys.map((concern) => (
              <circle
                key={concern.key}
                cx={(concern.height / 100) * imageWidthPx}
                cy={(concern.width / 100) * imageHeightPx}
                r={(0.04 * imageWidthPx) / 2}
                fill={concern.color}
              />
            ))}
          </svg>

          {filteredKeys.length > 1 ? (
            <Row
              style={{ left: 25, position: "relative" }}
              align={"middle"}
              justify={"end"}
            >
              <Col
                // className="list"
                style={{
                  position: "relative",
                  textAlign: "left",

                  bottom: 250
                }}
              >
                {filteredKeys.map((k) => (
                  <Col
                    style={{
                      marginBottom: mq.matches ? null : "-7.5%",
                      width: "100%"
                    }}
                  >
                    <Text
                      style={{
                        color: Theme.TEXT,
                        fontSize: 15,
                        fontWeight: "bold",
                        textAlign: "left"
                      }}
                    >
                      {k.area}:
                    </Text>
                  </Col>
                ))}
              </Col>
              <Col
                className="list"
                style={{
                  position: "relative",
                  bottom: 250
                }}
              >
                {filteredKeys.map((k) => (
                  <Col style={{ marginBottom: mq.matches ? null : "-7.5%" }}>
                    <Text
                      style={{
                        color: Theme.PRIMARY,
                        fontSize: 15,
                        fontWeight: "bold",
                        // fontFamily: "ArialBold",
                        marginLeft: "1vw"
                      }}
                    >
                      {k.type}
                    </Text>
                  </Col>
                ))}
              </Col>
            </Row>
          ) : (
            <Col
              justify="center"
              align="middle"
              style={{
                position: "relative",
                listStyle: "none",
                width: "100%",
                left: "11vw",
              
                  bottom: 225,
                alignContent: "center"
              }}
            >
              <div>
                <IoCheckmarkCircleOutline
                  size={20}
                  color={Theme.PRIMARY}
                />
              </div>
              {movements.length === 1 && movements[0] === "Posture" ? (
                <Col>
                  <Text
                    style={{
                      fontSize: ".75vw",
                      fontWeight: "600",
                      left: "1.3vw",
                      position: "relative"
                    }}
                  >
                    Not Applicable
                  </Text>
                  <Col>
                    <Text
                      style={{
                        fontSize: ".9vw",
                        fontWeight: "600",
                        left: "2vw",
                        position: "relative",
                        textAlign: "center"
                      }}
                    >
                      for Posture
                    </Text>
                  </Col>
                </Col>
              ) : (
                <Text
                  style={{
                    fontSize: 14,
                    fontWeight: "600",
                    color: Theme.TEXT,

                    bottom: 250
                  }}
                >
                  No Concerns Measured
                </Text>
              )}
            </Col>
          )}
        </div>
      </div>
    </Col>
  );
};

export default Body;
