import React from "react";
import { Card, Col, Row, Typography, Spin } from "antd";
import Theme from "../../constants/Theme";
import { useSelector } from "react-redux";
import ResultsSectionTitle from "./ResultsSectionTitle";
import sections from "../../constants/sections";
import Compensations from "./Compensations";
import JointDisplacement from "./JointDiscplacement";
import ScoreCircle from "../chart/ScoreCircle";
const { Text } = Typography;

const RdlResults = ({ item, compensations, rdl }) => {
  const { Theme } = useSelector((state) => state.auth);
  const styles = createStyles(Theme);

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        top: "5%",
        position: "relative"
      }}
    >
      <Col justify="start">
        <Row
          style={{ alignSelf: "center", position: "relative" }}
          gutter={[16, 16]}
        >
          <Col>
            <Card
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: "30vw",
                display: "flex",
                borderRadius: 10,
                backgroundColor: Theme.CARD,
                boxShadow: "2px 6px 6px 6px rgba(0, 0, 0, 0.025)",
                marginBottom: "15%",
                borderWidth: 0,
                paddingBottom: "5vw"
              }}
              className="criclebox h-full"
            >
              <div
                className="aoc"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  top: "2vw",
                  position: "relative",
                  width: "28vw"
                  // minHeight: "13vw",
                  // height: 'auto'
                }}
              >
                <Col align="center" style={{ position: "relative" }}>
                  <Row
                    align="center"
                    style={{
                      position: "relative",
                      width: "28vw",
                      right: "1.15vw"
                    }}
                  >
                    <Col
                      align={"start"}
                      style={{ position: "relative", right: "17.5%" }}
                    >
                      <Text style={styles.movementTitle}>SINGLE LEG</Text>
                      <div style={{ bottom: ".25vw", position: "relative" }}>
                        <Text style={styles.movementTitle}>
                          HINGE ({rdl.side[0]})
                        </Text>
                      </div>
                    </Col>
                    <Col
                      align="middle"
                      style={{
                        position: "relative",
                        left: "20%",
                        bottom: "1vw"
                      }}
                    >
                      <ScoreCircle
                        score={Math.round(rdl.rdl_overall_score)}
                        height="4vw"
                      />
                      <div>
                        <Text style={styles.messageText}>Overall</Text>
                      </div>
                    </Col>
                  </Row>
                </Col>

                <Col
                  align="center"
                  justify="center"
                  style={{
                    marginBottom: ".5vw",
                    position: "relative"
                    // bottom: ".5vw",
                    // right: '1.5%'
                  }}
                >
                  <Col align="center">
                    <Row align="center" justify="center">
                      <Col
                        align="center"
                        justify="center"
                        style={{ marginTop: "1.5vw" }}
                      >
                        <Col
                          style={{
                            top: ".5vw",
                            position: "relative"
                          }}
                        >
                          <Text style={styles.messageText}>
                            {rdl.side} Hip Mobility
                          </Text>
                        </Col>
                        <Row
                          align="middle"
                          justify="center"
                          style={{ marginTop: "1vw" }}
                        >
                          <div>
                            <ScoreCircle
                              score={Math.round(rdl?.hip_score)}
                              height="5vw"
                            />
                          </div>

                          {rdl?.hip_range && (
                            <Col style={{ marginLeft: "1vw" }}>
                              <Text style={styles.typeText}>Starting</Text>
                              <Col>
                                <Text style={styles.angleText}>
                                  {Math.round(rdl?.hip_max)}°
                                </Text>
                              </Col>
                              <Text style={styles.typeText}>Ending</Text>
                              <Col>
                                <Text style={styles.angleText}>
                                  {Math.round(rdl?.hip_min)}°
                                </Text>
                              </Col>
                              <Text style={styles.typeText}>ROM</Text>
                              <Col>
                                <Text style={styles.angleText}>
                                  {Math.round(rdl?.hip_range)}°
                                </Text>
                              </Col>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: "2.5vw" }}>
                      <Col align="center">
                        <Col
                          style={{
                            top: ".5vw",
                            position: "relative"
                          }}
                        >
                          <Text style={styles.messageText}>
                            {rdl.side} Knee Stability
                          </Text>
                        </Col>
                        <Row
                          align="middle"
                          justify="center"
                          style={{ marginTop: "1vw" }}
                        >
                          <div>
                            <ScoreCircle
                              score={Math.round(rdl?.knee_score)}
                              height="5vw"
                            />
                          </div>

                          {rdl?.knee_range && (
                            <Col style={{ marginLeft: "1vw" }}>
                              <Col style={{ visibility: "hidden" }}>
                                <Text style={styles.typeText}>Starting</Text>
                              </Col>
                              <Col>
                                <Text style={styles.angleText}>
                                  {Math.round(rdl?.knee_range)}°
                                </Text>
                              </Col>
                              <Text style={styles.typeText}>from</Text>

                              <Col>
                                <Text style={styles.typeText}>Starting</Text>
                              </Col>
                              <Col>
                                <Text style={styles.typeText}>Position</Text>
                              </Col>
                            </Col>
                          )}
                        </Row>
                      </Col>
                      <Col style={{ marginLeft: "5.5vw" }}>
                        <Col
                          style={{
                            position: "relative",
                            top: ".5vw"
                          }}
                        >
                          <Text style={styles.messageText}>
                            {rdl?.side} Ankle Stability
                          </Text>
                        </Col>
                        <Row
                          align="middle"
                          justify="center"
                          style={{ marginTop: "1vw" }}
                        >
                          <div>
                            <ScoreCircle
                              score={Math.round(rdl?.ankle_score)}
                              height="5vw"
                            />
                          </div>

                          {rdl?.ankle_range && (
                            <Col style={{ marginLeft: "1vw" }}>
                              <Col style={{ visibility: "hidden" }}>
                                <Text style={styles.typeText}>Starting</Text>
                              </Col>
                              <Col>
                                <Text style={styles.angleText}>
                                  {Math.round(rdl?.ankle_range)}°
                                </Text>
                              </Col>
                              <Text style={styles.typeText}>from</Text>

                              <Col>
                                <Text style={styles.typeText}>Starting</Text>
                              </Col>
                              <Col>
                                <Text style={styles.typeText}>Position</Text>
                              </Col>
                            </Col>
                          )}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Col>
              </div>
            </Card>
          </Col>

          <Col>
            <Card
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: "20vw",
                borderRadius: 10,
                background: Theme.CARD,
                borderWidth: 0
              }}
              className="criclebox h-full"
            >
              <Compensations
                compensations={compensations}
                score={rdl.compensation_score}
              />
            </Card>
          </Col>
          <Col>
            <Card
              style={{
                zIndex: 100,
                flex: 1,
                position: "relative",
                width: "20vw",
                borderRadius: 10,
                background: Theme.CARD,
                borderWidth: 0
              }}
              className="criclebox h-full"
            >
              <JointDisplacement movement={rdl} />
            </Card>
          </Col>
        </Row>
      </Col>
    </div>
  );
};

export default RdlResults;

let textSize = 14;
let modalText = 16;
let compTitle = 20;
let compSectText = 18;
let modalMargin = 0;
let picLeft = "2%";
let avgRight = 160;
let minHeight = 160;

const createStyles = (theme) => ({
  container: {
    alignSelf: "center"
  },
  card: {
    backgroundColor: Theme.COLORS.WHITE,
    //   marginVertical: Theme.SIZES.BASE,
    borderWidth: 0,
    minHeight: minHeight,
    marginBottom: 1,
    marginHorizontal: 1
  },

  cardTitle: {
    flex: 1,
    alignSelf: "center",
    fontWeight: "500"
    // paddingTop: 5,
  },
  cardDescription: {
    // paddingTop: 5,
  },
  imageContainer: {
    borderRadius: 3,
    elevation: 1,
    overflow: "hidden",
    minHeight: 80
  },

  horizontalImage: {
    width: "40%",
    height: "40%"
    // position: "absolute",
    // left: "5%",
    // bottom: "1vw",
  },
  videoText: {
    color: Theme.COLORS.BLACK,
    fontSize: 12,
    left: "5.5%",
    marginTop: "1%",
    maxWidth: "60%",
    maxHeight: "50%",
    fontWeight: "bold"
  },
  horizontalStyles: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0
  },
  verticalStyles: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0
  },
  fullImage: {
    height: 215
  },
  messageText: {
    color: theme.TEXT,
    fontSize: ".85vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  typeText: {
    color: theme.TEXT,
    fontSize: ".85vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".65vw"
  },
  depthText: {
    color: theme.TEXT,
    fontSize: ".65vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".5vw"
  },
  messageTextVideo: {
    color: Theme.COLORS.WHITE,
    fontSize: ".5vw",
    fontWeight: "bold",
    paddingVertical: 8,
    paddingRight: 4,
    fontFamily: "Arial"
  },
  compScoreText: {
    color: Theme.COLORS.BLACK,
    fontSize: textSize,
    fontWeight: "bold",
    // paddingVertical: 8,
    // paddingRight: 4,
    fontFamily: "Arial",
    // alignSelf: 'flex-end',

    top: 275
  },
  resultText: {
    color: Theme.COLORS.PRIMARY,
    fontSize: ".75vw",
    position: "relative",
    right: "35%",
    fontWeight: "bold"
    // marginRight: "5%",
  },
  sectionText: {
    fontWeight: "500",
    fontSize: 19,
    color: Theme.COLORS.BLACK
  },
  compensationTitle: {
    fontWeight: "500",
    fontSize: compTitle,
    marginTop: 30,
    marginBottom: 5,
    color: Theme.COLORS.POP
  },
  scoreText: {
    fontWeight: "500",
    fontSize: ".75vw",
    color: Theme.COLORS.PRIMARY
  },
  movementTitle: {
    textAlign: "left",
    fontSize: "1.2vw",
    fontWeight: "bold",
    // fontFamily: "ArialBold",
    color: theme.TEXT,
    position: "relative",
    // alignSelf: "center",
    left: ".5%"
  },
  movementScore: {
    fontWeight: "bold",
    fontSize: ".75vw",
    // alignSelf: "center",
    color: Theme.COLORS.PRIMARY,
    left: ".45vw",
    position: "relative"
    // marginTop: 5,
  },
  list: {
    position: "absolute",
    top: 20,
    zIndex: 20,
    flex: 1
  },

  modalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4,
    top: modalMargin
    // elevation: 5,
  },
  bottomView: {
    flex: 1,
    justifyContent: "flex-end",
    top: 20,
    alignSelf: "center"
  },
  infoModalView: {
    width: "100%",
    height: "75%",
    // backgroundColor: "black",
    // backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2
    },
    alignSelf: "center",
    shadowOpacity: 0.25,
    shadowRadius: 4
    // elevation: 5,
  },
  button: {
    marginVertical: 15,
    width: 250,
    borderRadius: 15,
    // position: "relative",
    backgroundColor: "white"
  },
  buttontext: {
    color: Theme.COLORS.PRIMARY,
    fontWeight: "bold",
    paddingHorizontal: 5
  },
  modalText: {
    color: Theme.COLORS.WHITE,
    maxWidth: "85%",
    marginBottom: 20,
    textAlign: "center",
    fontSize: modalText,
    marginTop: 5
  },
  video: {
    alignSelf: "center",
    width: "100%",
    height: "95%",
    marginVertical: "10%",
    marginHorizontal: "2%",
    bottom: "8%",

    backgroundColor: "black"
  },
  angleText: {
    color: theme.BLUE,
    fontSize: ".85vw",
    position: "relative",

    fontWeight: "bold",
    bottom: ".65vw"
  }
});
