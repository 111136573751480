import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { Input, Button, Checkbox, Select, Card, Row, Typography, Col } from "antd";
import { Formik } from "formik";
import * as yup from "yup";
import {
  updateTrainerGroups,
  resetGroupStatus
} from "../../redux/actions/trainer_groups";
import {
 getClients
} from "../../redux/actions/clients";

import { css, StyleSheet } from "aphrodite";
import Theme from "../../constants/Theme";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import DebounceSelect from "../loaders/DebounceSelect";
import axios from "axios";
import { REACT_APP_API_URL } from "../../env";

const AddClientGroupForm = ({group, closeModal}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const formikRef = useRef(null);
  const {user,Theme} = useSelector(state => state.auth)
  
  const handleSubmit = async (values, { resetForm }) => {
    var data = {}

    data = {
        add_clients: values.trainer_id
      };
      
    // Dispatch action to update trainer groups
    dispatch(updateTrainerGroups(id, data));

    // Reset the form
    resetForm();
    
    // Close the modal
    closeModal()
  };

  

  async function fetchClientsList(searchTerm) {
    try {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          Authorization: `JWT ${token}`,
        },
      };
  
      const response = await axios.get(
        `${REACT_APP_API_URL}/api/clientsnew/?search=${searchTerm}`,
        config
      );
      const filteredClients = response.data.results.filter((client) => {
        // Exclude clients already in the group + Guests
        const isClientInGroup = group.clients.some((groupClient) => groupClient.id === client.id);
        const isGuest = client.isGuest === true;
        return !isClientInGroup && !isGuest;
      });

      
      return filteredClients.map(client => ({
        label: `${client.first_name} ${client.last_name}`,
        value: client.id.toString(),
      }));
    } catch (error) {
      return [];
    }
  }
  
  return (
    <Col
      bordered={true}
      style={{
        zIndex: 100,
        // flex: 1,
        position: "relative"
      }}
      className="criclebox h-full"
    >
      <div>
        <Formik
          initialValues={{
            trainer_id: [],
            account_type: ""
          }}
          ref={formikRef}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          validationSchema={yup.object().shape({
            trainer_id: yup.array().required("Required")
          })}
        >
          {({
            values,
            setFieldValue,
            handleChange,
            handleSubmit,
            resetForm,
            touched,
            errors
          }) => (
            <Col align="center" className={css(styles.registerContainer)}>
              <div className={css(styles.block, styles.center)}>
                <div className={css(styles.title)}>
                  <p
                    className={css(styles.titleText)}
                    style={{ color: Theme.TEXT }}
                  >
                    Add {user?.profile?.client_label}s
                  </p>
                </div>

                <Row
                  justify={"center"}
                  className={css(styles.row)}
                  style={{ bottom: 25, marginBottom: ".75vw" }}
                >
                  <div className={css(styles.column, styles.margin5)}>
                    <DebounceSelect
                      style={{ width: "15vw", bottom: 5 }}
                      showSearch
                      suffixIcon={null}
                      placeholder={
                        <span
                          style={{
                            color:
                              Theme.BACKGROUND === "#000" ? "#fff" : "#000",
                            fontSize: ".65vw",
                            top: ".05vw",
                            position: "relative",
                            fontWeight: "normal"
                          }}
                        >
                          Search
                        </span>
                      }
                      mode="multiple"
                      tokenSeparators={[","]}
                      optionFilterProp="children"
                      onChange={(selectedValues) => {
                        setFieldValue("trainer_id", selectedValues);
                      }}
                      fetchOptions={fetchClientsList}
                      debounceTimeout={800}
                    />
                    {touched.trainer_id && errors.trainer_id && (
                      <p className={css(styles.errors)}>{errors.trainer_id}</p>
                    )}
                  </div>
                </Row>

                <button
                  className={css(styles.createButton)}
                  type="submit"
                  onClick={handleSubmit}
                  style={{
                    cursor: "pointer"
                  }}
                >
                  <div className={css(styles.buttonGradient)}>
                    <p className={css(styles.buttontext)}>Submit</p>
                  </div>
                </button>
              </div>
            </Col>
          )}
        </Formik>
      </div>
    </Col>
  );
};

export default AddClientGroupForm;

const styles = StyleSheet.create({
  title: {
    color: "#232b2b",
    fontSize: 18,

    bottom: 25,
    fontWeight: "600",
    alignSelf: "center",
  },
  registerContainer: {
  
    borderRadius: 10,
    shadowColor: Theme.COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 4,
    },
    justifyContent: "center",
    shadowRadius: 8,
    shadowOpacity: 0.3,
    elevation: 1,
    // height: "15vw",
    // width: "15vw",
    // marginTop: 10,
  },
  inputIcons: {
    marginRight: 12,
  },
  titleText: { fontSize: "1vw" },
  createButton: {
    width: "5vw",
    marginTop: "5%",
    backgroundImage:
    "linear-gradient(45deg, #C04848 0%, #480048 80%)",
    borderWidth: 0,
    borderRadius: 10,
  },
  buttontext: {
    color: Theme.COLORS.WHITE,
    fontWeight: "bold",
    marginHorizontal: 5,
    // paddingHorizontal: 5,
  },
  errors: {
    position: "relative",
    // top: 55,
    color: "red",
    fontSize: '.75vw',
    // right: '25%'
    // alignSf: 'flex-start'
    // marginLeft: 12,
  },
  checkbox: {
    // top: 9,
    color: Theme.COLORS.PRIMARY,
    borderRadius: 5,
    width: 15,
    height: 15,
    position: "relative",
    right: "5%",
    top: "10%",
  },
  movementTitle: {
    alignSelf: "center",
    fontSize: "1.3vw",
    fontWeight: "bold",
    color: Theme.COLORS.BLACK,
    textAlign: "center",
  },
  clientDescr: {
    alignSelf: "center",
    fontSize: ".75vw",
    // fontWeight: "bold",
    color: Theme.COLORS.BLACK,
    textAlign: "center",
  },
});
