import {
  CREATE_TRAINER_GROUP_SUCCESS,
  CREATE_TRAINER_GROUP_FAIL,
  GET_TRAINER_GROUPS,
  GET_SINGLE_TRAINER_GROUP,
  RESET_TRAINER_GROUP_STATUS,
  UPDATE_TRAINER_GROUP,
  DELETE_TRAINER_GROUP,
  SEARCH_TRAINER_GROUPS,
  TRAINER_GROUP_MOVEMENTS,
} from "../actions/types";

const initialState = {
  trainer_groups: [],
  trainer_group: {},
  traineGroupStatus: null,
  trainerGroupId: null,
  trainerGroupMovements: []
};

function trainerGroupsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_TRAINER_GROUPS:
      return {
        ...state,
        trainer_groups: action.payload,
      };
    case SEARCH_TRAINER_GROUPS:
      return {
        ...state,
        trainer_groups: action.payload,
      };
    case GET_SINGLE_TRAINER_GROUP:
      return {
        ...state,
        trainer_group: action.payload,
      };
    case CREATE_TRAINER_GROUP_SUCCESS:
      return {
        ...state,
        trainer_groups: [...state.trainer_groups, action.payload.data],
        trainerGroupStatus: action.payload.status,
        trainerGroupId: action.payload.data.id,
      };
    case CREATE_TRAINER_GROUP_FAIL:
      return {
        ...state,
        trainerGroupStatus: action.payload,
      };
    case UPDATE_TRAINER_GROUP:
      const groupIndex = state.trainer_groups.findIndex(
        (trainer_group) => trainer_group.id === action.payload.data.id
      );
      const updateGroup = [...state.trainer_groups];
      updateGroup[groupIndex] = action.payload.data;
      return {
        ...state,
        trainer_group: updateGroup[groupIndex],
        trainerGroupStatus: action.payload.status,
        trainer_groups: updateGroup,
      };
    case DELETE_TRAINER_GROUP:
      const updatedGroups = state.trainer_groups.filter(
        (trainer_group) => trainer_group.id !== action.payload.id
      );
      return {
        ...state,
        trainer_groups: updatedGroups,
        trainer_group: {},
      };
    case RESET_TRAINER_GROUP_STATUS:
      return {
        ...state,
        trainerGroupStatus: null,
      };
    case TRAINER_GROUP_MOVEMENTS:
        return{
          ...state,
          trainerGroupMovements: action.payload
        }
    default:
      return state;
  }
}

export default trainerGroupsReducer;
