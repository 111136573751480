import {
  CREATE_GROUP_SUCCESS,
  CREATE_GROUP_FAIL,
  GET_GROUPS,
  GET_SINGLE_GROUP,
  RESET_GROUP_STATUS,
  UPDATE_GROUP,
  DELETE_GROUP,
  SEARCH_GROUPS,
  RESET_GROUP
} from "../actions/types";

const initialState = {
  groups: [],
  group: {},
  groupStatus: null,
  groupId: null,
};

function groupsReducer(state = initialState, action) {
  switch (action.type) {
    case GET_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case SEARCH_GROUPS:
      return {
        ...state,
        groups: action.payload,
      };
    case GET_SINGLE_GROUP:
      return {
        ...state,
        group: action.payload,
      };
    case CREATE_GROUP_SUCCESS:
      return {
        ...state,
        groups: [...state.groups, action.payload.data],
        groupStatus: action.payload.status,
        groupId: action.payload.data.id,
      };
    case CREATE_GROUP_FAIL:
      return {
        ...state,
        groupStatus: action.payload,
      };
    case UPDATE_GROUP:
      const groupIndex = state.groups.findIndex(
        (group) => group.id === action.payload.data.id
      );
      const updateGroup = [...state.groups];
      updateGroup[groupIndex] = action.payload.data;

      return {
        ...state,
        group: updateGroup[groupIndex],
        groupStatus: action.payload.status,
        groups: updateGroup,
      };
    case DELETE_GROUP:
      const updatedGroups = state.groups.filter(
        (group) => group.id !== action.payload.id
      );
      return {
        ...state,
        groups: updatedGroups,
        group: {},
      };
    case RESET_GROUP_STATUS:
      return {
        ...state,
        groupStatus: null,
      };
    case RESET_GROUP:
      return {
        ...state,
        group: initialState.group,
      }
    default:
      return state;
  }
}

export default groupsReducer;
