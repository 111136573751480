import axios from "axios";
import {
  GET_TRAINERS,
  CREATE_TRAINER_FAIL,
  GET_SINGLE_TRAINER,
  CREATE_TRAINER_SUCCESS,
  UPDATE_TRAINER,
  DELETE_TRAINER,
  SEARCH_TRAINERS,
  UPDATE_TRAINER_PROFILE,
  GET_SINGLE_TRAINER_FAILED,
  RESET_TRAINER
} from "./types";
 
import { REACT_APP_API_URL } from "../../env";
import { checkAuthenticated, generatePassword, signupTrainer } from "./auth";


// Create trainer
export const createTrainer = (data) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };
  const tmpPassword = generatePassword(20)
  data.append("password", tmpPassword);
  data.append("account_type", 1);
  try {
    const res = await axios.post(
      `${REACT_APP_API_URL}/api/trainers/create`,
      data,
      config
    );
    if (res.data) {
      dispatch({
        type: CREATE_TRAINER_SUCCESS,
        payload: res
      });
    }
  } catch (err) {
    dispatch({
      type: CREATE_TRAINER_FAIL,
      payload: err.response.status,
    });
  }
    
};

// Get Trainers
export const getTrainers = (page_no = 1,  includeSelf = true) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: "JWT " + token,
        },
      };
      const res = await axios.get(`${REACT_APP_API_URL}/api/organization/trainers/?page=${page_no}&includeSelf=${includeSelf}`, config);
      if (res.data) {
        dispatch({
          type: GET_TRAINERS,
          payload: res.data.results,
          total_count: res.data.count
        });
      } else {

      }
    };
  } catch (err) {
  
  }
};


// Search Trainers
export const searchTrainers = (searchTerm, page_no = 1) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          // 'Content-Type': 'application/json',
          Authorization: "JWT " + token,
        },
      };
      const res = await axios.get(
        `${REACT_APP_API_URL}/api/organization/trainers?search=${searchTerm}&page=${page_no}&includeSelf=false`,
        config
      );
      if (res.data) {
        dispatch({
          type: SEARCH_TRAINERS,
          payload: res.data.results,
        });
      } else {
      }
    };
  } catch (err) {

  }
};

export const fetchTrainers = async (searchTerm, page_no = 1) => {
  try {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        Authorization: "JWT " + token,
      },
    };
    const res = await axios.get(
      `${REACT_APP_API_URL}/api/organization/trainers?search=${searchTerm}&page=${page_no}&includeSelf=true`,
      config
    );
    if (res.data) {
      return res.data.results.map(trainer => ({
        label: `${trainer.user_account.first_name} ${trainer.user_account.last_name}`,
        value: trainer.user_account.id.toString(),
      }));
    }
    return []; // Return an empty array if no data
  } catch (err) {
    return []; // Return an empty array in case of error
  }
};

  export const updateTrainer = (id, data) => async (dispatch) => {
    const token = await localStorage.getItem("access");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "JWT " + token,
      },
    };
    const body = JSON.stringify(data);
   
    try {
      const res = await axios.patch(
        `${REACT_APP_API_URL}/api/updateaccount/${id}/`,
        body,
        config
      );
  
      if (res.data) {
        dispatch({
          type: UPDATE_TRAINER_PROFILE,
          payload: res.data,
        });
      }
    } catch (err) {
    }
  };

// Delete trainer
export const deleteTrainer = (id) => async (dispatch) => {
  const token = await localStorage.getItem("access");
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "JWT " + token,
    },
  };

  try {
    const res = await axios.delete(
      `${REACT_APP_API_URL}/api/organization/trainers/${id}/`,
      config
    );
    dispatch({
      type: DELETE_TRAINER,
      payload: {res, id},
    });
    return res;
  } catch (err) {

  }
};

// Get single trainer
export const getSingleTrainer = (id) => {
  try {
    return async (dispatch) => {
      const token = await localStorage.getItem("access");
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: "JWT " + token,
        },
      };

      try {
        const res = await axios.get(
          `${REACT_APP_API_URL}/api/organization/trainers/${id}/`,
          config
        );
        if (res.data) {
          dispatch({
            type: GET_SINGLE_TRAINER,
            payload: res.data,
          });
        }
      } catch (err) {
        dispatch({
          type: GET_SINGLE_TRAINER_FAILED,
        });
      }
    };
  } catch (err) {

  }
};

export const resetTrainer = () => ({
  type: RESET_TRAINER,
});

export const resetTrainerStatus = () => ({
  type: "RESET_TRAINER_STATUS",
});

