import { useState, useEffect } from "react";
import { Button, Col, Grid, Modal, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";

import TrainerGroupList from "../components/TrainerGroupList";
import { getSingleTrainerGroup } from "../redux/actions/trainer_groups";
import {
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
const TrainerGroupDetails = ({ route, navigation }) => {
  const dispatch = useDispatch();
  const [isFadedIn, setIsFadedIn] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const { id } = useParams();
  useEffect(() => {
    dispatch(getSingleTrainerGroup(id));
    setTimeout(() => setLoading(false), 1500);
  }, []);

  useEffect(() => {
    setIsFadedIn(true);
  }, []);
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  return (
    <Row style={{ minHeight: "400vh", marginRight: '15vw' }}>
      <Col >
        <TrainerGroupList/>
      </Col>
    </Row>
  );
};

export default TrainerGroupDetails;
