import { useState, useEffect, useRef } from "react";
import { Button, Col, Grid, Modal, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getSingleClient } from "../redux/actions/clients";
import {
  getSingleAssessment,
  resetAssessment,
  resetAssessmentStatus,
} from "../redux/actions/assessments";

import { load_user } from "../redux/actions/auth";
import ResultsSections from "../components/results/ResultsSections";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import LottieResults from "../components/loaders/LoadingResults";

const { Text } = Typography;
const { confirm } = Modal;
const { Title } = Typography;

const Results = ({ route, navigation }) => {
  const [loading, setLoading] = useState(true);
  const [isFadedIn, setIsFadedIn] = useState(false);
  const [isRowLayout, setIsRowLayout] = useState(window.innerWidth >= 1000);
  const flexDirection = isRowLayout ? "row" : "column";
  const { id } = useParams();
  const { clientId } = useParams();
  const { client } = useSelector((state) => state.clients);

  var clientName = `${client.first_name} ${client.last_name}`;
  const { assessment, assessmentStatus } = useSelector(
    (state) => state.assessments
  );

  const intervalIDRef = useRef(null);
  const [freeChecked, setFreeChecked] = useState(false);
  const dispatch = useDispatch();
  const fetchAssessment = () => dispatch(getSingleAssessment(id, clientId));

  useEffect(() => {
    clientName = `${client.first_name} ${client.last_name}`;
  }, [client]);

  useEffect(() => {
    fetchAssessment();

    dispatch(getSingleClient(clientId));
    dispatch(resetAssessment());
  }, []);

  if (
    assessment.overallScore !== 0 &&
    assessment.overallScore !== null &&
    freeChecked === false
  ) {
    clearInterval(intervalIDRef.current);
    setFreeChecked(true);
  }

  if (assessment.free_record !== null) {
    if (
      assessment.free_record.id !== null &&
      assessment.free_record.video !== null &&
      freeChecked === false
    ) {
      clearInterval(intervalIDRef.current);
      setFreeChecked(true);
    }
  }

  if (assessment.overallScore === null) {
    if (
      assessment.free_record.id !== null &&
      assessment.free_record.video !== null &&
      intervalIDRef.current !== null
    ) {
      clearInterval(intervalIDRef.current);
    }
  }

  if (assessment.posture) {
    var posture = [
      Math.round(parseInt(assessment.posture.posture_overall_score)),
    ];
  }

  const navigateOption = () => {
    if (assessmentStatus === "success") {
      dispatch(load_user());
      dispatch(resetAssessmentStatus());
    }
    // else {
    navigation.navigate("Clients", {
      screen: "ClientDetailScreen",
      params: { params: { id: clientId } },
    });
    // }
  };

  useEffect(() => {
    if(assessment){
      setLoading(false)
    }
  }, [assessment]);

  useEffect(() => {
    setIsFadedIn(true);
  }, []);

  // Function to handle window resize event and update layout mode
  const handleWindowResize = () => {
    setIsRowLayout(window.innerWidth >= 996);
  };

  useEffect(() => {
    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  if (loading) {
    return null;
  }

  return (
    <Row
      style={{
        display: "block",
        flexDirection: "column",
        alignSelf: "center", // Center the content horizontally
        // justifyContent: "center", // Center the content vertically
        marginBottom: "2rem",

        position: "relative",
        top: "2vw",
        transform: "translateX(-50%)", // Center horizontally using transform
      }}
      className={`fade-in-slide-up-element ${
        isFadedIn ? "fade-in-slide-up-animation" : ""
      }`}
    >
      <ResultsSections
        navigateOption={navigateOption}
        assessment={assessment}
        clientName={clientName}
        loading={loading}
      />
    </Row>
  );
};

export default Results;
